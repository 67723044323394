import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnInit {
  @Input() identifier: string;
  @Input() options: Array<SelectedItemModel>;
  @Input() enableSelectAll = true;
  @Output() selectedOptionsChanged: EventEmitter<Array<number>> = new EventEmitter();
  public isAllChecked: boolean;
  public isIndeterminate: boolean;

  public ngOnInit() {
    // On active le SelectAll si la liste contient plus d'1 option
    this.enableSelectAll = this.enableSelectAll && this.options && this.options.length > 1;
    this.checkOptionsState();
  }

  public checkOptionsState() {
    if (!this.enableSelectAll) {
      return;
    }

    const selectedOptionsSize = this.options.filter(option => option.selected === true).length;

    this.isAllChecked = selectedOptionsSize === this.options.length;
    this.isIndeterminate = !this.isAllChecked && selectedOptionsSize !== 0;
  }

  public masterChange() {
    this.options.forEach(options => options.selected = !this.isAllChecked);
    this.onChange();
  }

  public onChange() {
    this.checkOptionsState();
    const selectedValues = this.options.filter(x => x.selected).map(x => x.value);
    // On émet la liste des élements sélectionnés
    this.selectedOptionsChanged.emit(selectedValues);
  }

  public trackByFunction(index: number, option: SelectedItemModel) {
    return option ? index : undefined;
  }

}

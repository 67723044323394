import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CriterionSemanticViewModel } from 'app/shared/models/advanced-search/criterion-semantic.model';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-criterion-semantic-custom-synonym',
  templateUrl: './criterion-semantic-custom-synonym.component.html',
  styleUrls: ['./criterion-semantic-custom-synonym.component.scss']
})
export class CriterionSemanticCustomSynonymComponent implements OnInit {
  @Input() identifier: string;
  @Input() isExpanded ?= true;
  @Input() criterionSemantic: CriterionSemanticViewModel;
  @Input() customSynonymToAdd: Observable<string>;
  @Input() customSynonymToRemove: Observable<string>;
  @Output() removedSynonym: EventEmitter<string> = new EventEmitter();
  @Output() addedSynonym: EventEmitter<string> = new EventEmitter();
  @Output() changeCustomSynonym: EventEmitter<CriterionSemanticViewModel> = new EventEmitter<CriterionSemanticViewModel>(null);

  public options: Array<SelectedItemModel>;
  public customSynonymValue: string;
  public loading = false;
  public removing = false;
  public customSynonymsToRemove: Array<string> = [];

  public ngOnInit() {
    this.loadOptions();
    this.customSynonymToAdd.subscribe(synonym => {
      // On ajoute le nouveau synonyme dans la liste des synonymes
      this.options.push(new SelectedItemModel(
        this.options.length,
        synonym,
        true
      ));
      this.loading = false;
    });

    // Suppression du synonyme
    this.customSynonymToRemove.subscribe(value => {
      // On récupère l'index et on supprime le synonyme depuis la liste des synonymes
      const removedSynonym = this.options.find(el => el.label === value);
      const removedSynonymIndex = this.options.indexOf(removedSynonym);
      if (removedSynonymIndex !== -1) {
        this.options.splice(removedSynonymIndex, 1);
      }

      // On récupère et on supprime le synonyme depuis la liste temporaire des synonymes à supprimer
      const index = this.customSynonymsToRemove.indexOf(value);
      if (index !== -1) {
        this.customSynonymsToRemove.splice(index, 1);
      }

      this.removing = false;
    });
  }

  private loadOptions() {
    this.options = this.criterionSemantic && this.criterionSemantic.options
      ? this.criterionSemantic.options.map((option, index) => ({ value: index, label: option.value, selected: option.selected }))
      : [];
  }

  public onChange() {
    const selectedValues = this.options.filter(x => x.selected).map(x => x.value);
    this.criterionSemantic.options.forEach((option, index) => option.selected = selectedValues.indexOf(index) >= 0);
    this.changeCustomSynonym.emit(this.criterionSemantic);
  }

  /**
   * Ajoute au click du bouton d'ajout d'un synonyme
   * personalisé le nouveau synonyme
   */
  public addCustomSynonym() {
    // On évite l'ajout de synonyme vide ou dupliqué
    if (this.customSynonymValue === undefined
      || this.customSynonymValue.trim() === ''
      || this.options.find(option => option.label === this.customSynonymValue)) {
      return;
    }
    this.loading = true;
    // On emet un event pour le refined criterion component
    this.addedSynonym.emit(this.customSynonymValue);
    // On réinitialise le contenu du textbox
    this.customSynonymValue = '';
  }

  /**
   * Supprime un synonyme personnalisé au click du bouton de suppression
   * @param value Valeur de l'option selectionnée
   */
  public removeCustomSynonym(label: string) {
    // On contrôle la suppression multiple des synonymes personnalisés
    if (!this.removing) {
      this.customSynonymsToRemove.push(label);
      // On emet un event pour le refined criterion component
      this.removedSynonym.emit(label);
      // On réinitialise le contenu de la textbox
      this.customSynonymValue = '';
    }
    this.removing = true;
  }
}

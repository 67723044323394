import { CriterionCategory } from 'common-services';

export class RefinedCriterionHelper {

  public static getCriterionIcon(criterionCategory: CriterionCategory): string {
    switch (criterionCategory) {
      case CriterionCategory.Fulltext:
        return 'search';
      case CriterionCategory.ComputerSkills:
        return 'control_point';
      case CriterionCategory.LastJob:
      case CriterionCategory.Job:
        return 'vpn_key';
      case CriterionCategory.ExperienceLevel:
        return 'card_travel';
      case CriterionCategory.HighestStudyLevel:
      case CriterionCategory.InstituteName:
      case CriterionCategory.Diploma:
        return 'school';
      case CriterionCategory.City:
        return 'location_city';
      case CriterionCategory.Region:
        return 'place';
      case CriterionCategory.Chip:
        return 'panorama_fish_eye';
      case CriterionCategory.LanguageSkills:
        return 'translate';
      case CriterionCategory.LastActionFollowUp:
      case CriterionCategory.LastActionUser:
        return 'done';
      case CriterionCategory.ActionsFollowUp:
      case CriterionCategory.ActionFollowUpComment:
      case CriterionCategory.ActionUser:
        return 'done_all';
      case CriterionCategory.TagCloudsOthers:
        return 'info';
      case CriterionCategory.Deprecated:
        return 'warning_amber';
      default:
        return 'location_searching';
    }
  }
}

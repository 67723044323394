<div class="blocCriteria">
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LastActionFollowUp)">
    <div class="form-group">
      <app-select-group [identifier]="'criteria_group_last_status_last_step_select'"
                        class="form-control-select-custom"
                        [groups]="lastActionFollowUpTypesHeaders"
                        [emptyOption]="emptyOptionLabel"
                        [isEmptyOptionHasValue]="true"
                        [resetAtDefault]="true"
                        [label]="lastStepLabel"
                        (selectedOptionsChanged)="onLastActionFollowUpTypeChange($event)">
      </app-select-group>
    </div>
    <app-criteria-list [criterionCategory]="criterionCategory.LastActionFollowUp"></app-criteria-list>
  </ng-container>
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LastActionUser)">
    <form>
      <div class="mat-form-field-container form-group">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label for="lastActionResponsibleSearch">{{ 'actionResponsible' | translate  }}</mat-label>
          <input id="criteria_group_last_status_responsible_select"
                 matInput
                 type="text"
                 name="responsibleSearch"
                 placeholder="{{ 'keywordEllipsis' | translate  }}"
                 [formControl]="responsibleControl"
                 [matAutocomplete]="autoCompleteResponsible" />
          <mat-autocomplete #autoCompleteResponsible="matAutocomplete"
                            [displayWith]="individualManager.responsibleDisplayFunction"
                            (optionSelected)="addLastActionResponsibleCriterion($event.option.value)">
            <mat-option id="criteria_group_last_status_responsible_select_{{i}}" *ngFor="let user of responsibleCompletionOptions;index as i;"
                        [value]="user" class="h-auto py-2">
              {{individualManager.responsibleDisplayFunction(user)}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <app-criteria-list [criterionCategory]="criterionCategory.LastActionUser"></app-criteria-list>
    </form>
  </ng-container>
</div>

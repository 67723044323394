import { NgModule } from '@angular/core';
import { SavedRequestCriterionComponent } from './saved-request-criterion/saved-request-criterion.component';
import { SavedRequestResumeComponent } from './saved-request-resume/saved-request-resume.component';
import { SavedRequestComponent } from './saved-request/saved-request.component';
import { SavedRequestsListComponent } from './saved-requests-list/saved-requests-list.component';
import { CandidateModule } from 'app/business-components/candidate/candidate.module';
import { MatExpansionModule } from '@angular/material/expansion';

// Ajoutez vos components ici
const components = [
  SavedRequestComponent,
  SavedRequestResumeComponent,
  SavedRequestCriterionComponent,
  SavedRequestsListComponent
];

// Ajoutez vos modules ici
const modules = [
  CandidateModule,
  MatExpansionModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components
  ]
})
export class SavedRequestsModule { }

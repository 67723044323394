<div class="mat-form-field-container form-group">
  <div class="form-group form-control-select-custom blocCriteria">
    <app-select class="form-control-select-custom"
                [identifier]="'criteria_group_tag_cloud_category_select'"
                [options]="dynamicFieldsLists"
                (selectedOptionsChanged)="onTagCloudsCategoryChange($event)"
                [emptyOption]="emptyOptionLabel"
                [isEmptyOptionHasValue]="true"
                [label]="dynamicFieldListsLabel">
    </app-select>
  </div>

  <div class="form-group form-control-select-custom blocCriteria">
    <app-select *ngIf="dynamicFieldPredefinedValues"
                class="form-control-select-custom"
                [identifier]="'criteria_group_tag_cloud_select'"
                [options]="dynamicFieldPredefinedValues"
                (selectedOptionsChanged)="onPredefinedValueChanged($event)"
                [emptyOption]="emptyOptionLabel"
                [isEmptyOptionHasValue]="true"
                [resetAtDefault]="true"
                [label]="dynamicFieldLabel">
    </app-select>
  </div>

  <div class="form-group form-control-select-custom blocCriteria">
    <app-select *ngIf="dynamicFieldsBooleanLists"
                class="form-control-select-custom"
                [identifier]="'criteria_group_boolean_tag_cloud_select'"
                [options]="dynamicFieldsBooleanLists"
                (selectedOptionsChanged)="onTagCloudsBooleanCategoryChanged($event)"
                [emptyOption]="emptyOptionLabel"
                [isEmptyOptionHasValue]="true"
                [resetAtDefault]="true"
                [label]="dynamicFieldBooleanListsLabel">
    </app-select>
  </div>

  <div class="form-group blocCriteria">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ 'tagCloudFullText' | translate  }}</mat-label>
      <input id="criteria_menu_tag_cloud_full_text_input" matInput [formControl]="tagCloudsFormControl" [matAutocomplete]="autoCompleteClient"
        (keyup.enter)="search($event.target.value)" placeholder="{{ 'searchedTerm' | translate  }}">
      <mat-spinner *ngIf="isLoading" class="inputSpinner" diameter="20"></mat-spinner>
      <mat-autocomplete #autoCompleteClient="matAutocomplete" (optionSelected)="search($event.option.value)">
        <mat-option id="criteria_menu_tag_cloud_full_text_input_{{ i }}" *ngFor="let tagCloud of filteredTagCloudsObservable | async;index as i;" [value]="tagCloud" class="h-auto py-2">
          <span [innerHTML]="tagCloud | strongify:tagCloudsFormControl.value"></span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
<app-criteria-list [criterionCategory]="criterionCategory.TagCloudsOthers"></app-criteria-list>

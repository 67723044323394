import { KeyValue } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription;

  @Input() identifier: string; // Identifiant unique du composant
  @Input() class: string;
  @Input() options: Array<KeyValue<string | number, string>>; // Les données pour remplir le select
  @Input() selectedOption: any; // Définie la valeur par défaut
  @Input() emptyOption: string; // Définie l'option vide (Ex: choisir, aucune ... ou simplement une chaine vide)
  @Input() isEmptyOptionHasValue: boolean; // Autorise la sélection de l'option vide
  @Input() label: string; // Définie un label pour le select
  @Input() required = false; // Rend le champ obligatoire
  @Input() multiple = false; // Active le choix multiple
  @Input() resetAtDefault: boolean; // Indique si l'on doit revenir à la valeur par défaut une fois une sélection faite
  @Output() selectedOptionsChanged: EventEmitter<any> = new EventEmitter(); // Renvoie la/les option(s) sélectionnée(s)

  public selectControl = new FormControl();
  public isLoading = true;

  @Input()
  public get isDisabled() {
    return this.selectControl?.disabled;
  }

  // Méthod pour activer ou désactiver le composant
  public set isDisabled(disabled: boolean) {
    if (disabled) {
      this.selectControl?.disable();
    } else {
      this.selectControl?.enable();
    }
  }

  public get emptyOptionValue(): null | string {
    if (this.isEmptyOptionHasValue) {
      return '';
    }

    return null;
  }

  ngOnInit() {
    // Initialiser la valeur par défaut
    this.writeValue(this.selectedOption);
    // Ajout de validateur s'il existe
    this.selectControl.setValidators((this.required) ? [Validators.required] : null);

    this.subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onToggle(filterVal: any) {
    // On émet l'élement sélectionné
    if (!isNaN(+filterVal)) {
      filterVal = +filterVal;
    }

    this.selectedOptionsChanged.emit(filterVal);

    // Remet à sa valeur par défaut une fois le choix effectué
    if (this.resetAtDefault) {
      this.writeValue(this.emptyOptionValue);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // On change l'état du spinner suite au résultat du callback
    if (changes?.options?.currentValue !== undefined || this.isDisabled) {
      this.isLoading = false;
      if (this.selectedOption) {
        this.writeValue(this.selectedOption);
      }
    }
  }

  // Implémentation des méthodes de l'interface ControlValueAccess
  public onTouched: any = () => { };
  writeValue(value: any): void {
    if (value) {
      this.selectControl.setValue(value);
    } else {
      this.selectControl.reset(this.emptyOptionValue);
    }
  }
  registerOnChange(fn: any): void {
    const selectControlSubscription = this.selectControl.valueChanges.subscribe(fn);
    this.subscriptions.add(selectControlSubscription);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  validate(c: AbstractControl): boolean {
    return this.selectControl.valid;
  }
}

import { Component } from '@angular/core';
import { ListComponent } from '../lists/list/list.component';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent extends ListComponent {
}

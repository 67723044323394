import { NgModule } from '@angular/core';
import { GenericComponentsModule } from 'app/generic-components/generic-components.module';
import { AngularMaterialsModule } from 'app/shared/angular-materials.module';
import { SearchPositionComponent } from './search-position/search-position.component';

// Ajoutez vos components ici
const components = [
  SearchPositionComponent
];

// Ajoutez vos components ouverts par dialog ici
const dialogComponents = [
  SearchPositionComponent
];

// Ajoutez vos modules ici
const modules = [
  GenericComponentsModule,
  AngularMaterialsModule
];

@NgModule({
  entryComponents: [
    ...dialogComponents
  ],
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class PositionModule { }

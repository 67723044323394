import { Component, Input, OnInit } from '@angular/core';
import { SendMailComponent } from 'app/business-components/candidate/send-mail/send-mail.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { LetterModelsType } from 'app/shared/enums/letter-models-type.enum';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { CandidateModel } from 'common-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidate-summary',
  templateUrl: './candidate-summary.component.html',
  styleUrls: ['./candidate-summary.component.scss']
})
export class CandidateSummaryComponent implements OnInit {
  @Input() candidateObservable: Observable<CandidateModel>;
  public candidate: CandidateModel;
  constructor(
    private readonly dialogsManager: DialogsManager,
  ) { }

  ngOnInit(): void {
    this.candidateObservable.subscribe((candidate) => {
      this.candidate = candidate;
    }
    );
  }

  public openSendMailForm() {
    // On spécifie les données à envoyer à la popup d'envoi de mail
    const data = {
      candidates: [this.candidate],
      elementType: LetterModelsType.CANDIDAT
    };
    // On ouvre la popup
    this.dialogsManager.openDialog<SendMailComponent>({ dialog: Dialogs.SendMail, closeClickOutside: false, data });
  }
}

<button id="actions_candidate_button" *ngIf="multipleActions; else elseBlock" mat-raised-button [matMenuTriggerFor]="actionMenu" color="primary"
        [disabled]="isDisabled">
  <mat-icon class="mr-2">more_horiz</mat-icon>
  <span>{{ 'actions' | translate }}</span>
  <span *ngIf="isCandidatesCountVisible"> ({{ selectedCandidatesIds.length }})</span>
</button>
<ng-template #elseBlock>
  <button mat-icon-button [matMenuTriggerFor]="actionMenu" color="primary" [disabled]="isDisabled" class="ml-3" (click)="openCandidateActionsPopUp($event)">
    <mat-icon class="mr-2">more_horiz</mat-icon>
  </button>
</ng-template>
<mat-menu #actionMenu="matMenu">
  <button id="actions_candidate_button_menu_add_action_or_comment" mat-menu-item (click)="addActionFollowUp()">
    <mat-icon>add_comment</mat-icon>
    <span>{{ 'addActionsOrComments' | translate }}</span>
  </button>
  <button id="actions_candidate_button_menu_attach_to_position" mat-menu-item (click)="attachPosition()">
    <mat-icon>queue</mat-icon>
    <span>{{ 'attachToPosition' | translate }}</span>
  </button>
  <button id="actions_candidate_button_menu_send_mail" mat-menu-item (click)="sendMail()">
    <mat-icon>mail_outline</mat-icon>
    <span>{{ 'sendMail' | translate }}</span>
  </button>
  <button *ngIf="isMergeCandidateOptionEnabled" id="actions_candidate_button_merge" mat-menu-item (click)="mergeCandidates()">
    <mat-icon>merge_type</mat-icon>
    <span>{{ 'merge' | translate }}</span>
  </button>
  <!--
  <button id="actions_candidate_button_menu_delete" mat-menu-item (click)="deleteCandidates()">
    <mat-icon>delete</mat-icon>
    <span>{{ 'delete' | translate }}</span>
  </button>
  -->
</mat-menu>

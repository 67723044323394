import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MomentUtcDateAdapter } from 'app/shared/adapters/moment-utc-date.adapter';

@Pipe({
  name: 'toLocalDate',
  pure: false
})
export class ToLocalDatePipe implements PipeTransform {

  constructor(
    private readonly momentUtcDateAdapter: MomentUtcDateAdapter,
    private readonly datePipe: DatePipe
  ) { }

  /**
   * Transformer la date UTC+0 en date locale
   * @param value La date à convertir
   * @param format Le format de date souhaité
   * @param timezone Le fuseau horaire souhaité
   * @param locale le code locale du format de date (Par exemple 'fr-FR' ou 'en-US')
   */
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {

    let date = new Date(value);

    // Si la valeur donnée (value) n'est pas convertible en date valide on renvoie la valeur
    if (isNaN(date.getTime())) {
      return value;
    }

    // On calcule la différence en minutes entre le fuseau horaire UTC, et celui de l'heure locale.
    const difference = - (new Date().getTimezoneOffset());

    date = new Date(date.getTime() + difference * 60000);

    //  Si le format d'affichage de la date n'est pas spécifié on récupère le format par défaut du navigateur
    if (format === 'short') {
      format = this.momentUtcDateAdapter.parseFormat('short').split('Y').join('y').split('D').join('d');
    } else {
      format = format ?? this.momentUtcDateAdapter.parseFormat().split('Y').join('y').split('D').join('d');
    }

    return this.datePipe.transform(date.getTime(), format, timezone, locale);
  }
}

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory, LanguageService } from 'common-services';

@Component({
  selector: 'app-criteria-group-language',
  templateUrl: './criteria-group-language.component.html'
})
export class CriteriaGroupLanguagesComponent implements OnInit {

  public label: string;
  public emptyOptionLabel: string;
  public criterionCategory = CriterionCategory;
  public candidatesLanguages: Array<KeyValue<number, string>>;

  constructor(
    private readonly languageService: LanguageService,
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService
  ) { }

  public ngOnInit() {
    this.storeLevelLanguages();
    this.languageService.getCandidatesLanguages().then(data => {
      localStorage.setItem('languages', JSON.stringify(data));
      this.candidatesLanguages = data.map(candidateLanguage => ({ key: candidateLanguage.id, value: candidateLanguage.label }));
    });

    this.translateService.get(['language', 'chooseOption']).subscribe(translations => {
      this.label = translations.language;
      this.emptyOptionLabel = translations.chooseOption;
    });
  }

  public onLanguageChange(selectedLanguage: number) {
    const selected = this.candidatesLanguages.find(a => a.key === selectedLanguage);
    if (selected && selected.key > 0) {
      // On ajoute le critère à la liste des critères raffinés du state
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.LanguageSkills,
        selected.key.toString(),
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
  }

  private storeLevelLanguages() {
    this.languageService.getLanguageLevels().then(levels => {
      localStorage.setItem('languageLevels', JSON.stringify(levels));
    });
  }
}

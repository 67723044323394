import { Component, Input, OnInit } from '@angular/core';
import { SendMailComponent } from 'app/business-components/candidate/send-mail/send-mail.component';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { DisplayMode } from 'app/shared/enums/display-mode.enum';
import { LetterModelsType } from 'app/shared/enums/letter-models-type.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { PRCandidateModel } from 'app/shared/models/candidate/candidate.model';
import { AttachmentModel, CandidatureModel, CandidatureService } from 'common-services';

@Component({
  selector: 'app-candidate-resume',
  templateUrl: './candidate-resume.component.html',
  styleUrls: ['./candidate-resume.component.scss']
})

export class CandidateResumeComponent implements OnInit {

  @Input() candidateModel: PRCandidateModel;

  public lastCandidateCandidature: CandidatureModel;
  public attachments: Array<AttachmentModel> = [];
  public displayMode = DisplayMode;

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly candidatureService: CandidatureService,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) { }

  ngOnInit(): void {
    if (this.candidateModel.visibility) {
      this.candidatureService.getLastCandidatureOfCandidateWithSubObjects(this.candidateModel.id)
        .then((candidature: CandidatureModel) => {
          this.lastCandidateCandidature = candidature;
          this.candidatureService.getCandidatureAttachmentsByCandidatureId(candidature.id).then(candidatureAttachments => {
            if (candidatureAttachments) {
              candidatureAttachments.slice(0, 3).forEach(ca => {
                this.candidatureService.getCandidatureAttachmentById(ca.candidatureId, ca.attachmentId).then(attachment => {
                  this.attachments.push(attachment);
                });
              });
            }
          });
        });
    }
  }

  public get isSelected(): boolean {
    const candidatesIds = this.advancedSearchManager.advancedSearchResponseState?.selectedCandidates?.map(c => c.id);
    return candidatesIds?.includes(this.candidateModel.id);
  }

  public get enablePreview(): boolean {
    return !this.dialogsManager.isDialogOpen(Dialogs.CandidateForm) && !this.dialogsManager.isDialogOpen(Dialogs.GeolocateCandidates);
  }

  public openCandidateForm(event: Event) {
    if (!this.candidateModel.visibility) {
      return;
    }
    this.dialogsManager.openDialog<CandidateContainerComponent>(
      { dialog: Dialogs.CandidateForm, closeClickOutside: true, data: this.candidateModel.id }).currentCandidate = this.candidateModel;
    event.stopPropagation();
  }

  public openSendMailForm(event: Event) {
    if (!this.candidateModel.visibility) {
      return;
    }
    event.stopPropagation();
    // On spécifie les données à envoyer à la popup d'envoi de mail
    const data = {
      candidates: [this.candidateModel],
      elementType: LetterModelsType.CANDIDAT
    };
    // On ouvre la popup
    this.dialogsManager.openDialog<SendMailComponent>({ dialog: Dialogs.SendMail, closeClickOutside: false, data });
  }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory, EmployabilitiesService, StringHelper } from 'common-services';

@Component({
  selector: 'app-criteria-group-formation',
  templateUrl: './criteria-group-formation.component.html'
})
export class CriteriaGroupFormationsComponent implements OnInit {

  public criterionCategory = CriterionCategory;
  public _studyLevels: Array<SelectedItemModel>;
  public instituteNameValue: string;

  constructor(
    public readonly advancedSearchManager: AdvancedSearchManager,
    private readonly stringHelper: StringHelper,
    private readonly employabilitiesService: EmployabilitiesService,
    private readonly store: Store<AppState>
  ) { }

  ngOnInit() {
    this.employabilitiesService.getStudyLevels().then(data => {
      localStorage.setItem('studyLevels', JSON.stringify(data));
      this._studyLevels = data.map(studyLevel => new SelectedItemModel(studyLevel.id, studyLevel.label));
    });
  }

  public get studyLevels(): Array<SelectedItemModel> {
    if (this.advancedSearchManager.advancedSearchResponseState.dataState === StateStatus.LOADED) {
      const selectedItems = this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria
      .filter(c => c.category === CriterionCategory.HighestStudyLevel).map(c => c.value );

      // On met à jour la liste des options du select
      this._studyLevels = this._studyLevels?.map(
        studyLevel => {
          studyLevel.selected = selectedItems?.includes(studyLevel.value.toString());
          return studyLevel;
        }
      );
    }

    return this._studyLevels;
  }

  public onStudyLevelChange(checkedStudyLevels: Array<number>) {
    this.store.dispatch(resetSelectCandidates());
    // On ajoute les éléments sélectionnés à la liste des critères raffinés du manager
    this.advancedSearchManager.addRefinedCriteria(
      CriterionCategory.HighestStudyLevel,
      this._studyLevels
        .filter(checkBoxItem => checkedStudyLevels.indexOf(checkBoxItem.value) !== -1)
        .map(checkBoxItem => checkBoxItem.value.toString()),
      CriterionPriority.Optional
    );
  }

  /**
   * Ajoute le critère raffiné de l'établissement scolaire
   */
  public addInstitutionCriterion() {
    // On vérifie que la recherche ne soit pas nulle ou vide
    if (!this.stringHelper.isNullOrWhiteSpace(this.instituteNameValue)) {
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.InstituteName,
        this.instituteNameValue,
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
    // On vide l'input après ajout
    this.instituteNameValue = '';
  }
}

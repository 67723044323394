<div class="uploaderContainer">
  <h2>{{'addAttachmentFile' | translate }}</h2>
  <app-files-uploader 
    [showQueueFilesCount]="false"
    [deleteItemsFromQueue]="false"
    [showQueue] = false
    (uploadedFiles)="uploadNewFiles()"
    [candidateId]="candidateIdObservable"
    [translation]="fileUploaderTranslation">
  </app-files-uploader>
  <h2 class="mt-4">{{'listAttachmentFiles' | translate }}</h2>
</div>
<div class="wrapperContent wrapperContentPj">
  <div class="scroll-wrapper">
    <div class="scroll grey with-padding">
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      <mat-table *ngIf="attachmentFilesDataSource.data.length > 0; else noAttachementsBlock"
        [dataSource]="attachmentFilesDataSource" class="mat-elevation-z8 candidate-attachments">
        <ng-container matColumnDef="attachmentFileTypeId">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="coltext colAttachmeTypeFile">
            {{'attachmentType' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let attachment" class="coltext colAttachmeTypeFile">
            <app-select [identifier]="'candidate_attachement_file_type_select'"
                        (selectedOptionsChanged)="onAttachmentTypeChange($event, attachment)"
                        [options]="attachmentFileTypes"
                        [selectedOption]="attachment.attachmentTypeId"
                        [class]="'noEmptyOption'"
                        class="formFieldSelect">
            </app-select>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="coltext colLabel">
            {{'label' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let attachment" class="coltext colLabel">
            <div class="truncated"
              matTooltip="{{attachment?.label}}">
              <strong>{{attachment?.label}}</strong>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="coltext">
            {{'attachmentFileName' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let attachment" class="coltext colFileName">
            <app-attached-file [preview]="false" [attachment]="attachment"></app-attached-file>
            <div class="truncated" matTooltip="{{attachment.fileName}}">
              {{attachment.fileName}}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="receiptDate">
          <mat-header-cell *matHeaderCellDef class="coltext colReceiptDate">
            {{'attachmentDate' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let attachment" class="coltext colReceiptDate">
            <div matTooltip="{{ attachment.receiptDate }}">
              {{ attachment.receiptDate | toLocalDate:'short' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete" stickyEnd>
          <mat-header-cell *matHeaderCellDef class="colicon colDel">
            <mat-icon matTooltip="{{'delete' | translate }}" class="d-none">delete</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let attachment" class="colicon colDel">
            <button mat-icon-button class="deleteButton" matTooltip="{{'delete' | translate }}"
              (click)="deleteCandidateAttachmentFile(attachment)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
      </mat-table>
      <ng-template #noAttachementsBlock>
        <mat-card style="display: flex; justify-content: center; align-items: center">
          {{'noAttachmentFiles' | translate }}
        </mat-card>
      </ng-template>
      <app-pagination [ngClass]="{'hidden': !(attachmentFilesDataSource.data.length > 0)}"
        #pagination
        [length]="attachmentFilesDataSource.data.length"
        [pageSize]="5"
        [showFirstLastButtons]="true"
        [displayInput]="false"
      ></app-pagination>
      </div>
    </div>
</div>

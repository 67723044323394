import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Dialogs } from '../enums/dialogs-enum';
import { DialogHelper } from '../helpers/dialog.helper';
import { DialogsManager } from '../managers/dialogs.manager';

@Injectable({
  providedIn: 'root'
})
export class DialogBehaviorHandler {
  public constructor(
    protected readonly dialog: MatDialog,
    protected readonly dialogHelper: DialogHelper
  ) { }

  public handle<T>(dialogManager: DialogsManager, dialog: Dialogs, dialogConfig: MatDialogConfig): MatDialogRef<T> {
    let dialogRef: MatDialogRef<T>;
    switch (dialog) {
      case Dialogs.AddCandidateForm:
        this.dialog.closeAll();
        dialogRef = this.open(dialogManager, dialog, dialogConfig);
        dialogRef.disableClose = true;
        break;
      case Dialogs.GeolocateCandidates:
        if (dialogManager.isDialogOpen(Dialogs.CandidateForm)) {
          dialogConfig.position = { top: '0', left: '0' };
        } else {
          dialogConfig.position = { top: '0', right: '0' };
        }
        dialogRef = this.open(dialogManager, dialog, dialogConfig);
        this.handleGeolocateCandidatesDialogBehavior(dialogManager, dialogRef);
        break;
      case Dialogs.CandidateForm:
        dialogRef = this.open(dialogManager, dialog, dialogConfig);
        this.handleCandidateFormDialogBehavior(dialogManager, dialogRef);
        break;
      case Dialogs.Attachment:
        if (dialogManager.isDialogOpen(Dialogs.CandidateForm) || dialogManager.isDialogOpen(Dialogs.GeolocateCandidates)) {
          dialogConfig.position = { top: '0', left: '0' };
        } else {
          dialogConfig.position = { top: '0', right: '0' };
        }
        dialogRef = this.open(dialogManager, dialog, dialogConfig);
        break;
      default:
        dialogRef = this.open(dialogManager, dialog, dialogConfig);
        break;
    }
    return dialogRef;
  }

  private open<T>(dialogManager: DialogsManager, dialog: Dialogs, dialogConfig: MatDialogConfig): MatDialogRef<T> {
    const dialogComponentType = dialogManager.getDialogComponentType(dialog);
    return this.dialog.open(dialogComponentType, dialogConfig);
  }

  private handleCandidateFormDialogBehavior(dialogManager: DialogsManager, dialogRef: MatDialogRef<any>): void {
    const candidateFormDialogAfterOpenedObservable = dialogRef.afterOpened();
    const candidateFormDialogAfterClosedObservable = dialogRef.afterClosed();
    // Comportement du dialogue CandidateForm après sa ouverture
    candidateFormDialogAfterOpenedObservable
      .subscribe(() => {
        const isAttachmentDialogOpen = dialogManager.isDialogOpen(Dialogs.Attachment);
        const isGeolocateCandidatesOpen = dialogManager.isDialogOpen(Dialogs.GeolocateCandidates);
        const attachmentDialogRef = dialogManager.getDialogRef(Dialogs.Attachment);
        const geolocateCandidatesDialogRef = dialogManager.getDialogRef(Dialogs.GeolocateCandidates);
        if (isGeolocateCandidatesOpen) {
          geolocateCandidatesDialogRef.updatePosition({ top: '0', left: '0' });
          if (isAttachmentDialogOpen) {
            dialogManager.closeDialog(attachmentDialogRef);
          }
        } else if (isAttachmentDialogOpen) {
          const attachmentDialogData = attachmentDialogRef._containerInstance._config.data;
          const candidateFormDialogData = dialogRef._containerInstance._config.data;
          if (attachmentDialogData && candidateFormDialogData && candidateFormDialogData === attachmentDialogData) {
            attachmentDialogRef.updatePosition({ top: '0', left: '0' });
          } else {
            dialogManager.closeDialog(attachmentDialogRef);
          }
        }
      });

    // Comportement du dialogue CandidateForm après sa fermeture
    candidateFormDialogAfterClosedObservable
      .subscribe(() => {
        if (dialogManager.isDialogOpen(Dialogs.GeolocateCandidates)) {
          const geolocateCandidatesDialogRef = dialogManager.getDialogRef(Dialogs.GeolocateCandidates);
          geolocateCandidatesDialogRef.updatePosition({ top: '0', right: '0' });
        } else if (dialogManager.isDialogOpen(Dialogs.Attachment)) {
          const attachmentDialogRef = dialogManager.getDialogRef(Dialogs.Attachment);
          attachmentDialogRef.updatePosition({ top: '0', right: '0' });
        }
      });
  }

  private handleGeolocateCandidatesDialogBehavior(dialogManager: DialogsManager, dialogRef: MatDialogRef<any>): void {
    const geolocateCandidatesDialogAfterOpenedObservable = dialogRef.afterOpened();
    const geolocateCandidatesDialogAfterClosedObservable = dialogRef.afterClosed();
    // Comportement du dialogue GeolocateCandidates après sa ouverture
    geolocateCandidatesDialogAfterOpenedObservable.subscribe(() => {
      if (dialogManager.isDialogOpen(Dialogs.Attachment)) {
        const attachmentDialogRef = dialogManager.getDialogRef(Dialogs.Attachment);
        dialogManager.closeDialog(attachmentDialogRef);
      }
    });

    // Comportement du dialogue GeolocateCandidates après sa fermeture
    geolocateCandidatesDialogAfterClosedObservable.subscribe(() => {
      const isAttachmentDialogOpen = dialogManager.isDialogOpen(Dialogs.Attachment);
      const attachmentDialogRef = dialogManager.getDialogRef(Dialogs.Attachment);
      if (isAttachmentDialogOpen) {
        attachmentDialogRef.updatePosition({ top: '0', right: '0' });
      }
    });
  }
}

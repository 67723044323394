import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { CandidateFormModule } from './candidate-form/candidate-form.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

// Ajoutez vos modules ici
const modules = [
  AdvancedSearchModule,
  CandidateFormModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule
];

const components = [
  LoginComponent,
  HomeComponent
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ],
  declarations: [
    ...components
  ]
})
export class PagesModule { }

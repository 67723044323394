import { KeyValue } from '@angular/common';
import { CriteriaGroup } from 'app/shared/enums/criteria-group.enum';
import { CriterionCategory } from 'common-services';

export class CriteriaGroupModel {
  public static readonly defaultCriteriaGroups: ReadonlyArray<Readonly<KeyValue<CriteriaGroup, CriteriaGroupModel>>> = [
    {
      key: CriteriaGroup.Function,
      value: {
        group: CriteriaGroup.Function,
        categories: [CriterionCategory.LastJob, CriterionCategory.Job],
        title: 'jobTitle',
        icon: 'vpn_key'
      }
    },
    {
      key: CriteriaGroup.Region,
      value: {
        group: CriteriaGroup.Region,
        categories: [CriterionCategory.Region],
        title: 'region',
        icon: 'place'
      }
    },
    {
      key: CriteriaGroup.City,
      value: {
        group: CriteriaGroup.City,
        categories: [CriterionCategory.City],
        title: 'city',
        icon: 'location_city'
      }
    },
    {
      key: CriteriaGroup.LastStatus,
      value: {
        group: CriteriaGroup.LastStatus,
        categories: [CriterionCategory.LastActionFollowUp, CriterionCategory.LastActionUser],
        title: 'lastStep',
        icon: 'done'
      }
    },
    {
      key: CriteriaGroup.Status,
      value: {
        group: CriteriaGroup.Status,
        categories: [CriterionCategory.ActionsFollowUp, CriterionCategory.ActionFollowUpComment, CriterionCategory.ActionUser],
        title: 'step',
        icon: 'done_all'
      }
    },
    {
      key: CriteriaGroup.Chip,
      value: {
        group: CriteriaGroup.Chip,
        categories: [CriterionCategory.Chip],
        title: 'chip',
        icon: 'panorama_fish_eye'
      }
    },
    {
      key: CriteriaGroup.Language,
      value: {
        group: CriteriaGroup.Language,
        categories: [CriterionCategory.LanguageSkills],
        title: 'language',
        icon: 'translate'
      }
    },
    {
      key: CriteriaGroup.Skill,
      value: {
        group: CriteriaGroup.Skill,
        categories: [CriterionCategory.ComputerSkills],
        title: 'computerSkill',
        icon: 'control_point'
      }
    },
    {
      key: CriteriaGroup.Formation,
      value: {
        group: CriteriaGroup.Formation,
        categories: [CriterionCategory.HighestStudyLevel, CriterionCategory.Diploma, CriterionCategory.InstituteName],
        title: 'education',
        icon: 'school'
      }
    },
    {
      key: CriteriaGroup.Experience,
      value: {
        group: CriteriaGroup.Experience,
        categories: [CriterionCategory.ExperienceLevel],
        title: 'experience',
        icon: 'card_travel',
      }
    },
    {
      key: CriteriaGroup.TagCloudsOthers,
      value: {
        group: CriteriaGroup.TagCloudsOthers,
        categories: [CriterionCategory.TagCloudsOthers],
        title: 'additionalInfos',
        icon: 'info'
      }
    }
  ];

  public group: CriteriaGroup;
  public categories: Array<CriterionCategory>;
  public title: string;
  public icon: string;
}

<div>
  <div *ngIf="enableSelectAll">
    <mat-checkbox class="masterChecker" [(checked)]="isAllChecked" (change)="masterChange()"
      [(indeterminate)]="isIndeterminate">
      {{ 'selectAll' | translate  }}
    </mat-checkbox>
    <mat-divider></mat-divider>
  </div>
  <div class="form-check" *ngFor="let option of options;index as i; trackBy: trackByFunction">
    <mat-checkbox id="{{ identifier }}_{{ i }}" [(ngModel)]="option.selected" (change)="onChange()">
      {{ option.label }}
    </mat-checkbox>
  </div>
</div>

import { NgModule } from '@angular/core';
import { GenericComponentsModule } from 'app/generic-components/generic-components.module';
import { CandidateDetailsModule } from './candidate-details/candidate-details.module';
import { CandidateModalModule } from './candidate-modal/candidate-modal.module';

const modules = [
  CandidateDetailsModule,
  CandidateModalModule,
  GenericComponentsModule
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ]
})
export class CandidateFormModule { }

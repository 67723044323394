import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { deleteCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory } from 'common-services';

@Component({
  selector: 'app-criteria-list',
  templateUrl: './criteria-list.component.html',
  styleUrls: ['./criteria-list.component.scss']
})
export class CriteriaListComponent {

  @Input() criterionCategory: CriterionCategory;

  public readonly criterionPriority = CriterionPriority;

  constructor(
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly store: Store<AppState>
  ) {}

  // Récupérer les critères
  public get criteria(): Array<RefinedCriterionViewModel>  {
    const result = this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse?.advancedSearch?.criteria?.filter(f => f.category === this.criterionCategory);
    return result?.map(c => this.advancedSearchManager.defineCriterionLabel(c));
  }

  public onClickDeleteCriterion(refinedcriterion: RefinedCriterionViewModel) {
    this.store.dispatch(resetSelectCandidates());
    this.store.dispatch(deleteCriterion({ payload: refinedcriterion }));
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class MomentUtcDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  public createDate(year: number, month: number, date: number): moment.Moment {
    return moment({ year, month, date }).locale(this.locale);
  }

  // Transforme la date (qui est en string) en moment
  public parse(value: string, format = ''): moment.Moment | undefined {
    if (!value || typeof value !== 'string') {
      return undefined;
    }

    format = format ?? this.parseFormat();

    return moment(value, format).locale(this.locale);
  }

  // Choisit le format de la date en 'L'
  public parseFormat(format = 'long'): string {
    moment.locale(this.locale);
    const localeData = moment.localeData();
    return (format === 'long') ? localeData.longDateFormat('L') : localeData.longDateFormat('l').replace('YYYY', 'YY');
  }

  // Formate la date (mat date picker) selon la langue utilisateur
  public format(date: moment.Moment): string {
    const parseFormat = this.parseFormat();
    return date.locale(this.locale).format(parseFormat);
  }

}

import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CkEditorHelper } from 'app/shared/helpers/ck-editor.helper';
import { ActionManager } from 'app/shared/managers/action.manager';
import { ActionFollowUpModel, ActionFollowUpService, PositionModel, PositionService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-action-follow-up',
  templateUrl: './action-follow-up.component.html',
  styleUrls: ['./action-follow-up.component.scss']
})
export class ActionFollowUpComponent implements OnInit, OnDestroy {

  private readonly actionFollowUpSavedCandidateIds: Subject<number[]>;
  private readonly candidatesIdsSubject: Subject<Array<number>>;
  private readonly positionsObservable: Observable<Array<PositionModel>>;

  // Paramètres de la popup
  private candidatesIds: Array<number>;
  private speechTempText = '';

  // Formulaire
  public readonly saveActionForm: FormGroup;

  public isSaving = false;
  public emptyOptionLabel: string;
  public labelPosition: string;
  public labelActionType: string;
  public actionTypesHeaderArray: Array<KeyValue<string, Array<KeyValue<number, string>>>>;
  public positionsArray: Array<KeyValue<string | number, string>>;
  public language: string;
  public translations: Object;

  private failedCandidateCount = 0;
  private readonly savedCandidateIds: Array<number> = [];

  public get positions(): Observable<Array<PositionModel>> {
    return this.positionsObservable;
  }

  public get isSingleCandidate(): boolean {
    return this.candidatesIds.length === 1;
  }

  public set candidateIds(ids: Array<number>) {
    this.candidatesIdsSubject.next(ids);
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackbarService: CustomSnackBarService,
    private readonly dialogRef: MatDialogRef<ActionFollowUpComponent>,
    private readonly translateService: TranslateService,
    private readonly positionService: PositionService,
    private readonly ckEditorHelper: CkEditorHelper,
    private readonly actionManager: ActionManager,
    private readonly actionFollowUpService: ActionFollowUpService
  ) {
    this.candidatesIdsSubject = new Subject<Array<number>>();
    this.candidatesIds = new Array<number>();
    this.actionFollowUpSavedCandidateIds = new Subject<number[]>();

    this.saveActionForm = this.formBuilder.group({
      date: new FormControl(moment(), [Validators.required]),
      actionTypeId: new FormControl('', [Validators.required]),
      positionId: new FormControl(''),
      comments: new FormControl('')
    });

    this.candidatesIdsSubject.subscribe(candidateIds => {
      if (!candidateIds || candidateIds.length === 0) {
        this.snackbarService.showSuccess(this.translations['noCandidateSelected']);
        this.close();
        return;
      } else if (candidateIds.length === 1) {
        this.positionService.getPositionsByCandidateId(candidateIds[0]).then(
          result => {
            this.positionsArray = result.map(position => ({ key: position.id, value: `${position.title} - ${position.reference}` }));
          }
        );
      }
      this.candidatesIds = candidateIds;
    });
    this.language = JSON.parse(localStorage.getItem('individual')).languageBU.code;
  }

  ngOnInit() {
    this.translateService.get(
      [
        'none',
        'forThePosition',
        'actionType',
        'noCandidateSelected',
        'saveCandidatesActionSucceed',
        'saveCandidatesActionFailed',
        'candidates'
      ]
    ).subscribe(translation => {
      this.translations = translation;
      this.emptyOptionLabel = translation['none'];
      this.labelPosition = translation['forThePosition'];
      this.labelActionType = translation['actionType'];
    });

    this.actionFollowUpService.getActionsTypeGroupByHeaders().then(result => {
      this.actionTypesHeaderArray = this.actionManager.getActionsTypeGroupByHeaders(result);
    });
  }

  ngOnDestroy(): void {
    if (this.candidatesIdsSubject) {
      this.candidatesIdsSubject.complete();
    }

    if (this.actionFollowUpSavedCandidateIds) {
      this.actionFollowUpSavedCandidateIds.complete();
    }
  }

  public get actionFollowUpSavedCandidateIdsObservable(): Observable<number[]> {
    return this.actionFollowUpSavedCandidateIds;
  }

  /** Poste la sauvegarde des suivis d'actions au serveur */
  public async saveActions() {
    this.isSaving = true;
    const actionFollowUp: ActionFollowUpModel = Object.assign({}, this.saveActionForm.getRawValue());
    actionFollowUp.comments = this.ckEditorHelper.getNormalizedContent(true);

    for (const id of this.candidatesIds) {
      actionFollowUp.candidateId = id;
      await this.actionFollowUpService.addActionFollowUp(actionFollowUp)
        .then(() => {
          this.savedCandidateIds.push(id);
        })
        .catch(() => {
          this.failedCandidateCount += 1;
        });
    }

    if (this.failedCandidateCount === 0) {
      this.saveSuccess();
    } else {
      this.saveFailure();
    }
  }

  /** Méthode après réussite de l'enregistrement */
  private saveSuccess() {
    // On ouvre la snack bar
    this.snackbarService.showSuccess(this.translations['saveCandidatesActionSucceed']);
    // On ferme la fenêtre
    this.actionFollowUpSavedCandidateIds.next(this.savedCandidateIds);
    this.close();
  }

  /** Méthode après échec de l'enregistrement */
  private saveFailure() {
    // On ouvre la snack bar
    let errorMessage = this.translations['saveCandidatesActionFailed'];

    if (this.candidatesIds.length > 1) {
      errorMessage += `: ${this.failedCandidateCount} ${this.translations['candidate']}`;
    }

    this.snackbarService.showDanger(errorMessage);

    // On ferme la fenêtre
    this.close();
  }

  /** Méthode pour fermer la dialog */
  private close() {
    this.dialogRef.close();
  }

  onSpeech(text: string): void {
    this.saveActionForm.patchValue({
      comments: `${this.speechTempText} ${text}`
    });
  }

  onStartSpeech(): void {
    this.onStartOrStopSpeech();
  }

  onStopSpeech(): void {
    this.onStartOrStopSpeech();
  }

  private onStartOrStopSpeech(): void {
    const comment = this.saveActionForm.controls.comments.value;
    this.speechTempText = comment ? this.ckEditorHelper.cleanCkEditorText(comment) : '';
  }
}

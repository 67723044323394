import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { ActionWithPayload } from 'app/shared/models/state/action-with-payload.model';
import { updateAdvancedSearchResponse } from 'app/state/actions/advanced-search-response.action';
import * as advancedSearchActions from 'app/state/actions/advanced-search.action';
import { AppState } from 'app/state/app.state';
import { AdvancedSearchModel } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saved-request',
  templateUrl: './saved-request.component.html',
  styleUrls: ['./saved-request.component.scss']
})

export class SavedRequestComponent implements OnInit, OnDestroy {

  private readonly subscriptions: Subscription;
  private snackbarSuccessMessage: string;
  private errorMessage: string;
  private _disabled = false;

  public propDescription: FormControl;
  public form: FormGroup;
  public isFormSubmitted = false;
  public isSavingNewAdvancedSearch: boolean;

  public get disabled(): boolean {
    return this._disabled;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<SavedRequestComponent>,
    private readonly snackbarService: CustomSnackBarService,
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { advancedSearch: AdvancedSearchViewModel },
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.subscriptions = new Subscription();
  }

  public ngOnInit() {
    const translationSubscription = this.translateService.get(['saveSuccess', 'error']).subscribe(translations => {
      this.snackbarSuccessMessage = translations.saveSuccess;
      this.errorMessage = translations.error;
    });
    this.subscriptions.add(translationSubscription);

    this.propDescription = new FormControl(this.data.advancedSearch.label, Validators.required);
    this.form = this.formBuilder.group({
      propDescription: this.propDescription
    });

    this.isSavingNewAdvancedSearch = !this.data.advancedSearch.label;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** Met à jour le libellé de la recherche avancée, puis l'enregistre */
  public saveAdvancedSearch() {
    this._disabled = true;
    /** Met à jour le libellé puis met à jour en BDD la recherche avancée */
    this.isFormSubmitted = true;
    const advancedSearch = _.cloneDeep(this.data.advancedSearch);
    advancedSearch.label = this.propDescription.value;

    if (this.isSavingNewAdvancedSearch) {
      // dispatcher l'action pour ajouter une nouvelle recherche
      this.store.dispatch(advancedSearchActions.addAdvancedSearch({ payload: advancedSearch }));

      // Afficher message d'erreur lors d'enregistrement d'une recherche
      const errorSavingSearchSubscription = this.actions.pipe(
        ofType(advancedSearchActions.addAdvancedSearchError.type)
      ).subscribe(() => {
        this.error();
        this._disabled = false;
      });

      this.subscriptions.add(errorSavingSearchSubscription);
    } else {
      // dispatcher l'action pour modifier une recherche
      this.store.dispatch(advancedSearchActions.updateAdvancedSearch({ payload: advancedSearch }));

      // Afficher message d'erreur lors modification d'une recherche
      const errorUpdatingSearchSubscription = this.actions.pipe(
        ofType(advancedSearchActions.updateAdvancedSearchError.type)
      ).subscribe(() => {
        this.error();
        this._disabled = false;
      });

      this.subscriptions.add(errorUpdatingSearchSubscription);
    }

    // Afficher message aprés enregistrement ou modification d'une recherche
    const saveOrUpdateSearchSubscription = this.actions.pipe(
      ofType(
        advancedSearchActions.addAdvancedSearchSuccess.type,
        advancedSearchActions.updateAdvancedSearchSuccess.type
      )
    ).subscribe(( action: ActionWithPayload<AdvancedSearchViewModel>) => {
      const advancedSearch = action.payload;
      this.selectStateAndShowMesssage(advancedSearch);
      this._disabled = false;
    });

    this.subscriptions.add(saveOrUpdateSearchSubscription);
  }

  /** Met à jour le libellé puis ajoute en BDD une copie de la recherche avancée */
  public cloneAdvancedSearch() {
    this._disabled = true;
    this.isFormSubmitted = true;
    const advancedSearch = { ...this.data.advancedSearch };
    advancedSearch.label = this.propDescription.value;
    if (advancedSearch.criteria.length === 0) {
      // Charger les critères d'une recherche avant le clonage
      this.store.dispatch(advancedSearchActions.loadCriterionByAdvancedSearchId({payload: advancedSearch.id}));
      const loadCritereionSubscription = this.actions.pipe(
        ofType(advancedSearchActions.loadCriterionByAdvancedSearchIdSuccess.type)
      ).subscribe(( result: ActionWithPayload<AdvancedSearchModel>) => {
        advancedSearch.criteria = _.cloneDeep(result.payload.criteria);
        // dispatcher l'action pour cloner une recherche
        this.store.dispatch(advancedSearchActions.cloneAdvancedSearch({ payload: advancedSearch }));
      });
      
      this.subscriptions.add(loadCritereionSubscription);
    }else {
      // dispatcher l'action pour cloner une recherche
      this.store.dispatch(advancedSearchActions.cloneAdvancedSearch({ payload: advancedSearch }));
    }

    // Afficher message aprés duplication d'une recherche
    const cloneSearchSubscription = this.actions.pipe(
      ofType(advancedSearchActions.cloneAdvancedSearchSuccess.type)
    ).subscribe(() => {
      this.selectStateAndShowMesssage(advancedSearch);
      this._disabled = false;
    });

    // Afficher message d'erreur lors de la duplication d'une recherche
    const errorCloningSearchSubscription = this.actions.pipe(
      ofType(advancedSearchActions.cloneAdvancedSearchError.type)
    ).subscribe(() => {
      this.error();
      this._disabled = false;
    });
    
    this.subscriptions.add(cloneSearchSubscription);
    this.subscriptions.add(errorCloningSearchSubscription);
  }

  /** Informe de chercher à nouveau la liste des recherches, et ferme la fenêtre */
  private successfullSave(search: AdvancedSearchViewModel) {
    const advancedSearchResponse = this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse;
    // On met à jour le nouvel id de recherche, sa date de mise à jour et aussi son libellé
    advancedSearchResponse.advancedSearch.id = search.id;
    advancedSearchResponse.advancedSearch.lastUpdate = search.lastUpdate;
    advancedSearchResponse.advancedSearch.label = search.label;
    this.store.dispatch(updateAdvancedSearchResponse({payload: advancedSearchResponse}));

    // On affiche le retour
    this.snackbarService.showSuccess(this.snackbarSuccessMessage);
    this.dialogRef.close();
  }

  /** Indique par snack bar une erreur */
  private error() {
    this.snackbarService.showDanger(this.errorMessage);
  }

  private selectStateAndShowMesssage(advancedSearch: AdvancedSearchViewModel) {
    // Afficher message aprés modification
    const searchesObservable$ = this.store.select(data => data.advancedSearchState);

    const storeSubscription = searchesObservable$.subscribe(data => {
      switch (data.dataState) {
        case StateStatus.ADDED:
        case StateStatus.UPDATED:
        case StateStatus.CLONED:
          this.successfullSave(advancedSearch);
          break;

        case StateStatus.ERRORADDING:
        case StateStatus.ERRORUPDATING:
        case StateStatus.ERRORCLONIG:
          this.error();
          break;

        default:
          break;
      }
    });

    this.subscriptions.add(storeSubscription);
  }

}

import { Component } from '@angular/core';
import { CriterionCategory } from 'common-services';

@Component({
  selector: 'app-criteria-group-skill',
  templateUrl: './criteria-group-skill.component.html'
})
export class CriteriaGroupSkillsComponent {

  public criterionCategory = CriterionCategory;
  
}

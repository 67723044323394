import { NgModule } from '@angular/core';
import { CandidateContainerComponent } from './candidate-container/candidate-container.component';
import { CandidateSummaryComponent } from './candidate-summary/candidate-summary.component';
import { CandidateDetailsModule } from '../candidate-details/candidate-details.module';
import { AngularMaterialsModule } from 'app/shared/angular-materials.module';
import { CandidateChipComponent } from './candidate-chip/candidate-chip.component';
import { CandidateAddContainerComponent } from './candidate-add-container/candidate-add-container.component';

const components = [
  CandidateContainerComponent,
  CandidateChipComponent,
  CandidateSummaryComponent,
  CandidateAddContainerComponent
];

const businessModules = [
  CandidateDetailsModule
];

const modules = [
  AngularMaterialsModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules,
    ...businessModules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class CandidateModalModule { }

<div class="form-group" id="criteria_menu">
  <div class="input-group mb-1 flex-row-reverse">
    <mat-form-field class="form-control searchInput" appearance="outline"
                    (keyup.enter)="setCriterion(searchInputValue)">
      <input id="criteria_menu_full_text_input" [formControl]="autocompleteFormControl" [matAutocomplete]="autoCompleteClient"
             placeholder="{{ 'freeSearch' | translate  }}" matInput>
      <mat-spinner *ngIf="isLoading" class="inputSpinner" diameter="20"></mat-spinner>
      <mat-autocomplete #autoCompleteClient="matAutocomplete" (optionSelected)="setCriterion($event.option.value)">
        <mat-option id="criteria_menu_full_text_input_{{ i }}" *ngFor="let completion of completionItemsObservable | async;index as i;" [value]="completion" class="h-auto py-2">
          <span class="searchSelectOptionValue d-flex align-items-start w-100">
            <span [innerHTML]="completion.value | strongify:searchInputValue"></span>
            <span class="ml-auto criterionCat">{{ 'CriterionCategory.' + criterionCategory[completion.criterionCategory] | translate }}</span>
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="input-group-btn">
      <button class="btn btn-primary px-1 py-0" type="button" role="button" (click)="setCriterion(searchInputValue)">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</div>

<div id="accordion">
  <app-criteria-group *ngFor="let group of criteriaGroupModels" [groupModel]="group" class="oneCriteriaGroup">
  </app-criteria-group>
</div>

<div class="blocCriteria">
  <mat-expansion-panel *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.HighestStudyLevel)"
                       [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'24px'" [expandedHeight]="'24px'">
      <mat-panel-title>
        {{ 'educationLevel' | translate  }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-checkbox-list [identifier]="'criteria_group_formation_select'" [options]="studyLevels" [enableSelectAll]="false" (selectedOptionsChanged)="onStudyLevelChange($event)"></app-checkbox-list>
  </mat-expansion-panel>
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.Diploma)">
    <app-criterion-autocomplete [identifier]="'criteria_group_formation_input'" label="{{ 'diploma' | translate  }}" [criterionCategory]="criterionCategory.Diploma">
    </app-criterion-autocomplete>
    <app-criteria-list [criterionCategory]="criterionCategory.Diploma"></app-criteria-list>
  </ng-container>
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.InstituteName)">
    <div class="mat-form-field-container form-group">
      <mat-form-field appearance="outline">
        <mat-label for="criteria_group_formation_institution_input">{{ 'institution' | translate  }}</mat-label>
        <input id="criteria_group_formation_institution_input"
               name="criteria_group_formation_institution_input"
               type="text"
               placeholder="{{ 'keywordEllipsis' | translate  }}"
               [(ngModel)]="instituteNameValue"
               (keyup.enter)="addInstitutionCriterion()"
               matInput />
        <button *ngIf="instituteNameValue"
                attr.aria-label="{{ 'search' | translate  }}"
                (click)="addInstitutionCriterion()"
                mat-button matSuffix mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <app-criteria-list [criterionCategory]="criterionCategory.InstituteName"></app-criteria-list>
  </ng-container>
</div>

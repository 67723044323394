<div class="wrapperContent wrapperContentInfos">
  <div class="scroll-wrapper">
    <div class="scroll grey with-padding">
      <mat-progress-bar *ngIf="isSaving" mode="indeterminate"></mat-progress-bar>
      <form [formGroup]="candidateForm" (ngSubmit)="save()" class="formContainer" #candidateFormTag="ngForm">
        <mat-card class="matCard identity-card">
          <mat-card-header>
            <div mat-card-avatar class="identity-avatar">
              <mat-icon>perm_identity</mat-icon>
            </div>
            <mat-card-title>{{'identity' | translate  | uppercase}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="wrapperFormField">
              <mat-form-field disabled="true" appearance="outline" class="formField">
                <mat-label>{{'identification' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.id" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'email' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.emailAddress" 
                       [errorStateMatcher]="errorMatcher"
                       [class.redInvalideEmail]="Validators.email(candidateForm.controls.emailAddress)"
                       matInput required>
                <mat-error *ngIf="candidateForm.controls.emailAddress.hasError('required')">
                  {{'requiredField' | translate }}
                </mat-error>
                <mat-error *ngIf="candidateForm.controls.emailAddress.hasError('invalidateMailAddress')">
                  {{'invalidEmail' | translate }}
                </mat-error>
                <mat-error *ngIf="candidateForm.controls.emailAddress.hasError('emailAddressExists')">
                  {{'emailAddressExists' | translate }}
                </mat-error>
              </mat-form-field>
              <app-select [identifier]="'candidate_informations_civility_select'"
                          [options]="civilities"
                          [formControl]="candidateForm.controls.civilityId"
                          [label]="labelCivility"
                          class="formField">
              </app-select>
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'firstName' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.firstName" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'lastName' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.lastName" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formField">
                <input [formControl]="candidateForm.controls.birthDate" [matDatepicker]="birthDatepicker"
                      [errorStateMatcher]="errorMatcher" matInput>
                <mat-label>{{'birthDate' | translate }}</mat-label>
                <mat-datepicker-toggle [for]="birthDatepicker" matSuffix class="datepickerBtn"></mat-datepicker-toggle>
                <mat-datepicker #birthDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="wrapperFormField oneLine">
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'address' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.address" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
            </div>
            <div class="wrapperFormField">
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'zipCode' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.zipCode" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'city' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.city" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <app-select [identifier]="'candidate_informations_country_select'"
                [options]="countries"
                (selectedOptionsChanged)="countryValueChanges($event)"
                [formControl]="candidateForm.controls.countryId"
                [emptyOption]="emptyOptionLabel"
                [label]="labelCountry"
                class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_region_select'"
                [options]="regions"
                [formControl]="candidateForm.controls.regionOriginId"
                [emptyOption]="emptyOptionLabel"
                [isDisabled]="regionOriginIdDisabled"
                [label]="labelRegion"
                class="formField">
              </app-select>
              <mat-form-field type="tel" appearance="outline" class="formField">
                <mat-label>{{'mobilePhone' | translate }}</mat-label>
                <input type="tel" [formControl]="candidateForm.controls.mobilePhone" [errorStateMatcher]="errorMatcher"
                      matInput>
              </mat-form-field>
              <mat-form-field type="tel" appearance="outline" class="formField">
                <mat-label>{{'professionalPhone' | translate }}</mat-label>
                <input type="tel" [formControl]="candidateForm.controls.professionalPhone"
                      [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field type="tel" appearance="outline" class="formField">
                <mat-label>{{'personalPhone' | translate }}</mat-label>
                <input type="tel" [formControl]="candidateForm.controls.personalPhone" [errorStateMatcher]="errorMatcher"
                      matInput>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="matCard situation-card">
          <mat-card-header>
            <div class="identity-avatar" mat-card-avatar>
              <mat-icon>work_outline</mat-icon>
            </div>
            <mat-card-title>{{'currentSituation' | translate  | uppercase}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="wrapperFormField">
              <mat-form-field appearance="outline" *ngIf="candidateParameter.showCurrentCompanyFreeText" class="formField">
                <mat-label>{{'currentCompany' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.currentCompany" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="candidateParameter.showCurrentCompanySelectList" class="formField">
                <mat-label>{{'currentCompany' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.customerId" [errorStateMatcher]="errorMatcher"
                      [matAutocomplete]="autoCompleteClient" matInput>
                <mat-autocomplete #autoCompleteClient="matAutocomplete" [displayWith]="displayCustomer.bind(this)">
                  <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.id" class="h-auto py-2">
                    {{customer.label}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="candidateForm.controls.customerId.hasError('incorrect')">
                  {{'invalidValue' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'currentPosition' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.currentPosition" [errorStateMatcher]="errorMatcher" matInput>
              </mat-form-field>
            </div>
            <div class="wrapperFormField">
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'salary' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.currentSalary" [errorStateMatcher]="errorMatcher" matInput>
                <mat-error *ngIf="candidateForm.controls.currentSalary.hasError('pattern')">
                  {{'invalidValue' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="candidateParameter.showNationalitySelectList" class="formField">
                <mat-label>{{'nationality' | translate }}</mat-label>
                <mat-select [formControl]="candidateForm.controls.nationalityId" [errorStateMatcher]="errorMatcher"
                            disableOptionCentering>
                  <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id" class="h-auto py-2">
                    {{nationality.nationality}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="candidateParameter.showNationalityFreeText" class="formField">
                <mat-label>{{'nationality' | translate }}</mat-label>
                <input [formControl]="candidateForm.controls.nationality" [errorStateMatcher]="errorMatcher"
                      [matAutocomplete]="autoCompleteNationality" matInput>
                <mat-autocomplete #autoCompleteNationality="matAutocomplete" [displayWith]="displayNationality.bind(this)">
                  <mat-option *ngFor="let nationality of filteredNationalities | async" [value]="nationality.nationality" class="h-auto py-2">
                    {{nationality.nationality}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="wrapperFormField oneLine">
              <mat-form-field appearance="outline" class="formField">
                <mat-label>{{'additionalTextArea' | translate }}</mat-label>
                <textarea [formControl]="candidateForm.controls.additionalTextArea" [errorStateMatcher]="errorMatcher" rows="5"
                          matInput>
                </textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="matCard languages-card">
          <mat-card-header>
            <div class="identity-avatar" mat-card-avatar>
              <mat-icon>translate</mat-icon>
            </div>
            <mat-card-title>{{'languages' | translate  | uppercase}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="wrapperFormField">
              <app-select [identifier]="'candidate_informations_firstLanguage_select'"
                          [formControl]="candidateForm.controls.firstLanguageId"
                          (selectedOptionsChanged)="firstLanguageValueChanges($event)"
                          [options]="candidatesLanguages"
                          [emptyOption]="emptyOptionLabel"
                          [label]="labelFirstLanguage"
                          class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_firstLanguageLevel_select'"
                          [formControl]="candidateForm.controls.firstLanguageLevelId"
                          [options]="languageLevels"
                          [emptyOption]="emptyOptionLabel"
                          [isDisabled]="firstLanguageLevelDisabled"
                          [label]="labelLevelLanguage"
                          class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_secondLanguage_select'"
                          [formControl]="candidateForm.controls.secondLanguageId"
                          (selectedOptionsChanged)="secondLanguageValueChanges($event)"
                          [options]="candidatesLanguages"
                          [emptyOption]="emptyOptionLabel"
                          [label]="labelSecondLanguage"
                          class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_secondLanguageLevel_select'"
                          [formControl]="candidateForm.controls.secondLanguageLevelId"
                          [options]="languageLevels"
                          [emptyOption]="emptyOptionLabel"
                          [isDisabled]="secondLanguageLevelDisabled"
                          [label]="labelLevelLanguage"
                          class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_thirdLanguage_select'"
                          [formControl]="candidateForm.controls.thirdLanguageId"
                          (selectedOptionsChanged)="thirdLanguageValueChanges($event)"
                          [options]="candidatesLanguages"
                          [emptyOption]="emptyOptionLabel"
                          [label]="labelThirdLanguage"
                          class="formField">
              </app-select>
              <app-select [identifier]="'candidate_informations_thirdLanguageLevelId_select'"
                          [formControl]="candidateForm.controls.thirdLanguageLevelId"
                          [options]="languageLevels"
                          [emptyOption]="emptyOptionLabel"
                          [isDisabled]="thirdLanguageLevelDisabled"
                          [label]="labelLevelLanguage"
                          class="formField">
              </app-select>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="matCard dynamic-field-card">
          <mat-card-header>
            <div class="identity-avatar" mat-card-avatar>
              <mat-icon>info</mat-icon>
            </div>
            <mat-card-title>{{'additionalInfos' | translate  | uppercase}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-dynamic-field-adapter [parentForm]="candidateForm" [dynamicFieldModel]="dynamicFieldBaseModel"></app-dynamic-field-adapter>
          </mat-card-content>
        </mat-card>
      </form>
    </div>
  </div>
  <div class="actionBtn position-absolute bg-white text-right">
    <span class="form-error" *ngIf="saveTriedOnce && candidateForm.status === 'INVALID' && candidateForm.touched">
      {{'formRequiredFieldsErrors' | translate }}
    </span>
    <button color="primary" type="submit" class="mx-4" (click)="candidateFormTag.ngSubmit.emit()" 
      [disabled]="!candidateForm.dirty || isSaving" mat-raised-button>
      {{'save' | translate  | uppercase}}
    </button>
  </div>
</div>

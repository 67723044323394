import { AdvancedSearchRequestModel } from 'common-services';
import { AdvancedSearchViewModel } from './advanced-search.model';

export class AdvancedSearchRequestViewModel implements AdvancedSearchRequestModel {
  constructor(
    public resultOffset: number,
    public advancedSearch: AdvancedSearchViewModel
  ) { }
  provideTagCloud: string;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFieldBaseModel, DynamicFieldDtoModel, DynamicFieldInputControlType } from 'common-services';
import { Observable, Subject, Subscription } from 'rxjs';
import { DynamicFieldFormControl } from './dynamic-field-form-control.class';
import { DynamicFieldFormGroup } from './dynamic-field-form-group.class';

const DYNAMIC_FIELD_CONFIGURATIONS = 'dynamicFields';

@Component({
  selector: 'app-dynamic-field-adapter',
  templateUrl: './dynamic-field-adapter.component.html',
  styleUrls: ['./dynamic-field-adapter.component.scss']
})
export class DynamicFieldAdapterComponent implements OnInit, OnDestroy {
  ControlType = DynamicFieldInputControlType;

  @Input() parentForm: FormGroup;
  @Input() dynamicFieldModel: Observable<NonNullable<any extends DynamicFieldBaseModel ? DynamicFieldBaseModel : null>>;

  private requiredErrorMessage: string;
  private readonly subscription: Subscription;
  private readonly dynamicFieldsSubject: Subject<Array<DynamicFieldDtoModel>>;
  private readonly dynamicFieldsFormGroup: DynamicFieldFormGroup;

  public get formGroup(): DynamicFieldFormGroup {
    return this.dynamicFieldsFormGroup;
  }

  public get formControls(): Observable<Array<DynamicFieldFormControl>> {
    return this.dynamicFieldsFormGroup.formControls;
  }

  public get requiredMessage(): string {
    return this.requiredErrorMessage;
  }

  constructor(
    private readonly translateService: TranslateService
  ) {
    this.dynamicFieldsSubject = new Subject<Array<DynamicFieldDtoModel>>();
    this.dynamicFieldsFormGroup = new DynamicFieldFormGroup(this.dynamicFieldsSubject);
    this.subscription = new Subscription();
    this.subscription.add(this.dynamicFieldsSubject.subscribe(fields => {
      if (!this.parentForm.contains(DYNAMIC_FIELD_CONFIGURATIONS)) {
        this.parentForm.addControl(DYNAMIC_FIELD_CONFIGURATIONS, this.dynamicFieldsFormGroup);
      }
    }));
  }

  ngOnInit() {
    this.translateService.get('requiredField').subscribe(res => this.requiredErrorMessage = res);

    if (this.dynamicFieldModel && this.parentForm) {
      this.subscription.add(this.dynamicFieldModel.subscribe(
        dynamicFieldModel =>
          this.dynamicFieldsSubject.next(dynamicFieldModel.dynamicFields)
      ));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dynamicFieldsSubject.complete();
  }

  public identifyDynamicField(index: number, item: DynamicFieldFormControl) {
    return item.name;
  }
}

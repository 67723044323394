<ng-container *ngIf="candidateObservable | async as candidateModel; else elseBlock">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingCandidate"></mat-progress-bar>
  <div class="modalboxHeader m-3">
    <button class="close" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
    <app-candidate-summary [candidateObservable]="candidateObservable"></app-candidate-summary>
  </div>
  <div class="modalboxBody">
    <mat-tab-group mat-align-tabs="start" dynamicHeight="true">
      <mat-tab label="{{ 'candidaturesList' | translate  | uppercase }}">
        <ng-template matTabContent>
          <app-candidate-candidatures [candidateObservable]="candidateObservable">
          </app-candidate-candidatures>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'candidateInformations' | translate  | uppercase }}">
        <ng-template matTabContent>
          <app-candidate-informations [candidate]="candidateObservable" [formEditMode]="true">
          </app-candidate-informations>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'candidateFollowUp' | translate  | uppercase }}">
        <ng-template matTabContent>
          <app-candidate-actions [candidate]="candidateObservable"></app-candidate-actions>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'experienceSlashEducation' | translate  | uppercase }}">
        <ng-template matTabContent>
          <app-candidate-experiences-formations [candidate]="candidateObservable"></app-candidate-experiences-formations>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'attachmentFiles' | translate  | uppercase }}">
        <ng-template matTabContent>
          <app-candidate-attachments-list [candidate]="candidateObservable"></app-candidate-attachments-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
<ng-template #elseBlock>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

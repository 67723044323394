import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PaginationComponent } from 'app/generic-components/pagination/pagination.component';
import { DefaultControlsErrorStateMatcher } from 'app/shared/material/default-controls-error-state-matcher';
import {
  ActionFollowUpModel,
  ActionFollowUpService,
  CandidateModel,
  DataDepthLevel,
  IndividualModel,
  OrganizationService,
  PagedItems,
  PositionModel,
  PositionService,
  QueryableModel,
  SortOrder
} from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-actions',
  templateUrl: './candidate-actions.component.html',
  styleUrls: ['./candidate-actions.component.scss']
})
export class CandidateActionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() candidate: Observable<CandidateModel>;
  @ViewChild('pagination') paginator: PaginationComponent;
  @ViewChild('ActionsDisplayGroup') public actionsDisplayGroup: ElementRef<HTMLElement>;

  private readonly observablesSubscriptions: Subscription;
  private paginatorSubscription: Subscription;
  private unsuccessfulOperationMessage: string;
  private candidateId: number;
  private isSearchDone = false; // pour ne pas refaire la recherche si on change les filtres sans clique sur le betoun de filtrage
  private includeFilters = true;
  private whereExpression: string;
  private actionsHeight = 0;

  public paginationLength = 0;
  public paginationSize = 5;
  public actionForm: FormGroup;
  public positions: Array<KeyValue<number, string>>;
  public responsibles: Array<KeyValue<number, string>>;
  public fetching: boolean;
  public actions: Array<ActionFollowUpModel> = [];
  public errorMatcher: DefaultControlsErrorStateMatcher;
  public emptyOptionLabel: string;
  public selectPosition: string;
  public selectResponsible: string;
  public selectedPosition: number;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly actionFollowUpService: ActionFollowUpService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: CustomSnackBarService,
    private readonly positionService: PositionService,
    private readonly organizationService: OrganizationService
  ) {
    this.observablesSubscriptions = new Subscription();
    this.fetching = false;

    this.translateService.get(['error', 'chooseOption', 'selectPosition', 'selectResponsible']).subscribe(translations => {
      this.unsuccessfulOperationMessage = translations.error;
      this.emptyOptionLabel = translations.chooseOption;
      this.selectPosition = translations.selectPosition;
      this.selectResponsible = translations.selectResponsible;
    });

    this.actionForm = this.formBuilder.group({
      selectedPositionId: new FormControl(),
      selectedResponsibleId: new FormControl(),
      start: new FormControl(),
      end: new FormControl(),
      viewTechnicalStepsFormControl: new FormControl()
    });

    this.errorMatcher = new DefaultControlsErrorStateMatcher();
  }

  ngOnInit() {
    const candidateSubscription = this.candidate.subscribe(candidate => {
      this.candidateId = candidate.id;
      /* si on change les filtres et on clique sur le bouton de rafraichir sans avoir effectuer un filtrage,
       on initialise seulement les filtres sans faire une recherche */
      this.isSearchDone = false;
      this.fetching = false;
      /* si on selectionne un autre candidat, on doit initiliaser les filtres,
       et pour ne pas afficher le bouton de rafraichir */
      this.actionForm.reset();
      this.actionForm.markAsPristine();

      this.ResetPaginatorAndFilter();

      this.positionService.getPositionsByCandidateId(this.candidateId)
        .then(
          result => this.positions = result.map(position => ({ key: position.id, value: this.getPositionTitle(position) }))
        );

      const query: QueryableModel = {
        whereExpression: 'i => i.licenses.any(l=> l.applicativeAreaCode=20)',
        dataDepthLevel: DataDepthLevel.Flat,
        sortExpression: 'firstName',
        sortOrder: SortOrder.Ascending.toString(),
        page: 0,
        pageSize: 10000
      };

      this.organizationService.findIndividualsByQueryable(query)
        .then(result => {
          // On récupère tous les individus qui ont un nom ou un prénom
          this.responsibles = result.items.filter(i => i.firstName || i.lastName)
            .map(responsible => ({ key: responsible.id, value: this.getResponsableFullName(responsible) }));
        })
    });
    this.observablesSubscriptions.add(candidateSubscription);

    /* on calcule la hauteur pour l'affichage les actionsFollowUp,
    on déduit la hauteur des filtres et paginations */
    const containerActionsFilter: HTMLElement = document.querySelector('#container-actions-filter');
    const containerActionsPaginator: HTMLElement = document.querySelector('#container-actions-paginator');
    this.actionsHeight = containerActionsFilter.offsetHeight + containerActionsPaginator.offsetHeight;

    document.querySelector('#wrapperContentActions').setAttribute('style', `height: calc(100% - ${this.actionsHeight}px )`);
  }

  ngAfterViewInit(): void {
    this.paginatorSubscription = this.paginator.paginator.page.pipe(
      debounceTime(100)
    ).subscribe(this.loadActions.bind(this));
  }

  ngOnDestroy(): void {
    if (this.observablesSubscriptions) {
      this.observablesSubscriptions.unsubscribe();
    }

    if (this.paginatorSubscription) {
      this.paginatorSubscription.unsubscribe();
    }
  }

  private getPositionTitle(position: PositionModel) {
    return `${position.title} - ${position?.reference}`;
  }
  private getResponsableFullName(responsible: IndividualModel) {
    return `${responsible.firstName} ${responsible.lastName}`;
  }

  private loadActions(): void {
    this.fetching = true;

    if (this.actionForm.valid) {
      this.actionsPromise()
        .then((pagedItems: PagedItems<ActionFollowUpModel>) => {
          if (this.includeFilters) {
            this.includeFilters = false;
          }

          this.actionsDisplayGroup.nativeElement.scrollTop = 0;

          if (this.paginator.paginator) {
            this.paginationLength = pagedItems.rowCount;
            this.paginationSize = pagedItems.pageSize;
          }

          if (!pagedItems.items) {
            this.paginationLength = 0;
            this.paginationSize = 5;
            this.actions = [];
            return;
          }

          this.actions = pagedItems.items;
          this.fetching = false;
        })
        .catch(() => {
          this.snackbarService.showWarning(this.unsuccessfulOperationMessage);
          this.fetching = false;
        });
    }
  }

  public filterActions() {
    this.isSearchDone = true;
    this.ResetPaginatorAndFilter();
  }

  public refrech() {
    /* on initialise les filtres */
    this.actionForm.reset();
    this.actionForm.markAsPristine();

    /* on fait la recherche sans filtres si on a déjà fait une recherche précedante avec filtre */
    if (this.isSearchDone) {
      this.ResetPaginatorAndFilter();
      this.isSearchDone = false;
    }
  }

  private ResetPaginatorAndFilter() {
    /* on recalcule whereExpression si on filtre,
     on rafraichit la recherche et si on change le candidat selectionné */
    this.includeFilters = true;

    if (this.paginator?.paginator) {
      this.paginator.paginator.pageIndex = 0;
    }
    this.loadActions();
  }

  private buildQuery(): string {
    let query = `ac => ac.candidateId = ${this.candidateId}`;
    const viewTechnicalSteps = !this.actionForm.controls.viewTechnicalStepsFormControl.value;

    if (viewTechnicalSteps) {
      query += ` && ac.actionType.system = ${!viewTechnicalSteps}`;
    }

    if (this.actionForm.controls.selectedPositionId.value) {
      query += ` && ac.position.id = ${this.actionForm.controls.selectedPositionId.value}`;
    }

    if (this.actionForm.controls.selectedResponsibleId.value) {
      query += ` && ac.individualId = ${this.actionForm.controls.selectedResponsibleId.value}`;
    }

    if (this.actionForm.controls.start.value && this.actionForm.controls.end.value) {
      const difference = - (new Date().getTimezoneOffset());

      const startDate = new Date(this.actionForm.controls.start.value).setHours(0, 0, 0, 0);
      const startDatetoISOString = new Date(new Date(startDate).getTime() + difference * 60000).toISOString();

      const endDate = new Date(this.actionForm.controls.end.value).setHours(23, 59, 59, 999);
      const endDatetoISOString = new Date(new Date(endDate).getTime() + difference * 60000).toISOString();

      query += ` && ac.actionDate >= "${startDatetoISOString}" && ac.actionDate <= "${endDatetoISOString}" `;
    }
    return query;
  }

  private actionsPromise(): Promise<PagedItems<ActionFollowUpModel>> {
    if (this.includeFilters) {
      this.whereExpression = this.buildQuery();
    }

    const query: QueryableModel = {
      whereExpression: this.whereExpression,
      parameters: [],
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: `actionDate`,
      sortOrder: SortOrder.Descending.toString(),
      page: this.paginator ? this.paginator.paginator.pageIndex : this.paginationLength,
      pageSize: this.paginator ? this.paginator.paginator.pageSize : this.paginationSize
    };
    return this.actionFollowUpService.findByQueryable(query);
  }
}

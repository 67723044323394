import { Injectable } from '@angular/core';
import { IndividualModel } from 'common-services';
import { UtilHelper } from '../helpers/util.helper';

@Injectable()
export class IndividualManager {

  constructor(
    private readonly utilHelper: UtilHelper
  ) {
  }

  public responsibleDisplayFunction(individual: IndividualModel): string {
    return `${(individual?.firstName) ? individual.firstName.trim() : ''} ${(individual?.lastName) ? individual.lastName.trim() : ''}`.trim();
  }

  /**
   * Filtre les utilisateurs comprenant la valeur tapée
   * @param value La valeur tapée
   * @param users L'ensemble des utilisateurs
   */
  public filterUser(value: string, individuals: Array<IndividualModel>): Array<IndividualModel> {
    if (!value) {
      return individuals;
    }
    const filterValue = this.utilHelper.removeUnusedSpaces(value.toLowerCase());
    return individuals.filter(user => this.responsibleDisplayFunction(user).toLowerCase().includes(filterValue));
  }
}

<div class="container-action" id="container-actions-filter">
  <form [formGroup]="actionForm">
    <div class="container">
      <div class="row">
        <!-- select position -->
        <div class="field-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
          <app-select #selectPositionApp 
            [identifier]="'candidate_actions_component_position_select'" 
            [class]="'w-100'"
            [options]="positions" 
            [emptyOption]="emptyOptionLabel" 
            [label]="selectPosition"
            [isEmptyOptionHasValue]="true"
            [formControl]="actionForm.controls.selectedPositionId">
          </app-select>
        </div>
        <!-- select responsible -->
        <div class="field-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
          <app-select #selectResponsibleApp 
            [identifier]="'candidate_actions_component_responsible_select'"
            [class]="'w-100'" 
            [options]="responsibles" 
            [emptyOption]="emptyOptionLabel" 
            [label]="selectResponsible"
            [isEmptyOptionHasValue]="true"
            [formControl]="actionForm.controls.selectedResponsibleId">
          </app-select>
        </div>
        <!-- dates range -->
        <div class="field-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'dateRange' | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker" class="date-font">
              <input matStartDate [formControl]="actionForm.controls.start" placeholder="{{ 'startDate' | translate}}">
              <input matEndDate [formControl]="actionForm.controls.end" placeholder="{{ 'endDate' | translate}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
              *ngIf="actionForm.controls.start.hasError('matStartDateInvalid') && actionForm.controls.end.hasError('matEndDateInvalid')">
              {{ 'invalidStartEndDate' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
          <div class="viewTechnicalSteps bg-white border rounded p-2 pr-3 mt-1 mb-1">
            <mat-checkbox [formControl]="actionForm.controls.viewTechnicalStepsFormControl">{{'viewTechnicalSteps' |
              translate }}
            </mat-checkbox>
          </div>
        </div>
        <div
          class="field-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 buttons offset-xxl-4 offset-xl-6 offset-lg-6">
          <button id="refined_criteria_menu_save_button" type="button" (click)="filterActions()"
            [disabled]="!actionForm.dirty || fetching || !actionForm.valid" class="btn btn-primary saveBtn btn-sm">
            <mat-icon class="material-icons-outlined">
              filter_alt
            </mat-icon>
          </button>
          <button *ngIf="!actionForm.pristine" id="refined_criteria_menu_clear_button" (click)="refrech()"
            [disabled]="fetching" class="btn btn-secondary btn-sm clear-button ml-3">
            <mat-icon class="material-icons-outlined">
              refresh
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="actionsComponent wrapperContent wrapperContentActions" id="wrapperContentActions">
  <mat-progress-bar class="loader" mode="indeterminate" *ngIf="fetching">
  </mat-progress-bar>
  <div class="scroll-wrapper">
    <div class="scroll grey with-padding" #ActionsDisplayGroup>
      <ng-container *ngFor="let action of actions; last as last ; first as isFirst">
        <app-candidate-action [action]="action" class="candidateAction">
        </app-candidate-action>
      </ng-container>
    </div>
  </div>
</div>
<div class="position-absolute bg-white paginator" id="container-actions-paginator">
  <app-pagination #pagination [ngClass]="{'hidden': !(actions.length > 0)}" [length]="paginationLength"
    [pageSize]="paginationSize" [showFirstLastButtons]="true" [displayInput]="false"></app-pagination>
</div>
import { PipeTransform, Pipe } from '@angular/core';
import * as findAndReplaceDOMText from 'findandreplacedomtext';

@Pipe({
  name: 'highlight',
  pure: false
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, queries: Set<string>): string {
    if (queries && queries.size > 0 && text) {
      const htmlPreview = document.createElement('div');
      htmlPreview.innerHTML = text;

      queries.forEach(q => {
        const pattern = q.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        const regex = new RegExp(pattern, 'gi');

        findAndReplaceDOMText(htmlPreview, {
          find: regex,
          replace(portion) {
            const highlighted = document.createElement('span');
            highlighted.style.backgroundColor = '#F2E366';
            highlighted.innerHTML = portion.text;
            return highlighted;
          }
        });
      });

      return htmlPreview.innerHTML;
    }

    return text;
  }
}

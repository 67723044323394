<div class="pl-3">
  <mat-accordion multi="true" class="d-block">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading(status)"></mat-progress-bar>

    <div *ngIf="isLoadedWithSuccess(status)">
      <div class="oneSearchSaveRequest" *ngFor="let advancedSearch of searches; index as i; trackBy: trackByFunction">
        <app-saved-request-resume [advancedSearch]="advancedSearch">
        </app-saved-request-resume>
      </div>
    </div>

    <div *ngIf="status === stateStatusEnum.ERRORLOADING">{{ 'error' | translate }}</div>

  </mat-accordion>
</div>
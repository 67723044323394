import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { CkEditorUploadAdapter } from 'app/shared/adapters/ckeditor.upload.adapter';
import { TemporaryFilesService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { TextEditorConfigType } from './Enums/text-editor-config-type.enum';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextEditorComponent),
    multi: true
  }]
})

export class TextEditorComponent implements ControlValueAccessor, OnInit {

  @Input() configType?: TextEditorConfigType;
  @Input() language = 'fr';
  @Input() placeHolder: string;

  public editor = ClassicEditor;

  private innerValue = '';
  private mailBodyPlaceholder: string;
  private defaultEditorPlaceholder: string;
  private errorMessage: string;
  // Callbacks surchargés par ControlValueAccessor
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(
    public readonly translateService: TranslateService,
    private readonly snackbarService: CustomSnackBarService,
    private readonly temporaryFilesService: TemporaryFilesService
  ) { }

  ngOnInit() {
    this.translateService.get(['mailBodyPlaceholder', 'defaultEditorPlaceholder', 'fileUploadError']).subscribe(translation => {
      this.mailBodyPlaceholder = translation.mailBodyPlaceholder;
      this.defaultEditorPlaceholder = translation.defaultEditorPlaceholder;
      this.errorMessage = translation.fileUploadError;
    });
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /** Implémentation de ControlValueAccessor */
  onBlur() {
    this.onTouchedCallback();
  }

  /** Implémentation de ControlValueAccessor */
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  /** Implémentation de ControlValueAccessor */
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  /** Implémentation de ControlValueAccessor */
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public get config() {
    const config = {
      language: this.language,
      height: 100
    };

    // Si l'éditeur est pour le texte d'un mail
    if (this.configType === TextEditorConfigType.Mail) {
      // Si aucun placeholder n'est défini en entrée
      if (!this.placeHolder) {
        this.placeHolder = this.mailBodyPlaceholder;
      }

      config['toolbar'] = ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', 'undo', 'redo'];
      config['placeholder'] = this.placeHolder;
      // On renvoie la config
      return config;
      // Sinon config par défaut
    }

    // Si aucun placeholder n'est défini en entrée
    if (!this.placeHolder) {
      this.placeHolder = this.defaultEditorPlaceholder;
    }
    config['placeholder'] = this.placeHolder;
    // On renvoie la config
    return config;
  }

  async onReady(eventData: ClassicEditor) {
    eventData.plugins.get('FileRepository').createUploadAdapter = ((loader) => {
      // creation de l'upload adapter
      const result = new CkEditorUploadAdapter(loader, this.snackbarService, this.errorMessage, this.temporaryFilesService);
      // événement lancé si le fichier est uploadé
      loader.on('uploadFile', () => {
        const uploadedFile = result.uploadedFile();
        this.temporaryFilesService.getFile(uploadedFile.item.fileName).then(
          (file) => {
            if (file.fileData) {
              // on sélectionne tous les images via le contenu du base 64
              const imgs = document.querySelectorAll(`img[src$="${file.fileData}"]`);

              // ajout d'un nouveau attribue aux images sélectionnées
              imgs.forEach(img => {
                img.setAttribute('data-name', file.fileName);
              });
            }
          }
        );
      });

      return result;
    });
  }
}

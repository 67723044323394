import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActionFollowUpTypeHeaderModel, ActionFollowUpTypeModel } from 'common-services';

@Injectable()
export class ActionManager {

  public getActionsTypeGroupByHeaders(actionsTypeGroup: ReadonlyArray<ActionFollowUpTypeHeaderModel>): KeyValue<string, KeyValue<number, string>[]>[] {
    const sortedActionsTypeGroup: Array<ActionFollowUpTypeHeaderModel> = [];

    // on trie les actions en ordre croissant par label pour chaque header
    actionsTypeGroup.map(actionTypeGroup => {
      if (actionTypeGroup?.actionsFollowUpTypes.length > 0) {
        const sortedActionTypeGroup = actionTypeGroup;
        sortedActionTypeGroup.actionsFollowUpTypes = [...actionTypeGroup.actionsFollowUpTypes].sort((a, b) => a.label.localeCompare(b.label))
        sortedActionsTypeGroup.push(sortedActionTypeGroup)
      }
    });

    return sortedActionsTypeGroup.map(typeHeader =>
    ({
      key: typeHeader.label,
      value: typeHeader.actionsFollowUpTypes.map(type =>
      ({
        key: type.id,
        value: type.label
      })
      )
    })
    );
  }

  public getActionsTypes(actionsTypes: Array<ActionFollowUpTypeModel>): KeyValue<string, KeyValue<number, string>[]>[] {
    let groupedActionsByHeader = new Map<string, Map<number, string>>();
    const noHeaderLabel = '----';
    const noHeaderMap = new Map<number, string>();
    let typeHeaderLabel = '';

    // on trie en ordre croissant le label des suivis d'actions
    actionsTypes.sort((a, b) => a.label.localeCompare(b.label));

    actionsTypes.forEach(action => {
      if (action?.actionFollowUpTypeHeader) {
        typeHeaderLabel = action.actionFollowUpTypeHeader.label;
        groupedActionsByHeader.has(typeHeaderLabel) ? groupedActionsByHeader.get(typeHeaderLabel).set(action.id, action.label) :
          groupedActionsByHeader.set(typeHeaderLabel, new Map<number, string>([[action.id, action.label]]));
      } else {
        // on regroupe les actions sans header
        noHeaderMap.set(action.id, action.label);
      }
    });

    // on trie par ordre croissant le label des headers des types d'actions
    groupedActionsByHeader = new Map([...groupedActionsByHeader.entries()].sort((a, b) => String(a[0]).localeCompare(b[0])));
    groupedActionsByHeader.set(noHeaderLabel, noHeaderMap);

    return this.mapToKeyValues(groupedActionsByHeader);
  }

  private mapToKeyValues(map: Map<string, Map<number, string>>): KeyValue<string, KeyValue<number, string>[]>[] {
    const result: KeyValue<string, KeyValue<number, string>[]>[] = [];
    map.forEach((value: Map<number, string>, key: string) => {
      const keyValues = [];
      value.forEach((value: string, key: number) => {
        keyValues.push({ key, value })
      })
      result.push({
        key,
        value: keyValues
      })
    });
    return result;
  }
}

import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CandidateService, CustomerService, HelpersService, IndexingService } from 'common-services';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentUtcDateAdapter } from './adapters/moment-utc-date.adapter';
import { IgnoreCloseDialogDirective } from './directives/ignore-close-dialog.directive';
import { AuthGuardHelper } from './helpers/auth-guard.helper';
import { CkEditorHelper } from './helpers/ck-editor.helper';
import { FormHelper } from './helpers/form.helper';
import { HttpHelper } from './helpers/http.helper';
import { UtilHelper } from './helpers/util.helper';
import { ActionManager } from './managers/action.manager';
import { AdvancedSearchManager } from './managers/advanced-search.manager';
import { EventsManager } from './managers/events.manager';
import { IconsLoaderManager } from './managers/icons-loader.manager';
import { IndividualManager } from './managers/individual.manager';
import { InitializerProvider } from './providers/initializer.provider';
import { ImageService } from './services/image.service';
import { ParameterService } from './services/parameter.service';

// Ajoutez vos pipes natifs ici
const nativePipes = [
  DatePipe
];

// Ajoutez vos helpers ici
const helpers = [
  AuthGuardHelper,
  FormHelper,
  HttpHelper,
  CkEditorHelper,
  UtilHelper
];

// Ajoutez vos managers ici
const managers = [
  ActionManager,
  AdvancedSearchManager,
  EventsManager,
  IconsLoaderManager,
  IndividualManager
];

// Ajoutez vos modules ici
const modules = [
  HttpClientModule,
  InfiniteScrollModule
];

// Ajoutez vos services ici
const services = [
  CandidateService,
  CustomerService,
  HelpersService,
  ImageService,
  IndexingService,
  ParameterService
];

// Ajoutez les adapters injectable dans un autre adapter ici
const adapters = [
  MomentUtcDateAdapter
];

// Ajoutez les providers ici
const providers = [
  InitializerProvider
];

// Ajoutez vos factories ici
const factories = [
  {
    provide: APP_INITIALIZER,
    useFactory: (
      initializerProvider: InitializerProvider
    ) =>
      () => Promise.all([initializerProvider.init()]),
    deps: [InitializerProvider],
    multi: true
  }
];


@NgModule({
  providers: [
    ...factories,
    ...services,
    ...helpers,
    ...managers,
    ...nativePipes,
    ...adapters,
    ...providers
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ],
  declarations: [IgnoreCloseDialogDirective]
})
export class SharedModule { }

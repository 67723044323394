import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from 'common-services';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHelper {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly loggerService: LoggerService
  ) { }

  public get<T>(
    serviceName: string,
    methodName: string,
    controllerUrl: string,
    controllerName: string,
    params?: { [param: string]: string | string[] },
    isUndefinedOrNullResponseAllowed = false
  ): Promise<T> {
    const serviceAndMethod = `${serviceName}.${methodName}`;
    const calledUrl = controllerUrl + controllerName;

    return this.requestPromise(
      this.httpClient.get<T>(calledUrl, { params }),
      serviceAndMethod,
      isUndefinedOrNullResponseAllowed,
      `GET ${calledUrl}`
    );
  }

  public post<T>(
    serviceName: string,
    methodName: string,
    controllerUrl: string,
    controllerName: string,
    body?: unknown,
    isUndefinedOrNullResponseAllowed = false
  ): Promise<T> {
    const serviceAndMethod = `${serviceName}.${methodName}`;
    const calledUrl = controllerUrl + controllerName;

    return this.requestPromise(
      this.httpClient.post<T>(calledUrl, body),
      serviceAndMethod,
      isUndefinedOrNullResponseAllowed,
      `POST ${calledUrl}`
    );
  }

  public put<T>(
    serviceName: string,
    methodName: string,
    controllerUrl: string,
    controllerName: string,
    body?: unknown,
    isUndefinedOrNullResponseAllowed = false
  ): Promise<T> {
    const serviceAndMethod = `${serviceName}.${methodName}`;
    const calledUrl = controllerUrl + controllerName;

    return this.requestPromise(
      this.httpClient.put<T>(calledUrl, body),
      serviceAndMethod,
      isUndefinedOrNullResponseAllowed,
      `PUT ${calledUrl}`
    );
  }

  private requestPromise<T>(
    httpObservable: Observable<T>,
    serviceAndMethod: string,
    isUndefinedOrNullResponseAllowed: boolean,
    methodAndCalledUrl: string
  ): Promise<T> {
    const methodStartDate = new Date();
    // On transforme l'observable en promesse
    const httpPromise = httpObservable.toPromise();

    // Si la promesse echoue
    httpPromise.catch(() => {
      // On log une erreur
      this.loggerService.logError(
        serviceAndMethod,
        `Error while calling ${methodAndCalledUrl}`,
        methodStartDate
      );

      // On rejette la promesse avec un message d'erreur
      return Promise.reject(new Error(`Bad request error (from method '${serviceAndMethod}')`));
    });

    // A la fin de la promesse et dans tous les cas
    httpPromise.finally(() =>
      // On log un message d'information
      this.loggerService.logInformation(
        serviceAndMethod,
        `Called ${methodAndCalledUrl}`,
        methodStartDate)
    );

    // Si les réponses nulles sont autorisées sur cet appel
    if (isUndefinedOrNullResponseAllowed) {
      // On retourne la promesse telle quelle
      return httpPromise;
    }

    // Sinon on chaîne la promesse (avec une autre)
    const chainedHttpPromise = httpPromise.then(data => {
      // Si je n'ai pas de données
      if (data === undefined || data === null) {
        // On rejette la promesse avec un message d'erreur
        return Promise.reject(new Error(`Unexpected null response (from method '${serviceAndMethod}')`));
      }

      // On résoud la promesse
      return Promise.resolve(data);
    });

    // Si la promesse chaînée échoue (exemple: pas de données)
    chainedHttpPromise.catch((reason: Error) =>
      // On log un avertissement d'appel
      this.loggerService.logWarning(
        serviceAndMethod,
        `Warning while calling ${methodAndCalledUrl} : ${reason.message}`,
        methodStartDate
      )
    );

    return chainedHttpPromise;
  }
}

<div class="mat-form-field-container form-group">
  <mat-form-field appearance="outline" class="flex-grow-1" (keyup.enter)="setCriterion()">
    <mat-label>{{ label }}</mat-label>
    <input id="{{ identifier }}"
           [formControl]="autocompleteFormControl"
           [matAutocomplete]="autoCompleteClient"
           placeholder="{{ 'keywordEllipsis' | translate }}"
           matInput>
    <mat-spinner *ngIf="isLoading" class="inputSpinner" diameter="20"></mat-spinner>
    <mat-autocomplete #autoCompleteClient="matAutocomplete" (optionSelected)="setCriterion()">
      <mat-option *ngFor="let completion of completionItemsObservable | async" [value]="completion" class="h-auto py-2">
        <span [innerHTML]="completion | strongify:autocompleteFormControl.value"></span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

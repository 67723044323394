import 'moment-timezone';
import * as moment from 'moment';
import { FormControl, ValidatorFn, AbstractControlOptions } from '@angular/forms';
import { Observable, BehaviorSubject, Subscription, of, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import { DynamicFieldInputControlType, DynamicFieldDtoModel, DynamicFieldPredefinedValueModel, DynamicFieldSelectedValueModel, SelectedValue } from 'common-services';

type Validation = ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;

@Injectable()
export class DynamicFieldFormControl extends FormControl implements OnDestroy {

  private readonly dynamicFieldSubject: BehaviorSubject<DynamicFieldDtoModel>;
  private readonly parentControlSubject: Subject<DynamicFieldFormControl>;
  private readonly predefinedValues: Array<DynamicFieldPredefinedValueModel>;
  private readonly predefinedValuesObservable: Observable<Array<DynamicFieldPredefinedValueModel>>;
  private readonly subscriptions: Subscription;
  private readonly controlName: string;

  public get name(): string {
    return this.controlName;
  }

  public get label(): string {
    return this.field.label;
  }

  public get controlType(): DynamicFieldInputControlType {
    return this.field.inputControlType;
  }

  public get required(): boolean {
    return this.field.required;
  }

  public get hasValue(): boolean {
    return this.field.selectedValues?.length > 0 &&
      this.field.selectedValues.some(sv => (sv.value && sv.value.toString().toLowerCase() !== 'null') || sv.predefinedValueId);
  }

  public get multipleValues(): boolean {
    return this.field.multipleValues;
  }

  public get options(): Observable<Array<DynamicFieldPredefinedValueModel>> {
    return this.predefinedValuesObservable;
  }

  public set dynamicField(field: DynamicFieldDtoModel) {
    this.dynamicFieldSubject.next(field);
  }

  public set parentControl(parentControl: DynamicFieldFormControl) {
    this.parentControlSubject.next(parentControl);
  }

  public get dynamicField(): DynamicFieldDtoModel {
    return this.field;
  }

  constructor(
    private field: DynamicFieldDtoModel,
    validatorOrOpts?: Validation
  ) {
    super(undefined, validatorOrOpts);
    this.controlName = field.id.toString();
    this.subscriptions = new Subscription();
    this.parentControlSubject = new Subject<DynamicFieldFormControl>();
    this.predefinedValues = new Array<DynamicFieldPredefinedValueModel>();
    this.predefinedValuesObservable = of(this.predefinedValues);
    this.dynamicFieldSubject = new BehaviorSubject<DynamicFieldDtoModel>(field);
    this.dynamicFieldSubject.subscribe(dynamicFieldSubjectValue => this.updateControl(dynamicFieldSubjectValue));
    this.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
      if ((value instanceof Array && value.length === 0) || !value) {
        this.clearSelectedValues();
      }
      this.updateDynamicField();
    });
    this.parentControlSubject.pipe(take(1)).subscribe(parent => {
      this.parentValueChanged(parent.value);
      this.subscriptions.add(parent.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
        this.parentValueChanged(value);
      }));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dynamicFieldSubject.complete();
    this.parentControlSubject.complete();
  }

  private updateControl(field: DynamicFieldDtoModel): void {
    this.field = field;
    this.updateControValue();
    if (!this.field.parentFieldId) {
      this.predefinedValues.length = 0;
      this.predefinedValues.push(...this.field.predefinedValues);
    }
  }

  private updateControValue(): void {
    const selectedValue = this.getValueOfDynamicField();
    this.setValue(selectedValue, { onlySelf: true, emitEvent: false });
  }

  private getValueOfDynamicField(): SelectedValue {
    let value: SelectedValue;
    let selectedValue: DynamicFieldSelectedValueModel;
    if (!this.hasValue) {
      return undefined;
    }
    if (this.field.selectedValues?.length > 0) {
      selectedValue = this.field.selectedValues[0];
    }
    switch (this.controlType) {
      case DynamicFieldInputControlType.Select:
        if (this.multipleValues) {
          value = this.field.selectedValues
            .filter(sv => sv.predefinedValueId)
            .map(svm => svm.predefinedValueId);
        } else {
          value = selectedValue.predefinedValueId;
        }
        break;
      case DynamicFieldInputControlType.DateTime:
      case DynamicFieldInputControlType.Date:
        const date = moment.utc(selectedValue.value?.toString()).local(true);
        value = date?.toDate();
        break;
      case DynamicFieldInputControlType.Checkbox:
        value = selectedValue.value === 'True';
        break;
      case DynamicFieldInputControlType.Number:
        value = Number(selectedValue.value);
        break;
      case DynamicFieldInputControlType.Radio:
        value = selectedValue.predefinedValueId;
        break;
      case DynamicFieldInputControlType.Text:
      case DynamicFieldInputControlType.TextArea:
        value = selectedValue.value?.toString();
        break;
    }
    return value;
  }

  private updateDynamicField(): void {
    switch (this.controlType) {
      case DynamicFieldInputControlType.Select:
        const parsedSelectValue = this.value as Array<number> | number;
        this.setPredefinedValuesToSelectedValue(parsedSelectValue);
        break;
      case DynamicFieldInputControlType.DateTime:
      case DynamicFieldInputControlType.Date:
        const date = (this.value as moment.Moment)?.utcOffset('Z', true).format();
        this.setValueToSelectedValue(date);
        break;
      case DynamicFieldInputControlType.Checkbox:
        const booleanParsedValue = !!this.value;
        this.setValueToSelectedValue(booleanParsedValue);
        break;
      case DynamicFieldInputControlType.Number:
        const numberParsedValue = Number(this.value);
        this.setValueToSelectedValue(numberParsedValue);
        break;
      case DynamicFieldInputControlType.Radio:
        const parsedRadioValue = this.value as Array<number> | number;
        this.setPredefinedValuesToSelectedValue(parsedRadioValue);
        break;
      case DynamicFieldInputControlType.Text:
      case DynamicFieldInputControlType.TextArea:
        this.setValueToSelectedValue(this.value?.toString());
        break;
    }
  }

  private setPredefinedValuesToSelectedValue(parsedValue: Array<number> | number): void {
    const predefinedValues = this.field.predefinedValues;
    if (this.field.multipleValues) {
      this.field.selectedValues.length = 0;
      const parsedValues = parsedValue as Array<number>;
      predefinedValues
        .filter(predefinedValue => parsedValues.includes(predefinedValue.id))
        .forEach(
          (definedValue: DynamicFieldPredefinedValueModel) => {
            this.field.selectedValues.push({ predefinedValueId: definedValue.id } as DynamicFieldSelectedValueModel);
          });
    } else {
      const selectedPredefinedValue = predefinedValues.find(predefinedValue => parsedValue === predefinedValue.id);
      if (this.field.selectedValues.length > 0) {
        this.field.selectedValues[0].predefinedValueId = selectedPredefinedValue?.id;
      } else if (selectedPredefinedValue) {
        this.field.selectedValues.push({ predefinedValueId: selectedPredefinedValue.id } as DynamicFieldSelectedValueModel);
      }
    }
  }

  private setValueToSelectedValue(parsedValue: string | number | boolean | Date): void {
    if (this.field.selectedValues.length > 0) {
      this.field.selectedValues[0].value = parsedValue;
    } else {
      this.field.selectedValues = new Array<DynamicFieldSelectedValueModel>({ value: parsedValue } as DynamicFieldSelectedValueModel);
    }
  }

  private clearSelectedValues(): void {
    if (this.field.selectedValues?.length > 0 && this.field.selectedValues.some(sv => sv.id)) {
      const selectedValues = this.field.selectedValues.filter(sv => sv.id).slice();
      this.field.selectedValues = Array.from(selectedValues);
    }
  }

  private parentValueChanged(parentValue: Array<number> | number): void {
    if ((parentValue instanceof Array && parentValue.length === 0) || !parentValue) {
      this.predefinedValues.length = 0;
      this.predefinedValues.push(...this.field.predefinedValues);
    } else {
      let filteredPredefinedValues: Array<DynamicFieldPredefinedValueModel>;
      if (parentValue instanceof Array) {
        filteredPredefinedValues = this.field.predefinedValues.filter(pv =>
          pv.parentPredefinedValueId && parentValue.includes(pv.parentPredefinedValueId)
        );
      } else {
        filteredPredefinedValues = this.field.predefinedValues.filter(pv =>
          pv.parentPredefinedValueId === parentValue
        );
      }
      this.predefinedValues.length = 0;
      this.predefinedValues.push(...filteredPredefinedValues);
      const predefinedValuesIds = this.predefinedValues.map(pv => pv.id);
      if (this.multipleValues) {
        const filteredSelectedValues = this.field.selectedValues.filter(sv => sv.predefinedValueId && predefinedValuesIds.includes(sv.predefinedValueId));
        this.field.selectedValues = filteredSelectedValues;
      } else if (this.field.selectedValues[0]?.predefinedValueId && !predefinedValuesIds.includes(this.field.selectedValues[0].predefinedValueId)) {
        this.field.selectedValues[0].predefinedValueId = null;
      }
      this.updateControValue();
    }
  }
}

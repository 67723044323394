import { FormGroup, Validators } from '@angular/forms';
import { DynamicFieldFormControl } from './dynamic-field-form-control.class';
import { Observable, of } from 'rxjs';
import { DynamicFieldDtoModel } from 'common-services';

export class DynamicFieldFormGroup extends FormGroup {

  private readonly dynamicFieldControlsObservable: Observable<Array<DynamicFieldFormControl>>;
  private readonly dynamicFieldControls: Array<DynamicFieldFormControl>;

  public get formControls(): Observable<Array<DynamicFieldFormControl>> {
    return this.dynamicFieldControlsObservable;
  }

  constructor(dynamicFieldObservable: Observable<Array<DynamicFieldDtoModel>>) {
    super({});
    this.dynamicFieldControls = new Array<DynamicFieldFormControl>();
    this.dynamicFieldControlsObservable = of(this.dynamicFieldControls);
    dynamicFieldObservable.subscribe(fields => {
      if (fields) {
        fields.forEach(field => this.constructDynamicControl(field));
        fields.filter(field => field?.parentFieldId).forEach(field => this.setDynamicControlParent(field));
      }
    });
  }

  getRawValue() {
    const fields = new Array<DynamicFieldDtoModel>();
    Object.keys(this.controls).forEach(key => {
      const control = this.controls[key] as DynamicFieldFormControl;
      fields.push(control.dynamicField);
    });
    return fields;
  }

  private constructDynamicControl(field: DynamicFieldDtoModel): void {
    let control: DynamicFieldFormControl;
    const controlName = field.id.toString();
    if (this.contains(controlName)) {
      control = this.controls[controlName] as DynamicFieldFormControl;
      control.dynamicField = field;
    } else {
      if (field.required) {
        control = new DynamicFieldFormControl(field, Validators.required);
      } else {
        control = new DynamicFieldFormControl(field);
      }
      this.registerControl(controlName, control);
      this.dynamicFieldControls.push(control);
    }
  }

  private setDynamicControlParent(field: DynamicFieldDtoModel): void {
    const controlName = field.id.toString();
    const parentControlName = field.parentFieldId.toString();
    const control = this.controls[controlName] as DynamicFieldFormControl;
    if (control) {
      control.parentControl = this.controls[parentControlName] as DynamicFieldFormControl;
    }
  }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { AppState } from 'app/state/app.state';
import { CandidateModel } from 'common-services';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-open-street-map',
  templateUrl: './open-street-map.component.html',
  styleUrls: ['./open-street-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OpenStreetMapComponent implements AfterViewInit, OnDestroy {
  private readonly subscription: Subscription;
  private readonly defaultLat = 47.159840;
  private readonly defaultLong = 9.204435;
  private readonly defaultZoom = 4;
  private readonly defaultMaxZoom = 14;
  private readonly markerClusters = L.markerClusterGroup({
    iconCreateFunction: cluster => this.iconCreateFunction(cluster)
  });
  private map: L.Map;
  private candidates: Array<CandidateModel>;
  private markers: Array<L.Marker> = [];

  @ViewChild('map')
  private readonly mapContainer: ElementRef<HTMLElement>;

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly store: Store<AppState>
  ) {
    this.subscription = new Subscription();
  }

  ngAfterViewInit() {
    // Création de la map
    this.map = L.map(this.mapContainer.nativeElement, {
      zoomAnimation: true,
      fadeAnimation: true,
      markerZoomAnimation: true
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    const selectedCandidateObservale$ = this.store.select(data => data.advancedSearchResponseState);
    const selectedCandidatesSubscription = selectedCandidateObservale$.subscribe(advancedSearchResponse => {
      this.candidates = advancedSearchResponse.selectedCandidates;
      this.markers = [];
      this.markerClusters.clearLayers();
      this.populateMarkers();
      this.populateMap();
    });
    this.subscription.add(selectedCandidatesSubscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private populateMap() {
    if (this.markers.length) {
      // Ajout des markeurs a la map
      this.markers.forEach(marker => {
        if (!this.mapHasMarker(marker)) {
          this.markerClusters.addLayer(marker);
        }
      });

      // Centrer la map selon les marqueurs
      const coordinates = this.markers.map(marker => marker.getLatLng());
      const bounds = L.latLngBounds(coordinates);
      this.map.fitBounds(bounds);

      this.map.addLayer(this.markerClusters);

    } else {
      // Afficher la carte par défaut
      this.map.setView([this.defaultLat, this.defaultLong], this.defaultZoom);
    }

    // Fixer le Zoom max par défaut
    if (this.map.getZoom() > this.defaultMaxZoom) {
      this.map.setZoom(this.defaultMaxZoom);
    }
  }

  // Création des icônes personnalisées pour les clusters
  private iconCreateFunction(cluster: L.MarkerCluster) {
    const childCount = cluster.getChildCount();

    let markerClusterClass = ' marker-cluster';

    if (childCount < 10) {
      markerClusterClass += '-small';
    } else if (childCount < 50) {
      markerClusterClass += '-medium';
    } else {
      markerClusterClass += '-large';
    }

    return new L.DivIcon({
      html: `<div><span appIgnoreCloseDialog> ${childCount} </span></div>`,
      className: `marker-cluster ${markerClusterClass} `,
      iconSize: new L.Point(40, 40),
    });
  }

  // Tester si le marqueur est déjà ajouté à la map
  private mapHasMarker(marker: L.Marker): boolean {
    let hasMarker = false;

    this.map.eachLayer((layer: L.Layer) => {
      if (layer instanceof L.Marker && (JSON.stringify(layer.getLatLng()) === JSON.stringify(marker.getLatLng()))) {
        hasMarker = true;
      }
    });

    return hasMarker;
  }

  // Création des marqueurs
  private populateMarkers() {
    this.candidates.forEach(candidate => {
      if (!this.markers[candidate.id] && candidate.latitude && candidate.longitude) {
        const marker = new L.Marker({
          lat: candidate.latitude,
          lng: candidate.longitude
        });
        const popupContent = (!candidate.firstName && !candidate.lastName) ? '-' : `${candidate.lastName ?? ''} ${candidate.firstName ?? ''}`;
        marker.options.title = popupContent;
        marker.bindPopup(popupContent);
        this.markers[candidate.id] = marker;
        marker.on('click', () => {
          const candidateFormDialog = this.dialogsManager.openDialog<CandidateContainerComponent>(
            { dialog: Dialogs.CandidateForm, closeClickOutside: true, data: candidate?.id });
          if (candidate?.id !== candidateFormDialog.currentCandidate?.id) {
            candidateFormDialog.currentCandidate = candidate;
          }
        });
      }
    });
  }
}

<div [ngClass]="{'has-criterion-used': hasCriterionUsed }">
  <div class="card">
    <div class="card-header" [attr.id]="groupHtmlId">
      <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#' + groupName" aria-expanded="false" [attr.aria-controls]="groupName">
        <em class="material-icons group-icon">{{ groupModel.icon }}</em>
        <span>{{ groupModel.title | translate  }}</span>
        <em class="material-icons vis-off">keyboard_arrow_down</em>
        <em class="material-icons vis-on">keyboard_arrow_up</em>
      </button>
    </div>

    <div [attr.id]="groupName" class="collapse" data-parent="#accordion" [attr.aria-labelledby]="groupHtmlId">
      <div class="card-body" [ngSwitch]="groupModel.group">
        <ng-container *ngSwitchCase="criteriaGroup.Function">
          <app-criteria-group-function *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LastJob) || 
          advancedSearchManager.isCriterionAvailable(criterionCategory.Job)"></app-criteria-group-function>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Region">
          <app-criteria-group-region *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.Region)">
          </app-criteria-group-region>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.City">
          <app-criteria-group-city  *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.City)">
          </app-criteria-group-city>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.LastStatus">
          <app-criteria-group-last-status *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LastActionFollowUp) || 
          advancedSearchManager.isCriterionAvailable(criterionCategory.LastActionUser)"></app-criteria-group-last-status>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Status">
          <app-criteria-group-status *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ActionsFollowUp) || 
          advancedSearchManager.isCriterionAvailable(criterionCategory.ActionFollowUpComment) ||
          advancedSearchManager.isCriterionAvailable(criterionCategory.ActionUser)"></app-criteria-group-status>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Chip">
          <app-criteria-group-chips *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.Chip)">
          </app-criteria-group-chips>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Language">
          <app-criteria-group-language *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LanguageSkills)">
          </app-criteria-group-language>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Skill">
          <app-criteria-group-skill  *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ComputerSkills)">
          </app-criteria-group-skill>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Formation">
          <app-criteria-group-formation></app-criteria-group-formation>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.Experience">
          <app-criteria-group-experience *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ExperienceLevel)" >
          </app-criteria-group-experience>
        </ng-container>
        <ng-container *ngSwitchCase="criteriaGroup.TagCloudsOthers">
          <app-criteria-group-tag-clouds></app-criteria-group-tag-clouds>
        </ng-container>
      </div>
    </div>
  </div>
</div>

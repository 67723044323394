<!-- Etape -->
<ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ActionsFollowUp)">
  <div class="form-group blocCriteria">
    <app-select-group [identifier]="'criteria_group_status_select'"
                      class="form-control-select-custom"
                      [groups]="actionsFollowUpTypesHeaders"
                      [emptyOption]="emptyOptionLabel"
                      [isEmptyOptionHasValue]="true"
                      [resetAtDefault]="true"
                      [label]="stepLabel"
                      (selectedOptionsChanged)="onActionsFollowUpTypeChange($event)"></app-select-group>
  </div>
  <app-criteria-list [criterionCategory]="criterionCategory.ActionsFollowUp"></app-criteria-list>
</ng-container>
<!-- Commentaire -->
<ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ActionFollowUpComment)">
  <div class="mat-form-field-container form-group blocCriteria">
    <mat-form-field appearance="outline">
      <mat-label for="actionFollowUpCommentsSearch">{{ 'comment' | translate  }}</mat-label>
      <input id="criteria_group_status_comment_input"
              matInput
              type="text"
              placeholder="{{ 'keywordEllipsis' | translate  }}"
              [(ngModel)]="actionFollowUpCommentValue"
              (keyup.enter)="addActionFollowUpCommentsCriterion()" />
      <button mat-button
              *ngIf="actionFollowUpCommentValue"
              matSuffix mat-icon-button
              attr.aria-label="{{ 'search' | translate  }}"
              (click)="addActionFollowUpCommentsCriterion()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <app-criteria-list [criterionCategory]="criterionCategory.ActionFollowUpComment"></app-criteria-list>
</ng-container>
<!-- Responsable -->
<ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.ActionUser)">
  <form>
    <div class="mat-form-field-container form-group blocCriteria">
      <mat-form-field appearance="outline">
        <mat-label for="actionResponsibleSearch">{{ 'actionResponsible' | translate  }}</mat-label>
        <input id="criteria_group_status_responsible_select"
                matInput
                type="text"
                name="actionResponsibleSearch"
                placeholder="{{ 'keywordEllipsis' | translate  }}"
                [formControl]="responsibleControl"
                [matAutocomplete]="autoCompleteResponsible" />
        <mat-autocomplete #autoCompleteResponsible="matAutocomplete"
                          [displayWith]="individualManager.responsibleDisplayFunction"
                          (optionSelected)="addActionResponsibleCriterion($event.option.value)">
          <mat-option id="criteria_group_status_responsible_select_{{i}}" *ngFor="let individual of responsibleCompletionOptions;index as i;"
                      [value]="individual" class="h-auto py-2">
            {{individualManager.responsibleDisplayFunction(individual)}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <app-criteria-list [criterionCategory]="criterionCategory.ActionUser"></app-criteria-list>
  </form>
</ng-container>

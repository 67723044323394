import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconsLoaderManager {
  readonly rootFolder = '/assets/images/files/';

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) { }

  public loadCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'word_document',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.rootFolder + 'doc.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'pdf_document',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.rootFolder + 'pdf.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'jpg_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.rootFolder + 'jpeg.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'envelope',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.rootFolder + 'envelope.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'attachment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.rootFolder + 'attachment.svg')
    );
  }
}

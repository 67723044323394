<div>
  <div>
    <button class="close" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
    <h1 class="text-uppercase text-primary font-weight-bold" mat-dialog-title>{{ 'attachToPosition' | translate  }}</h1>
  </div>
  
  <mat-dialog-content class="custom-padding">
    <div class="d-flex has-padding">
      <app-select [identifier]="'search_position_component_position_status_select'"
                  [class]="'mb-4 mr-3'"
                  [options]="positionsStatusObservable | async"
                  [formControl]="positionStatusFormControl"
                  [selectedOption]="selectedPositionStatus"
                  [emptyOption]="emptyOptionLabel"
                  [isEmptyOptionHasValue]="true"
                  [label]="labelPositionStatus">
      </app-select>

      <mat-form-field appearance="outline" class="flex-grow-1">
        <mat-label>{{ 'selectAnAgency' | translate  }}</mat-label>
        <input id="search_position_component_agency_select" [formControl]="agencyFormControl" [errorStateMatcher]="errorMatcher" [matAutocomplete]="autoCompleteClient"
                matInput>
        <mat-autocomplete #autoCompleteClient="matAutocomplete" [displayWith]="displayAgency.bind(this)">
          <mat-option id="search_position_component_agency_select_{{ i }}" *ngFor="let agency of filteredAgencies | async;index as i;" [value]="agency.id" class="h-auto py-2">
            {{ agency.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="agencyFormControl.hasError('invalid')">
          {{'invalidValue' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    
    <div class="has-padding background-grey">
      <mat-form-field appearance="outline" class="search d-block bg-white mb-4">
        <mat-icon color="primary" class="mat-icon-search" matPrefix>search</mat-icon>
        <input id="search_position_reference_input" [formControl]="filterFormControl" placeholder="{{ 'searchLabelOrReference' | translate  }}" class="ml-2"
                matInput>
      </mat-form-field>
      <mat-table id="search_position_table" [dataSource]="positionsSource" class="mat-elevation-z8">
        <!-- Position Status Column -->
        <ng-container matColumnDef="statusId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'state' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position" class="font-weight-bold">
            <span appIgnoreCloseDialog> {{ getStatusLabel(position?.statusId) }}</span>
          </mat-cell>
        </ng-container>
        <!-- title Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'label' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position" class="font-weight-bold text-primary">
            <div class="truncated" matTooltip="{{ position?.title }}" appIgnoreCloseDialog>{{ position?.title }}</div>
          </mat-cell>
        </ng-container>
        <!-- Reference Column -->
        <ng-container matColumnDef="reference">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'reference' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position">
            <div class="truncated" matTooltip="{{ position?.reference }}" appIgnoreCloseDialog>
              {{ position?.reference }}
            </div>
          </mat-cell>
        </ng-container>
        <!-- Customer Column -->
        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'customer' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position">
            <div class="truncated" matTooltip="{{ position?.customer?.label }}" appIgnoreCloseDialog>
              {{ position?.customer?.label }}
            </div>
          </mat-cell>
        </ng-container>
        <!-- Agency Column -->
        <ng-container matColumnDef="agencyId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'agency' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position" class="font-weight-bold">
            <div class="truncated" matTooltip="{{ getAgencyLabel(position?.agencyId) }}" appIgnoreCloseDialog>
              {{ getAgencyLabel(position?.agencyId) }}
            </div>
          </mat-cell>
        </ng-container>
        <!-- Manager Column -->
        <ng-container matColumnDef="manager">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'humanResourcesManager' | translate  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let position">
            <div class="truncated" matTooltip="{{ position?.consultant?.label }}" appIgnoreCloseDialog>{{ position?.consultant?.label }}</div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;" (click)="onRowClick(row)">
        </mat-row>
      </mat-table>
      <app-pagination
        #pagination
        [length]="paginationLength"
        [pageSize]="paginationSize"
        [showFirstLastButtons]="true"
        [displayInput]="false"
      ></app-pagination>
    </div>
  </mat-dialog-content>
    
</div>

<div class="candidateContainer d-flex align-items-center" [ngClass]="{ 'isSelected': isSelected, 'duplicateCandidat': !candidateModel.visibility}">
  <div class="candidate-sel">
    <app-bright-checkbox *ngIf="candidateModel.visibility" [checked]="isSelected" class="mx-auto">
    </app-bright-checkbox>
  </div>
  <div class="candidate-profile-picture">
    <app-profile-picture [candidateModel]="candidateModel" class=" profile-picture mx-auto"></app-profile-picture>
  </div>
  <div class="candidate-profil" data-id="{{ candidateModel.id }}">
    <h2 (click)="openCandidateForm($event)" appIgnoreCloseDialog>
      <ng-container *ngIf="!candidateModel.lastName && !candidateModel.firstName; else elseBlock">
        -
      </ng-container>
      <ng-template #elseBlock>
        {{ candidateModel.lastName | uppercase }}
        {{ candidateModel.firstName }}
      </ng-template>
    </h2>
    <div class="d-inline-block align-top candidateActionsBtn">
      <app-actions-candidate *ngIf="candidateModel.visibility" [candidateIds]="[candidateModel.id]" [multipleActions]="false"></app-actions-candidate>
    </div>
    <div class="actualJobAndAgency">
      <span class="data-high text-uppercase">{{ candidateModel.currentPosition }}</span>
      <span class="data-separator" *ngIf="candidateModel.currentPosition && candidateModel.currentCompany">|</span>
      <span>{{ candidateModel.currentCompany }}</span>
    </div>
    <div class="d-flex flex-wrap moreinfo">
      <div class="candidate-phone" *ngIf="candidateModel.mobilePhone; else personalPhone">
        {{ candidateModel.mobilePhone }}
      </div>
      <ng-template #personalPhone>
        <div class="candidate-phone" *ngIf="candidateModel.personalPhone">
          {{ candidateModel.personalPhone }}
        </div>
      </ng-template>
      <div class="email" *ngIf="candidateModel.emailAddress">
        <span class="data-separator" *ngIf="candidateModel.mobilePhone || candidateModel.personalPhone">|</span>
        <a (click)="openSendMailForm($event)">{{ candidateModel.emailAddress }}</a>
      </div>
      <div *ngIf="candidateModel.birthDate">
        <span class="data-separator" *ngIf="candidateModel.emailAddress || candidateModel.mobilePhone">|</span>
        {{ candidateModel.age }} {{ 'yearsOld' | translate }}
      </div>
      <address class="candidate-address"
               *ngIf="candidateModel.zipCode || candidateModel.city || candidateModel.originCountry?.label">
        <span>
          <span class="data-separator"
                *ngIf="candidateModel.emailAddress || candidateModel.mobilePhone || candidateModel.age">|</span>
          {{ candidateModel.zipCode }}
          <span *ngIf="candidateModel.zipCode && candidateModel.city">&nbsp;</span>
          {{ candidateModel.city }}
          <span *ngIf="(candidateModel.zipCode || candidateModel.city) && candidateModel.originCountry?.label">&nbsp;-&nbsp;</span>
          {{ candidateModel.originCountry?.label }}
        </span>
      </address>
    </div>
    <div *ngIf="lastCandidateCandidature" class="candidatures d-inline-flex align-items-center">
      <app-attached-documents [attachments]="attachments" [enablePreview]="enablePreview" [candidateId]="candidateModel.id"></app-attached-documents>
      <div class="cand_position">
        <div>
          <span class="last-candidature-date">{{ lastCandidateCandidature.candidatureDate | toLocalDate:'short' }}</span><br />
          <span class="first-candidature-position-label">
            <ng-container *ngIf="lastCandidateCandidature.position; else elseBlock">
              {{ lastCandidateCandidature.position.title }} - {{ lastCandidateCandidature.position.customer.label }}
            </ng-container>
            <ng-template #elseBlock>-</ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="candidate-social">
    <div class="chip-block">
      <app-chip-card [chip]="candidateModel.chip"></app-chip-card>
    </div>
    <div class="social-networks">
      <app-candidate-social-networks *ngIf="candidateModel.visibility" [candidateModel]="candidateModel"></app-candidate-social-networks>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { CandidateModel, CommonService, SocialNetworkModel } from 'common-services';

@Component({
  selector: 'app-candidate-social-networks',
  templateUrl: './candidate-social-networks.component.html',
  styleUrls: ['./candidate-social-networks.component.scss']
})
export class CandidateSocialNetworksComponent implements OnInit {

  @Input() candidateModel: CandidateModel;

  public socialNetworksParameters: ReadonlyArray<SocialNetworkModel>;

  constructor(
    private readonly commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.getActiveSocialNetworks()
      .then(
        data => this.socialNetworksParameters = data
      );
  }

  public loadSocialNetworkProfile(socialNetwork: string, event: Event) {
    window.open(this.buildProfileLink(socialNetwork), '_blank');
    event.stopPropagation();
  }

  private buildProfileLink(socialNetwork: string) {
    return this.socialNetworksParameters
      .find(sn => sn.socialNetworkName === socialNetwork)
      .socialNetworkUrl
      .replace('$PRENOM$', this.candidateModel.firstName)
      .replace('$NOM$', this.candidateModel.lastName);
  }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates, searchCompletions, searchCompletionsSuccess } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { AutoCompleteModel, CriterionCategory } from 'common-services';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';

@Component({
  selector: 'app-criterion-autocomplete',
  templateUrl: './criterion-autocomplete.component.html',
  styleUrls: ['./criterion-autocomplete.component.scss']
})
export class CriterionAutocompleteComponent implements OnDestroy {
  @Input() identifier: string;
  @Input() label: string;
  @Input() criterionCategory: CriterionCategory;

  public autocompleteFormControl: FormControl;
  public completionItemsObservable: Observable<Array<string>>;
  public isLoading: boolean;
  private readonly completionItems: Array<string>;
  private readonly subscriptions: Subscription;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions
  ) {
    this.subscriptions = new Subscription();
    this.completionItems = new Array<string>();
    this.completionItemsObservable = of(this.completionItems);
    this.autocompleteFormControl = new FormControl();
    const autocompleteSubscription = this.autocompleteFormControl.valueChanges.pipe(
      map(value => value?.trim()),
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(value => {
      if (value) {
        this.searchCompletions(value);
      } else {
        this.reset();
      }
    });
    this.subscriptions.add(autocompleteSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public searchCompletions(value: string) {
    if (!this.criterionCategory || !value) {
      this.completionItems.splice(0, this.completionItems.length);
    } else {
      this.isLoading = true;
      const autoComplete = { criterionCategory: this.criterionCategory, value } as AutoCompleteModel;
      this.store.dispatch(searchCompletions({payload: autoComplete}));
      
      const actionSubscription = this.actions.pipe(
        ofType(searchCompletionsSuccess),
        first()
      )
      .subscribe(
        result => {
          const data = result.payload as AutoCompleteModel[];
          this.isLoading = false;
          this.completionItems.splice(0, this.completionItems.length);
          this.completionItems.push(...data.map(suggestion => suggestion.value));
        }
      );
  
      this.subscriptions.add(actionSubscription);
    }
  }

  public setCriterion() {
    const value: string = this.autocompleteFormControl.value;
    // On ajoute le critère à la liste des critères raffinés du manager si une valeur est passée en paramètre
    // On vérifie que la recherche ne soit pas nulle ou vide
    if (value) {
      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());
      const refinedCriterion = new RefinedCriterionViewModel(this.criterionCategory, value, CriterionPriority.Required);
      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
    this.reset();
  }

  private reset() {
    this.autocompleteFormControl.reset();
    this.completionItems.splice(0, this.completionItems.length);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentPopupComponent } from 'app/pages/advanced-search/search-results/attachment-popup/attachment-popup.component';
import { SHOW_DOCUMENT_PREVIEW_TIMEOUT } from 'app/shared/constants/time.constants';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { AttachmentModel, CandidateService, FileHelper } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';

@Component({
  selector: 'app-attached-file',
  templateUrl: './attached-file.component.html',
  styleUrls: ['./attached-file.component.scss']
})
export class AttachedFileComponent implements OnInit {
  @Input() attachment: AttachmentModel;
  @Input() preview = true;
  @Input() previewDialogData: any;
  private setTimeOutConst: number;
  private errorMessage: string;

  constructor(
    private readonly translateService: TranslateService,
    private readonly fileHelper: FileHelper,
    private readonly snackbarService: CustomSnackBarService,
    private readonly dialogsManager: DialogsManager,
    private readonly candidateService: CandidateService
  ) { }

  public ngOnInit() {
    this.translateService.get('errorWhenDownloadFile').subscribe(res => this.errorMessage = res);
  }

  public getAttachmentIconName(attachment: AttachmentModel): string {
    if (!attachment?.fileName) {
      return undefined;
    }

    const ext = this.fileHelper.getFileExtension(attachment.fileName);
    return this.fileHelper.getIconNameFromExtension(ext);
  }

  /**
   * Téléchargement du fichier
   * @param attachment Le fichier à télécharger
   */
  public onDownload(attachment: AttachmentModel): void {
    if (attachment.fileName) {
      // Récupération du fichier depuis le serveur
      this.candidateService
        .getCandidateAttachmentById(attachment.candidateId, attachment.id, true)
        .then(file => this.fileHelper.downloadFile(file))
        .catch(() => {
          // Affichage d'un message d'erreur si le fichier n'est pas téléchargé
          this.snackbarService.showDanger(this.errorMessage);
        });
    }
  }

  /**
   * Affichage du preview d'une piece jointe
   * @param attachment Le fichier à visualiser
   */
  public onPreview(attachment: AttachmentModel) {
    if (this.preview) {
      if (this.dialogsManager.isDialogOpen(Dialogs.Attachment)) {
        this.openAttachmentDialog(attachment);
      } else {
        this.setTimeOutConst = window.setTimeout(() => {
          this.openAttachmentDialog(attachment);
        }, SHOW_DOCUMENT_PREVIEW_TIMEOUT);
      }
    }
  }

  private openAttachmentDialog(attachment: AttachmentModel) {
    this.dialogsManager.openDialog<AttachmentPopupComponent>(
      { dialog: Dialogs.Attachment, closeClickOutside: true, data: this.previewDialogData }).currentAttachmentFile = attachment;
  }

  /**
   * Fermer l'affichage du piece jointe
   */
  public onMouseLeave() {
    if (this.preview) {
      window.clearTimeout(this.setTimeOutConst);
    }
  }
}

export enum AdvancedSearchRequestSource {
  SearchInit = 0,
  SavedSearchLoad = 1,
  PageChange = 2,
  InfiniteScrollFetch = 3,
  AddCandidate = 4,
  SortChange = 5,
  MatchByPositionUrl = 6,
  MatchByPositionId = 7
}

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ImageService } from 'app/shared/services/image.service';

@Component({
  selector: 'app-show-svg',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() svgfiledata = '';
  @Input() toggle: boolean | null = null;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();

  public svgel: SafeHtml = '';
  private _toggled = false;

  constructor(
    private readonly imageService: ImageService,
    private readonly sanitiser: DomSanitizer
  ) { }

  @HostBinding('class.on')
  @Input()
  get toggled(): boolean {
    return this._toggled;
  }

  set toggled(val: boolean) {
    this._toggled = val;
    this.toggleChange.emit(this.toggled);
  }

  public ngOnInit(): void {
    this.imageService.loadImage(this.svgfiledata).then(
      response => {
        this.svgel = this.sanitiser.bypassSecurityTrustHtml(response.toString());
      });
    if (this.toggle !== null) {
      this._toggled = this.toggle;
    }
  }

  public click() {
    if (this.toggle !== null) {
      this.toggled = !this.toggled;
      this.toggleChange.emit(this.toggled);
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditingMode } from 'app/shared/enums/editing-mode.enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchState } from 'app/shared/models/state/advanced-search-state.model';
import { AppState } from 'app/state/app.state';
import { AdvancedSearchModel } from 'common-services';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import * as AdvancedSearchActions from 'app/state/actions/advanced-search.action';

@Component({
  selector: 'app-saved-requests-list',
  templateUrl: './saved-requests-list.component.html'
})
export class SavedRequestsListComponent implements OnInit, OnDestroy {

  private readonly subscriptions: Subscription;
  private searchesObservable$: Observable<AdvancedSearchState>;

  public searches: Array<AdvancedSearchModel>;
  public editingMode = EditingMode;
  public status: StateStatus;
  public stateStatusEnum = StateStatus;

  constructor(
    private readonly store: Store<AppState>
  ) {
    this.subscriptions = new Subscription();
  }

  public ngOnInit() {
    // dispatcher l'action pour récupérer les recherches sauvegardés
    this.store.dispatch(AdvancedSearchActions.getAdvancedSearch());
    
    // Récupérer les recherches suvgardées depuis le store
    this.searchesObservable$ = this.store.select(data => data.advancedSearchState);
    const storeSubscription = this.searchesObservable$.subscribe(data => {
      this.status = data.dataState;
      this.searches = _.orderBy(data.advancedSearches, ['id'], ['desc']);
    });

    this.subscriptions.add(storeSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public isLoading(status: StateStatus): boolean {
    return [
      this.stateStatusEnum.LOADING,
      this.stateStatusEnum.DELETING,
      this.stateStatusEnum.UPDATING,
      this.stateStatusEnum.CLONING,
      this.stateStatusEnum.ADDING
    ].includes(status);
  }

  public isLoadedWithSuccess(status: StateStatus): boolean {
    return [
      this.stateStatusEnum.LOADED,
      this.stateStatusEnum.DELETED,
      this.stateStatusEnum.UPDATED,
      this.stateStatusEnum.CLONED,
      this.stateStatusEnum.ADDED,
      this.stateStatusEnum.LOADINGCRITERION,
      this.stateStatusEnum.ERRORLOADINGCRITERION
    ].includes(status);
  }

  public trackByFunction(index: number, searche: AdvancedSearchModel) {
    return searche ? index : undefined;
  }
}

<mat-form-field appearance="outline" class="mat-form-field-container search d-block mb-3">
  <mat-icon matPrefix color="primary" class="mat-icon-search">search</mat-icon>
  <input matInput type="search" placeholder="{{'search' | translate }}" [formControl]="searchFormControl" class="pl-2">
</mat-form-field>
<div class="wrapperContent wrapperContentCandidatures">
  <div class="scroll-wrapper">
    <div class="scroll grey with-padding">
      <mat-progress-bar *ngIf="isFetching" mode="indeterminate"></mat-progress-bar>
      <mat-table [dataSource]="candidaturesSource" class="mat-elevation-z8 candidat_applications">
        <ng-container matColumnDef="reject">
          <mat-header-cell *matHeaderCellDef class="colstep colicon">
            <mat-icon matTooltip="{{'rejectAction' | translate }}">clear</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colstep colicon">
            <button mat-icon-button class="rejectButton" matTooltip="{{'rejectAction' | translate }}">
              <mat-icon [ngClass]="{'activeRejectButton':getIsButtonActive(candidature,'reject')}">clear</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="pending">
          <mat-header-cell *matHeaderCellDef class="colstep colicon coltext" matTooltip="{{'pendingAction' | translate }}">
            ?
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colstep colicon">
            <button mat-icon-button class="pendingButton textButton" matTooltip="{{'pendingAction' | translate }}">
              <span [ngClass]="{'activePendingButton':getIsButtonActive(candidature,'pending')}">?</span>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accept">
          <mat-header-cell *matHeaderCellDef class="colstep colicon">
            <mat-icon matTooltip="{{'acceptAction' | translate }}">play_arrow</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colstep colicon">
            <button mat-icon-button class="acceptButton" matTooltip="{{'acceptAction' | translate }}">
              <mat-icon [ngClass]="{'activeAcceptButton':getIsButtonActive(candidature,'accept')}">play_arrow</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deny">
          <mat-header-cell *matHeaderCellDef class="colicon">
            <mat-icon matTooltip="{{'denyAction' | translate }}">stop</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <button mat-icon-button class="denyButton" matTooltip="{{'denyAction' | translate }}">
              <mat-icon [ngClass]="{'activeDenyButton':getIsButtonActive(candidature,'deny')}">stop</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="like">
          <mat-header-cell *matHeaderCellDef class="colicon">
            <mat-icon matTooltip="{{'portalManagerAction' | translate }}">thumb_up</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <button mat-icon-button class="likeButton" matTooltip="{{'portalManagerAction' | translate }}">
              <mat-icon [ngClass]="{'activeLikeButton':getIsButtonActive(candidature,'like')}">thumb_up</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef matTooltip="{{'emailAction' | translate }}" class="colicon coltext">
            @
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <button mat-icon-button class="emailButton textButton" *ngIf="getIsButtonActive(candidature,'email')"
                    matTooltip="{{'emailAction' | translate }}" (mouseenter)="openEmailPreview(candidature.emailBody)"
                    (mouseleave)="mouseLeave()">
              <span class="activeEmailButton">@</span>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="attachment1">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="colicon">
            {{'attachment' | translate  | uppercase}}1
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <app-attached-file [attachment]="candidature.candidatureAttachments[0]?.attachment" [previewDialogData]="candidature.candidateId"></app-attached-file>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="attachment2">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="colicon">
            {{'attachment' | translate  | uppercase}}2
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <app-attached-file [attachment]="candidature.candidatureAttachments[1]?.attachment" [previewDialogData]="candidature.candidateId"></app-attached-file>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="attachment3">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="colicon">
            {{'attachment' | translate  | uppercase}}3
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon">
            <app-attached-file [attachment]="candidature.candidatureAttachments[2]?.attachment" [previewDialogData]="candidature.candidateId"></app-attached-file>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="coldate coltext">
            {{'date' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="coldate coltext">
            <span *ngIf="candidature.candidatureDate">
              {{candidature.candidatureDate | toLocalDate:'short'}}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="coltext">
            {{'positionLabel' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="coltext">
            <div class="truncated" matTooltip="{{(candidature.position) ? candidature.position.title : '-'}}">
              {{(candidature.position) ? candidature.position.title : '-'}}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="client">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="colcustomer coltext">
            {{'client' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colcustomer coltext">
            <div class="truncated" matTooltip="{{candidature.position?.customer?.label}}">
              {{candidature.position?.customer?.label}}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="origin">
          <mat-header-cell *matHeaderCellDef class="colorigin coltext">
            {{'origin' | translate  | uppercase}}
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colorigin coltext">
            <div class="truncated" matTooltip="{{candidature.origin?.label}}">
              {{candidature.origin?.label}}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete" stickyEnd>
          <mat-header-cell *matHeaderCellDef class="colicon coldel">
            <mat-icon matTooltip="{{'delete' | translate }}" class="d-none">delete</mat-icon>
          </mat-header-cell>
          <mat-cell *matCellDef="let candidature" class="colicon coldel">
            <button mat-icon-button class="deleteButton" matTooltip="{{'delete' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
      </mat-table>
      <app-pagination
        #pagination
        [length]="paginationLength"
        [pageSize]="paginationSize"
        [showFirstLastButtons]="true"
        [displayInput]="false"
      ></app-pagination>
    </div>
  </div>
</div>


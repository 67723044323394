import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-horizontal-switch',
  templateUrl: './horizontal-switch.component.html',
  styleUrls: ['./horizontal-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalSwitchComponent {

  @Input() checked = false;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

  public get cx() {
    return this.checked ? 32 : 12;
  }

  public check() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}

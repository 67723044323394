<div class="d-flex flex-grow-1 align-items-center">
  <div class="candidate-profile-picture">
    <app-profile-picture [candidateModel]="candidate" class="profile-picture mx-auto">
    </app-profile-picture>
  </div>
  <div class="candidate-profil" data-id="{{ candidate.id }}">
    <div class="candidate-name">
      <ng-container *ngIf="!candidate.lastName && !candidate.firstName; else elseBlock">
        -
      </ng-container>
      <ng-template #elseBlock>
        {{ candidate.lastName | uppercase }}
        {{ candidate.firstName }}
      </ng-template>
    </div>
    <div *ngIf="candidate.currentPosition || candidate.currentCompany" class="actualJobAndAgency">
      <span class="data-high">{{ candidate.currentPosition }} </span>
      <span class="data-separator" *ngIf="candidate.currentPosition && candidate.currentCompany">|</span>
      <span class="candidate-company">
        {{ candidate.currentCompany }}
      </span>
    </div>
    <div class="d-flex moreinfo">
      <div class="candidate-phone" *ngIf="candidate.mobilePhone; else personalPhone">
        {{ candidate.mobilePhone }}
      </div>
      <ng-template #personalPhone>
        <div class="candidate-phone" *ngIf="candidate.personalPhone">
          {{ candidate.personalPhone }}
        </div>
      </ng-template>
      <div *ngIf="candidate.emailAddress" class="candidate-email email">
        <span class="data-separator" *ngIf="candidate.mobilePhone || candidate.personalPhone">|</span>
        <a (click)="openSendMailForm()">{{ candidate.emailAddress }}</a>
      </div>
      <div *ngIf="candidate.birthDate">
        <span class="data-separator" *ngIf="candidate.emailAddress || candidate.mobilePhone">|</span>
        {{ candidate.age }} {{ 'yearsOld' | translate  }}
      </div>
      <address class="candidate-address" *ngIf="candidate.zipCode || candidate.city || candidate.originCountry?.label">
        <span>
          <span class="data-separator" *ngIf="candidate.mobilePhone || candidate.emailAddress">|</span>
          {{ candidate.zipCode }}
          <span *ngIf="candidate.zipCode && candidate.city">&nbsp;</span>
          {{ candidate.city }}
          <span *ngIf=" (candidate.zipCode || candidate.city) && candidate.originCountry?.label">&nbsp;-&nbsp;</span>
          {{ candidate.originCountry?.label }}
        </span>
      </address>
    </div>
    <div class="d-inline-flex align-items-center mt-2">
      <div class="chip-block">
        <app-candidate-chip [candidateObservable]="candidateObservable"></app-candidate-chip>
      </div>
      <div class="actions-block ml-3">
        <app-actions-candidate [candidateIds]="[candidate.id]"></app-actions-candidate>
      </div>
    </div>
  </div>
  <div class="candidate-social position-absolute">
    <div class="social-networks">
      <app-candidate-social-networks [candidateModel]="candidate"></app-candidate-social-networks>
    </div>
  </div>
</div>

import { SemanticOptionModel } from 'common-services';

export class SemanticOptionViewModel implements SemanticOptionModel {
  constructor(
    public value: string,
    public selected = false,
    public label?: string,
  ) { }
  id: number;
  semanticId: number;
  semantic: string;
}

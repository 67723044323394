<div id="login">
  <div class="form-wrapper">
    <form [formGroup]="loginForm">

      <div *ngIf="error" class="alert alert-danger">
        {{ error }}
      </div>

      <mat-form-field class="example-full-width">
        <mat-label>{{ 'userName' | translate }}</mat-label>
        <input type="text" matInput formControlName="login">
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>{{ 'password' | translate }}</mat-label>
        <input type="password" matInput formControlName="password">
      </mat-form-field>

      <div class="input-wrapper">
        <button [ngClass]="{ 'spinner' : loading }" color="primary" (click)="onSubmit()" mat-raised-button>
          {{ 'login' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
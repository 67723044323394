import { NgModule } from '@angular/core';
import { CriteriaMenuModule } from './criteria-menu/criteria-menu.module';
import { RefinedCriteriaModule } from './refined-criteria/refined-criteria.module';
import { SavedRequestsModule } from './saved-requests/saved-requests.module';
import { SearchResultsModule } from './search-results/search-results.module';

// Ajoutez vos modules ici
const modules = [
  CriteriaMenuModule,
  RefinedCriteriaModule,
  SavedRequestsModule,
  SearchResultsModule
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ]
})
export class AdvancedSearchModule { }

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent {

  private static _UNIQUE_NAME_ID = 0;

  @Input() options: Array<SelectedItemModel>;
  @Output() selectedOptionsChanged: EventEmitter<number> = new EventEmitter<number>();

  protected name = 'radio-group-' + RadioButtonGroupComponent.UNIQUE_NAME_ID;

  private static get UNIQUE_NAME_ID(): number {
    const id = RadioButtonGroupComponent._UNIQUE_NAME_ID;
    RadioButtonGroupComponent._UNIQUE_NAME_ID++;
    return id;
  }

  public onToggle(selectedValue: string) {
    this.options.forEach(option => option.selected = option.value === +selectedValue);
    // On émet l'élément sélectionné
    this.selectedOptionsChanged.emit(+selectedValue);
  }
}

import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'app/generic-components/confirm-dialog/confirm-dialog.component';
import { PaginationComponent } from 'app/generic-components/pagination/pagination.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { ConfirmDialogModel } from 'app/shared/models/confirm-dialog.model';
import { AttachmentModel, CandidateModel, CandidateService, FileHelper, FileModel } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-candidate-attachments-list',
  templateUrl: './candidate-attachments-list.component.html',
  styleUrls: ['./candidate-attachments-list.component.scss']
})
export class CandidateAttachmentsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() candidate: Observable<CandidateModel>;
  @ViewChild('pagination', { static: true }) paginator: PaginationComponent;
  private readonly observablesSubscriptions: Subscription;
  private candidateAttachmentDeleted: string;
  private deleteCandidateAttachmentFailed: string;
  private dialogMessage: string;
  private dialogTitle: string;
  private snackbarMessage: string;
  private errorMessage: string;
  private readonly fileUploaderCandidateIdSubject: ReplaySubject<number>;

  public savedFiles = new Array<FileModel>();
  public attachmentFilesDataSource: MatTableDataSource<AttachmentModel>;
  public columns: Array<string>;
  public candidateAttachmentFilesSubject: Subject<Array<AttachmentModel>>;
  public attachmentFileTypes = new Array<KeyValue<number, string>>();
  public attachmentFilecontrol: FormControl;
  public fileUploaderTranslation: Object;
  public candidateId: number;
  public isLoading = true;

  constructor(
    private readonly snackbarService: CustomSnackBarService,
    private readonly translateService: TranslateService,
    private readonly dialogManager: DialogsManager,
    private readonly fileHelper: FileHelper,
    private readonly candidateService: CandidateService
  ) {
    this.columns = ['attachmentFileTypeId', 'label', 'fileName', 'receiptDate', 'delete'];
    this.attachmentFilesDataSource = new MatTableDataSource<AttachmentModel>();
    this.candidateAttachmentFilesSubject = new Subject<Array<AttachmentModel>>();
    this.fileUploaderCandidateIdSubject = new ReplaySubject<number>();
    this.observablesSubscriptions = new Subscription();
    this.attachmentFilecontrol = new FormControl();
  }

  ngAfterViewInit() {
    this.attachmentFilesDataSource.paginator = this.paginator.paginator;
  }

  ngOnInit() {
    this.translateService.get([
      'candidateAttachmentDeleted',
      'deleteCandidateAttachmentFailed',
      'deleteAttachmentFileConfirmationLabel',
      'deleteConfirmationTitle',
      'candidateAttachmentTypeUpdated',
      'updateCandidateAttachmentTypeFailed'
    ]).subscribe(translation => {
      this.candidateAttachmentDeleted = translation.candidateAttachmentDeleted;
      this.deleteCandidateAttachmentFailed = translation.deleteCandidateAttachmentFailed;
      this.dialogMessage = translation.deleteAttachmentFileConfirmationLabel;
      this.dialogTitle = translation.deleteConfirmationTitle;
      this.snackbarMessage = translation.candidateAttachmentTypeUpdated;
      this.errorMessage = translation.updateCandidateAttachmentTypeFailed;
    });

    // Charger les traductions du composant File-Uploader
    this.translateService.get([
      'files',
      'maxFilesUploadError',
      'maxFileSizeError',
      'fileTypeError',
      'fileTypeInfo',
      'fileUploadError',
      'deleteCandidateAttachmentFailed',
      'invalidFileModel',
      'dragDropFiles',
      'or',
      'addFiles'
    ]).subscribe(translation => {
      this.fileUploaderTranslation = translation;
    });

    this.candidateService.getCandidateAttachmentTypes().then((types) => {
      this.attachmentFileTypes = types.map(fileType => ({ key: fileType.id, value: fileType.label }));
    });
    const candidateSubscription = this.candidate.subscribe((candidate) => {
      // On initialise les fichiers uploader lors du changement de candidat
      this.isLoading = true;
      this.candidateId = candidate.id;
      this.fileUploaderCandidateIdSubject.next(this.candidateId);
      this.candidateService.getCandidateAttachments(this.candidateId).then((data) => {
        this.candidateAttachmentFilesSubject.next(data);
      });
    });

    const attachmentsSubscription = this.candidateAttachmentFilesSubject.subscribe(
      attachments => {
        attachments.sort((a, b) => a.id > b.id ? -1 : 1);
        this.attachmentFilesDataSource.data = attachments;
        this.isLoading = false;
      }
    );

    this.observablesSubscriptions.add(candidateSubscription);
    this.observablesSubscriptions.add(attachmentsSubscription);
  }

  ngOnDestroy(): void {
    if (this.observablesSubscriptions) { this.observablesSubscriptions.unsubscribe(); }
    if (this.candidateAttachmentFilesSubject) { this.candidateAttachmentFilesSubject.complete(); }
    if (this.fileUploaderCandidateIdSubject) { this.fileUploaderCandidateIdSubject.complete(); }
  }

  public get candidateIdObservable(): Observable<number> {
    return this.fileUploaderCandidateIdSubject;
  }

  public get candidateAttachmentFilesObservable(): Observable<Array<AttachmentModel>> {
    return this.candidateAttachmentFilesSubject;
  }

  public getAttachmentIconName(attachment: string): string {
    const ext = this.fileHelper.getFileExtension(attachment);
    return this.fileHelper.getIconNameFromExtension(ext);
  }

  public uploadNewFiles() {
    this.isLoading = true;
    this.refreshCandidateAttachmentsList(this.candidateId);
  }

  public onAttachmentTypeChange(attachmentTypeId: number, attachment: AttachmentModel) {
    attachment.attachmentTypeId = attachmentTypeId;
    if (attachment?.attachmentTypeId) {
      this.candidateService.updateCandidateAttachment(this.candidateId, attachment.id, attachment).then((updatedFile) => {
        if (updatedFile) {
          this.snackbarService.showSuccess(this.snackbarMessage);
        } else {
          this.snackbarService.showDanger(this.errorMessage);
        }
      });
    }
  }

  public deleteCandidateAttachmentFile(attachment: AttachmentModel) {
    // Préparation de la dialog de confirmation de suppression
    const dialogData = new ConfirmDialogModel(this.dialogTitle, this.dialogMessage);

    // Ouvrir la confirmation
    this.dialogManager.openDialog<ConfirmDialogComponent>({ dialog: Dialogs.ConfirmDialog, closeClickOutside: false, data: dialogData });
    const dialogRef = this.dialogManager.getDialogRef(Dialogs.ConfirmDialog);
    // Lancer la suppression si l'utilisateur confirme
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.actionDeleteCandidateAttachementFile(attachment);
      }
    });
  }

  private refreshCandidateAttachmentsAndShowMessage(response: boolean) {
    if (response) {
      // Rafraîchir la liste des pièces jointes après suppression
      this.refreshCandidateAttachmentsList(this.candidateId);
      // Afficher le message de suppression
      this.snackbarService.showSuccess(this.candidateAttachmentDeleted);
    } else {
      this.snackbarService.showDanger(this.deleteCandidateAttachmentFailed);
    }
  }

  // Action pour supprimer une pièce jointe candidat
  private actionDeleteCandidateAttachementFile(attachment: AttachmentModel) {
    this.candidateService
      .deleteCandidateAttachment(this.candidateId, attachment.id)
      .then(() => {
        this.refreshCandidateAttachmentsAndShowMessage(true);
      })
      .catch(() => {
        this.snackbarService.showDanger(this.deleteCandidateAttachmentFailed);
      });
  }

  private refreshCandidateAttachmentsList(candidateId: number) {
    this.candidateService.getCandidateAttachments(candidateId).then((data) => {
      this.isLoading = false;
      this.paginator.paginator.pageIndex = 0;
      this.candidateAttachmentFilesSubject.next(data);
    });
  }
}

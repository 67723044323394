import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';
import { resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { ChipModel, ChipService, CriterionCategory } from 'common-services';

@Component({
  selector: 'app-criteria-group-chips',
  templateUrl: './criteria-group-chips.component.html',
  styleUrls: ['./criteria-group-chips.component.scss']
})
export class CriteriaGroupChipsComponent implements OnInit {

  /** Modèle de la pastille "Aucune pastille" */
  public readonly noChip: ChipModel = { id: 0, label: this.advancedSearchManager.noChipIndexingCode, color: undefined };

  private chips: Array<ChipModel> = [];

  public criterionCategory = CriterionCategory;
  public _chipSelected: Array<SelectedItemModel>;

  constructor(
    private readonly chipService: ChipService,
    private readonly store: Store<AppState>,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) { }

  public ngOnInit() {
    // On obtient la liste des pastilles
    this.chipService.getCandidateChips().then(data => {
      const chips = data.concat([this.noChip]);
      localStorage.setItem('chips', JSON.stringify(chips));
      this._chipSelected = chips.map(chip => new SelectedItemModel(chip.id, chip.label));
      this.chips = chips;
    });
  }

  public get chipSelected(): Array<SelectedItemModel> {
    if (this.advancedSearchManager.advancedSearchResponseState.dataState === StateStatus.LOADED) {
      const selectedItems = this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria
      .filter(c => c.category === CriterionCategory.Chip).map(c => c.value );

      // On met à jour la liste des options du select
      return this._chipSelected?.map(
        chip => {
          chip.selected = selectedItems?.includes(chip.value.toString());
          return chip;
        }
      );
    }

    return this._chipSelected;
  }

  public setStyle(chipSelectedItem: SelectedItemModel) {
    // On sélectionne la pastille concernée
    const chip = this.chips.find(x => x.id === chipSelectedItem?.value);
    // Si la couleur n'est pas renseignée, on attribut la couleur par défaut de la pastille
    return {
      'color': chip?.color || 'rgb(125, 125, 125)'
    };
  }

  /** Ajoute le critère raffiné de pastille sélectionné lors d'un changement de sélection des cases à cocher  */
  public onChipChange() {
    this.store.dispatch(resetSelectCandidates());
    const selectedValues = this._chipSelected
      .filter(selectedItem => selectedItem.selected)
      .map(selectedItem => selectedItem.value);
    // On ajoute les élément sélectionnés à la liste des critères raffinés du manager
    this.advancedSearchManager.addRefinedCriteria(
      CriterionCategory.Chip,
      this._chipSelected
        .filter(selectedItem => selectedValues.indexOf(selectedItem.value) !== -1)
        .map(selectedItem => selectedItem.value.toString()),
      CriterionPriority.Required
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CriteriaGroup } from 'app/shared/enums/criteria-group.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { CriteriaGroupModel } from 'app/shared/models/advanced-search/criteria-group.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates, searchCompletions, searchCompletionsSuccess } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { AutoCompleteModel, CriterionCategory, CriterionPriority } from 'common-services';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';

@Component({
  selector: 'app-criteria-menu',
  templateUrl: './criteria-menu.component.html',
  styleUrls: ['./criteria-menu.component.scss']
})
export class CriteriaMenuComponent implements OnInit, OnDestroy {

  public criterionCategory = CriterionCategory;
  public criteriaGroupModels: Array<CriteriaGroupModel>;
  public autocompleteFormControl: FormControl;
  public completionItemsObservable: Observable<Array<AutoCompleteModel>>;
  public isLoading: boolean;
  private readonly completionItems: Array<AutoCompleteModel>;
  private readonly subscriptions: Subscription;

  public get searchInputValue(): string {
    return this.autocompleteFormControl.value;
  }

  constructor(
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly store: Store<AppState>,
    private readonly actions: Actions
  ) {
    this.subscriptions = new Subscription();
    this.completionItems = new Array<AutoCompleteModel>();
    this.completionItemsObservable = of(this.completionItems);
    this.autocompleteFormControl = new FormControl();
    const autocompleteSubscription = this.autocompleteFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(value => {
      if (value) {
        this.searchCompletions(value);
      } else {
        this.clearCompletion();
      }
    });

    this.subscriptions.add(autocompleteSubscription);
  }

  public ngOnInit() {
    this.criteriaGroupModels = this.getAvailableCriteriaGroupsModels();
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  public searchCompletions(value: string) {
    this.isLoading = true;
    const autoComplete = {criterionCategory: CriterionCategory.Fulltext, value} as AutoCompleteModel;
    this.store.dispatch(searchCompletions({payload: autoComplete}));

    const actionSubscription = this.actions.pipe(
      ofType(searchCompletionsSuccess),
      first()
    )
    .subscribe(
      result => {
        const data = result.payload as AutoCompleteModel[];
        this.isLoading = false;
        this.clearCompletion();
        this.completionItems.push(...data?.map(suggestion => ({criterionCategory: suggestion.criterionCategory, value: suggestion.value} as AutoCompleteModel)));
      }
    );

    this.subscriptions.add(actionSubscription);
  }

  public setCriterion(tuple: AutoCompleteModel | string) {
    if (typeof tuple === 'string') {
      tuple = { criterionCategory: CriterionCategory.Fulltext, value: tuple.trim() };
    }
    // On ajoute le critère à la liste des critères raffinés du manager si une valeur est passée en paramètre
    // On vérifie que la recherche ne soit pas nulle ou vide
    if (tuple?.value) {

      const refinedCriterion = new RefinedCriterionViewModel(
        tuple.criterionCategory,
        tuple.value,
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({payload: refinedCriterion}));
    }
    this.reset();
  }

  private clearCompletion() {
    this.completionItems.splice(0, this.completionItems.length);
  }

  private reset() {
    this.autocompleteFormControl.reset();
    this.clearCompletion();
  }

  /**
   * Retourne la liste des groupes de critères ayant au moins un critère disponible
   */
  private getAvailableCriteriaGroupsModels(): Array<CriteriaGroupModel> {
    // Pour chaque enum de CriteriaGroup
    return Object.keys(CriteriaGroup)
      .filter(key => !isNaN(Number(CriteriaGroup[key])))
      .map(key => {
        // On obtient les groupes par défaut
        const criteriaGroup = CriteriaGroupModel.defaultCriteriaGroups.find(dcg => dcg.key === CriteriaGroup[key]).value;
        // On ne garde que les catégories de critères paramétrées comme visible
        criteriaGroup.categories = criteriaGroup.categories.filter(category => this.advancedSearchManager.isCriterionAvailable(category));
        return criteriaGroup;
      })
      // On enlève les groupes qui n'ont plus de critères, puis on retourne la liste des groupes
      .filter(groupModel => groupModel.categories.length);
  }
}

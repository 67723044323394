import { AdvancedSearchResponseModel, MatchResultModel } from 'common-services';
import { AdvancedSearchViewModel } from './advanced-search.model';

export class AdvancedSearchResponseViewModel implements AdvancedSearchResponseModel {
  constructor(
    public matchSize: number,
    public hasMoreResults: boolean,
    public advancedSearch: AdvancedSearchViewModel,
    public candidatesMatches: MatchResultModel[]
  ) { }
}


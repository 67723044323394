import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormHelper {

    public get disableChangeEventsEmitting(): any {
        return { emitEvent: false };
    }

    /**
    *Expression régulière pour valider les currencies inputs
    *Matches 3,023,123.34 | 9,876,453 | 123456.78
    *Non-Matches 4,33,234.34 | $1.234 | abc
    */
    public get moneyValidationPattern(): ValidatorFn {
        return Validators.pattern('^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$');
    }

    /**
    *Validate si un champ d'autocomlete est
    */
    public get validateAutocomplete(): ValidatorFn {
        return (control) => {
            const selection: any = control.value;
            if (typeof selection === 'string') {
                return { incorrect: selection !== '' };
            }
            return null;
        };
    }

    /**
    *Itère sur un Formgroup ou Formarray et marquer tous les contrôles comme
    *touché, y compris ses enfants.
    * @param rootControl - la forme à itérer
    * @param visitChildren - Préciser s’il doit itérer ses enfants.
    */
    public markControlsAsTouched(
        rootControl: FormGroup | FormArray,
        visitChildren = true
    ) {
        const stack: (FormGroup | FormArray)[] = [];

        // Vérifier le type de rootControl
        if (rootControl &&
            (rootControl instanceof FormGroup || rootControl instanceof FormArray)) {
            stack.push(rootControl);
        }

        while (stack.length > 0) {
            const currentControl = stack.pop();
            (Object).values(currentControl.controls).forEach((control: FormGroup | FormArray) => {

                if (visitChildren &&
                    (control instanceof FormGroup || control instanceof FormArray)
                ) {
                    stack.push(control);
                } else {
                    control.markAsTouched();
                }
            });
        }
    }

    /**
    *Désactive et assigne une valeur à un contrôle
    * @param control - le contrôle
    * @param modelValue - la valeur
    */
    public disableAndResetValue(control: FormControl, modelValue?: any) {
        control.disable(this.disableChangeEventsEmitting);
        if (modelValue) {
            control.setValue(modelValue, this.disableChangeEventsEmitting);
        } else {
            control.reset(null, this.disableChangeEventsEmitting);
        }
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExpandableTableComponent } from 'app/generic-components/expandable-table/expandable-table.component';
import { CandidateExperienceModel, CandidateModel } from 'common-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidate-experiences',
  templateUrl: './candidate-experiences.component.html',
})
export class CandidateExperiencesComponent implements OnInit {
  @Input() candidate: Observable<CandidateModel>;
  @ViewChild('expandableTable', { static: true }) expandableTable: ExpandableTableComponent<CandidateExperienceModel>;

  ngOnInit() {
    this.expandableTable.expandableColumns = new Set(['description']);
    this.expandableTable.columns = { startDate: 'startDate', endDate: 'endDate', title: 'position', organisationName: 'company' };

    this.candidate.subscribe(
      candidate => {
        this.expandableTable.dataSource = candidate.experiences;
      }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { InitializerProvider } from 'app/shared/providers/initializer.provider';
import { ParameterService } from "app/shared/services/parameter.service";
import { AuthenticationsService } from "common-services";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public readonly loginForm: FormGroup;
  public error: string;
  public loading = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly authenticationsService: AuthenticationsService,
    private readonly translateService: TranslateService,
    private readonly parameterService: ParameterService,
    private readonly initializerProvider: InitializerProvider
  ) {
    // Création du formulaire d'authentification
    this.loginForm = this.formBuilder.group({
      login: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
    // Utiliser la langue du navigateur
    this.parameterService.initTranslation(navigator.language);
    // Redirection vers l'accueil si l'utilisateur est connecté
    if (this.authenticationsService.isLoggedIn()) {
      this.router.navigate(["/"]);
    }
  }

  public onSubmit(): void {
    this.loading = true;
    const login = this.loginForm.controls.login.value.trim();
    const password = this.loginForm.controls.password.value.trim();
    this.authenticationsService
      .login(login, password)
      .then(async (response) => {
        if (response) {
          this.parameterService.initParams();
          // Initiliser l'application
          this.initializerProvider.init().then(() => {
            // Redirection vers l'accueil aprés connexion
            this.router.navigate(["/"]);
          });
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          this.translateService
            .get("invalidCredentials")
            .subscribe((translation) => (this.error = translation));
        } else {
          this.translateService
            .get("error")
            .subscribe((translation) => (this.error = translation));
        }
        this.loading = false;
      });
  }
}

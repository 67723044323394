import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RefinedCriterionHelper } from 'app/core/helpers/refined-criterion.helper';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { AdvancedSearchResponseViewModel } from 'app/shared/models/advanced-search/advanced-search-response.model';
import { CriterionSemanticViewModel } from 'app/shared/models/advanced-search/criterion-semantic.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { SemanticOptionViewModel } from 'app/shared/models/advanced-search/semantic-option.model';
import * as AdvancedSearchResponseActions from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import {
  CriterionCategory,
  CriterionPriority,
  CustomSynonymsModel,
  SemanticCategory
} from 'common-services';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-refined-criterion',
  templateUrl: './refined-criterion.component.html',
  styleUrls: ['./refined-criterion.component.scss']
})
export class RefinedCriterionComponent implements AfterContentChecked, OnInit {
  @Input() identifier: string;
  @Input() criterion: RefinedCriterionViewModel;

  private isEmptyRadius = false;
  private _advancedSearchResponse: AdvancedSearchResponseViewModel;

  public readonly criterionCategory = CriterionCategory;
  public readonly criterionPriority = CriterionPriority;
  public readonly addCustomSynonymSubject: Subject<string> = new Subject<string>();
  public readonly eventRemovedcustomSynonym: Subject<string> = new Subject<string>();
  public radius: string;

  public customSynonymValue: string;
  // Liste des critères qui peuvent avoir des synonymes personnalisés
  private readonly criterionCategoryForCustomSynonym = [
    CriterionCategory.Fulltext,
    CriterionCategory.Job,
    CriterionCategory.LastJob,
    CriterionCategory.ComputerSkills,
    CriterionCategory.Diploma,
    CriterionCategory.InstituteName];

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.criterion = this.advancedSearchManager.defineCriterionLabel(this.criterion);
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  public get outrunHeight(): boolean {
    return document.getElementById(`criterion-title-${this.identifier}`).clientHeight > 25;
  }

  public get icon(): string {
    return RefinedCriterionHelper.getCriterionIcon(this.criterion.category);
  }

  public get isCityCriterion(): boolean {
    return this.criterion.category === CriterionCategory.City;
  }

  public get isDeprecated() {
    return (this.criterion.category === CriterionCategory.Deprecated);
  }

  public onEmptyRadius(): void {
    this.isEmptyRadius = true;
  }

  /** Afficher le nombre d'options de sémantique */
  public get semanticCounter(): string | number {
    // On exclut les responsables
    if (this.criterion.category === CriterionCategory.ActionUser
      || this.criterion.category === CriterionCategory.LastActionUser) {
      return '';
    }

    if (this.isCityCriterion) {
      return this.semanticCount;
    }

    return this.semanticCount ? `(+${this.semanticCount})` : '';
  }

  /** Obtient le nombre d'options de sémantique du critère pour l'afficher */
  public get semanticCount(): number {
    // On obtient la liste des options de toutes les sémantiques du critère
    const optionsList = this.criterion.semantics.reduce<SemanticOptionViewModel[]>(
      (opt, sem) => [...opt, ...sem.options], []).filter(x => x.selected);
    // S'il s'agit d'un critère de ville, on affiche le radius
    if (this.isCityCriterion) {
      if (!optionsList.find(opt => opt.selected) && this.isEmptyRadius) {
        return 0;
      }
      const selectedRadius = optionsList.find(opt => opt.selected)?.value;
      // Valeur par défaut si la valeur du rayon de recherche si il est vide
      return isNaN(parseInt(selectedRadius, 10)) ? 20 : parseInt(selectedRadius, 10);
    }

    return optionsList.length;
  }

  public get criterionTitle(): string {
    return this.isCityCriterion ? `${this.criterion?.label} + ${this.semanticCounter} km` : `${this.criterion?.label} ${this.semanticCounter}`;
  }

  public setPriority(priority: CriterionPriority, checked: boolean) {
    // Réinitialiser les candidats sélectionnés
    this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());

    this.criterion.priority = checked ? priority : CriterionPriority.Optional;
    this.store.dispatch(AdvancedSearchResponseActions.updateCriterion({ payload: this.criterion }));
  }

  public deleteCriterion() {
    // Réinitialiser les candidats sélectionnés
    this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());
    this.store.dispatch(AdvancedSearchResponseActions.deleteCriterion({ payload: this.criterion }));
  }

  /**
   * Vérifie que la sémantique soit de la catégorie des synonymes personnalisés
   * @param semantic la catégorie de la sémantique
   */
  public isCustomSynonym(semantic: SemanticCategory) {
    return semantic === SemanticCategory.CustomSynonym;
  }

  /**
   * Vérifie que le critère peut avoir des synonymes personnalisés,
   * et qu'il ne possède pas de sémantique de type synonymes personnalisés
   * @param selectedCriterion Le critère selectionné
   */
  public get canHaveButHasNoCustomSynonyms() {
    return this.criterionCategoryForCustomSynonym.includes(this.criterion.category)
      && !this.criterion.semantics.find(semantic => semantic.category === SemanticCategory.CustomSynonym);
  }

  /**
   * / Supprime le synonyme personnalisé
   * @param value Identifiant du synonyme
   */
  public removeSynonym(value: string) {
    const everyCustomSynonyms = this.criterion
      .semantics
      .find(semantic => semantic.category === SemanticCategory.CustomSynonym)
      .options
      .map(option => option.value)
      .filter(optionValue => optionValue !== value);

    const everySynonyms = this.criterion
      .semantics
      .map(semantic => semantic
        .options
        .map(option => option.value)
        .filter(optionValue => semantic.category !== SemanticCategory.CustomSynonym || optionValue !== value))
      .reduce((previous, current) => previous.concat(current));

    const customSynonymsModel = {
      keyword: this.criterion.value,
      userGuid: this.advancedSearchManager.userGuid,
      criterionCategory: this.criterion.category,
      customSynonyms: everyCustomSynonyms,
      selectedSynonyms: everySynonyms
    } as CustomSynonymsModel;

    this.store.dispatch(AdvancedSearchResponseActions.defineKeywordSynonyms({ payload: customSynonymsModel }));

    this.actions.pipe(
      ofType(
        AdvancedSearchResponseActions.defineKeywordSynonymsSuccess,
        AdvancedSearchResponseActions.defineKeywordSynonymsError
      ),
      first()
    )
      .subscribe(
        action => {
          if (action.payload) {
            const index = this.findCustomSynonymAndCriterionIndex();
            const customSynonymIndex = index.customSynonymIndex;
            const criterionIndex = index.criterionIndex;

            if (customSynonymIndex !== -1) {
              const removedSynonym = this.criterion.semantics[customSynonymIndex].options.find(el => el.value === value);
              const removedSynonymIndex = this.criterion.semantics[customSynonymIndex].options.indexOf(removedSynonym);

              if (removedSynonymIndex !== -1) {
                this.criterion.semantics[customSynonymIndex].options.splice(removedSynonymIndex, 1);
                this._advancedSearchResponse.advancedSearch.criteria[criterionIndex] = this.criterion;

                // On dispatch une action pour supprimer les synonymes
                this.store.dispatch(AdvancedSearchResponseActions.updateAdvancedSearchResponse({ payload: this._advancedSearchResponse }));

                // On informe le composant enfant que le synonyme est supprimé
                this.eventRemovedcustomSynonym.next(value);

                // Réinitialiser les candidats sélectionnés
                this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());

                // On actualise les candidats
                this.advancedSearchManager.fetchCandidates();
              }
            }

          }
        }
      );
  }

  /**
   * Ajoute le synonyme personnalisé
   * @param customSynonymValue Libellé du synonyme personnalisé à ajouter
   */
  public addSynonym(customSynonymValue: string) {
    const everyCustomSynonyms = [
      ...this.criterion
        .semantics
        .find(semantic => semantic.category === SemanticCategory.CustomSynonym)
        ?.options
        .map(option => option.value) ?? [],
      customSynonymValue];

    const everySynonyms = [
      ...this.criterion
        .semantics
        .map(semantic => semantic.options.map(option => option.value))
        .reduce((previous, current) => previous.concat(current)),
      customSynonymValue];

    const customSynonym = {
      keyword: this.criterion.value,
      userGuid: this.advancedSearchManager.userGuid,
      criterionCategory: this.criterion.category,
      customSynonyms: everyCustomSynonyms,
      selectedSynonyms: everySynonyms
    } as CustomSynonymsModel;

    this.store.dispatch(AdvancedSearchResponseActions.defineKeywordSynonyms({ payload: customSynonym }));
    this.actions.pipe(
      ofType(
        AdvancedSearchResponseActions.defineKeywordSynonymsSuccess,
        AdvancedSearchResponseActions.defineKeywordSynonymsError
      ),
      first()
    )
      .subscribe(
        action => {
          if (action.payload) {
            // Réinitialiser les candidats sélectionnés
            this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());

            const index = this.findCustomSynonymAndCriterionIndex();
            const customSynonymIndex = index.customSynonymIndex;
            const criterionIndex = index.criterionIndex;

            // On ajoute le nouveau synonyme à la liste des synonymes
            if (criterionIndex !== -1) {
              const customSynonymOption = new SemanticOptionViewModel(customSynonymValue, true);

              // Ajouter le custom synonym s'il n'existe pas
              if (customSynonymIndex === -1) {
                this.criterion.semantics.push(new CriterionSemanticViewModel(SemanticCategory.CustomSynonym, [customSynonymOption]));
              } else {
                this.criterion.semantics[customSynonymIndex].options.push(customSynonymOption);
              }

              this._advancedSearchResponse.advancedSearch.criteria[criterionIndex] = this.criterion;
              // On dispatch une action pour ajouter les synonymes
              this.store.dispatch(
                AdvancedSearchResponseActions.updateAdvancedSearchResponse({ payload: this._advancedSearchResponse })
              );
            }

            // On informe le composant enfant que le synonyme est ajouté
            this.addCustomSynonymSubject.next(customSynonymValue);

            // On actualise les candidats
            this.advancedSearchManager.fetchCandidates();

            // On vide l'input text
            customSynonymValue = '';
          }
        }
      );
  }

  public onChangeCriterionSemantics(criterion: RefinedCriterionViewModel) {
    // Éviter les appels multiple de l'Api après le matching par position url ou Id et lang
    if (this.advancedSearchManager.advancedSearchResponseState.dataState !== StateStatus.LOADEDBYMATCHING) {
      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());
      this.store.dispatch(AdvancedSearchResponseActions.updateCriterion({ payload: criterion }));
    }

  }

  public onChangeCustomSynonym(criterionSemantic: CriterionSemanticViewModel) {
    const customSynonymIndex = this.criterion.semantics.indexOf(
      this.criterion.semantics.find(element => element.category === SemanticCategory.CustomSynonym)
    );

    if (customSynonymIndex !== -1) {
      this.criterion.semantics[customSynonymIndex] = criterionSemantic;
      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(AdvancedSearchResponseActions.resetSelectCandidates());
      this.store.dispatch(AdvancedSearchResponseActions.updateCriterion({ payload: this.criterion }));
    }
  }

  public trackByFunction(index: number, semantic: CriterionSemanticViewModel) {
    if (!semantic) { return null; }
    return index;
  }

  private findCustomSynonymAndCriterionIndex(): { customSynonymIndex: number, criterionIndex: number } {
    this._advancedSearchResponse = this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse;
    // On relance la recherche des candidats pour mettre les informations à jour
    const criterionIndex = this._advancedSearchResponse?.advancedSearch?.criteria
      .findIndex(c => (c.value === this.criterion.value && c.category === this.criterion.category));

    // On supprime le synonyme depuis la liste des synonymes (data.advancedSearch.criteria)
    if (criterionIndex !== -1) {
      const customSynonymIndex = this.criterion.semantics.indexOf(
        this.criterion.semantics.find(element => element.category === SemanticCategory.CustomSynonym)
      );

      return {
        customSynonymIndex,
        criterionIndex
      };
    }

    return {
      customSynonymIndex: -1,
      criterionIndex: -1
    };
  }
}

import { KeyValue } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectGroupComponent),
      multi: true
    }
  ]
})
export class SelectGroupComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() identifier: string; // Identifiant unique du composant
  @Input() class: string;
  @Input() groups: Array<KeyValue<string, Array<KeyValue<number, string>>>>; // Les données pour remplir le select group
  @Input() selectedOption: any; // Définie la valeur par défaut
  @Input() emptyOption: string; // Définie l'option vide (Ex: choisir, aucune ... ou simplement une chaine vide)
  @Input() isEmptyOptionHasValue: boolean; // Autorise la sélection de l'option vide
  @Input() label: string; // Définir un label pour le select group
  @Input() required = false; // Rend le champ obligatoire
  @Input() multiple = false; // Active le choix multiple
  @Input() resetAtDefault: boolean; // Indique si l'on doit revenir à la valeur par défaut une fois une sélection faite
  @Output() selectedOptionsChanged: EventEmitter<any> = new EventEmitter(); // Renvoie la/les option(s) sélectionnée(s)
  public selectGroupControl = new FormControl();
  public isLoading = true;

  @Input()
  public get isDisabled() {
    return this.selectGroupControl?.disabled;
  }

  // Méthod pour activer ou désactiver le composant
  public set isDisabled(disabled: boolean) {
    if (disabled) {
      this.selectGroupControl?.disable();
    } else {
      this.selectGroupControl?.enable();
    }
  }

  public get emptyOptionValue(): null | string {
    if (this.isEmptyOptionHasValue) {
      return '';
    }

    return null;
  }

  ngOnInit() {
    // Initialiser la valeur par défaut
    this.writeValue(this.selectedOption);
    // Ajout de validateur s'il existe
    this.selectGroupControl.setValidators((this.required) ? [Validators.required] : null);
  }

  public onToggle(filterVal: any) {
    // On émet l'élement sélectionné
    if (!isNaN(+filterVal)) {
      filterVal = +filterVal;
    }

    this.selectedOptionsChanged.emit(filterVal);

    // Remet à sa valeur par défaut une fois le choix effectué
    if (this.resetAtDefault) {
      this.writeValue(this.emptyOptionValue);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // On change l'état du spinner suite au résultat du callback
    if (changes?.groups?.currentValue !== undefined || this.isDisabled) {
      this.isLoading = false;
      if (this.selectedOption) {
        this.writeValue(this.selectedOption);
      }
    }
  }

  // Implémentation des méthodes de l'interface ControlValueAccess
  public onTouched: any = () => { };
  writeValue(value: any): void {
    if (value) {
      this.selectGroupControl.setValue(value);
    } else {
      this.selectGroupControl.reset(this.emptyOptionValue);
    }
  }
  registerOnChange(fn: any): void {
    this.selectGroupControl.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  validate(c: AbstractControl): boolean {
    return this.selectGroupControl.valid;
  }
}

import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { PaginationComponent } from 'app/generic-components/pagination/pagination.component';
import { DefaultControlsErrorStateMatcher } from 'app/shared/material/default-controls-error-state-matcher';
import { AgencyModel, DataDepthLevel, OrganizationService, PagedItems, PositionModel, PositionService, QueryableModel, SortOrder } from 'common-services';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-position',
  templateUrl: './search-position.component.html',
  styleUrls: ['./search-position.component.scss']
})
export class SearchPositionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pagination') paginator: PaginationComponent;
  public paginationLength = 0;
  public paginationSize = 5;
  public emptyOptionLabel: string;
  public labelPositionStatus: string;
  public selectedPositionStatus: number;
  public positionStatusFormControl: FormControl;
  public agencyFormControl: FormControl;
  public filterFormControl: FormControl;
  public errorMatcher: DefaultControlsErrorStateMatcher;
  public filteredAgencies: Observable<Array<AgencyModel>>;

  private readonly subscriptions: Subscription;
  private readonly displayedColumns: ReadonlyArray<string>;
  private readonly positionsStatusSubject: Subject<Array<KeyValue<number, string>>>;
  private readonly agenciesSubject: Subject<ReadonlyArray<AgencyModel>>;
  private readonly selectedPositionSubject: Subject<PositionModel>;
  private readonly positionsDataSource: MatTableDataSource<PositionModel>;
  private positionsStatus: Array<KeyValue<number, string>>;
  private agencies: ReadonlyArray<AgencyModel>;


  // Listes
  public get positionsStatusObservable(): Observable<Array<KeyValue<number, string>>> {
    return this.positionsStatusSubject;
  }

  public get agenciesObservable(): Observable<ReadonlyArray<AgencyModel>> {
    return this.agenciesSubject;
  }

  public get positionsSource(): MatTableDataSource<PositionModel> {
    return this.positionsDataSource;
  }

  public get selectedPosition(): Observable<PositionModel> {
    return this.selectedPositionSubject;
  }

  public get columns(): ReadonlyArray<string> {
    return this.displayedColumns;
  }

  constructor(
    public readonly dialogRef: MatDialogRef<SearchPositionComponent>,
    private readonly positionService: PositionService,
    private readonly organizationService: OrganizationService,
    private readonly translateService: TranslateService
  ) {
    this.subscriptions = new Subscription();
    this.displayedColumns = ['statusId', 'title', 'reference', 'customer', 'agencyId', 'manager'];
    this.positionsDataSource = new MatTableDataSource<PositionModel>();
    this.positionsStatusSubject = new Subject<Array<KeyValue<number, string>>>();
    this.selectedPositionSubject = new Subject<PositionModel>();
    this.agenciesSubject = new Subject<ReadonlyArray<AgencyModel>>();
    this.errorMatcher = new DefaultControlsErrorStateMatcher();
    // On construit le formulaire
    this.positionStatusFormControl = new FormControl();
    this.agencyFormControl = new FormControl();
    this.filterFormControl = new FormControl();
  }

  ngAfterViewInit() {
    const paginatorSubscription = this.paginator.paginator.page.pipe(
      debounceTime(100)
    ).subscribe(this.filterPositions.bind(this));

    this.subscriptions.add(paginatorSubscription);
  }

  public ngOnInit() {
    const positionsStatusSubjectSubscription = this.positionsStatusSubject.subscribe(positionsStatus => {
      this.positionsStatus = positionsStatus;
      this.selectedPositionStatus = positionsStatus[0].key;
    });
    const agenciesSubjectSubscription = this.agenciesSubject.subscribe(agencies => {
      this.agencyFormControl.setValue(agencies[0].id, { emitEvent: false });
      this.agencies = agencies;
    });

    const positionStatusFormControlSubscription = this.positionStatusFormControl.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(this.resetPageIndexAndFilterPositions.bind(this));

    const agencyFormControlSubscription = this.agencyFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      filter((data) => {
        return typeof data === 'number'
          || this.agencies.findIndex(agency => agency.label?.toLowerCase() === data.toLowerCase()) > -1
          || data === '';
      })
    ).subscribe(this.resetPageIndexAndFilterPositions.bind(this));

    const filterFormControlSubscription = this.filterFormControl.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(this.resetPageIndexAndFilterPositions.bind(this));

    this.translateService.get(['all', 'positionStatus']).subscribe(translations => {
      this.emptyOptionLabel = translations.all;
      this.labelPositionStatus = translations.positionStatus;
    });

    this.subscriptions.add(positionStatusFormControlSubscription);
    this.subscriptions.add(agencyFormControlSubscription);
    this.subscriptions.add(filterFormControlSubscription);
    this.subscriptions.add(positionsStatusSubjectSubscription);
    this.subscriptions.add(agenciesSubjectSubscription);

    const positionStatusPromise = this.positionService.getPositionStatus().then(result => {
      if (result && result.length > 0) {
        this.positionsStatusSubject.next(result.map((positionStatus) => ({ key: positionStatus.id, value: positionStatus.label } as KeyValue<number, string>)));
      }
    });

    const agenciesPromise = this.getAgencies().then(data => {
      if (data && data.items.length > 0) {
        this.agencies = data.items;
      }
    }).then(() => {
      this.filteredAgencies = this.agencyFormControl.valueChanges
        .pipe(
          debounceTime(100),
          distinctUntilChanged(),
          startWith(''),
          map(agency => agency ? this.filterAgencies(agency) : this.agencies.slice())
        );
    }
    );

    Promise.all([positionStatusPromise, agenciesPromise]).then(() =>
      this.resetPageIndexAndFilterPositions()
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.positionsStatusSubject) {
      this.positionsStatusSubject.complete();
    }
    if (this.agenciesSubject) {
      this.agenciesSubject.complete();
    }

    if (this.selectedPositionSubject) {
      this.selectedPositionSubject.complete();
    }
  }

  public displayAgency(agencyId: number): string {
    if (this.agencies) {
      const agency = this.agencies.find(ag => ag.id === agencyId);
      if (agency) {
        return agency.label;
      }
    }
    return '';
  }

  public getStatusLabel(id: number): string {
    const status = this.positionsStatus.find(s => s.key === id);
    return status ? status.value : undefined;
  }

  public getAgencyLabel(id: number): string {
    const agency = this.agencies.find(s => s.id === id);
    return agency ? agency.label : undefined;
  }

  public onRowClick(position: PositionModel) {
    this.selectedPositionSubject.next(position);
  }

  private getAgencies(): Promise<PagedItems<AgencyModel>> {
    const query: QueryableModel = {
      whereExpression: `a => true`,
      dataDepthLevel: DataDepthLevel.Flat,
      sortOrder: 'NONE',
      page: 0,
      pageSize: 10000
    };

    return this.organizationService.findAgenciesByQueryable(query);
  }

  private filterAgencies(formFilter: string | number): Array<AgencyModel> {
    if (!formFilter || typeof formFilter !== 'string') {
      return this.agencies.slice();
    }
    const filterValue = formFilter.toLowerCase();
    return this.agencies.filter(agency => agency.label &&
      agency.label.toLowerCase().includes(filterValue));
  }

  private resetPageIndexAndFilterPositions() {
    if (this.paginator.paginator) {
      this.paginator.paginator.pageIndex = 0;
      this.filterPositions();
    }
  }

  private buildQuery(positionStatusId = null, filterValue = ''): string {
    let query = '';

    if (positionStatusId) {
      query += `p.statusId = ${positionStatusId}`;
    }

    if (filterValue) {
      filterValue = filterValue.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
      const filterValueQuery = `(p.title.Contains("${filterValue}") || p.reference.Contains("${filterValue}"))`;
      query === '' ? (query += filterValueQuery) : (query += ` && ${filterValueQuery}`);
    }

    let agencyId = -1;
    if (this.agencyFormControl.value) {
      agencyId = Number(this.agencyFormControl.value) ?
        this.agencyFormControl.value :
        this.agencies.find(agency => agency.label.toLowerCase() === String(this.agencyFormControl.value).toLowerCase())?.id;
    }

    if (agencyId !== -1) {
      const agencyIdQuery = `p.agencyId = ${agencyId}`;
      query === '' ? (query += agencyIdQuery) : (query += ` && ${agencyIdQuery}`);
    }

    if (query !== '') {
      query = `p => p.type = 0 && ${query}`;
    } else {
      query = `p => p.type = 0`
    }

    return query;
  }

  private filterPositions() {
    const positionStatusId: number = this.positionStatusFormControl.value;
    const filterValue: string = this.filterFormControl.value;
    const whereExpression = this.buildQuery(positionStatusId, filterValue);

    const query: QueryableModel = {
      whereExpression,
      parameters: [],
      dataDepthLevel: DataDepthLevel.WithSubObjectsAndSubLists,
      sortExpression: 'p => p.creationDate',
      sortOrder: SortOrder.Descending.toString(),
      page: this.paginator.paginator.pageIndex,
      pageSize: this.paginator.paginator.pageSize
    };

    this.positionService.findByQueryable(query).then((pagedItems: PagedItems<PositionModel>) => {
      if (this.paginator.paginator) {
        this.paginationLength = pagedItems.rowCount;
        this.paginationSize = pagedItems.pageSize;
      }

      if (!pagedItems.items) {
        this.paginationLength = 0;
        this.paginationSize = 5;
        this.positionsDataSource.data = [];
        return;
      }

      this.positionsDataSource.data = pagedItems.items;
    });
  }
}

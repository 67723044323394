<h1 mat-dialog-title class="text-uppercase text-primary font-weight-bold mr-5">
  {{data.title}}
</h1>

<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions class="m-auto justify-content-end">
  <button id="confirm_dialog_confirm" (keydown.enter)="$event.preventDefault()" mat-button (click)="onConfirm()" class="btn btn-primary text-white mr-3 text-uppercase" appIgnoreCloseDialog>
    <span>{{'confirm' | translate }}</span>
  </button>
  <button id="confirm_dialog_cancel" mat-button (click)="onDismiss()" class="btn btn-secondary text-uppercase" appIgnoreCloseDialog>
    <span>{{'cancel' | translate }}</span>
  </button>
</mat-dialog-actions>

<div id="candidate_results" class="candidate-results-actions d-flex flex-wrap">
  <div class="flex-grow-1 d-flex flex-wrap align-self-start align-items-center">
    <div *ngIf="!isInfiniteScroll" class="p-2">
      <mat-checkbox [checked]="isSelectAllChecked"
                    (change)="selectAllChanged()"
                    [disabled]="isDisabled"
                    [indeterminate]="isSelectAllIndeterminated">
      </mat-checkbox>
    </div>
    <div class="p-2">
      <label for="selectAllCandidates">
        <span id="candidate_results_candidate_count_span" class="data-grey">
          <span class="advanced-search-header-title" *ngIf="candidatesCount !== 1; else elseBlock">
            {{ candidatesCount }} {{ 'selectedCandidates' | translate }}
          </span>
          <ng-template #elseBlock>
            <span>
              {{ candidatesCount }} {{ 'selectedCandidate' | translate }}
            </span>
          </ng-template>
        </span>
      </label>
    </div>
    <app-select *ngIf="sortOptions.length > 0" [identifier]="'candidate_results_sort_select'"
                [class]="'p-2 mr-3 orderby'"
                [isDisabled]="disableSortCandidates"
                (selectedOptionsChanged)="onSortChange($event)"
                [options]="sortOptions"
                [selectedOption]="selectedSort"
                [label]="labelSortSelect">
    </app-select>
  </div>
  <div class="ml-auto p-2">
    <div class="d-flex justify-content-end align-items-center">
      <app-actions-candidate [isCandidatesCountVisible]="true"></app-actions-candidate>
      <app-geolocate-candidate></app-geolocate-candidate>
      <button mat-stroked-button class="bg-transparent ml-3" (click)="openAddCandidateDialog()">
        <mat-icon class="mr-2">person_add</mat-icon>
        {{ 'add' | translate }}
      </button>
      <button mat-stroked-button class="bg-transparent ml-3" (click)="openImportCVDialog()">
        <mat-icon class="mr-2">person_add</mat-icon>
        {{ 'importCV' | translate }}
      </button>
    </div>
  </div>
</div>
<mat-progress-bar id="candidate_results_loading_progress_bar" mode="indeterminate" *ngIf="isSearching && !isScrolled">
</mat-progress-bar>
<ng-template #candidates>
  <div *ngFor="let matchResult of candidatesMatches; index as i;"
  (click)="candidateReverseState(matchResult.candidate.id)"
    [ngClass]="{ 'isSelected': isMatchResultSelected(matchResult) }"
    class="candidate-display-box d-flex align-items-center">
    <div class="flex-grow-1">
      <app-candidate-resume [attr.id]="'candidate_resume_' + i" [candidateModel]="matchResult.candidate">
      </app-candidate-resume>
    </div>
    <div class="position-relative float-left">
      <div class="candidate-match-score">
        <app-candidate-scoring [score]="matchResult.score"></app-candidate-scoring>
      </div>
    </div>
  </div>
</ng-template>

<div class="candidates-display-group scroll-wrapper">
  <div *ngIf="isInfiniteScroll; else elseBlockInfiniteScroll" class="scroll" id="candidate_results_infinite_scroll"
    scrollViewport #candidatesDisplayGroup infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="getMoreResults()" [scrollWindow]="false">
    <ng-container *ngTemplateOutlet="candidates"></ng-container>
  </div>

  <ng-template #elseBlockInfiniteScroll>
    <div #candidatesDisplayGroup id="candidate_results_infinite_scroll" class="scroll">
      <ng-container *ngTemplateOutlet="candidates"></ng-container>
    </div>
  </ng-template>
</div>
<div class="candidate-results-footer" [ngClass]="{'paginate': elseBlockInfiniteScroll }">
  <div class="footer-progress-bar-wrapper">
    <mat-progress-bar class="footer-progress-bar position-absolute" mode="query" *ngIf="isScrolled"></mat-progress-bar>
  </div>
  <app-pagination *ngIf="displayPagination"
                  [maxLength]="10000"
                  [maxPagesCount]="500"
                  [length]="candidatesCount"
                  [pageSize]="pageSize"
                  [showFirstLastButtons]="true"
                  [displayInput]="true"
                  [hidePaginatorRange]="true"
                  (paginated)="onPaginate($event)"
                  #elseBlockInfiniteScroll></app-pagination>
</div>

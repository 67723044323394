import { LogLevel } from 'app/shared/enums/log-level.enum';

export const environment = {
  productName: 'Candidates',
  baseUrl: 'https://msinternal-recette.eolia-software.com',
  production: true,
  isInfiniteScroll: false,
  log: {
    minimumLevel: LogLevel.Error
  },
  file: {
    maxFileSize: 10485760, // cette valeur = 10 * 1024 * 1024 = 10 MB
    maxTotalFiles: 10
  }
};

import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnChanges {
  @Input() length: number;
  @Input() pageSize: number;
  @Input() showFirstLastButtons: boolean;
  @Input() displayInput: boolean;
  @Input() hidePaginatorRange: boolean;
  @Input() maxLength: number;
  @Input() maxPagesCount: number;
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() paginated: EventEmitter<number> = new EventEmitter<number>();
  public paginationForm = new FormGroup({ page: new FormControl(1) });

  ngOnChanges() {
    this.paginationForm.controls.page.setValidators(
      [
        Validators.pattern(/^[0-9]*$/),
        Validators.min(1),
        Validators.max(this.pagesCount)
      ]
    );

    // activer ou désactiver la pagination
    if(this.pagesCount < 2) {
      this.paginationForm.controls.page.disable();
    }else {
      this.paginationForm.controls.page.enable();
    }
  }

  get exceedMaxLength(): boolean {
    return this.maxLength && (this.length > this.maxLength);
  }

  get pagesCount(): number {
    const count = Math.ceil(this.length / this.pageSize);
    return (this.maxPagesCount && count > this.maxPagesCount) ? this.maxPagesCount : count;
  }

  // Pagination depuis l'input
  public paginate(): void {
    if (this.paginationForm.valid && (this.paginationForm.controls.page.value !== null)) {
      const pageIndex = this.paginationForm.controls.page.value;
      this.paginated.emit(pageIndex);
      // Changement d'index du page de (Mat Paginator)
      this.paginator.pageIndex = pageIndex - 1;
    }
  }

  // pagination depuis ( Mat Paginator )
  public onChangePage(event: PageEvent): void {
    const page = event.pageIndex + 1;
    this.paginated.emit(page);
    this.paginationForm.patchValue({
      page
    });
  }
}

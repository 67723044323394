
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActionFollowUpComponent } from 'app/business-components/candidate/action-follow-up/action-follow-up.component';
import { ImportCvComponent } from 'app/business-components/candidate/import-cv/import-cv.component';
import { SendMailComponent } from 'app/business-components/candidate/send-mail/send-mail.component';
import { SearchPositionComponent } from 'app/business-components/position/search-position/search-position.component';
import { ConfirmDialogComponent } from 'app/generic-components/confirm-dialog/confirm-dialog.component';
import { OpenStreetMapContainerComponent } from 'app/generic-components/open-street-map-container/open-street-map-container/open-street-map-container.component';
import { SavedRequestComponent } from 'app/pages/advanced-search/saved-requests/saved-request/saved-request.component';
import { AttachmentPopupComponent } from 'app/pages/advanced-search/search-results/attachment-popup/attachment-popup.component';
import { CandidateAddContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-add-container/candidate-add-container.component';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { Dialogs } from '../enums/dialogs-enum';
import { DialogBehaviorHandler } from '../handlers/dialog-behavior.handler';
import { DialogCloseOutsideBehaviorHandler } from '../handlers/dialog-close-outside-behavior.handler';
import { DialogHelper } from '../helpers/dialog.helper';

@Injectable({
  providedIn: 'root',
})
export class DialogsManager {

  private readonly openedDialogs: Array<MatDialogRef<any>>;
  private readonly dialogHelper: DialogHelper;

  constructor(
    private readonly dialogBehaviorHandler: DialogBehaviorHandler,
    private readonly dialogCloseOutsideBehaviorHandler: DialogCloseOutsideBehaviorHandler,
  ) {
    this.openedDialogs = new Array<MatDialogRef<any>>();
    this.dialogHelper = new DialogHelper();
  }

  public isDialogOpen(dialog: Dialogs | string): boolean {
    let id: string;
    if (typeof dialog !== 'string') {
      const dialogConfig = this.dialogHelper.getDialogConfig(dialog);
      if (!dialogConfig) {
        return false;
      }
      id = dialogConfig.id;
    } else {
      id = dialog;
    }
    return this.openedDialogs.some(dialogRef => id === dialogRef.id);
  }

  public openDialog<T>({ dialog, closeClickOutside = false, data }: { dialog: Dialogs; closeClickOutside?: boolean; data?: any; }): T | any {
    if (!dialog) {
      return undefined;
    }
    let dialogRef: MatDialogRef<T>;
    const dialogConfig = this.dialogHelper.getDialogConfig(dialog);
    if (!dialogConfig) {
      return undefined;
    }
    if (data) {
      dialogConfig.data = data;
    }
    if (this.isDialogOpen(dialog)) {
      return this.openedDialogs.find(dRef => dRef.id === dialogConfig.id).componentInstance;
    }
    if (closeClickOutside) {
      dialogRef = this.dialogCloseOutsideBehaviorHandler.handle(this, dialog, dialogConfig);
    } else {
      dialogRef = this.dialogBehaviorHandler.handle(this, dialog, dialogConfig);
    }
    if (!dialogRef) {
      return undefined;
    }
    this.untrackDialogAfterClose(dialogRef);
    this.openedDialogs.push(dialogRef);
    return dialogRef.componentInstance;
  }

  public getDialogRef<T>(dialog: Dialogs | string): MatDialogRef<any | T> {
    const id = this.dialogHelper.getDialogIdentity(dialog);
    return this.openedDialogs.find(dialogRef => id === dialogRef.id);
  }

  public closeDialog<T>(dialogRef: MatDialogRef<T>): void {
    if (dialogRef) {
      dialogRef.close();
    }
  }

  public getDialogComponentType(dialog: Dialogs): ComponentType<any> {
    switch (dialog) {
      case Dialogs.GeolocateCandidates:
        return OpenStreetMapContainerComponent;
      case Dialogs.AddCandidateForm:
        return CandidateAddContainerComponent;
      case Dialogs.CandidateForm:
        return CandidateContainerComponent;
      case Dialogs.Attachment:
        return AttachmentPopupComponent;
      case Dialogs.ActionsCandidate:
        return ActionFollowUpComponent;
      case Dialogs.SavedRequest:
        return SavedRequestComponent;
      case Dialogs.SendMail:
        return SendMailComponent;
      case Dialogs.SearchPosition:
        return SearchPositionComponent;
      case Dialogs.ConfirmDialog:
        return ConfirmDialogComponent;
      case Dialogs.importCV:
        return ImportCvComponent
      default:
        return undefined;
    }
  }

  private untrackDialogAfterClose<T>(dialogRef: MatDialogRef<T>): void {
    dialogRef.afterClosed().subscribe(
      () => {
        const index = this.openedDialogs.indexOf(dialogRef);
        if (index > -1) {
          this.openedDialogs.splice(index, 1);
        }
      }
    );
  }
}

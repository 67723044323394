<div class="criterion-semantic">
  <mat-expansion-panel [expanded]="isExpanded">
    <mat-expansion-panel-header [collapsedHeight]="'18px'" [expandedHeight]="'18px'">
      <mat-panel-title>
        {{ 'SemanticCategory.Radius' | translate  }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field appearance="outline" class="d-block">
      <input id="{{ identifier }}_radius_input"
             matInput
              type="number"
              [(ngModel)]="sliderCurrentValue"
              name="sliderCurrentValue"
              [min]="minRadius"
              [max]="maxRadius"
              (change)="onInputChange()" />
      <span matSuffix class="pl-2">{{ 'km' | translate  }}</span>
    </mat-form-field>
    <div class="d-flex justify-content-between align-items-end mx-2">
      <div class="sliderRadius">{{ minRadius }}</div>
      <mat-slider id="{{ identifier }}_radius_slider"
                  [min]="minRadius"
                  [max]="maxRadius"
                  [step]="stepRadius"
                  [(value)]="sliderCurrentValue"
                  (change)="onSliderChanged($event)"
                  thumbLabel
                  class="w-100">
      </mat-slider>
      <div class="sliderRadius">{{ maxRadius }}</div>
    </div>
  </mat-expansion-panel>
</div>

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory, GeographicAreasService } from 'common-services';

@Component({
  selector: 'app-criteria-group-city',
  templateUrl: './criteria-group-city.component.html'
})
export class CriteriaGroupCityComponent implements OnInit {

  public criterionCategory = CriterionCategory;
  public emptyOptionLabel: string;
  public noOptionAvailableLabel: string;

  public countries: Array<KeyValue<number, string>>;
  public regions: Array<KeyValue<number, string>>;

  constructor(
    private readonly geographicAreasService: GeographicAreasService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) { }

  public ngOnInit() {
    this.geographicAreasService.getAvailableCountries().then(data =>
      this.countries = data.map(country => ({ key: country.id, value: country.label }))
    );

    this.translateService.get(['chooseOption', 'noOptionAvailable']).subscribe(translations => {
      this.emptyOptionLabel = translations.chooseOption;
      this.noOptionAvailableLabel = translations.noOptionAvailable;
    });
  }

  public onCountryChange(selectedCountryId: number) {
    this.geographicAreasService.getRegionsByCountryId(selectedCountryId).then(data =>
      this.regions = data.map(region => ({ key: region.id, value: region.label }))
    );
  }

  public onRegionChange(selectedRegion: number) {
    const selected = this.regions.find(a => a.key === selectedRegion);
    if (selected && selected.key > 0) {
      // On ajoute le critère à la liste des critères raffinés du state
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.Region,
        selected.value,
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchState } from 'app/shared/models/state/advanced-search-state.model';
import * as _ from 'lodash';
import * as AdvancedSearchActions from '../actions/advanced-search.action';

const initialState: AdvancedSearchState = {
  advancedSearches: [],
  dataState: StateStatus.INITIAL
};

const reducer = createReducer(
  initialState,
  // Modification du status du store seulement
  on(AdvancedSearchActions.getAdvancedSearch, (state) => ({ ...state, dataState: StateStatus.LOADING })),
  on(AdvancedSearchActions.getAdvancedSearchError, (state) => ({ ...state, dataState: StateStatus.ERRORLOADING })),
  on(AdvancedSearchActions.deleteAdvancedSearch, (state) => ({ ...state, dataState: StateStatus.DELETING })),
  on(AdvancedSearchActions.deleteAdvancedSearchError, (state) => ({ ...state, dataState: StateStatus.ERRORDELETING })),
  on(AdvancedSearchActions.updateAdvancedSearch, (state) => ({ ...state, dataState: StateStatus.UPDATING })),
  on(AdvancedSearchActions.updateAdvancedSearchError, (state) => ({ ...state, dataState: StateStatus.ERRORUPDATING })),
  on(AdvancedSearchActions.addAdvancedSearch, (state) => ({ ...state, dataState: StateStatus.ADDING })),
  on(AdvancedSearchActions.addAdvancedSearchError, (state) => ({ ...state, dataState: StateStatus.ERRORADDING })),
  on(AdvancedSearchActions.cloneAdvancedSearch, (state) => ({ ...state, dataState: StateStatus.CLONING })),
  on(AdvancedSearchActions.cloneAdvancedSearchError, (state) => ({ ...state, dataState: StateStatus.ERRORCLONIG })),

  // Modification du status du store et de data, et récupération des recherches sauvgardées depuis payload
  on(
    AdvancedSearchActions.getAdvancedSearchSuccess,
    (state, result) => {
      return { ...state, advancedSearches: result.payload, dataState: StateStatus.LOADED };
    }
  ),
  // Supprimer une recherche depuis le store
  on(
    AdvancedSearchActions.deleteAdvancedSearchSuccess,
    (state, result) => {
      const searchs = state.advancedSearches.filter(element => element.id !== result.payload);
      return { ...state, advancedSearches: searchs, dataState: StateStatus.DELETED };
    }
  ),
  // Remplacer une recherche dans le store aprés l'update
  on(
    AdvancedSearchActions.updateAdvancedSearchSuccess,
    (state, result) => {
      const searchs = [...state.advancedSearches];
      const index = searchs.indexOf(searchs.find(element => element.id === result.payload.id));
      if (index !== -1) {
        searchs[index] = result.payload;
      }
      return { ...state, advancedSearches: searchs, dataState: StateStatus.UPDATED };
    }
  ),
  // Insertion d'une nouvelle recherche dans le store
  on(
    AdvancedSearchActions.addAdvancedSearchSuccess,
    (state, result) => {
      const searchs = [...state.advancedSearches];
      searchs.push(result.payload);
      return { ...state, advancedSearches: searchs, dataState: StateStatus.ADDED };
    }
  ),
  // Clone recherche dans le store
  on(
    AdvancedSearchActions.cloneAdvancedSearchSuccess,
    (state, result) => {
      const searchs = [...state.advancedSearches];
      searchs.push(result.payload);
      return { ...state, advancedSearches: searchs, dataState: StateStatus.CLONED };
    }
  ),
  // Récupérer les critères d'une recherche enregistrée via l'Id
  on(
    AdvancedSearchActions.loadCriterionByAdvancedSearchId,
    (state) => {
        return { ...state, dataState: StateStatus.LOADINGCRITERION };
    }
  ),
  // Récupérer les critères d'une recherche enregistrée via l'Id avec succées
  on(
    AdvancedSearchActions.loadCriterionByAdvancedSearchIdSuccess,
    (state, result) => {
      const advancedSearches = _.cloneDeep(state.advancedSearches);
      const advancedSearch = result.payload;
      const index = advancedSearches.findIndex(search => search.id === advancedSearch.id)
      
      if(index !== -1) {
        advancedSearches[index] = advancedSearch;
      }

      return { ...state, dataState: StateStatus.ERRORLOADINGCRITERION, advancedSearches };
    }
  ),
  // Erreur lors de la récupération des critères d'une recherche enregistrée
  on(
    AdvancedSearchActions.loadCriterionByAdvancedSearchIdIdError,
    (state) => {
        return { ...state, dataState: StateStatus.ERRORLOADINGCRITERION };
    }
  ),
);

export function advancedSearchReducer(state: AdvancedSearchState = initialState, action: Action): AdvancedSearchState {
  return reducer(state, action);
}

import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { HighlightPipe } from './pipes/highlight.pipe';
import { HtmlSecurityByPasserPipe } from './pipes/html-security-bypasser.pipe';
import { StrongifyPipe } from './pipes/strongify.pipe';
import { ToLocalDatePipe } from './pipes/to-local-date.pipe';

// Ajoutez vos modules ici
const modules = [
  SharedModule
];

// Ajoutez vos pipes ici
const pipes = [
  HighlightPipe,
  HtmlSecurityByPasserPipe,
  StrongifyPipe,
  ToLocalDatePipe
];

@NgModule({
  declarations: [
    ...pipes
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...pipes
  ],
  providers: [
    ...pipes
  ]
})
export class CoreModule { }

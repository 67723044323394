import { Injectable } from '@angular/core';

@Injectable()
export class CkEditorHelper {
  /**
   * Supprime les tags et le contenu ajouté par CkEditor
   * et revoie le contenu de CkEditor
   */
  public getNormalizedContent(readOnly = false): string {
    let content = document.querySelector('.ck-content').innerHTML;

    content = content.replace(/<p><br data-cke-filler="true"><\/p>/g, '&nbsp;')
      .replace(/image widget/g, '')
      .replace(/<br data-cke-filler="true">/g, '');

    // on enlève les classes de ckeditor et on remplace editable par false
    if (readOnly) {
      content = content.split('contenteditable="true"').join('contenteditable="false"')
        .split('ck-editor__editable').join('')
        .split('ck-editor__nested-editable').join('');
    }

    return content;
  }

  /**
   * Suppression de base64
   * remplace le src d'img par le nom de fichier
   */
  public getNormalizedContentWithReplacedBase64(): string {
    const content = document.createElement('div');
    content.innerHTML = this.getNormalizedContent(true);
    const imgs = content.querySelectorAll('img[data-name]');

    imgs.forEach(img => {
      img.classList.add('eolia_uploaded_img');
    });

    return content.innerHTML;
  }

  /**
   *  Nettoie le text en paramètre des tags CkEditor
   * @param content Text à nettoyer des tags CkEditor
   */
  public cleanCkEditorText(content: string): string {
    // Enlever la fermeture du paragraphe ajoutée par wysiwyg
    const searchPattern = '</p><p>';
    content = content.trim();
    const splited = content.split(searchPattern);
    if (splited[splited.length - 1] === '') {
      content = content.substr(0, content.length - searchPattern.length);
    }

    const tag = document.createElement('div');
    tag.innerHTML = content;
    const lastchild = tag.lastChild;

    if (lastchild && lastchild.nodeName === 'P' && tag.childElementCount === 1) {
      const htmlContent = tag.firstChild as HTMLElement;
      const text = htmlContent.innerHTML ?? tag.firstChild.textContent;
      tag.innerHTML = text;

    } else if (lastchild && lastchild.nodeName === 'P') {
      const htmlLastChild = tag.lastChild as HTMLElement;
      tag.removeChild(tag.lastChild);
      tag.innerHTML += htmlLastChild.innerHTML;
    }

    return tag.innerHTML;
  }
}

<mat-form-field appearance="outline" class="{{ class }}">
  <mat-label *ngIf="label != undefined">{{label}}</mat-label>
  <mat-select id="{{ identifier }}"
              (selectionChange)="onToggle($event.value)"
              [formControl]="selectGroupControl"
              [required]="required"
              [multiple]="multiple"
              disableOptionCentering>
    <mat-option *ngIf="emptyOption != undefined" [value]="emptyOptionValue" class="h-auto py-2">
      {{emptyOption}}
    </mat-option>
    <mat-optgroup *ngFor="let group of groups" [label]="group.key">
      <mat-option id="{{ identifier }}_{{ i }}" *ngFor="let option of group.value;index as i;" [value]="option.key" class="h-auto py-2">
        {{option.value}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-spinner *ngIf="isLoading" class="spinnerSelectGroup" diameter="20"></mat-spinner>
</mat-form-field>

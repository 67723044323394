import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationsService, NavigatorHelper } from "common-services";
import { InitializerProvider } from "./shared/providers/initializer.provider";
import { ParameterService } from "./shared/services/parameter.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public authByToken = false;

  constructor(
    private readonly router: Router,
    private readonly authenticationsService: AuthenticationsService,
    private readonly parameterService: ParameterService,
    private readonly navigatorHelper: NavigatorHelper,
    private readonly initializerProvider: InitializerProvider
  ) {}

  ngOnInit(): void {
    const url = new URL(document.URL);
    const token = url.searchParams.get("token");
    this.authenticateByToken(token);
  }

  // Authentification par token
  private authenticateByToken(token: string): void {
    if (token) {
      // Détruire l'ancien cookie et supprimer le localStorage
      const cookieName = this.navigatorHelper.getDefaultConfig().name;
      const cookiePath = this.navigatorHelper.getDefaultConfig().name;
      this.navigatorHelper.destroy(cookieName, cookiePath);
      localStorage.clear();

      const url = new URL(document.URL);
      // Récupération de l'ID du poste depuis URL pour le match poste candidats
      const positionIdParam = url.searchParams.get('positionId');
      const positionId = (positionIdParam) ? parseInt(positionIdParam, 10) : 0;
      // Récupération de la langue du poste depuis Url pour le match poste candidats
      const codeLangueParam = url.searchParams.get('codeLanguage');
      const codeLanguage = (codeLangueParam) ?? '';
      // Récupération d'URL du poste depuis URL pour le match poste candidats
      const positionUriParam = url.searchParams.get('positionUrl');
      const positionUri = this.navigatorHelper.isValidUrl(positionUriParam) ? positionUriParam : '';

      this.authByToken = true;
      token = token.replace(/\s/g, "+");

      this.authenticationsService
        .authenticateByToken(token)
        .then(async () => {
          this.parameterService.initParams();
          // Initiliser l'application
          await this.initializerProvider.init().then(() => {
            // Redirection vers l'accueil aprés l'authentification

            let query = {};
            
            if( positionId && codeLanguage ) {
              query = { positionId, codeLanguage };
            } else if( positionUri ) {
              query = { 'positionUrl' : positionUri };
            }

            this.router.navigate(['/'], { queryParams: query });
          });
        })
        .catch(() => {
          // Redirection vers la page d'authentification
          this.router.navigate(['/login']);
        })
        .finally(() => (this.authByToken = false));
    }
  }
}

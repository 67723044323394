import { Component } from '@angular/core';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { CriterionCategory } from 'common-services';

@Component({
  selector: 'app-criteria-group-function',
  templateUrl: './criteria-group-function.component.html'
})
export class CriteriaGroupFunctionComponent {

  public criterionCategory = CriterionCategory;

  constructor(
    public readonly advancedSearchManager: AdvancedSearchManager
  ) { }
}

import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { FormHelper } from 'app/shared/helpers/form.helper';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { DefaultControlsErrorStateMatcher } from 'app/shared/material/default-controls-error-state-matcher';
import { PRCandidateModel } from 'app/shared/models/candidate/candidate.model';
import { CandidateParameterModel } from 'app/shared/models/parameter/candidate-parameter.model';
import { ParameterService } from 'app/shared/services/parameter.service';
import {
  CandidateService,
  CivilityService,
  CountryModel,
  CustomerModel,
  CustomerService,
  DataDepthLevel,
  DynamicFieldBaseModel,
  DynamicFieldEntity,
  DynamicFieldSelectedValueModel,
  GeographicAreasService,
  HelpersService,
  IndexingService,
  LanguageService,
  QueryableModel
} from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { CandidateContainerComponent } from '../../candidate-modal/candidate-container/candidate-container.component';

@Component({
  selector: 'app-candidate-informations',
  templateUrl: './candidate-informations.component.html',
  styleUrls: ['candidate-informations.component.scss']
})
export class CandidateInformationsComponent implements OnInit, OnDestroy, AfterViewInit {
  Validators = Validators;
  @Input() candidate: Subject<PRCandidateModel>;
  @Input() formEditMode: boolean;

  public paginationLength = 0;
  public paginationSize = 20;
  public candidateForm: FormGroup;
  public regions: Array<KeyValue<number, string>>;
  public civilities: Array<KeyValue<number, string>>;
  public countries: Array<KeyValue<number, string>>;
  public nationalities: ReadonlyArray<CountryModel>;
  public filteredNationalities: Observable<Array<CountryModel>>;
  public customers: ReadonlyArray<CustomerModel>;
  public filteredCustomers: Observable<Array<CustomerModel>>;
  public candidatesLanguages: Array<KeyValue<number, string>>;
  public languageLevels: Array<KeyValue<number, string>>;
  public errorMatcher: DefaultControlsErrorStateMatcher;
  public saveTriedOnce: boolean;
  public isSaving = true;
  public labelCivility: string;
  public labelCountry: string;
  public labelRegion: string;
  public labelFirstLanguage: string;
  public labelSecondLanguage: string;
  public labelThirdLanguage: string;
  public labelLevelLanguage: string;
  public noOptionAvailableLabel: string;
  public emptyOptionLabel = '';
  public regionOriginIdDisabled = true;
  public firstLanguageLevelDisabled = true;
  public secondLanguageLevelDisabled = true;
  public thirdLanguageLevelDisabled = true;
  public validateMailAddress = true;
  public readonly candidateParameter: CandidateParameterModel;

  private currentCandidate: PRCandidateModel;
  private successfulOperationMessage: string;
  private unsuccessfulOperationMessage: string;
  private readonly identificationFormControl: FormControl;
  private readonly emailAddressFormControl: FormControl;
  private readonly firstLanguageFormControl: FormControl;
  private readonly firstLanguageLevelFormControl: FormControl;
  private readonly secondLanguageFormControl: FormControl;
  private readonly secondLanguageLevelFormControl: FormControl;
  private readonly thirdLanguageFormControl: FormControl;
  private readonly thirdLanguageLevelFormControl: FormControl;
  private readonly subscriptions: Subscription;

  public get dynamicFieldBaseModel(): Observable<DynamicFieldBaseModel> {
    if (!this.formEditMode) {
      return this.candidate.pipe(map((dynamicFieldBaseModel: DynamicFieldBaseModel) => {
        const dynamicFieldsWithDefaultValues = dynamicFieldBaseModel.dynamicFields.filter(dynamicField =>
          dynamicField.defaultValues?.length > 0 &&
          dynamicField.defaultValues.some(defaultValue => defaultValue.value || defaultValue.predefinedValueId) &&
          (!dynamicField.selectedValues || dynamicField.selectedValues.length === 0));

        dynamicFieldsWithDefaultValues.forEach(dynamicField =>
          dynamicField.selectedValues = dynamicField.defaultValues.map(defaultValue => {
            return { value: defaultValue.value, predefinedValueId: defaultValue.predefinedValueId } as DynamicFieldSelectedValueModel;
          })
        );
        return dynamicFieldBaseModel;
      }));
    }
    return this.candidate;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly formHelper: FormHelper,
    private readonly customerService: CustomerService,
    private readonly geographicAreasService: GeographicAreasService,
    private readonly candidateService: CandidateService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: CustomSnackBarService,
    private readonly dialogsManager: DialogsManager,
    private readonly parameterService: ParameterService,
    private readonly languageService: LanguageService,
    private readonly indexingService: IndexingService,
    private readonly helpersService: HelpersService,
    private readonly civilityService: CivilityService,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.subscriptions = new Subscription();
    this.candidateParameter = this.parameterService.candidateParameter;
    this.errorMatcher = new DefaultControlsErrorStateMatcher();
    this.saveTriedOnce = false;
    this.geographicAreasService.getAvailableCountries()
      .then(data => {
        this.countries = data.map(nationality => ({ key: nationality.id, value: nationality.label }));
        const nationalitiesArray = data.slice();
        this.nationalities = nationalitiesArray;
      }).then(() => {
        this.filteredNationalities = this.candidateForm.controls.nationality.valueChanges
          .pipe(distinctUntilChanged())
          .pipe(
            startWith(''),
            map(nationality => nationality ? this.filterNationalities(nationality) : this.nationalities.slice())
          );
      });

    this.civilityService.getCivilities()
      .then(data => this.civilities = data.map(civility => ({ key: civility.id, value: civility.label })));

    this.languageService.getCandidatesLanguages()
      .then(data => this.candidatesLanguages = data.map(languages => ({ key: languages.id, value: languages.label })));

    this.languageService.getLanguageLevels()
      .then(data => this.languageLevels = data.map(languageLevel => ({ key: languageLevel.id, value: languageLevel.label })));

    this.identificationFormControl = new FormControl();
    this.emailAddressFormControl = new FormControl('', {
      validators: [Validators.required],
      updateOn: 'blur',
      asyncValidators: [this.checkEmailTaken.bind(this), this.isValidateMailAddress.bind(this)]
    });
    this.firstLanguageFormControl = new FormControl();
    this.firstLanguageLevelFormControl = new FormControl();
    this.secondLanguageFormControl = new FormControl();
    this.secondLanguageLevelFormControl = new FormControl();
    this.thirdLanguageFormControl = new FormControl();
    this.thirdLanguageLevelFormControl = new FormControl();

    this.candidateForm = this.formBuilder.group({
      id: this.identificationFormControl,
      emailAddress: this.emailAddressFormControl,
      civilityId: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      birthDate: new FormControl(),
      address: new FormControl(),
      zipCode: new FormControl(),
      city: new FormControl(),
      countryId: new FormControl(),
      regionOriginId: new FormControl(),
      professionalPhone: new FormControl(),
      personalPhone: new FormControl(),
      mobilePhone: new FormControl(),
      currentCompany: new FormControl(),
      customerId: new FormControl('', this.formHelper.validateAutocomplete),
      currentPosition: new FormControl(),
      currentSalary: new FormControl('', this.formHelper.moneyValidationPattern),
      nationalityId: new FormControl(),
      nationality: new FormControl(),
      additionalTextArea: new FormControl(),
      firstLanguageId: this.firstLanguageFormControl,
      firstLanguageLevelId: this.firstLanguageLevelFormControl,
      secondLanguageId: this.secondLanguageFormControl,
      secondLanguageLevelId: this.secondLanguageLevelFormControl,
      thirdLanguageId: this.thirdLanguageFormControl,
      thirdLanguageLevelId: this.thirdLanguageLevelFormControl
    });
  }

  public ngOnInit(): void {
    this.translateService.get([
      'civility',
      'country',
      'region',
      'language',
      'level',
      'noOptionAvailable',
      'candidateInformationsSaved',
      'error'
    ]).subscribe(translations => {
      this.labelCivility = translations.civility;
      this.labelCountry = translations.country;
      this.labelRegion = translations.region;
      this.labelFirstLanguage = `${translations.language} 1`;
      this.labelSecondLanguage = `${translations.language} 2`;
      this.labelThirdLanguage = `${translations.language} 3`;
      this.labelLevelLanguage = translations.level;
      this.noOptionAvailableLabel = translations.noOptionAvailable;
      this.successfulOperationMessage = translations.candidateInformationsSaved;
      this.unsuccessfulOperationMessage = translations.error;
    });

    if (!this.candidate) {
      this.candidate = new Subject<PRCandidateModel>();
    }
    this.candidate.subscribe(candidateModel => {
      this.currentCandidate = candidateModel;
      this.saveTriedOnce = false;
      this.candidateForm.markAsUntouched();
      this.identificationFormControl.setValue(candidateModel.id);
      this.candidateForm.controls.emailAddress.setValue(candidateModel.emailAddress);
      this.candidateForm.controls.address.setValue(candidateModel.address);
      this.candidateForm.controls.firstName.setValue(candidateModel.firstName);
      this.candidateForm.controls.lastName.setValue(candidateModel.lastName);
      this.candidateForm.controls.birthDate.setValue(candidateModel.birthDate);
      this.candidateForm.controls.zipCode.setValue(candidateModel.zipCode);
      this.candidateForm.controls.city.setValue(candidateModel.city);
      this.candidateForm.controls.professionalPhone.setValue(candidateModel.professionalPhone);
      this.candidateForm.controls.personalPhone.setValue(candidateModel.personalPhone);
      this.candidateForm.controls.mobilePhone.setValue(candidateModel.mobilePhone);
      this.candidateForm.controls.currentCompany.setValue(candidateModel.currentCompany);
      this.candidateForm.controls.customerId.setValue(candidateModel.customerId);
      this.candidateForm.controls.currentPosition.setValue(candidateModel.currentPosition);
      this.candidateForm.controls.currentSalary.setValue(candidateModel.currentSalary);
      this.candidateForm.controls.nationalityId.setValue(candidateModel.nationalityId);
      this.candidateForm.controls.nationality.setValue(candidateModel.nationality);
      this.candidateForm.controls.additionalTextArea.setValue(candidateModel.additionalTextArea);
      this.candidateForm.controls.civilityId.setValue(candidateModel.civilityId);
      this.candidateForm.controls.countryId.setValue(candidateModel.countryId);
      this.candidateForm.controls.regionOriginId.setValue(candidateModel.regionOriginId);
      this.candidateForm.controls.firstLanguageId.setValue(candidateModel.firstLanguageId);
      this.candidateForm.controls.firstLanguageLevelId.setValue(candidateModel.firstLanguageLevelId);
      this.candidateForm.controls.secondLanguageId.setValue(candidateModel.secondLanguageId);
      this.candidateForm.controls.secondLanguageLevelId.setValue(candidateModel.secondLanguageLevelId);
      this.candidateForm.controls.thirdLanguageId.setValue(candidateModel.thirdLanguageId);
      this.candidateForm.controls.thirdLanguageLevelId.setValue(candidateModel.thirdLanguageLevelId);

      if (candidateModel && candidateModel.customerId) {
        this.customerService.getCustomer(candidateModel.customerId)
          .then(customerApi => {
            this.customers = [customerApi];
            this.candidateForm.controls.customerId.setValue(customerApi.id);
          });
      }

      this.firstLanguageValueChanges(candidateModel.firstLanguageId);
      this.secondLanguageValueChanges(candidateModel.secondLanguageId);
      this.thirdLanguageValueChanges(candidateModel.thirdLanguageId);
      this.countryValueChanges(candidateModel.countryId);

      this.identificationFormControl.disable();
      this.isSaving = false;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    const customerIdFormControlSubscription = this.candidateForm.controls.customerId.valueChanges.pipe(distinctUntilChanged())
      .pipe(
        map(async customerFilter => this.customers = await this.filterCustomers(customerFilter)),
        map(filteredCustomersPromise => from(filteredCustomersPromise))
      )
      .subscribe(this.customerValueChanges.bind(this));

    this.subscriptions.add(customerIdFormControlSubscription);
  }

  public displayNationality(nationality: string): string {
    if (typeof nationality === 'string') {
      return nationality;
    }
    return undefined;
  }

  public displayCustomer(customer: string | number): string {
    if (customer && typeof customer === 'string') {
      return customer.toString();
    }
    if (this.customers) {
      const costumer = this.customers.find(customerModel => customerModel.id === customer);
      if (costumer) {
        return costumer.label;
      }
    }
    return undefined;
  }

  public save() {
    if (this.candidateForm.dirty && this.candidateForm.valid) {
      this.saveTriedOnce = true;
      this.isSaving = true;
      const savePromise = (this.formEditMode) ? this.updateCandidate() : this.addCandidate();
      savePromise
        .then(() => {
          this.snackbarService.showSuccess(this.successfulOperationMessage);
          this.isSaving = false;
        })
        .catch(() => {
          this.snackbarService.showDanger(this.unsuccessfulOperationMessage);
          this.isSaving = false;
        });
    } else {
      this.candidateForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('input.ng-invalid');
      firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private addCandidate(): Promise<PRCandidateModel> {
    const candidateFormValues = this.candidateForm.getRawValue();
    const candidate = Object.assign(this.currentCandidate, candidateFormValues);
    return this.candidateService.addCandidate(candidate)
      .then((addedCandidate: PRCandidateModel) => {
        this.indexingService.indexCandidateById(addedCandidate.id);

        // On actualise les candidats
        this.advancedSearchManager.fetchCandidates();

        this.dialogsManager.getDialogRef(Dialogs.AddCandidateForm)?.close();
        this.dialogsManager.openDialog<CandidateContainerComponent>({ dialog: Dialogs.CandidateForm, closeClickOutside: true })
          .currentCandidate = addedCandidate;
        return addedCandidate;
      });
  }

  private updateCandidate(): Promise<PRCandidateModel> {
    const candidateFormValues = this.candidateForm.getRawValue();
    const candidateToUpdate = Object.assign(this.currentCandidate, candidateFormValues);
    candidateToUpdate.dynamicFieldEntity = DynamicFieldEntity.Candidate;
    return this.candidateService.updateCandidate(candidateToUpdate)
      .then((updatedModel: PRCandidateModel) => {
        this.indexingService.indexCandidateById(updatedModel.id);
        this.candidateService.getCandidate(updatedModel.id, DataDepthLevel.WithSubObjectsAndSubListsAndDynamicFieldData).then(updatedCandidate => {
          const candidateIndex = this.advancedSearchManager.candidatesMatches.findIndex(c => c.candidate.id === updatedCandidate.id);

          if (candidateIndex !== -1) {
            const candidate = this.advancedSearchManager.candidatesMatches[candidateIndex].candidate;
            candidate.age = updatedCandidate.age;
            Object.assign(candidate, updatedCandidate);
            this.candidate.next(candidate as PRCandidateModel);

            this.advancedSearchManager.updateCandidate(candidate);
          }

          this.candidateForm.markAsPristine();
        });
        return updatedModel;
      });
  }

  public firstLanguageValueChanges(value: any): void {
    if (value) {
      this.firstLanguageLevelDisabled = false;
    } else {
      this.firstLanguageLevelDisabled = true;
      this.firstLanguageLevelFormControl.patchValue('');
    }
  }

  public secondLanguageValueChanges(value: any): void {
    if (value) {
      this.secondLanguageLevelDisabled = false;
    } else {
      this.secondLanguageLevelDisabled = true;
      this.secondLanguageLevelFormControl.patchValue('');
    }
  }

  public thirdLanguageValueChanges(value: any): void {
    if (value) {
      this.thirdLanguageLevelDisabled = false;
    } else {
      this.thirdLanguageLevelDisabled = true;
      this.thirdLanguageLevelFormControl.patchValue('');
    }
  }

  public countryValueChanges(value: any): void {
    if (value) {
      this.regionOriginIdDisabled = false;
      this.geographicAreasService.getRegionsByCountryId(value)
        .then(data => this.regions = data.map(region => ({ key: region.id, value: region.label })));
    } else {
      this.regionOriginIdDisabled = true;
      this.candidateForm.controls.regionOriginId.patchValue('');
    }
  }

  private customerValueChanges(filteredCustomers: Observable<Array<CustomerModel>>): void {
    if (filteredCustomers) {
      this.filteredCustomers = filteredCustomers;
      const filteredCustomersSubscription = filteredCustomers.pipe(distinctUntilChanged()).subscribe(customers => {
        if (customers) {
          const typedCustomer = customers.find(customer => customer.id === this.candidateForm.controls.customerId.value);
          if (typedCustomer) {
            this.candidateForm.controls.customerId.patchValue(typedCustomer.id, this.formHelper.disableChangeEventsEmitting);
          }
        }
      });
      this.subscriptions.add(filteredCustomersSubscription);
    }
  }

  private filterNationalities(filter: string): Array<CountryModel> {
    if (!filter || typeof filter !== 'string') {
      return this.nationalities.slice();
    }
    const filterValue = filter.toLowerCase();
    return this.nationalities.filter(nationality => nationality.nationality &&
      nationality.nationality.toLowerCase().indexOf(filterValue) === 0);
  }

  private async filterCustomers(filter: string): Promise<ReadonlyArray<CustomerModel>> {
    let filterValue: string;
    if (typeof filter === 'string') {
      filterValue = filter;
    } else if (this.customers) {
      const typedCustomer = this.customers.find(customer => customer.id === filter);
      if (typedCustomer) {
        filterValue = typedCustomer.label;
      }
    }
    const allCustomers = await this.customerService.findByQueryable({
      whereExpression: `p => p.label ==  "${filterValue}"`,
      dataDepthLevel: DataDepthLevel.Flat,
      page: this.paginationLength,
      pageSize: this.paginationSize
    } as QueryableModel);

    return (allCustomers.items) ? allCustomers.items.slice() : undefined;
  }

  private checkEmailTaken(): Observable<ValidationErrors | null> {
    if (this.emailAddressFormControl.value.trim() === this.currentCandidate.emailAddress) {
      return of(null);
    }
    return from(this.candidateService.findFirstByQueryable(
      {
        whereExpression: `p => p.emailAddress ==  "${this.emailAddressFormControl.value}"`,
        dataDepthLevel: DataDepthLevel.Flat
      } as QueryableModel))
      .pipe(
        map(exist => {
          return exist ? { emailAddressExists: exist } : null;
        })
      );
  }

  private isValidateMailAddress(): Observable<ValidationErrors | null> {
    if (this.emailAddressFormControl.value.trim() === this.currentCandidate.emailAddress) {
      return of(null);
    }
    return from(this.helpersService.validateMailAddress(this.emailAddressFormControl.value)).pipe(
      map(validate => {
        return !validate ? { invalidateMailAddress: !validate } : null;
      })
    );
  }
}

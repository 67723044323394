import { KeyValue } from '@angular/common';
import { Component, ElementRef, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UtilHelper } from 'app/shared/helpers/util.helper';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { EventsManager } from 'app/shared/managers/events.manager';
import { ParameterService } from 'app/shared/services/parameter.service';
import { CustomWebSearchModel } from 'common-services';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation:  ViewEncapsulation.None
})
export class HomeComponent implements OnDestroy {
  private readonly windowResizeSubscription: Subscription;
  private readonly subscription: Subscription;
  private readonly candidatesDisplaySubject: Subject<UIEvent>;
  private windowMutationObserver: MutationObserver;

  public customWebSearches: Array<KeyValue<CustomWebSearchModel, Subject<void>>>;

  // Pour afficher les moteurs de recherche
  public get hasCseCriteria(): boolean {
    return !!this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria.some(
      element => this.utilHelper.ignoredCategoryForCse().indexOf(element.category) === -1
    );
  }

  public get hasCriteria(): boolean {
    return this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria.length > 0;
  }

  constructor(
    appComponentElement: ElementRef,
    private readonly eventsManager: EventsManager,
    private readonly ngZone: NgZone,
    private readonly parameterService: ParameterService,
    private readonly utilHelper: UtilHelper,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.candidatesDisplaySubject = new Subject();
    this.subscription = new Subscription();
    this.candidatesDisplaySubject
      .pipe(
        debounceTime(50),
        map(() => this.calculateCandidatesDisplayHeight()),
        distinctUntilChanged()
      )
      .subscribe(
        // Méthode appelée lors du resize de la fenêtre
        candidatesResultsHeight =>
          // On calcule la hauteur de la zone d'affichage des candidats
          this.updateCandidatesResultsHeight(candidatesResultsHeight)
      );
    this.windowResizeSubscription = this.eventsManager.windowResize.subscribe(this.candidatesDisplaySubject);
    this.ngZone.runOutsideAngular(() => {
      this.windowMutationObserver = new MutationObserver(() => this.candidatesDisplaySubject.next());
      this.windowMutationObserver.observe(appComponentElement.nativeElement, {
        childList: true,
        subtree: true
      });
    });

    this.parameterService.candidateSearchParameter?.customWebSearches?.sort((a, b) => a.order - b.order);
    this.customWebSearches = this.parameterService.candidateSearchParameter?.customWebSearches.map(element => {
      return {
        key: element,
        value: new Subject()
      };
    });
  }

  ngOnDestroy() {
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
    }
    if (this.candidatesDisplaySubject) {
      this.candidatesDisplaySubject.complete();
    }
    if (this.windowMutationObserver) {
      this.windowMutationObserver.disconnect();
    }

    this.subscription.unsubscribe();
  }

  /**
   * Obtient le premier élément du DOM par nom de classe
   * @param className Le nom de la classe de l'élément à retrouver
   */
  private getHTMLElement(className: string): HTMLElement {
    return document.getElementsByClassName(className)[0] as HTMLElement;
  }

  /** Met à jour la hauteur des résultats de candidats */
  private updateCandidatesResultsHeight(candidatesDisplayHeight: number) {
    const candidatesDisplayGroup = this.getHTMLElement('candidates-display-group');
    if (candidatesDisplayGroup) {
      candidatesDisplayGroup.style.height = `${candidatesDisplayHeight}px`;
    }

    const gcse = this.getHTMLElement('gcse-wrapper');
    if (gcse) {
      gcse.style.height = `${candidatesDisplayHeight}px`;
    }
  }

  /**
   * Calcule la hauteur de la zone d'affichage des candidats
   */
  private calculateCandidatesDisplayHeight(hasActionsElement = true): number {
    const refinedCriteriaElement = this.getHTMLElement('refinedCriteriaMenu');
    /** Obtient la hauteur du menu des critères */
    const refinedCriteriaHeight = (refinedCriteriaElement) ? refinedCriteriaElement.offsetHeight : 0;

    const customSearchEngineElement = document.getElementsByClassName('customSearchEngineGroup')[0];
    const customSearchEngineElementHeight = customSearchEngineElement.getElementsByClassName('mat-tab-header')[0];
    const customSearchEngineHeight = (customSearchEngineElementHeight) ? customSearchEngineElementHeight.clientHeight : 0;

    const actionsElement = this.getHTMLElement('candidate-results-actions');
    const actionsHeight = (actionsElement && hasActionsElement) ? actionsElement.offsetHeight : 0;

    const footerElement = this.getHTMLElement('candidate-results-footer');
    const footerHeight = (footerElement) ? footerElement.offsetHeight : 0;

    const staticMargins = customSearchEngineHeight + actionsHeight + footerHeight;

    const criteriaMenuHeight = window.innerHeight - refinedCriteriaHeight;
    document.getElementById('criteriaMenuId').style.height = `${criteriaMenuHeight}px`

    return window.innerHeight - (refinedCriteriaHeight + staticMargins);
  }

  public loadNetwork(event: MatTabChangeEvent) {
    this.customWebSearches[event.index - 1]?.value.next();
    const key = this.customWebSearches[event.index - 1]?.key.key;
    if (key) {
      document.getElementById(`eolia-gcse-tag-${key}`).style.maxHeight = `${this.calculateCandidatesDisplayHeight(false)}px`;
    }
  }

  public trackByFunction(index: number) {
    return index;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionFollowUpComponent } from 'app/business-components/candidate/action-follow-up/action-follow-up.component';
import { SearchPositionComponent } from 'app/business-components/position/search-position/search-position.component';
import { ConfirmDialogComponent } from 'app/generic-components/confirm-dialog/confirm-dialog.component';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { LetterModelsType } from 'app/shared/enums/letter-models-type.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { ConfirmDialogModel } from 'app/shared/models/confirm-dialog.model';
import { unSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import {
CandidateModel,
CandidateService,
CandidatureModel,
CandidatureService,
DataDepthLevel,
IndexingService,
PagedItems,
PositionModel,
QueryableModel
} from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-actions-candidate',
  templateUrl: './actions-candidate.component.html',
  styleUrls: ['./actions-candidate.component.scss']
})
export class ActionsCandidateComponent implements OnInit, OnDestroy {

  @Input() candidateIds: Array<number>;
  @Input() isCandidatesCountVisible = false;
  @Input() multipleActions = true;

  private readonly subscription: Subscription;
  private candidatesCouldNotAttachToPositionMessage: string;
  private candidatesAttachedToPositionMessage: string;
  private noCandidateSelectedMessage: string;
  private mergeCandidatesConfirmationTitle: string;
  private mergeCandidatesConfirmationLabel: string;
  private errorWhenMergingCandidates: string;
  private deleteCandidateConfirmationMessage: string;
  private deleteConfirmationTitle: string;
  private candidatesSuccessfullyMerged: string;
  private candidatesSuccessfullyDeleted: string;
  private candidatesDeleteError: string;

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly candidateService: CandidateService,
    private readonly candidatureService: CandidatureService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: CustomSnackBarService,
    private readonly dialogManager: DialogsManager,
    private readonly indexingService: IndexingService,
    private readonly store: Store<AppState>,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    const tradsubscription = this.translateService.get([
      'candidatesCouldNotAttachToPosition',
      'candidatesAttachedToPosition',
      'noCandidateSelected',
      'mergeCandidatesConfirmationTitle',
      'mergeCandidatesConfirmationLabel',
      'errorWhenMergingCandidates',
      'deleteCandidateConfirmationLabel',
      'deleteConfirmationTitle',
      'candidatesSuccessfullyMerged',
      'candidatesSuccessfullyDeleted',
      'candidatesDeleteError'
    ]).subscribe(translation => {
      this.candidatesCouldNotAttachToPositionMessage = translation.candidatesCouldNotAttachToPosition;
      this.candidatesAttachedToPositionMessage = translation.candidatesAttachedToPosition;
      this.noCandidateSelectedMessage = translation.noCandidateSelected;
      this.mergeCandidatesConfirmationTitle = translation.mergeCandidatesConfirmationTitle;
      this.mergeCandidatesConfirmationLabel = translation.mergeCandidatesConfirmationLabel;
      this.errorWhenMergingCandidates = translation.errorWhenMergingCandidates;
      this.deleteCandidateConfirmationMessage = translation.deleteCandidateConfirmationLabel;
      this.deleteConfirmationTitle = translation.deleteConfirmationTitle;
      this.candidatesSuccessfullyMerged = translation.candidatesSuccessfullyMerged;
      this.candidatesSuccessfullyDeleted = translation.candidatesSuccessfullyDeleted;
      this.candidatesDeleteError = translation.candidatesDeleteError;
    });

    this.subscription.add(tradsubscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private get candidates(): CandidateModel[] {
    return this.isCandidatesCountVisible ? this.advancedSearchManager.advancedSearchResponseState.selectedCandidates :
      this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.candidatesMatches?.
        filter(m => this.selectedCandidatesIds.includes(m.candidate.id)).map(m => m.candidate);
  }

  public get selectedCandidatesIds(): Array<number> {
    return this.candidateIds ?? this.advancedSearchManager.advancedSearchResponseState?.selectedCandidates.map(c => c.id);
  }

  public get isDisabled(): boolean {
    // Si au moins un candidat est selectionné
    return !this.selectedCandidatesIds?.length;
  }

  public get isMergeCandidateOptionEnabled(): boolean {
    // Si au moins deux candidats sont selectionnés
    return this.selectedCandidatesIds.length >= 2;
  }

  public addActionFollowUp() {
    // On ouvre la pop-up et on spécifie la liste des candidats à envoyer
    const actionFollowUpComponent = this.dialogsManager.openDialog<ActionFollowUpComponent>({ dialog: Dialogs.ActionsCandidate });
    actionFollowUpComponent.candidateIds = this.selectedCandidatesIds;
    actionFollowUpComponent.actionFollowUpSavedCandidateIdsObservable.subscribe((changedCandidates) => {
      // Si un suivi d'action est bien enregistré on rafraîchit les données des candidats changés
      if (changedCandidates?.length) {
        this.refreshCandidatesOnDialogForm(changedCandidates);
      }
    });
  }

  public attachPosition() {
    // On spécifie la liste des Ids de candidats séléctionnés à envoyer à la popup
    const candidateIds = this.selectedCandidatesIds;
    if (!candidateIds || candidateIds.length < 1) {
      this.snackbarService.showSuccess(this.noCandidateSelectedMessage);
      return;
    }
    // On ouvre la popup
    const searchPositionComponent = this.dialogsManager.openDialog<SearchPositionComponent>(
      { dialog: Dialogs.SearchPosition, closeClickOutside: false });
    searchPositionComponent.selectedPosition.subscribe(
      position => {
        searchPositionComponent.dialogRef.close();
        this.attachCandidate(position);
      }
    );
  }

  public sendMail() {
    // On spécifie les données à envoyer à la popup
    const data = {
      // La liste des candidats sélectionnés
      candidates: this.candidates,
      // Le type d'élements auxquels seront envoyés les mails
      elementType: LetterModelsType.CANDIDAT
    };
    // On ouvre la popup
    this.dialogsManager.openDialog({ dialog: Dialogs.SendMail, closeClickOutside: false, data });
  }

  public mergeCandidates() {
    // On demande confirmation de fusion
    this.dialogManager.openDialog<ConfirmDialogComponent>({
      dialog: Dialogs.ConfirmDialog,
      closeClickOutside: false,
      data: new ConfirmDialogModel(
        this.mergeCandidatesConfirmationTitle,
        this.mergeCandidatesConfirmationLabel
      )
    });

    // Si c'est confirmé
    this.dialogManager.getDialogRef(Dialogs.ConfirmDialog).afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // On fusionne
        this.candidateService.mergeCandidates(this.selectedCandidatesIds)
          .then(destinationCandidate => {
            if (!destinationCandidate) {
              return Promise.reject();
            }
            this.snackbarService.showSuccess(`${this.selectedCandidatesIds.length} ${this.candidatesSuccessfullyMerged}`);
            // On actualise les candidats
            this.advancedSearchManager.fetchCandidates();
            // désélectionner les candidates mergés
            this.store.dispatch(unSelectCandidates({ payload: this.selectedCandidatesIds }));
          })
          .catch(() =>
            this.snackbarService.showDanger(this.errorWhenMergingCandidates)
          );
      }
    });
  }

  /** Suppression logique du candidat */
  public async deleteCandidates() {
    // Préparation de la dialog de confirmation de suppression
    const dialogData = new ConfirmDialogModel(this.deleteConfirmationTitle, this.deleteCandidateConfirmationMessage);

    // Ouvrir la confirmation
    this.dialogManager.openDialog<ConfirmDialogComponent>({ dialog: Dialogs.ConfirmDialog, closeClickOutside: false, data: dialogData });
    const dialogRef = this.dialogManager.getDialogRef(Dialogs.ConfirmDialog);

    // A la fermeture de la demande de confirmation
    dialogRef.afterClosed().subscribe(dialogResult => {
      // Si l'utilisateur confirme
      if (dialogResult) {
        // Pour chaque candidat sélectionné
        const undeletedCandidatesCount = this.selectedCandidatesIds
          .filter(
            async candidateId => {
              // On le supprime. Si ça échoue
              if (!await this.candidateService.softDeleteCandidate(candidateId).catch(() => false)) {
                // On le garde dans la liste des candidats en échec de suppression
                return true;
              }
              // Sinon on le désindexe
              this.indexingService.desindexCandidateById(candidateId);
              return false;
            }
          ).length;
        // On affiche un message selon s'il y a des candidats en échec de suppression
        if (!undeletedCandidatesCount) {
          this.snackbarService.showSuccess(`(${this.selectedCandidatesIds.length}) ${this.candidatesSuccessfullyDeleted}`);
        } else {
          this.snackbarService.showDanger(`(${undeletedCandidatesCount}) ${this.candidatesDeleteError}`);
        }

        this.advancedSearchManager.fetchCandidates();
      }
    });
  }

  private async attachCandidate(position: PositionModel) {
    const failedCandidatesIds = Array<number>();
    // Pour chaque candidat sélectionné
    for (const candidateId of this.selectedCandidatesIds) {
      // On ajoute une nouvelle candidature
      await this.candidatureService.addCandidature({
        candidateId,
        positionId: position.id
      } as CandidatureModel)
        .then(
          // Si ça réussit, on indèxe le candidat correspondant
          () => { this.indexingService.indexCandidateById(candidateId); },
          // Sinon, on le met dans la liste des candidatures en échec de création
          () => { failedCandidatesIds.push(candidateId); }
        );
    }

    // Si existants, on affiche les candidats qui n'ont pas pu être rattachés à un poste
    if (failedCandidatesIds.length) {
      const names = this.advancedSearchManager.candidatesMatches
        .filter(c => failedCandidatesIds.indexOf(c.candidate.id) !== -1)
        .map(c => `${c.candidate.firstName} ${c.candidate.lastName}`)
        .join(', ');
      const errorMessage = `${this.candidatesCouldNotAttachToPositionMessage} "${position.title}" : ${names}`;
      this.snackbarService.showDanger(errorMessage);
    } else {
      const message = `${this.selectedCandidatesIds.length} ${this.candidatesAttachedToPositionMessage} "${position.title}"`;
      this.snackbarService.showSuccess(message);
    }
    const changedCandidates = this.selectedCandidatesIds.filter(candidateId => !failedCandidatesIds.includes(candidateId));
    this.refreshCandidatesOnDialogForm(changedCandidates);
  }

  private findCandidatesByIds(candidateIds: number[]): Promise<PagedItems<CandidateModel>> {
    const whereExpression = candidateIds.length > 0 ? `c => c.id in (${candidateIds.join(',')})` : 'c => false';
    const query: QueryableModel = {
      whereExpression,
      dataDepthLevel: DataDepthLevel.Flat,
      sortOrder: 'NONE',
      page: 0,
      pageSize: 10000
    };

    return this.candidateService.findByQueryable(query);
  }

  private refreshCandidatesOnDialogForm(changedCandidatesIds: number[]) {
    this.findCandidatesByIds(changedCandidatesIds).then((candidates) => {
      candidates.items.forEach(
        candidate => {
          const candidateIndex = this.advancedSearchManager.candidatesMatches.findIndex(c => c.candidate.id === candidate.id);
          if (!candidate || candidateIndex === -1) {
            return;
          }
          this.advancedSearchManager.candidatesMatches[candidateIndex].candidate = candidate;
          const dialogModel = { dialog: Dialogs.CandidateForm, closeClickOutside: true, data: candidate.id };
          if (this.dialogsManager.isDialogOpen(Dialogs.CandidateForm)) {
            this.dialogsManager.openDialog<CandidateContainerComponent>(dialogModel).currentCandidate = candidate;
          }
        }
      );
    });
  }

  public openCandidateActionsPopUp(event: Event) {
    event.stopPropagation();
  }
}

<div class="wrapperContent wrapperContentExp">
  <div class="scroll-wrapper">
    <div class="scroll grey with-padding">
      <div class="app-candidate-experiences-formations">
        <mat-card class="matCard">
          <mat-card-header>
            <div mat-card-avatar class="identity-avatar">
              <mat-icon>card_travel</mat-icon>
            </div>
            <mat-card-title>{{ 'experiences' | translate  | uppercase }}</mat-card-title>
          </mat-card-header>

          <!-- Expériences -->
          <mat-card-content>
            <app-candidate-experiences [candidate]="candidate"></app-candidate-experiences>
          </mat-card-content>
          <!-- / Expériences -->
        </mat-card>

        <mat-card class="matCard">
          <mat-card-header>
            <div mat-card-avatar class="identity-avatar">
              <mat-icon>school</mat-icon>
            </div>
            <mat-card-title>{{ 'educations' | translate  | uppercase }}</mat-card-title>
          </mat-card-header>

          <!-- Formations -->
          <mat-card-content>
            <app-candidate-formations [candidate]="candidate"></app-candidate-formations>
          </mat-card-content>
          <!-- / Formations -->
        </mat-card>
      </div>
    </div>
  </div>
</div>

import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { EventsManager } from 'app/shared/managers/events.manager';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { AdvancedSearchResponseState } from 'app/shared/models/state/advanced-search-response-state.model';
import { loadAdvancedSearch, resetAdvancedSearchResponse, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import * as _ from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-refined-criteria-menu',
  templateUrl: './refined-criteria-menu.component.html',
  styleUrls: ['./refined-criteria-menu.component.scss']
})
export class RefinedCriteriaMenuComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  private readonly subscriptions: Subscription;
  private currentadvancedSearch: AdvancedSearchViewModel;
  private searchesObservable$: Observable<AdvancedSearchResponseState>;
  private scrollIndex: Array<number> = []; // liste des noeuds de scroll(un noeud de scroll c'est le critère qui ne s'affiche pas complètement dans le menu des critères)
  private visitedIndex = -1;
  private readonly windowResizeSubscription: Subscription;
  private readonly criteriaDisplaySubject: Subject<UIEvent>;
  private windowMutationObserver: MutationObserver;
  private criteriaMenuWidth = 0;
  private criteriaWidth = 0;
  private currentSearchId = 0;
  private readonly scrollBtnWidth = 50;
  private currentCriteriaLength = 0;
  private scrollEnd = false;


  public isAdvancedSearchModalVisible = false;
  public showLeftScrollButton = false;
  public showRightScrollButton = false;
  public criteriaArray: Array<RefinedCriterionViewModel> = [];

  public get hasSelectedCriteria(): boolean {
    return this.criteriaArray.length > 0;
  }

  constructor(
    appComponentElement: ElementRef,
    private readonly eventsManager: EventsManager,
    private readonly ngZone: NgZone,
    private readonly dialogsManager: DialogsManager,
    private readonly store: Store<AppState>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly actions: Actions
  ) {
    this.subscriptions = new Subscription();
    this.criteriaDisplaySubject = new Subject();
    this.criteriaDisplaySubject
      .subscribe(
        () => {
          this.displayCriteriaMenu();
        }
      );
    this.windowResizeSubscription = this.eventsManager.windowResize.subscribe(this.criteriaDisplaySubject);
    this.ngZone.runOutsideAngular(() => {
      this.windowMutationObserver = new MutationObserver(() => this.criteriaDisplaySubject.next());
      this.windowMutationObserver.observe(appComponentElement.nativeElement, {
        childList: true,
        subtree: true
      });
    });
    this.subscriptions.add(this.windowResizeSubscription)
  }

  ngOnInit(): void {
    this.searchesObservable$ = this.store.select(data => data.advancedSearchResponseState);
    const searchesSubcription = this.searchesObservable$.subscribe(() => {
      this.criteriaArray = this.getCriteria;
      if (this.criteriaArray.length > 0) {
        const scrollMenu = document.getElementById('scrollCriteria');
        // si on initialise la recherche
        if (scrollMenu && scrollMenu.style.right === '0px') {
          this.visitedIndex = -1;
          this.showLeftScrollButton = false;
        }
      } else {
        this.initialiseCriteriaMenuScroll();
        this.criteriaWidth = 0;
      }
    });
    this.subscriptions.add(searchesSubcription);
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public ngOnDestroy() {
    // On se désinscrit du manager des critères
    this.subscriptions.unsubscribe();

    if (this.criteriaDisplaySubject) {
      this.criteriaDisplaySubject.complete();
    }

    if (this.windowMutationObserver) {
      this.windowMutationObserver.disconnect();
    }
  }

  private get getCriteria(): Array<RefinedCriterionViewModel> {
    const result = [];

    if (this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse?.advancedSearch?.criteria) {
      [...this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse.advancedSearch.criteria].forEach(c => {
        // On clone les critère depuis le state
        const clonedCriteria = _.cloneDeep(c);
        result.push(this.advancedSearchManager.defineCriterionLabel(clonedCriteria));
      });
      this.currentadvancedSearch = this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse.advancedSearch;
    }
    return result;
  }

  public saveAdvancedSearch() {
    const data = {
      advancedSearch: this.currentadvancedSearch
    };
    this.dialogsManager.openDialog({ dialog: Dialogs.SavedRequest, closeClickOutside: false, data });
  }

  /** Réinitialise la recherche avancée */
  public clearAdvancedSearch() {
    // Réinitialiser les candidats sélectionnés
    this.store.dispatch(resetSelectCandidates());
    this.store.dispatch(resetAdvancedSearchResponse());
    // on initialise les paramétres pour le scroll
    this.initialiseCriteriaMenuScroll();
    this.visitedIndex = -1;
    this.criteriaWidth = 0;
  }

  public trackByFunction(index: number, criterion: RefinedCriterionViewModel) {
    if (!criterion) { return null; }
    return criterion.value + criterion.category + index;
  }

  public scrollLeft() {
    this.visitedIndex -= 1;
    /* on scroll vers le premier critère */
    if (this.visitedIndex === -1) {
      this.accordion.closeAll();
      document.getElementById('scrollCriteria').style.right = `0px`;
    } else {
      this.scrollTo(this.visitedIndex)
    }
    this.showScrollButtons();
  }

  public scrollRight() {
    this.visitedIndex += 1;
    this.scrollTo(this.visitedIndex)
    this.showScrollButtons();
  }

  private scrollTo(index: number) {
    // si on a un critère ouvert, on le ferme lors du scroll
    this.accordion.closeAll();
    const elementToScrollTo = document.getElementById(`refined_criteria_${this.scrollIndex[index]}_criterion`)?.offsetLeft - this.scrollBtnWidth;
    document.getElementById('scrollCriteria').style.right = `${elementToScrollTo}px`;
  }

  private showScrollButtons() {
    if (document?.getElementById('scrollCriteria')) {
      if (this.criteriaWidth <= this.criteriaMenuWidth) {
        this.showRightScrollButton = false;
      } else
        // si on scroll vers le dernier index des points de scroll, on n'affiche pas le bouton de scroll droit
        if (this.scrollIndex.length > 0 && this.visitedIndex >= (this.scrollIndex.length - 1)) {
          this.showRightScrollButton = false;
        } else if (this.criteriaWidth > this.criteriaMenuWidth) {
          this.showRightScrollButton = true;
        }
      // si on n'a pas fait de scroll vers la droite ou on revient vers le premier critère, on n'affiche pas le bouton de scroll gauche
      if (this.visitedIndex === -1) {
        this.showLeftScrollButton = false;
      } else {
        this.showLeftScrollButton = true;
      }
    }
  }

  private displayCriteriaMenu() {
    // on calcule la largeur total des critères et les noeuds de scroll
    if (document.getElementById(`searchButtonsId`)) {
      this.criteriaMenuWidth = window.innerWidth - document.getElementById(`searchButtonsId`).offsetWidth;
      this.criteriaWidth = this.calculateCriteriaWidth();

      if (this.criteriaWidth > this.criteriaMenuWidth) {
        // on élimine width du bouton droit du scroll
        this.criteriaMenuWidth -= this.scrollBtnWidth;
        // on calcule les noeuds de scroll
        this.scrollIndex = this.calculateScrollNodes();
      } else {
        // on n'a pas des noeuds de scroll
        this.scrollIndex = [];
      }
      // gérer l'affichage des boutons
      this.showScrollButtons();
      const actionSubscription = this.actions.pipe(
        ofType(loadAdvancedSearch),
        first()
      )
      .subscribe(
        result => {
          // quand on re load une recherche et on a déjà supprimer/ajouter des critères, pour éviter le scroll
          this.scrollEnd = this.currentSearchId === result.payload;
        }
      );
      this.subscriptions.add(actionSubscription);
      if (!this.scrollEnd && this.currentSearchId === this.currentadvancedSearch.id && this.currentCriteriaLength !== this.criteriaArray.length) {
        // si on ajoute un critère
        if (this.criteriaArray.length > this.currentCriteriaLength && this.scrollIndex.length > 0) {
          this.addCriteria();
        } else if (this.criteriaArray.length < this.currentCriteriaLength ) {
          // si on suprimme un critère
          this.deleteCriteria();
        }
        this.showScrollButtons();
      }
      this.currentSearchId = this.currentadvancedSearch.id;
      this.currentCriteriaLength = this.criteriaArray.length;
      this.scrollEnd = false;
    }
  }

  private calculateCriteriaWidth(): number {
    // on élimine margin droite pour le dernier critère
    let totalWidth = -10;
    const criteriaElements = document.querySelectorAll('.refinedCriteriaArea ');
    criteriaElements.forEach((element: HTMLElement) => {
      totalWidth += element?.offsetWidth;
    });
    return totalWidth;
  }

  private calculateScrollNodes(): Array<number> {
    const scrollIndex = [];
    let node = 0;
    const criteriaElements = document.querySelectorAll('.refinedCriteriaArea ');
    this.criteriaMenuWidth -= this.scrollBtnWidth;
    criteriaElements.forEach((element: HTMLElement, index) => {
      node += element.offsetWidth;
      if (node > this.criteriaMenuWidth) {
        scrollIndex.push(index);
        node = element.offsetWidth;
      }
    });
    return scrollIndex;
  }

  private addCriteria() {
    this.visitedIndex = this.scrollIndex.length - 1;
    this.scrollTo(this.visitedIndex);
  }

  private deleteCriteria() {
    if (this.scrollIndex.length === 0) {
      this.visitedIndex = -1;
      this.initialiseCriteriaMenuScroll();
    } else if (this.visitedIndex === this.scrollIndex.length) {
      this.visitedIndex = this.scrollIndex.length - 1;
      this.scrollTo(this.visitedIndex);
    }
  }

  private initialiseCriteriaMenuScroll() {
    const criteriaMenu = document.getElementById('scrollCriteria');
    if (criteriaMenu) {
      criteriaMenu.style.right = `0px`;
    }
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CandidateSearchParameterModel } from 'app/shared/models/parameter/candidate-search-parameter.model';
import { ApplicativeAreaCode, ApplicativeAreaParameterService, CustomWebSearchService, IndividualModel, PermissionService } from 'common-services';
import { ApplicativeAreaParameterModel } from 'common-services/lib/models/applicative-area/applicative-area-parameter.model';
import { ParameterPropertyDescriptorCandidate } from '../enums/parameter-property-descriptors.enum';
import { CandidateParameterModel } from '../models/parameter/candidate-parameter.model';
import { CandidatureRefusalParameterModel } from '../models/parameter/candidature-refusal-parameter.model';

@Injectable()
export class ParameterService {

  private readonly _applicativeAreas: Array<ApplicativeAreaParameterModel> = [];

  public activeApplicativeAreaCodes: Array<ApplicativeAreaCode> = [];
  public candidateSearchParameter = new CandidateSearchParameterModel();
  public candidatureRefusalParameter = new CandidatureRefusalParameterModel();
  public candidateParameter = new CandidateParameterModel();

  constructor(
    private readonly applicativeAreaParameterService: ApplicativeAreaParameterService,
    private readonly permissionService: PermissionService,
    private readonly customWebSearchService: CustomWebSearchService,
    private readonly translateService: TranslateService
  ) { }

  public getApplicativeArea(code: ApplicativeAreaCode): ApplicativeAreaParameterModel {
    return this._applicativeAreas[code];
  }

  public getCandidateParameter(): Promise<void> {
    return this.applicativeAreaParameterService.getApplicativeAreaParametersByApplicativeAreaCode(ApplicativeAreaCode.Candidate).then(
      applicativeArea => {
        const parameterPropertyDescriptors = applicativeArea.parameterPropertyDescriptors;

        Object.keys(ParameterPropertyDescriptorCandidate).forEach(param => {
          const descriptor = parameterPropertyDescriptors.find(
            element => element.name.toLocaleLowerCase() === ParameterPropertyDescriptorCandidate[param].toLowerCase()
          );
          if (descriptor) {
            this.candidateParameter[param] = descriptor.value === 'true';
          }
        });
      }
    );
  }

  public async getCandidateSearchParameter(): Promise<void> {
    await this.customWebSearchService.getCustomWebSearches().then(
      socialNetworks => {
        this.candidateSearchParameter.customWebSearches = socialNetworks;
      }
    );

    await this.applicativeAreaParameterService.getApplicativeAreaParametersByApplicativeAreaCode(ApplicativeAreaCode.CandidateSearch).then(
      applicativeArea => {
        const descriptor = applicativeArea.parameterPropertyDescriptors.find(
          element => element.name.toLocaleLowerCase() === ParameterPropertyDescriptorCandidate.hiddenCriteria.toLowerCase()
        );

        if (descriptor) {
          this.candidateSearchParameter.hiddenCriteria = JSON.parse(descriptor.value);
        }
      }
    );

    await this.applicativeAreaParameterService.getApplicativeAreaParametersByApplicativeAreaCode(ApplicativeAreaCode.MapsServices).then(
      param => {
        this._applicativeAreas[ApplicativeAreaCode.MapsServices] = param;
      }
    );
  }

  public async getActiveApplicativeAreaCodes(): Promise<void> {
    const individual: IndividualModel = JSON.parse(localStorage.getItem('individual'));
    await this.permissionService.getLicensesByIndividualId(individual.id).then(
      data => {
        data.forEach(element => {
          this.activeApplicativeAreaCodes.push(element.applicativeAreaCode);
        });
      }
    );
  }

  public async getCandidatureRefusalParameter(): Promise<void> {
    await this.applicativeAreaParameterService.getApplicativeAreaParametersByApplicativeAreaCode(ApplicativeAreaCode.CandidatureRefusal).then(
      applicativeArea => {
        const descriptor = applicativeArea.parameterPropertyDescriptors.find(
          element => element.name.toLocaleLowerCase() === ParameterPropertyDescriptorCandidate.secondPhaseActive.toLowerCase()
        );

        if (descriptor) {
          this.candidatureRefusalParameter.secondPhaseActive = JSON.parse(descriptor.value);
        }
      }
    );
  }

  public async getParameters() {
    // Initialiser les paramètres
    await Promise.all([
      this.getCandidateParameter(),
      this.getCandidateSearchParameter(),
      this.getCandidatureRefusalParameter(),
      this.getActiveApplicativeAreaCodes()
    ]);
  }

  public initParams(): void {
    // Changer la langue de l'application
    const individual: IndividualModel = JSON.parse(localStorage.getItem('individual'));
    this.initTranslation(individual.languageBU.code);
  }

  public initTranslation(language: string) {
    // Initialisation de la langue d'utilisateur actuelle
    const codeLanguage = language.split('-')[0] ?? 'fr';
    this.translateService.setDefaultLang(codeLanguage);
    this.translateService.use(codeLanguage);
    return Promise.resolve();
  }
}

import { FileLoader } from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import { UploadAdapter } from '@ckeditor/ckeditor5-upload/src/filerepository';
import { TemporaryFileModel, TemporaryFilesService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';

export class CkEditorUploadAdapter implements UploadAdapter {
  private fileModel: TemporaryFileModel | null;

  constructor(
    private readonly loader: FileLoader,
    private readonly snackbarService: CustomSnackBarService,
    private readonly errorMessage: string,
    private readonly temporaryFilesService: TemporaryFilesService
  ) { }

  public uploadedFile(): TemporaryFileModel | null {
    return this.fileModel;
  }

  /**
   * Envoie une requête vers le serveur afin d'uploader l'image
   */
  public upload() {
    return new Promise((resolve, reject) => {
      return this.sendRequest(resolve, reject);
    });
  }

  abort() {
    this.snackbarService.showDanger(this.errorMessage);
    this.fileModel = null;
  }

  /**
   *
   * @param resolve Fonction callBack si la requête est résolue
   * @param reject Fonction callBack si la requête est rejetée
   */
  private sendRequest(resolve, reject) {
    return this.loader.file
      .then(file => new Promise(() => {
        const uploader = this.temporaryFilesService.upload([file]);
        uploader.onSuccessItem = (item, response) => {
          this.fileModel = JSON.parse(response)[0];
          // On envoie un évènement (response)
          this.loader.fire('uploadFile');
          resolve(true);
        };
        // S'il y a des erreurs lors de l'upload de fichier
        uploader.onErrorItem = () => {
          this.abort();
          reject(false);
        };
      }));
  }
}

<div class="form-group blocCriteria">
  <app-select [identifier]="'criteria_group_region_country_select'"
              class="form-control-select-custom"
              [options]="countries"
              (selectedOptionsChanged)="onCountryChange($event)"
              [emptyOption]="emptyOptionLabel"
              [isEmptyOptionHasValue]="true"
              [selectedOption]="defaultCountryCode"
              [label]="countryLabel">
  </app-select>
</div>
<div class="form-group form-control-select-custom blocCriteria">
  <app-select [identifier]="'criteria_group_region_region_select'"
              [options]="regions"
              (selectedOptionsChanged)="onRegionChange($event)"
              [emptyOption]="emptyOptionLabel"
              [isEmptyOptionHasValue]="true"
              [resetAtDefault]="true"
              [label]="regionLabel">
  </app-select>
</div>
<app-criteria-list [criterionCategory]="criterionCategory.Region"></app-criteria-list>

import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationComponent } from 'app/generic-components/pagination/pagination.component';
import { AttachmentPopupComponent } from 'app/pages/advanced-search/search-results/attachment-popup/attachment-popup.component';
import { SHOW_DOCUMENT_PREVIEW_TIMEOUT } from 'app/shared/constants/time.constants';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { PRCandidateModel } from 'app/shared/models/candidate/candidate.model';
import { ParameterService } from 'app/shared/services/parameter.service';
import {
  ApplicativeAreaCode,
  AttachmentFileModel,
  CandidatureAttachmentModel,
  CandidatureModel,
  CandidatureService,
  DataDepthLevel,
  Opinion,
  PagedItems,
  QueryableModel,
  SortOrder,
  StringHelper
} from 'common-services';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-candidatures',
  templateUrl: './candidate-candidatures.component.html',
  styleUrls: ['./candidate-candidatures.component.scss']
})
export class CandidateCandidaturesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() candidateObservable: Observable<PRCandidateModel>;
  @ViewChild('pagination') paginator: PaginationComponent;
  public paginationLength = 0;
  public paginationSize = 5;
  private readonly searchControl: FormControl;
  private readonly subscriptions: Subscription;
  private readonly candidatureColumns: Array<string>;
  private candidaturesDataSource: MatTableDataSource<CandidatureModel>;
  private setTimeOutConst: number;
  private candidateId: number;
  public isFetching = true;

  public get columns(): Array<string> {
    return this.candidatureColumns;
  }

  public get searchFormControl(): FormControl {
    return this.searchControl;
  }

  public get candidaturesSource(): MatTableDataSource<CandidatureModel> {
    return this.candidaturesDataSource;
  }

  constructor(
    private readonly datePipe: DatePipe,
    private readonly parameterService: ParameterService,
    private readonly candidatureService: CandidatureService,
    private readonly stringHelper: StringHelper,
    private readonly dialogsManager: DialogsManager
  ) {
    const hideLikeColumn = !this.parameterService.activeApplicativeAreaCodes.some(ar => ar === ApplicativeAreaCode.ManagerPortal);
    const hideSecondPhaseColumn = !this.parameterService.activeApplicativeAreaCodes
      .some(ar => ar === ApplicativeAreaCode.CandidatureRefusal) ||
      !this.parameterService.candidatureRefusalParameter.secondPhaseActive;
    this.candidatureColumns = [
      { key: 'reject', value: false },
      { key: 'pending', value: false },
      { key: 'accept', value: false },
      { key: 'deny', value: hideSecondPhaseColumn },
      { key: 'like', value: hideLikeColumn },
      { key: 'email', value: false },
      { key: 'attachment1', value: false },
      { key: 'attachment2', value: false },
      { key: 'attachment3', value: false },
      { key: 'date', value: false },
      { key: 'position', value: false },
      { key: 'client', value: false },
      { key: 'origin', value: false },
      { key: 'delete', value: false }
    ].filter(column => !column.value)
      .map(column => column.key);

    this.subscriptions = new Subscription();
    this.searchControl = new FormControl();
  }

  ngAfterViewInit() {
    const candidateObservableSubscription = this.candidateObservable.subscribe(
      candidate => {
        this.candidateId = candidate.id;
        this.resetPageIndexAndFilterCandidatures();
      }
    );

    this.subscriptions.add(candidateObservableSubscription);

    const paginatorSubscription = this.paginator.paginator.page.pipe(
      debounceTime(100),
    ).subscribe(this.getFilteredCandidatures.bind(this));

    this.subscriptions.add(paginatorSubscription);
  }

  ngOnInit() {
    this.setMatTableConfiguration();
    const searchControlSubscription = this.searchControl.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged()
      ).subscribe(this.resetPageIndexAndFilterCandidatures.bind(this));

    this.subscriptions.add(searchControlSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  private resetPageIndexAndFilterCandidatures() {
    if (this.paginator.paginator) {
      this.paginator.paginator.pageIndex = 0;
      this.getFilteredCandidatures();
    }
  }

  private getFilteredCandidatures() {
    const query: QueryableModel = {
      whereExpression: `c => c.candidateId == ${this.candidateId}`,
      parameters: [],
      dataDepthLevel: DataDepthLevel.WithSubObjects,
      sortOrder: SortOrder.Descending.toString(),
      sortExpression: 'candidatureDate',
      page: this.paginator.paginator.pageIndex,
      pageSize: this.paginator.paginator.pageSize
    };

    // Filtrer les candidatures par ( position, client ou origine )
    if (this.searchControl.value) {
      query.whereExpression += ` && ( c.position.title.contains("${this.searchControl.value}") ||
                                c.position.customer.label.contains("${this.searchControl.value}") ||
                                c.origin.label.contains("${this.searchControl.value}") )`;
    }

    this.candidatureService.findByQueryable(query).then(
      (pagedItems: PagedItems<CandidatureModel>) => {
        if (this.paginator.paginator) {
          this.paginationLength = pagedItems.rowCount;
          this.paginationSize = pagedItems.pageSize;
        }

        if (!pagedItems.items) {
          this.paginationLength = 0;
          this.paginationSize = 5;
          this.candidaturesDataSource.data = [];
          return;
        }

        const candidaturesMap = new Map<string, Array<CandidatureModel>>();
        // Regrouper les candidatures qui ont la mm candidatureDate
        pagedItems.items.forEach(
          candidature => {
            const candidatureDate = new Date(candidature.candidatureDate).toISOString().split('T')[0];
            candidaturesMap.has(candidatureDate) ? candidaturesMap.get(candidatureDate).push(candidature)
              : candidaturesMap.set(candidatureDate, [candidature]);
          }
        );

        // Trier les candidatures groupé par candidatureDate par id Descending et affecter le tous dans un seul tableau
        let groupedSortedCandidatures: Array<CandidatureModel> = [];
        candidaturesMap.forEach((value: Array<CandidatureModel>, key: string) => {
          value.sort((a, b) => a.id < b.id ? 1 : -1);
          groupedSortedCandidatures = groupedSortedCandidatures.concat(value);
        });

        this.candidaturesDataSource.data = this.fetchCandidatureAttachments(groupedSortedCandidatures);
        this.isFetching = false;
      }
    );
  }

  // Récupère les attachmentsModel complets avec le preview et remplissage de la liste des attachments dans la candidature
  private fetchCandidatureAttachments(candidatures: CandidatureModel[]): CandidatureModel[] {
    candidatures.forEach(candidature => {
      candidature.candidatureAttachments = [];
      this.candidatureService.getCandidatureAttachmentsByCandidatureId(candidature.id).then(candidatureAttachments => {
        candidatureAttachments.forEach(ca => {
          this.candidatureService.getCandidatureAttachmentById(ca.candidatureId, ca.attachmentId).then(attachment => {
            candidature.candidatureAttachments.push({
              candidatureId: ca.candidatureId,
              attachmentId: ca.attachmentId,
              attachment
            } as CandidatureAttachmentModel);
          });
        });
      });
    });

    return candidatures;
  }

  private setMatTableConfiguration() {
    this.candidaturesDataSource = new MatTableDataSource<CandidatureModel>();
    this.candidaturesDataSource.filterPredicate = this.filterPredicate.bind(this);
  }

  public getIsButtonActive(candidature: CandidatureModel, column: string): boolean {
    switch (column) {
      case 'reject':
        return candidature.opinion === Opinion.Reject;
      case 'pending':
        return candidature.opinion === Opinion.Pending;
      case 'accept':
        return candidature.opinion === Opinion.Accept;
      case 'deny':
        return candidature.refusal2;
      case 'email':
        return !!candidature.emailBody;
      case 'like':
        return false;
      // TODO: not sure if replaced with receivedFrom
      /*return candidature.transferedToPortalManager;*/
    }
    return false;
  }

  /** Retourne true si la chaine fait partie d'un des champs date, PJ, origine, client ou poste. Ignore la casse et les diacritiques. **/
  private filterPredicate(candidature: CandidatureModel, filter: string): boolean {
    return [
      this.datePipe.transform(candidature.candidatureDate, 'short'),
      candidature.candidatureAttachments[0]?.attachment.fileName,
      candidature.candidatureAttachments[1]?.attachment.fileName,
      candidature.candidatureAttachments[2]?.attachment.fileName,
      candidature.origin?.label,
      candidature.position?.customer?.label,
      candidature.position?.title
    ].some(field =>
      this.stringHelper.removeDiacritics(field?.toLowerCase())?.indexOf(filter) >= 0
    );
  }

  public openEmailPreview(attachment: string) {
    const hoveredAttachment = { preview: attachment } as AttachmentFileModel;
    if (this.dialogsManager.isDialogOpen(Dialogs.Attachment)) {
      this.dialogsManager.openDialog<AttachmentPopupComponent>(
        { dialog: Dialogs.Attachment, closeClickOutside: true, data: this.candidateId }).currentAttachmentFile = hoveredAttachment;
    } else {
      this.setTimeOutConst = window.setTimeout(() => {
        this.dialogsManager.openDialog<AttachmentPopupComponent>(
          { dialog: Dialogs.Attachment, closeClickOutside: true, data: this.candidateId }).currentAttachmentFile = hoveredAttachment;
      }, SHOW_DOCUMENT_PREVIEW_TIMEOUT);
    }
  }

  public mouseLeave() {
    clearTimeout(this.setTimeOutConst);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionFollowUpModel, ActionFollowUpService, FileHelper, IndividualModel, OrganizationService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';

@Component({
  selector: 'app-candidate-action',
  templateUrl: './candidate-action.component.html',
  styleUrls: ['./candidate-action.component.scss']
})
export class CandidateActionComponent implements OnInit {
  @Input() action: ActionFollowUpModel;

  private errorMessage: string;

  public attachmentFileId: number;
  public responsible: IndividualModel;
  public trigram = '';

  constructor(
    private readonly translateService: TranslateService,
    private readonly actionFollowUpService: ActionFollowUpService,
    private readonly fileHelper: FileHelper,
    private readonly snackbarService: CustomSnackBarService,
    private readonly organizationService: OrganizationService
  ) { }

  public ngOnInit() {
    this.action.comments = this.action.comments ?? '<p></p>';
    
    this.translateService.get(['errorWhenDownloadFile']).subscribe(translations => {
      this.errorMessage = translations.errorWhenDownloadFile;
    });

    this.actionFollowUpService.getActionFollowUpAttachments(this.action.id)
      .then(
        attchementsList => {
          if (attchementsList.length > 0) {
            this.attachmentFileId = attchementsList[0].id;
          }
        }
      );
    this.responsible = this.organizationService.individualList.find(resp => resp.id === this.action.individualId);

    this.getActionResponsibleTrigram();
  }

  get borderColor(): string {
    return this.action.actionType?.color ?? 'transparent';
  }

  /** Obtient les 3 premières lettre (trigramme) du responsable du suivi d'action  */
  private getActionResponsibleTrigram() {    
    if (this.action?.individualTrigram) {
      this.trigram = this.action.individualTrigram;
    } else if (this.responsible?.trigram) {
      this.trigram = this.responsible.trigram;
    } else if (this.responsible?.firstName || this.responsible?.lastName) {
      this.trigram = `${this.responsible?.firstName.substring(0, 1)}${this.responsible?.lastName.substring(0, 2)}`;
    }
  }

  /** Téléchargement de la pièce jointe du suivi d'action */
  public downloadAttachment() {
    this.actionFollowUpService.getActionFollowUpAttachmentsById(this.action.id, this.attachmentFileId)
      .then(
        file => {
          if (!this.fileHelper.downloadFile(file)) {
            this.snackbarService.showDanger(this.errorMessage);
          }
        })
      .catch(
        () => this.snackbarService.showDanger(this.errorMessage)
      );
  }
}

import { Injectable } from '@angular/core';
import { CriterionCategory } from 'common-services';

@Injectable()
export class UtilHelper {

  // fonction utilisable pur daclancher la fonction callback après la création d'une propriété dans un objet
  public async objectHasProperty(object: Object, property: string, callback: () => void) {
    await Object.defineProperty(object, property, {
      configurable: true,
      set: async (v) => {
        await Object.defineProperty(object, property, {
          configurable: true, enumerable: true, writable: true, value: v
        });

        callback();
      }
    });
  }

  public ignoredCategoryForCse() {
    return [
      CriterionCategory.ActionsFollowUp,
      CriterionCategory.LastActionFollowUp,
      CriterionCategory.Chip,
      CriterionCategory.TagCloudsOthers,
      CriterionCategory.LastActionUser,
      CriterionCategory.ActionUser
    ];
  }

  /**
   * Nettoie une chaîne de caractères des espaces inutiles
   * @param str La chaîne de caractères à nettoyer des espaces
   */
  public removeUnusedSpaces(str: string): string {
    return str.replace(/\s\s+/g, ' ').trim();
  }
}

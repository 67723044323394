import { createAction, props } from '@ngrx/store';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchRequestViewModel } from 'app/shared/models/advanced-search/advanced-search-request.model';
import { AdvancedSearchResponseViewModel } from 'app/shared/models/advanced-search/advanced-search-response.model';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { AutoCompleteModel } from 'app/shared/models/advanced-search/auto-complete.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { PositionPayload } from 'app/shared/models/state/position-payload.model';
import { AdvancedSearchResponseModel, CustomSynonymsModel } from 'common-services';

export enum AdvancedSearchResponseActionsTypes {
    SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI = '[AdvancedSearchResponseViewModel] Search Candidates By PositionUri Or PositionId And Language',
    SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI_SUCCESS = '[AdvancedSearchResponseViewModel] Search Candidates By PositionUri Or PositionId And Language Success',
    SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI_ERROR = '[AdvancedSearchResponseViewModel] Search Candidates By PositionUri Or PositionId And Language Error',

    GET_ADVANCED_SEARCH_RESPONSE = '[AdvancedSearchResponseViewModel] Get Advanced Searches',
    GET_ADVANCED_SEARCH_RESPONSE_SUCCESS = '[AdvancedSearchResponseViewModel] Get Advanced Searches Success',
    GET_ADVANCED_SEARCH_RESPONSE_ERROR = '[AdvancedSearchResponseViewModel] Get Advanced Searches Error',

    UPDATE_ADVANCED_SEARCH_RESPONSE = '[AdvancedSearchResponseViewModel] Update Advanced Searches',
    UPDATE_ADVANCED_SEARCH_RESPONSE_ERROR = '[AdvancedSearchResponseViewModel] Update Advanced Searches Error',
    
    UPDATE_ADVANCED_SEARCH_RESPONSE_STATE_STATUS = '[AdvancedSearchResponseViewModel] Update Advanced Searches State Status',

    UPDATE_CURRENT_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Update Current Advanced Search',

    LOAD_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Load Advanced Search',
    LOAD_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Load Advanced Search Success',
    LOAD_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Load Advanced Search Error',

    RESET_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Clear Advanced Search',

    UPDATE_CRITERION = '[Criterion] Update Criterion',
    DELETE_CRITERION = '[Criterion] Delete Criterion',
    ADD_REFINED_CRITERION = '[RefinedCriterionViewModel] Add Criterion',
    ADD_REFINED_CRITERION_SUCCESS = '[RefinedCriterionViewModel] Add Criterion Success',
    ADD_REFINED_CRITERION_ERROR = '[RefinedCriterionViewModel] Add Criterion Error',

    SELECT_CANDIDATE = '[AdvancedSearchViewModel] Select Candidate',
    SELECT_CANDIDATES = '[AdvancedSearchViewModel] Select Candidates',
    UN_SELECT_CANDIDATES = '[AdvancedSearchViewModel] UnSelect Candidates',
    RESET_SELECT_CANDIDATES = '[AdvancedSearchViewModel] Reset Candidates',

    DEFINE_KEYWORD_SYNONYMS = '[boolean] Define Keyword Synonyms',
    DEFINE_KEYWORD_SYNONYMS_SUCCESS = '[boolean] Define Keyword Synonyms Success',
    DEFINE_KEYWORD_SYNONYMS_ERROR = '[boolean] Define Keyword Synonyms Error',

    SEARCH_COMPLETIONS = '[AutoCompleteModel] Search Completions',
    SEARCH_COMPLETIONS_SUCCESS = '[AutoCompleteModel] Search Completions Success'
}

// Lancement de la récupération de AdvancedSearchResponseViewModel
export const getAdvancedSearchResponse = createAction(
    AdvancedSearchResponseActionsTypes.GET_ADVANCED_SEARCH_RESPONSE,
    props<{ payload: AdvancedSearchRequestViewModel }>()
);
// Récupération de AdvancedSearchResponseViewModel avec succées
export const getAdvancedSearchResponseSuccess = createAction(
    AdvancedSearchResponseActionsTypes.GET_ADVANCED_SEARCH_RESPONSE_SUCCESS,
    props<{ payload: AdvancedSearchResponseViewModel }>()
);
// Erreur lors de la récupération de l'AdvancedSearchResponseViewModel
export const getAdvancedSearchResponseError = createAction(
    AdvancedSearchResponseActionsTypes.GET_ADVANCED_SEARCH_RESPONSE_ERROR
);
// Modification de l'AdvancedSearchResponseViewModel
export const updateAdvancedSearchResponse = createAction(
    AdvancedSearchResponseActionsTypes.UPDATE_ADVANCED_SEARCH_RESPONSE,
    props<{ payload: AdvancedSearchResponseViewModel }>()
);
// Charge la recherche
export const loadAdvancedSearch = createAction(
    AdvancedSearchResponseActionsTypes.LOAD_ADVANCED_SEARCH,
    props<{ payload: number }>()
);
// Charge la recherche avec succès
export const loadAdvancedSearchSuccess = createAction(
    AdvancedSearchResponseActionsTypes.LOAD_ADVANCED_SEARCH_SUCCESS,
    props<{ payload: AdvancedSearchResponseModel }>()
);
// Erreur lors du chargement de la recherche
export const loadAdvancedSearchError = createAction(
    AdvancedSearchResponseActionsTypes.LOAD_ADVANCED_SEARCH_ERROR
);
// Modification de la recherche actuelle
export const updateCurrentAdvancedSearch = createAction(
    AdvancedSearchResponseActionsTypes.UPDATE_CURRENT_ADVANCED_SEARCH,
    props<{ payload: AdvancedSearchViewModel }>()
);
// Supprimer un critère de recherche
export const deleteCriterion = createAction(
    AdvancedSearchResponseActionsTypes.DELETE_CRITERION,
    props<{ payload: RefinedCriterionViewModel }>()
);
// Modifier un critère de recherche
export const updateCriterion = createAction(
    AdvancedSearchResponseActionsTypes.UPDATE_CRITERION,
    props<{ payload: RefinedCriterionViewModel }>()
);
// Ajouter d'un critère raffiné
export const addRefinedCriterion = createAction(
    AdvancedSearchResponseActionsTypes.ADD_REFINED_CRITERION,
    props<{ payload: RefinedCriterionViewModel }>()
);
// Ajouter d'un critère raffiné avec succés
export const addRefinedCriterionSuccess = createAction(
    AdvancedSearchResponseActionsTypes.ADD_REFINED_CRITERION_SUCCESS,
    props<{ payload: RefinedCriterionViewModel }>()
);
// Erreur d'ajouter d'un critère raffiné
export const addRefinedCriterionError = createAction(
    AdvancedSearchResponseActionsTypes.ADD_REFINED_CRITERION_ERROR
);
// Cherche les candidats correspondants par position id
export const searchCandidatesByPositionIdOrUri = createAction(
    AdvancedSearchResponseActionsTypes.SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI,
    props<{ payload: PositionPayload }>()
);
// Cherche les candidats correspondants par position id
export const searchCandidatesByPositionIdOrUriSuccess = createAction(
    AdvancedSearchResponseActionsTypes.SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI_SUCCESS,
    props<{ payload: AdvancedSearchResponseModel }>()
);
// Erreur lors de la recherche des candidats correspondants par position id
export const searchCandidatesByPositionIdOrUriError = createAction(
    AdvancedSearchResponseActionsTypes.SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI_ERROR
);
// Sélectionner un candidat
export const selectCandidate = createAction(
    AdvancedSearchResponseActionsTypes.SELECT_CANDIDATE,
    props<{ payload: number }>()
);
// Sélectionner plusieurs candidats
export const selectCandidates = createAction(
    AdvancedSearchResponseActionsTypes.SELECT_CANDIDATES,
    props<{ payload: Array<number> }>()
);
// Désélectionner plusieurs candidats
export const unSelectCandidates = createAction(
    AdvancedSearchResponseActionsTypes.UN_SELECT_CANDIDATES,
    props<{ payload: Array<number> }>()
);
// Réinitialiser les candidats sélectionnés
export const resetSelectCandidates = createAction(
    AdvancedSearchResponseActionsTypes.RESET_SELECT_CANDIDATES
);
// Réinitialise la recherche avancée
export const resetAdvancedSearchResponse = createAction(
    AdvancedSearchResponseActionsTypes.RESET_ADVANCED_SEARCH_ERROR
);
// Définir les synonymes d'un critère
export const defineKeywordSynonyms = createAction(
    AdvancedSearchResponseActionsTypes.DEFINE_KEYWORD_SYNONYMS,
    props<{ payload: CustomSynonymsModel }>()
);
// Définir les synonymes d'un critère avec succées
export const defineKeywordSynonymsSuccess = createAction(
    AdvancedSearchResponseActionsTypes.DEFINE_KEYWORD_SYNONYMS_SUCCESS,
    props<{ payload: boolean }>()
);
// Erreur lors de la définition des synonymes pour un critère
export const defineKeywordSynonymsError = createAction(
    AdvancedSearchResponseActionsTypes.DEFINE_KEYWORD_SYNONYMS_ERROR,
    props<{ payload: boolean }>()
);
// Lancer une recherche pour l'autocomplete 
export const searchCompletions = createAction(
    AdvancedSearchResponseActionsTypes.SEARCH_COMPLETIONS,
    props<{ payload: AutoCompleteModel }>()
);
// Recherche pour l'autocomplete avec succées
export const searchCompletionsSuccess = createAction(
    AdvancedSearchResponseActionsTypes.SEARCH_COMPLETIONS_SUCCESS,
    props<{ payload: Array<AutoCompleteModel> }>()
);
// Modifier le statut du state
export const updateStateStatus = createAction(
    AdvancedSearchResponseActionsTypes.UPDATE_ADVANCED_SEARCH_RESPONSE_STATE_STATUS,
    props<{ payload: StateStatus }>()
);

<div class="actionComponent">
  <mat-tab-group mat-align-tabs="end" [ngStyle]="{'border-color' : borderColor }" [selectedIndex]="1" class="mb-4">
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <div class="actionTitle text-left">
          <div class="headTitle">
            <span *ngIf="action.position?.title; else elseBlock">
              {{ action.position.title }}
            </span>
            <ng-template #elseBlock>
              {{'noPosition' | translate}}
            </ng-template>
            <span *ngIf="action.position?.reference; else elseBlock2">
              - {{ action.position?.reference }}
            </span>
            <ng-template #elseBlock2>
              - {{'noReference' | translate}}
            </ng-template>

            <span *ngIf="action.actionType?.label; else elseBlock3">
              - {{ action.actionType.label }}
            </span>
            <ng-template #elseBlock3>
              - {{'noActionType' | translate}}
            </ng-template>
              - {{ action.actionDate | toLocalDate }}
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'comment' | translate }}">
      <div class="actionComment" [innerHtml]="action.comments | htmlSecurityByPasser">
      </div>
    </mat-tab>
    <mat-tab *ngIf="attachmentFileId">
      <ng-template mat-tab-label>
        <button (click)="downloadAttachment()" mat-icon-button class="actionButton actionPj" 
          matTooltip="{{ 'attachment' | translate }}">
          <mat-icon>attach_file</mat-icon>
        </button>
      </ng-template>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label  >
        <span class="resp" matTooltip="{{ responsible?.firstName }} {{ responsible?.lastName }}">{{ trigram }}</span>
      </ng-template>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <button mat-icon-button class="actionButton" matTooltip="{{ 'delete' | translate }}">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

import { Component, Input } from '@angular/core';
import { ChipModel } from 'common-services';

@Component({
  selector: 'app-chip-card',
  templateUrl: './chip-card.component.html',
  styleUrls: ['./chip-card.component.scss']
})
export class ChipCardComponent {
  @Input() chip: ChipModel;
}

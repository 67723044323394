<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 criteria-menu-padding">
        <app-refined-criteria-menu></app-refined-criteria-menu>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 content bg-white band_left">
        <div class="row">
          <mat-tab-group mat-align-tabs="center" class="w-100" id="criteriaMenuId" [ngClass]="{'has-criteria':hasCriteria}">
            <mat-tab label="{{ 'myCriteria' | translate  }}">
              <div class="scroll-wrapper">
                <div class="scroll">
                  <app-criteria-menu></app-criteria-menu>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'mySearches' | translate  }}">
              <ng-template matTabContent>
                <div class="scroll-wrapper">
                  <div class="scroll">
                    <app-saved-requests-list></app-saved-requests-list>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-sm-6 col-md-8 col-lg-8 col-xl-9 band_right">
        <div class="col-12">
          <div class="customSearchEngineGroup">
            <mat-tab-group *ngIf="hasCseCriteria; else elseBlock" mat-align-tabs="start" class="w-100" (selectedTabChange)="loadNetwork($event)">
              <ng-container>
                <mat-tab label="{{ 'Eolia' }}">
                  <app-candidate-results></app-candidate-results>
                </mat-tab>
                <mat-tab *ngFor="let customWebSearch of customWebSearches; index as i; trackBy: trackByFunction" label="{{customWebSearch.key.label}}">
                  <app-custom-web-search
                  [refreshObservable]="customWebSearch.value"
                  [customWebSearch]="customWebSearch.key">
                  </app-custom-web-search>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
            <ng-template  #elseBlock>
              <app-candidate-results></app-candidate-results>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExpandableTableComponent } from 'app/generic-components/expandable-table/expandable-table.component';
import { CandidateFormationModel, CandidateModel } from 'common-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidate-formations',
  templateUrl: './candidate-formations.component.html'
})
export class CandidateFormationsComponent implements OnInit {
  @Input() candidate: Observable<CandidateModel>;
  @ViewChild('expandableTable', { static: true }) expandableTable: ExpandableTableComponent<CandidateFormationModel>;

  ngOnInit() {
    this.expandableTable.expandableColumns = new Set(['description']);
    this.expandableTable.columns = { endDate: 'date', degreeName: 'diploma', school: 'institution' };
    this.expandableTable.dateColumnFilter = 'yyyy';

    this.candidate.subscribe(
      candidate => {
        this.expandableTable.dataSource = candidate.formations;
      }
    );
  }
}

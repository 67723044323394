import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExpandableTableComponent<T> implements OnInit {

  @Input() dataSource: Array<T>;
  @Input() columns: { [K in keyof T]?: string }; // Représente les clés du model. Leurs valeurs sont ce qui est affiché dans les <TH> du tableau.
  @Input() expandableColumns: Set<keyof T>; // La liste des clés dans la zone extensible.
  @Input() dateColumnFilter = 'short';

  private columnsKeys: Array<string>;

  ngOnInit() {
    this.columnsKeys = Object.keys(this.columns);
  }

  public get columnsToDisplay() {
    return this.columnsKeys;
  }

  isExpandable(element): boolean {
    return [...this.expandableColumns].some(column => element[column]);
  }

  isDate(str: any): boolean {
    return (Date.parse(str) > 0);
  }
}

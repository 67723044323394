export enum StateStatus {
  INITIAL = 'Initial',
  LOADING = 'Loading',
  LOADED = 'Loaded',
  LOADEDBYMATCHING = 'LoadedByMatching',
  LOADINGCRITERION = 'LoadingCriterion',
  ERRORLOADINGCRITERION = 'ErrorLoadingCriterion',
  ERRORLOADING = 'ErrorLoading',
  RELOAD = 'Reload',
  ADDING = 'Adding',
  ADDED = 'Added',
  ERRORADDING = 'ErrorAdding',
  UPDATING = 'Updating',
  UPDATED = 'Updated',
  ERRORUPDATING = 'ErrorUpdating',
  CLONING = 'Cloning',
  CLONED = 'Cloned',
  ERRORCLONIG = 'ErrorCloning',
  DELETING = 'Deleting',
  DELETED = 'Deleted',
  ERRORDELETING = 'ErrorDeleting'
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getAdvancedSearchResponse } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import {
  ActionFollowUpTypeModel,
  AdvancedSearchSorting,
  CandidateModel,
  ChipModel,
  CriterionCategory,
  CriterionPriority,
  ExperienceLevelModel,
  LanguageModel,
  OrganizationService,
  RegionModel,
  SortOrder,
  StudyLevelModel
} from 'common-services';
import * as _ from 'lodash';
import { StateStatus } from '../enums/state-status.enum';
import { IndividualManager } from '../managers/individual.manager';
import { AdvancedSearchRequestViewModel } from '../models/advanced-search/advanced-search-request.model';
import { AdvancedSearchViewModel } from '../models/advanced-search/advanced-search.model';
import { MatchResultModel } from '../models/advanced-search/match-result.model';
import { RefinedCriterionViewModel } from '../models/advanced-search/refined-criterion.model';
import { AdvancedSearchResponseState } from '../models/state/advanced-search-response-state.model';
import { AdvancedSearchState } from '../models/state/advanced-search-state.model';
import { ParameterService } from '../services/parameter.service';

@Injectable()
export class AdvancedSearchManager {
  private _advancedSearchResponseState: AdvancedSearchResponseState;
  private _advancedSearchState: AdvancedSearchState;
  private _candidatesMatches: MatchResultModel[];
  /** Code d'indexation de la pastille "Aucune pastille". Doit être identique à celui côté Eolia.Services */
  public readonly noChipIndexingCode = 'NoChip';
  private readonly _userGuid: string;
  private errorValue: string;

  public get candidatesMatches(): MatchResultModel[] {
    return this._candidatesMatches ?? [];
  }

  public get advancedSearchResponseState(): AdvancedSearchResponseState {
    return this._advancedSearchResponseState;
  }

  public get advancedSearchState(): AdvancedSearchState {
    return this._advancedSearchState;
  }

  public get userGuid(): string {
    return this._userGuid;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly individualManager: IndividualManager,
    private readonly organizationService: OrganizationService,
    private readonly translateService: TranslateService,
    private readonly parameterService: ParameterService
  ) {
    this._userGuid = JSON.parse(localStorage.getItem('individual')).userGuid;
    this.translateService.get('wrongValue').subscribe(res => this.errorValue = res);
    const advancedSearchesObservable$ = this.store.select(data => data.advancedSearchResponseState);
    advancedSearchesObservable$.subscribe(state => {
      this._advancedSearchResponseState = _.cloneDeep(state);
      if (
        state.dataState === StateStatus.LOADED ||
        state.dataState === StateStatus.LOADEDBYMATCHING
      ) {
        this._candidatesMatches = this._advancedSearchResponseState.advancedSearchResponse?.candidatesMatches;
      } else if (state.dataState === StateStatus.RELOAD) {
        this._candidatesMatches = [];
      }
    });

    const searchesObservable$ = this.store.select(data => data.advancedSearchState);
    searchesObservable$.subscribe(state => {
      this._advancedSearchState = _.cloneDeep(state);
    });
  }

  public updateCandidate(candidate: CandidateModel) {
    const index = this.candidatesMatches.findIndex(m => m.candidate.id === candidate.id);

    if (index !== -1) {
      this._candidatesMatches[index].candidate = candidate;
    }
  }

  /** Lancer la recherche et charger les critères de la recherche */
  public fetchCandidates(advancedSearchRequestModel: AdvancedSearchRequestViewModel = null, fetchMoreResults = false, resultOffset = 0) {

    if (!advancedSearchRequestModel) {
      advancedSearchRequestModel = this.buildAdvancedSearchRequestModel(fetchMoreResults, resultOffset);
    }

    this.store.dispatch(getAdvancedSearchResponse({ payload: advancedSearchRequestModel }));
  }

  public buildAdvancedSearchRequestModel(fetchMoreResults = false, resultOffset = 0): AdvancedSearchRequestViewModel {
    // Si il n'y a pas de critère et que le tri est par pertinance
    if (
      !this._advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.sortedElement &&
      this._advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria.length === 0
    ) {
      // On tri par défaut
      this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortedElement = AdvancedSearchSorting.LastCandidatureDate;
      this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortOrder = SortOrder.Descending;
    }
    // Si il s'agit du tri par défaut et qu'il y a au moins un critère
    if (
      this._advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.sortedElement === AdvancedSearchSorting.LastCandidatureDate &&
      this._advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria.length >= 1
    ) {
      // On tri par pertinance
      this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortedElement = AdvancedSearchSorting.None;
      this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortOrder = SortOrder.None;
    }

    const offset = (resultOffset !== 0) ? resultOffset : (fetchMoreResults ? this._advancedSearchResponseState?.advancedSearchResponse.candidatesMatches.length : 0);

    if (this._advancedSearchResponseState?.advancedSearchResponse?.advancedSearch) {
      return new AdvancedSearchRequestViewModel(offset, this._advancedSearchResponseState.advancedSearchResponse.advancedSearch);
    }

    const advancedSearchViewModel = new AdvancedSearchViewModel(AdvancedSearchSorting.LastCandidatureDate, SortOrder.Descending);
    advancedSearchViewModel.criteria = [];

    return new AdvancedSearchRequestViewModel(offset, advancedSearchViewModel);
  }

  /**
   * Définit le label du critère passé en paramètre en fonction de sa catégorie
   * @param criterion Le critère ayant le label à modifier
   * @returns Le critère avec label
   */
  public defineCriterionLabel(criterion: RefinedCriterionViewModel): RefinedCriterionViewModel {
    if (criterion.label) {
      return criterion;
    }

    switch (criterion.category) {
      case CriterionCategory.LanguageSkills:
        const languages = JSON.parse(localStorage.getItem('languages')) as LanguageModel[];
        const language = languages?.find(language => language.id.toString() === criterion.value);
        criterion.label = this.getCriterionValue(language);
        break;
      case CriterionCategory.Region:
        const regions = JSON.parse(localStorage.getItem('regions')) as RegionModel[];
        const region = regions?.find(region => region.id.toString() === criterion.value);
        criterion.label = this.getCriterionValue(region);
        break;
      case CriterionCategory.ExperienceLevel:
        const experiences = JSON.parse(localStorage.getItem('experiences')) as ExperienceLevelModel[];
        const experience = experiences?.find(experience => experience.id.toString() === criterion.value);
        criterion.label = this.getCriterionValue(experience);
        break;
      case CriterionCategory.HighestStudyLevel:
        const studyLevels = JSON.parse(localStorage.getItem('studyLevels')) as StudyLevelModel[];
        const studyLevel = studyLevels?.find(studyLevel => studyLevel.id.toString() === criterion.value);
        criterion.label = this.getCriterionValue(studyLevel);
        break;
      case CriterionCategory.LastActionFollowUp:
      case CriterionCategory.ActionsFollowUp:
        const actions = JSON.parse(localStorage.getItem('actionTypes')) as ActionFollowUpTypeModel[];
        const action = actions?.find(action => action.id.toString() === criterion.value);
        criterion.label = this.getCriterionValue(action);
        break;
      case CriterionCategory.ActionUser:
      case CriterionCategory.LastActionUser:
        const individual = this.organizationService.individualList?.find(indv => indv.id.toString() === criterion.value);
        criterion.label = individual ? this.individualManager.responsibleDisplayFunction(individual) : this.errorValue;
        break;
      case CriterionCategory.Chip:
        if (criterion.value === '0') {
          this.translateService.get('noChip').subscribe(res => criterion.label = res);
        } else {
          const chips = JSON.parse(localStorage.getItem('chips')) as ChipModel[];
          const chip = chips?.find(chip => chip.id.toString() === criterion.value);
          criterion.label = this.getCriterionValue(chip);
        }
        break;
      default:
        criterion.label = (criterion.category === CriterionCategory.Deprecated) ? this.errorValue : criterion.value;
        break;
    }

    if (criterion.label === this.errorValue) {
      criterion.category = CriterionCategory.Deprecated;
    }

    return criterion;
  }

  /**
   * Ajoute les critères raffinés
   * @param selectedCriteriaCategory La catégorie des critères sélectionnés
   * @param selectedCriteria Les critères sélectionnés
   * @param selectedCriteriaPriority La priorité des critères sélectionnés
   */
  public addRefinedCriteria(
    selectedCriteriaCategory: CriterionCategory,
    selectedCriteria: Array<string>,
    selectedCriteriaPriority: CriterionPriority
  ) {
    // On récupère les critères présents dans la recherche
    let refinedCriterionViewModel: RefinedCriterionViewModel[];
    const currentCriteria = this.getCriteriaOfCategory(selectedCriteriaCategory);
    refinedCriterionViewModel = currentCriteria;

    // On supprime les critères qui étaient dans la recherche mais qui ne sont plus dans selectedCriteria
    this.deleteCriteria(...refinedCriterionViewModel.filter(currentCriterion => selectedCriteria.indexOf(currentCriterion.value) < 0));

    // On liste les critères qui seront ajoutés
    const criteriaToPush = selectedCriteria.filter(selectedCriterion =>
      currentCriteria.findIndex(currentCriterion => currentCriterion.value === selectedCriterion) < 0
    );

    // Pour chaque critère à ajouter
    for (const criterion of criteriaToPush) {
      // On le crée
      const refinedCriterion = new RefinedCriterionViewModel(selectedCriteriaCategory, criterion, selectedCriteriaPriority);
      // On l'ajoute
      this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.criteria.push(refinedCriterion);
    }

    this.fetchCandidates();
  }

  /**
   * Indique si une catégorie de critère est disponible
   * @param criterionCategory La catégorie de critère
   */
  public isCriterionAvailable(criterionCategory: CriterionCategory): boolean {
    return !this.parameterService.candidateSearchParameter
      || !this.parameterService.candidateSearchParameter.hiddenCriteria
      || this.parameterService.candidateSearchParameter.hiddenCriteria.indexOf(criterionCategory) === -1;
  }

  /**
   * Obtient une liste de critères raffinés par catégorie
   * @param criterionCategory La catégorie de critère raffiné
   */
  private getCriteriaOfCategory(criterionCategory: CriterionCategory): RefinedCriterionViewModel[] {
    return this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.criteria.filter(rc => rc.category === criterionCategory);
  }

  private deleteCriteria(...criteriaToDelete: RefinedCriterionViewModel[]) {
    // Pour chaque critère à supprimer
    for (const criterion of criteriaToDelete) {
      // On obtient l'index du critère
      const criterionIndex = this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.criteria.indexOf(criterion);
      if (criterionIndex >= 0) {
        // On le supprime
        this._advancedSearchResponseState.advancedSearchResponse.advancedSearch.criteria.splice(criterionIndex, 1);
      }
    }
  }

  private getCriterionValue(criterion: Object) {
    if (criterion && criterion['label']) {
      return criterion['label'];
    }

    return this.errorValue;
  }
}

<div class="modalboxHeader m-3">
  <button class="close" matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modalboxBody">
  <div *ngIf="candidateObservable | async; else waitingTemplate" class="addCandidateContainer">
    <app-candidate-informations [candidate]="candidateObservable" [formEditMode]="false">
    </app-candidate-informations>
  </div>
  <ng-template #waitingTemplate>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>

<div class="criterion-semantic">
  <mat-expansion-panel id="{{ identifier }}_custom_synonyms_expansion_panel" [expanded]="isExpanded">
    <mat-expansion-panel-header [collapsedHeight]="'18px'" [expandedHeight]="'18px'">
      <mat-panel-title>
        {{ 'SemanticCategory.CustomSynonym' | translate  }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="form-group mt-0">
      <div class="input-group input-group-sm mb-2">
        <input id="{{ identifier }}_add_custom_synonym_input" type="text" [(ngModel)]="customSynonymValue" class="form-control" />
        <div class="input-group-btn">
          <button id="{{ identifier }}_add_custom_synonym_button" (click)="addCustomSynonym()" class="btn btn-primary rounded-right">
            <mat-spinner *ngIf="loading; else elseBlock" diameter="24"></mat-spinner>
            <ng-template #elseBlock>
              <mat-icon  title="{{ 'add' | translate  }}" class="iconAddList">playlist_add</mat-icon>
            </ng-template>
          </button>
        </div>
      </div>
      <div id="{{ identifier }}_custom_synonym_{{ i }}" *ngFor="let option of options;index as i;" class="form-check d-flex align-items-center" >
        <div class="flex-grow-1">
          <mat-checkbox [(ngModel)]="option.selected" (change)="onChange()" name="{{ i }}-name">
            {{ option.label }}
          </mat-checkbox>
        </div>
        <mat-spinner *ngIf="(customSynonymsToRemove.indexOf(option.label) !== -1); else elseBlock" diameter="24"></mat-spinner>
        <ng-template #elseBlock>
          <mat-icon id="{{ identifier }}_remove_custom_synonym_{{ i }}_button" (click)="removeCustomSynonym(option.label, option.value)" title="{{ 'delete' | translate  }}">clear</mat-icon>
        </ng-template>
      </div>
    </div>
  </mat-expansion-panel>
</div>

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CoreModule } from 'app/core/core.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrightCheckboxComponent } from './checkboxes/bright-checkbox/bright-checkbox.component';
import { HorizontalSwitchComponent } from './checkboxes/horizontal-switch/horizontal-switch.component';
import { ChipCardComponent } from './chip-card/chip-card.component';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { ImageComponent } from './image/image.component';
import { CheckboxListComponent } from './lists/checkbox-list/checkbox-list.component';
import { ListComponent } from './lists/list/list.component';
import { RadioButtonGroupComponent } from './lists/radio-button-group/radio-button-group.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { SelectGroupComponent } from './selects/select-group/select-group.component';
import { SelectComponent } from './selects/select/select.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { DynamicFieldAdapterComponent } from './dynamic-field-adapter/dynamic-field-adapter.component';
import { ExpandableTableComponent } from './expandable-table/expandable-table.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AttachedFileComponent } from './attached-file/attached-file.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OpenStreetMapComponent } from './open-street-map/open-street-map.component';
import { OpenStreetMapContainerComponent } from './open-street-map-container/open-street-map-container/open-street-map-container.component';

// Ajoutez vos components ici
const components = [
  BrightCheckboxComponent,
  CheckboxListComponent,
  ChipCardComponent,
  DropdownListComponent,
  HorizontalSwitchComponent,
  ImageComponent,
  ListComponent,
  ProfilePictureComponent,
  RadioButtonGroupComponent,
  SelectComponent,
  SelectGroupComponent,
  TextEditorComponent,
  DynamicFieldAdapterComponent,
  ExpandableTableComponent,
  ConfirmDialogComponent,
  AttachedFileComponent,
  DateTimePickerComponent,
  PaginationComponent,
  OpenStreetMapComponent,
  OpenStreetMapContainerComponent
];

// Ajoutez vos modules ici
const modules = [
  AngularSvgIconModule,
  BrowserAnimationsModule,
  BrowserModule,
  CKEditorModule,
  CoreModule,
  FileUploadModule,
  FormsModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTableModule,
  MatTooltipModule,
  NgxMatSelectSearchModule,
  ReactiveFormsModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatPaginatorModule,
  NgxMatNativeDateModule,
  NgxMatDatetimePickerModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class GenericComponentsModule { }

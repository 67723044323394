<div class="blocCriteria">
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.LastJob)">
    <app-criterion-autocomplete [identifier]="'criteria_group_lastjob_input'" label="{{ 'lastJobTitle' | translate  }}" [criterionCategory]="criterionCategory.LastJob"></app-criterion-autocomplete>
    <app-criteria-list [criterionCategory]="criterionCategory.LastJob"></app-criteria-list>
  </ng-container>
</div>
<div class="blocCriteria">
  <ng-container *ngIf="advancedSearchManager.isCriterionAvailable(criterionCategory.Job)">
    <app-criterion-autocomplete [identifier]="'criteria_group_job_input'" label="{{ 'jobTitle' | translate  }}" [criterionCategory]="criterionCategory.Job"></app-criterion-autocomplete>
    <app-criteria-list [criterionCategory]="criterionCategory.Job"></app-criteria-list>
  </ng-container>
</div>

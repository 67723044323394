import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { AuthenticationsService } from "common-services";

@Injectable()
export class AuthGuardHelper implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authenticationsService: AuthenticationsService
  ) {}

  // Vérifier si l'utilisateur est connecté
  canActivate(): boolean | UrlTree | Promise<boolean | UrlTree> {
    return this.authenticationsService.isLoggedIn() ? true : this.router.parseUrl("/login");
  }
}

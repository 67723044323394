import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { ActionManager } from 'app/shared/managers/action.manager';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { IndividualManager } from 'app/shared/managers/individual.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { ActionFollowUpService, CriterionCategory, DataDepthLevel, IndividualModel, OrganizationService, QueryableModel, SortOrder, StringHelper } from 'common-services';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-criteria-group-status',
  templateUrl: './criteria-group-status.component.html'
})
export class CriteriaGroupStatusComponent implements OnInit {

  public actionFollowUpCommentValue: string;
  public actionsFollowUpTypesHeaders: Array<KeyValue<string, Array<KeyValue<number, string>>>>;
  public criterionCategory = CriterionCategory;
  public stepLabel: string;
  public emptyOptionLabel: string;

  public responsibleControl = new FormControl();
  public responsibleOptions: Array<IndividualModel> = [];
  public responsibleCompletionOptions: Array<IndividualModel> = [];

  constructor(
    public readonly advancedSearchManager: AdvancedSearchManager,
    private readonly individualManager: IndividualManager,
    private readonly organizationService: OrganizationService,
    private readonly actionFollowUpService: ActionFollowUpService,
    private readonly actionManager: ActionManager,
    private readonly stringHelper: StringHelper,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) { }

  public ngOnInit() {
    this.translateService.get(['step', 'chooseOption']).subscribe(translations => {
      this.stepLabel = translations.step;
      this.emptyOptionLabel = translations.chooseOption;
    });

    this.actionFollowUpService.getActionFollowUpTypes().then(data => {
      this.actionsFollowUpTypesHeaders = this.actionManager.getActionsTypes(data);
    });

    const query: QueryableModel = {
      whereExpression: 'i => i.licenses.any(l=> l.applicativeAreaCode=20)',
      dataDepthLevel: DataDepthLevel.Flat,
      sortExpression: 'firstName',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    }

    this.organizationService.findIndividualsByQueryable(query)
      .then(result => {
        // On récupère tous les individus qui ont un nom ou un prénom
        this.responsibleOptions = result.items.filter(i => i.firstName || i.lastName);
        this.responsibleCompletionOptions = this.responsibleOptions;
      });

    this.responsibleControl.valueChanges.pipe(
      startWith(this.responsibleControl.value),
      map(user => {
        // S'il n'y a pas encore de prénom/nom de responsable entré
        if (!user) {
          return '';
        }
        return typeof user === 'string' ? user : this.individualManager.responsibleDisplayFunction(user);
      })).subscribe(name => {
        // On filtre s'il y a une recherche en cours, sinon on renvoie le tableau complet
        this.responsibleCompletionOptions = this.individualManager.filterUser(name, this.responsibleOptions);
      });
  }

  /**
   * Ajoute le critère raffiné du suivi d'action
   * @param selectedActionFollowUpType Le suivi d'action sélectionné
   */
  public onActionsFollowUpTypeChange(
    selectedActionFollowUpType: number
  ) {
    for (const header of this.actionsFollowUpTypesHeaders) {
      if (header.value) {
        for (const actionType of header.value) {
          if (actionType.key === selectedActionFollowUpType) {
            const refinedCriterion = new RefinedCriterionViewModel(
              CriterionCategory.ActionsFollowUp,
              actionType.key.toString(),
              CriterionPriority.Required
            );

            // Réinitialiser les candidats sélectionnés
            this.store.dispatch(resetSelectCandidates());
            this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
            return;
          }
        }
      }
    }
  }

  /**
   * Ajoute le critère raffiné du commentaire de suivi d'action RH
   */
  public addActionFollowUpCommentsCriterion() {
    // On vérifie que la recherche ne soit pas nulle ou vide
    if (!this.stringHelper.isNullOrWhiteSpace(this.actionFollowUpCommentValue)) {
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.ActionFollowUpComment,
        `"${this.actionFollowUpCommentValue}"`,
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
    // On vide l'input après ajout
    this.actionFollowUpCommentValue = '';
  }

  public addActionResponsibleCriterion(individual: IndividualModel): void {
    const refinedCriterion = new RefinedCriterionViewModel(
      CriterionCategory.ActionUser,
      individual.id.toString(),
      CriterionPriority.Required
    );

    // Réinitialiser les candidats sélectionnés
    this.store.dispatch(resetSelectCandidates());

    this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));

    // On vide l'input après l'ajout
    this.responsibleControl.reset('');
  }
}

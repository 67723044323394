import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SemanticCategory } from 'app/shared/enums/semantic-category.enum';
import { CriterionSemanticViewModel } from 'app/shared/models/advanced-search/criterion-semantic.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { SemanticOptionViewModel } from 'app/shared/models/advanced-search/semantic-option.model';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-criterion-semantic',
  templateUrl: './criterion-semantic.component.html',
  styleUrls: ['./criterion-semantic.component.scss']
})
export class CriterionSemanticComponent implements OnInit, OnDestroy {
  public readonly semanticCategory = SemanticCategory;
  @Input() identifier: string;
  @Input() isExpanded?= true;
  @Input() criterionSemantic: CriterionSemanticViewModel;
  @Input() radius: number;
  @Input() criterion: RefinedCriterionViewModel;
  @Output() emptyRadius: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() changeCriterionSemantics: EventEmitter<RefinedCriterionViewModel> = new EventEmitter<RefinedCriterionViewModel>(null);

  private readonly subscriptions: Subscription;
  private readonly flagSubject: BehaviorSubject<string>;
  private readonly languageInitialsSubject: Subject<string>;
  private readonly languageSubject: Subject<string>;
  private readonly criterionSemanticSubject: Subject<CriterionSemanticViewModel>;
  private readonly optionsArray: Array<SelectedItemModel>;
  private readonly optionsObservable: Observable<Array<SelectedItemModel>>;

  public get flag(): Observable<string> {
    return this.flagSubject;
  }

  public get criterionSemanticCategory(): SemanticCategory {
    if (!this.criterionSemantic) {
      return undefined;
    }
    return this.criterionSemantic.category;
  }

  public get languageInitials(): Observable<string> {
    return this.languageInitialsSubject;
  }

  public get options(): Observable<Array<SelectedItemModel>> {
    return this.optionsObservable;
  }

  constructor() {
    this.subscriptions = new Subscription();
    this.flagSubject = new BehaviorSubject<string>(undefined);
    this.languageInitialsSubject = new Subject<string>();
    this.languageSubject = new Subject<string>();
    this.criterionSemanticSubject = new Subject<CriterionSemanticViewModel>();
    this.optionsArray = new Array<SelectedItemModel>();
    this.optionsObservable = of(this.optionsArray);
  }

  ngOnInit(): void {
    const languageSubscription = this.languageSubject
      .pipe(
        // Si le langage du critère est null ou vide ou egale 'ml', on ignore
        filter((language) => !!language && language !== 'ml')
      )
      .subscribe(
        language => {
          this.languageInitialsSubject.next(language.toUpperCase());
          /** Génère le nom de la classe css en adéquation avec la langue de la sémantique */
          let countryLetters = language;
          // Si il s'agit d'UK
          if (countryLetters.toLowerCase() === 'en') {
            countryLetters = 'gb';
          }
          // Sinon, on retourne la classe css en adéquation avec le langage
          const flagCssClass = `flag-icon flag-icon-${countryLetters.toLowerCase()}`;
          this.flagSubject.next(flagCssClass);
        });

    const optionsSubscription = this.criterionSemanticSubject
      .pipe(
        filter((criterionSemantic) => !!criterionSemantic && !!criterionSemantic.options)
      )
      .subscribe(
        (criterionSemantic) => {
          this.optionsArray.splice(0);
          criterionSemantic.options.forEach((option, index) => {
            this.optionsArray.push({
              value: index, label: criterionSemantic.category === SemanticCategory.Level ? this.getLabelSemantic(option) : option.value, selected: option.selected
            });
          });
          this.languageSubject.next(this.criterionSemantic.language);
        });

    this.subscriptions.add(languageSubscription);
    this.subscriptions.add(optionsSubscription);

    this.criterionSemanticSubject.next(this.criterionSemantic);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.flagSubject) {
      this.flagSubject.complete();
    }
    if (this.languageInitialsSubject) {
      this.languageInitialsSubject.complete();
    }
    if (this.criterionSemanticSubject) {
      this.criterionSemanticSubject.complete();
    }
    if (this.languageSubject) {
      this.languageSubject.complete();
    }
  }

  public onRadiusChanged(radius: number) {
    // Si le périmètre sélectionné par l'utilisateur est 0, rien ne doit être retourné
    if (radius !== 0) {
      this.criterionSemantic.options = [new SemanticOptionViewModel(radius.toString(), true)];
    } else {
      this.criterionSemantic.options = [];
      this.emptyRadius.emit(true);
    }
    this.updateSemantics();
  }

  // Modifier un critère
  public onOptionChanged(selectedIndexes: Array<number>) {
    this.criterionSemantic.options.forEach((option, index) => option.selected = selectedIndexes.indexOf(index) >= 0);
    this.updateSemantics();
  }

  private updateSemantics() {
    this.criterion.semantics = this.criterion.semantics.map(semantic => {
      if (semantic.category !== this.criterionSemantic.category) {
        return semantic;
      }

      return this.criterionSemantic;
    });

    this.changeCriterionSemantics.emit(this.criterion);
  }

  private getLabelSemantic(semantic: SemanticOptionViewModel): string {
    const levels = JSON.parse(localStorage.getItem('languageLevels'));
    return levels.find(level => level.id.toString() === semantic.value).label;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, fromEvent, Subscription, Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventsManager implements OnDestroy {
  private readonly mouseEvent: Subject<MouseEvent>;
  private readonly resizeEvent: Subject<UIEvent>;
  private readonly subscriptions: Subscription;

  public get mouseClick(): Observable<MouseEvent> {
    return this.mouseEvent;
  }

  public get windowResize(): Observable<UIEvent> {
    return this.resizeEvent;
  }

  /**
   *
   */
  constructor(
  ) {
    this.subscriptions = new Subscription();
    this.mouseEvent = new Subject<MouseEvent>();
    this.resizeEvent = new Subject<UIEvent>();
    const documentClickSubscription = fromEvent(document, 'mousedown').pipe(auditTime(300)).subscribe(this.mouseEvent);
    const windowResizeSubscription = fromEvent(window, 'resize').pipe(auditTime(300)).subscribe(this.resizeEvent);
    this.subscriptions.add(documentClickSubscription);
    this.subscriptions.add(windowResizeSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CandidateModule } from 'app/business-components/candidate/candidate.module';
import { CriteriaListComponent } from './criteria-list/criteria-list.component';
import { CriteriaMenuComponent } from './criteria-menu/criteria-menu.component';
import { CriterionAutocompleteComponent } from './criterion-autocomplete/criterion-autocomplete.component';
import { CriteriaGroupChipsComponent } from './group/criteria-group-chips/criteria-group-chips.component';
import { CriteriaGroupCityComponent } from './group/criteria-group-city/criteria-group-city.component';
import { CriteriaGroupExperiencesComponent } from './group/criteria-group-experience/criteria-group-experience.component';
import { CriteriaGroupFormationsComponent } from './group/criteria-group-formation/criteria-group-formation.component';
import { CriteriaGroupFunctionComponent } from './group/criteria-group-function/criteria-group-function.component';
import { CriteriaGroupLanguagesComponent } from './group/criteria-group-language/criteria-group-language.component';
import { CriteriaGroupLastStatusComponent } from './group/criteria-group-last-status/criteria-group-last-status.component';
import { CriteriaGroupRegionComponent } from './group/criteria-group-region/criteria-group-region.component';
import { CriteriaGroupSkillsComponent } from './group/criteria-group-skill/criteria-group-skill.component';
import { CriteriaGroupStatusComponent } from './group/criteria-group-status/criteria-group-status.component';
import { CriteriaGroupTagCloudsComponent } from './group/criteria-group-tag-clouds/criteria-group-tag-clouds.component';
import { CriteriaGroupComponent } from './group/criteria-group/criteria-group.component';


// Ajoutez vos components ici
const components = [
  CriterionAutocompleteComponent,
  CriteriaGroupCityComponent,
  CriteriaGroupChipsComponent,
  CriteriaGroupComponent,
  CriteriaGroupExperiencesComponent,
  CriteriaGroupFormationsComponent,
  CriteriaGroupFunctionComponent,
  CriteriaGroupLanguagesComponent,
  CriteriaGroupLastStatusComponent,
  CriteriaGroupRegionComponent,
  CriteriaGroupSkillsComponent,
  CriteriaGroupStatusComponent,
  CriteriaGroupTagCloudsComponent,
  CriteriaListComponent,
  CriteriaMenuComponent
];

// Ajoutez vos modules ici
const modules = [
  CandidateModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class CriteriaMenuModule { }

<div [formGroup]="formGroup">
  <ng-container
    *ngFor="let control of formControls | async; let i = index; trackBy:identifyDynamicField">
    <ng-container [ngSwitch]="control.controlType">

      <mat-form-field appearance="outline" *ngSwitchCase="ControlType.Text">
        <mat-label> {{control.label}} </mat-label>
        <input [formControlName]="control.name" [required]="control.required" matInput>
        <mat-error *ngIf="control.hasError('required')">
          {{requiredMessage}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngSwitchCase="ControlType.TextArea">
        <mat-label> {{control.label}} </mat-label>
        <textarea [formControlName]="control.name" [required]="control.required" rows="5" matInput>
        </textarea>
        <mat-error *ngIf="control.hasError('required')">
          {{requiredMessage}}
        </mat-error>
      </mat-form-field>

      <div *ngSwitchCase="ControlType.Checkbox" class="mat-form-field">
        <div class="matFormFieldSpec bg-white border rounded mt-2">
          <mat-checkbox [formControlName]="control.name">{{control.label}}</mat-checkbox>
          <mat-error *ngIf="control.hasError('required')">
            {{requiredMessage}}
          </mat-error>
        </div>
      </div>

      <mat-form-field appearance="outline" *ngSwitchCase="ControlType.Radio">
        <mat-radio-group [formControlName]="control.name" [required]="control.required">
          <mat-radio-button *ngFor=" let option of control.options" class="form-group row" [value]="option.id">
            {{option.value}}
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="control.hasError('required')">
          {{requiredMessage}}
        </mat-error>
      </mat-form-field>

      <ng-container *ngSwitchCase="ControlType.Select">
        <ng-container *ngIf="control.multipleValues">
          <mat-form-field appearance="outline">
            <mat-label> {{control.label}} </mat-label>
            <mat-select [formControlName]="control.name" [required]="control.required" multiple disableOptionCentering>
              <mat-option *ngFor="let option of control.options | async" [value]="option.id" class="h-auto py-2">
                {{option.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="control.hasError('required')">
              {{requiredMessage}}
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!control.multipleValues">
          <mat-form-field appearance="outline">
            <mat-label> {{control.label}} </mat-label>
            <mat-select [formControlName]="control.name" [required]="control.required" disableOptionCentering>
              <mat-option *ngIf="!control.required"></mat-option>
              <mat-option *ngFor="let option of control.options | async" [value]="option.id">
                {{option.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="control.hasError('required')">
              {{requiredMessage}}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <mat-form-field appearance="outline" *ngSwitchCase="ControlType.Date">
        <input [formControlName]="control.name" [matDatepicker]="datepicker_i" [errorStateMatcher]="errorMatcher"
          [required]="control.required" matInput>
        <mat-label> {{control.label}} </mat-label>
        <mat-datepicker-toggle [for]="datepicker_i" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datepicker_i></mat-datepicker>
        <mat-error *ngIf="control.hasError('required')">
          {{requiredMessage}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngSwitchCase="ControlType.DateTime">
        <input [formControlName]="control.name" [matDatepicker]="datepicker_i" [errorStateMatcher]="errorMatcher"
          [required]="control.required" matInput>
        <mat-label> {{control.label}} </mat-label>
        <mat-datepicker-toggle [for]="datepicker_i" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #datepicker_i></mat-datepicker>
        <mat-error *ngIf="control.hasError('required')">
          {{requiredMessage}}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html'
})
export class DateTimePickerComponent {
  @Input() label = '';
  @Input() control: FormControl; // pour formControl
  @Input() disabled = false;
  @Input() showSpinners = true;
  @Input() showSeconds = false;
  @Input() touchUi = false; // ouvrir le datepicker dans une modal
  @Input() enableMeridian = false; // afficher (AM - PM)
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond = 1;
  @Input() color: ThemePalette = 'primary';
  @Input() disableMinute = false;
  @Input() hideTime = false;
  @Input() placeholder = '';

}

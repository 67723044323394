<div class="criterion-semantic {{ semanticCategory[criterionSemanticCategory] }}" [ngSwitch]="criterionSemanticCategory">
  <ng-container *ngSwitchCase="semanticCategory.Radius">
    <app-criterion-semantic-city-radius [identifier]="identifier" [sliderCurrentValue]="radius"
                                        (radiusChanged)="onRadiusChanged($event)">
    </app-criterion-semantic-city-radius>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-expansion-panel [expanded]="isExpanded">
      <mat-expansion-panel-header [collapsedHeight]="'18px'" [expandedHeight]="'18px'">
        <mat-panel-title class="semantic-header">
          {{ 'SemanticCategory.' + semanticCategory[criterionSemanticCategory] | translate }}
          <ng-container *ngIf="flag | async as flagSymbol">
            <span [class]="flagSymbol"></span>
            {{ languageInitials | async }}
          </ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-checkbox-list [identifier]="identifier" [options]="options | async" (selectedOptionsChanged)="onOptionChanged($event)">
      </app-checkbox-list>
    </mat-expansion-panel>
  </ng-container>
</div>

import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CandidateModel, CandidateService, CandidatureModel, DataDepthLevel } from 'common-services';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-candidate-container',
  templateUrl: './candidate-container.component.html',
  styleUrls: ['./candidate-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CandidateContainerComponent implements OnDestroy {
  public loadingCandidate = false;
  private _currentCandidate: CandidateModel;
  private readonly candidateSubject: ReplaySubject<CandidateModel>;
  private readonly candidaturesSubject: ReplaySubject<Array<CandidatureModel>>;

  public set currentCandidate(candidate: CandidateModel) {
    this.loadingCandidate = true;
    this.candidateService.getCandidate(candidate.id, DataDepthLevel.WithSubObjectsAndSubListsAndDynamicFieldData).then(c => {
      this._currentCandidate = c;
      this.candidateSubject.next(c);
      this.loadingCandidate = false;
    });
  }

  public get currentCandidate(): CandidateModel {
    return this._currentCandidate;
  }

  public get candidateObservable(): Observable<CandidateModel> {
    return this.candidateSubject;
  }

  public get candidatures(): Observable<Array<CandidatureModel>> {
    return this.candidaturesSubject;
  }

  constructor(
    private readonly candidateService: CandidateService
  ) {
    this.candidateSubject = new ReplaySubject<CandidateModel>(1);
    this.candidaturesSubject = new ReplaySubject<Array<CandidatureModel>>(1);
    this.candidateSubject.subscribe(candidate => {
      this.candidaturesSubject.next(candidate.candidatures);
    });
  }

  ngOnDestroy(): void {
    if (this.candidateSubject) {
      this.candidateSubject.complete();
    }
    if (this.candidaturesSubject) {
      this.candidaturesSubject.complete();
    }
  }
}

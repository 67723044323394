<mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-table-expandable">

  <ng-container matColumnDef="{{column.key}}" *ngFor="let column of columns | keyvalue">
    <mat-header-cell *matHeaderCellDef>
      {{ column.value | translate  | uppercase }}
    </mat-header-cell>

    <mat-cell *matCellDef="let element" [ngClass]= "{'is-expandable': isExpandable(element)}">
      <span *ngIf="element[column.key]; else elseBlock">
        <span *ngIf="isDate(element[column.key]); else elseBlockDate">
          {{ element[column.key] | toLocalDate : dateColumnFilter }}
        </span>
        <ng-template #elseBlockDate>
          <span>{{ element[column.key] }}</span>
        </ng-template>
      </span>
      <ng-template #elseBlock>
        <span> - </span>
      </ng-template>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let element"
              [ngClass]= "{'hidden': !isExpandable(element)}"
              [attr.colspan]="columnsToDisplay.length"
    >
      <div  class="expandable-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div 
            *ngFor="let expandableColumn of expandableColumns" 
            class="expandable-element-description"
        >
          {{ element[expandableColumn] }}
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="expandable-element-row"
    [class.expandable-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row expandedDetail"></mat-row>
</mat-table>

<div class="fixActionRow">
  <div class="modalHeader">
    <button class="close" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
    <h1 mat-dialog-title class="text-uppercase text-primary font-weight-bold">{{ 'addActionsOrComments' | translate  }}</h1>
  </div>
  <div class="position-relative modalSpacer">
    <mat-dialog-content>
      <form [formGroup]="saveActionForm" class="bg-grey">
        <fieldset>
          <legend>{{ 'actions' | translate  }}</legend>
          <app-date-time-picker id="add_action_follow_up_component_date_input" [control]="saveActionForm.controls.date"></app-date-time-picker>
          <app-select-group
                        [identifier]="'add_action_follow_up_component_action_type_select'"
                        [class]="'w-50 pl-2 mb-3'"
                        [formControl]="saveActionForm.controls.actionTypeId"
                        [groups]="actionTypesHeaderArray"
                        [emptyOption]="emptyOptionLabel"
                        [label]="labelActionType"
                        [required]="true">
          </app-select-group>
          <app-select *ngIf="isSingleCandidate"
                        [identifier]="'add_action_follow_up_component_position_select'"
                        [class]="'w-100 mb-3'"
                        [formControl]="saveActionForm.controls.positionId"
                        [options]="positionsArray"
                        [emptyOption]="emptyOptionLabel"
                        [label]="labelPosition">
          </app-select>
        </fieldset>
        <fieldset class="pb-0">
          <legend>
            {{ 'comments' | translate  }}
            <div class="micro d-inline-block position-relative">
              <app-web-speech [language]="language" (onStartSpeech)="onStartSpeech()" (onStopSpeech)="onStopSpeech()" (onSpeech)="onSpeech($event)"></app-web-speech>
            </div>
          </legend>
          <app-text-editor id="add_action_follow_up_component_text_editor" configType="Text" [formControl]="saveActionForm.controls.comments"></app-text-editor>
          <hr class="hrSpecCkeditor" />
        </fieldset>
      </form>
    </mat-dialog-content>
  </div>
  <mat-dialog-actions class="btns-actions justify-content-end mb-0 position-relative">
    <div class="bg-grey p-0" [ngClass]="{'bg-grey-2' : isSingleCandidate}">
      <button id="add_action_follow_up_component_add_button" mat-button [disabled]="saveActionForm.invalid || isSaving" (click)="saveActions()" mat-flat-button color="primary"
              class="btn text-uppercase">
        <mat-icon class="mr-2">save</mat-icon>
        <span>{{ 'add' | translate  }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { ChipCriterionModel } from 'app/shared/models/advanced-search/chip-criterion.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { AttachmentModel, CriterionCategory } from 'common-services';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-attachment-popup',
  templateUrl: './attachment-popup.component.html',
  styleUrls: ['./attachment-popup.component.scss']
})

export class AttachmentPopupComponent implements OnInit, OnDestroy {
  private readonly isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  private readonly attachmentSubject: BehaviorSubject<AttachmentModel>;
  private readonly attachmentValidationSubject: BehaviorSubject<boolean>;
  private readonly criteriaChips: Array<ChipCriterionModel>;
  private readonly criteriaChipsObservable: Observable<Array<ChipCriterionModel>>;
  private readonly search: Set<string>;
  // Les catégories de critères à exclure du surlignage
  public excludedCriteriaCategories: Array<CriterionCategory> = [
    CriterionCategory.ActionsFollowUp,
    CriterionCategory.LastActionFollowUp,
    CriterionCategory.HighestStudyLevel,
    CriterionCategory.ExperienceLevel,
    CriterionCategory.Chip,
    CriterionCategory.ActionFollowUpComment,
    CriterionCategory.LastActionUser,
    CriterionCategory.ActionUser];

  public set currentAttachmentFile(attachmentFile: AttachmentModel) {
    this.attachmentSubject.next(attachmentFile);
  }

  public get isValidHtmlDocument(): Observable<boolean> {
    return this.attachmentValidationSubject;
  }

  public get attachment(): Observable<AttachmentModel> {
    return this.attachmentSubject;
  }

  public get chips(): Observable<Array<ChipCriterionModel>> {
    return this.criteriaChipsObservable;
  }

  public get searchKeywords(): Set<string> {
    return this.search;
  }

  constructor(
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.search = new Set<string>();
    this.attachmentSubject = new BehaviorSubject<AttachmentModel>(undefined);
    this.attachmentValidationSubject = new BehaviorSubject<boolean>(false);
    this.criteriaChips = new Array<ChipCriterionModel>();
    this.criteriaChipsObservable = of(this.criteriaChips);
  }

  ngOnInit(): void {
    this.attachmentSubject.subscribe(
      attachment => {
        this.search.clear();
        this.criteriaChips.splice(0);
        if (attachment) {
          this.prepareHighlightedCriterias(attachment.preview);
          this.attachmentValidationSubject.next(
            attachment.preview && this.isHTML(attachment.preview)
          );
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.attachmentSubject) {
      this.attachmentSubject.complete();
    }
    if (this.attachmentValidationSubject) {
      this.attachmentValidationSubject.complete();
    }
  }

  public get highlightedCriteria(): Array<RefinedCriterionViewModel> {
    const criterias = this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria;
    return criterias?.filter(c => !this.excludedCriteriaCategories.includes(c.category));
  }

  public chipClick(chip: MatChip) {
    const criterion = chip.value as ChipCriterionModel;
    chip.selected = !chip.selected;
    if (chip.selected) {
      this.search.add(criterion.value);
    } else {
      this.search.delete(criterion.value);
    }
  }

  private prepareHighlightedCriterias(text: string) {
    if (text) {
      text = text.replace(/(<([^>]+)>)/ig, '').toLowerCase();
    }
    this.highlightedCriteria.forEach(
      criteria => {
        criteria = this.advancedSearchManager.defineCriterionLabel(criteria);
        const criteriaChip = new ChipCriterionModel(criteria.label, this.wordCount(text, criteria.label));
        if (criteria.category !== CriterionCategory.City) {
          criteria.semantics.forEach(
            semantic => {
              const options = semantic.options
                .filter(option => option.selected);
              if (options.length > 0 && !criteriaChip.childs) {
                criteriaChip.childs = new Array<ChipCriterionModel>();
              }
              options.forEach(
                option => {
                  const wordCount = this.wordCount(text, option.value);
                  if (wordCount > 0) {
                    const criteriaChipChild = new ChipCriterionModel(option.value, wordCount);
                    criteriaChip.childs.push(criteriaChipChild);
                  }
                }
              );
            }
          );
        }
        this.criteriaChips.push(criteriaChip);
      }
    );
  }

  private wordCount(text: string, word: string): number {
    if (!text || !word) {
      return 0;
    }
    // Encodage des entités HTML
    const htmlPreview = document.createElement('div');
    htmlPreview.innerHTML = text;

    return (htmlPreview.innerText.split(word.toLowerCase()).length - 1);
  }
}

<mat-form-field appearance="outline" class="{{ class }}">
  <mat-label *ngIf="label != undefined">{{ label }}</mat-label>
  <mat-select id="{{ identifier }}"
    (selectionChange)="onToggle($event.value)"
    [formControl]="selectControl"
    [multiple]="multiple"
    [required]="required"
    disableOptionCentering>
    <mat-option *ngIf="emptyOption != undefined" [value]="emptyOptionValue" class="h-auto py-2">
      {{emptyOption}}
    </mat-option>
    <mat-option id="{{ identifier }}_{{ i }}" *ngFor="let option of options;index as i;" [value]="option.key" class="h-auto py-2">
      {{ option.value }}
    </mat-option>
  </mat-select>
  <mat-spinner *ngIf="isLoading" class="spinnerSelect" diameter="20"></mat-spinner>
</mat-form-field>

import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MomentUtcDateAdapter } from './moment-utc-date.adapter';

@Injectable()
export class MomentUtcDateTimeAdapter extends NgxMatDateAdapter<moment.Moment> {

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    private readonly momentUtcDateAdapter: MomentUtcDateAdapter
  ) {
    super();
    moment.locale(dateLocale);
  }

  deserialize(value: string | undefined): moment.Moment | null {
    return value ? moment(value) : null;
  }

  /**
   * Crée une date
   */
  createDate(year: number, month: number, date: number): moment.Moment {
    return moment({ year, month, date });
  }

  // Transforme la date (qui est en string) en moment
  parse(value: string): moment.Moment | null {
    if (!value || typeof value !== 'string') {
      return undefined;
    }

    return this.momentUtcDateAdapter.parse(value, this.parseFormat());
  }

  // Formate la date (mat date time picker) selon la langue utilisateur
  format(date: moment.Moment): string {
    const parseFormat = this.parseFormat();
    return date.format(parseFormat);
  }

  /**
   * Choisit le format de la date en 'L'
   * format L revoie une date sous cette forme par exemple (DD/MM/YYYY)
   * 'HH:mm' est ajouté pour avoir les heures et les minutes dans la date
   */
  private parseFormat(): string {
    return this.momentUtcDateAdapter.parseFormat() + ' HH:mm';
  }

  getYear(date: moment.Moment): number {
    return date.year();
  }

  getMonth(date: moment.Moment): number {
    return date.month();
  }

  getDate(date: moment.Moment): number {
    return date.date();
  }

  getDayOfWeek(date: moment.Moment): number {
    return this.momentUtcDateAdapter.getDayOfWeek(date);
  }

  // Obtient un tableau des mois d'année avec une format spécifique
  // Exemple pour le mois Janvier: long => Janvier , short => Jan, narrow => J
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this.momentUtcDateAdapter.getMonthNames(style);
  }

  // Obtient les jours de la semaine avec un format spécifique (exemple: Lundi, Lu ou bien L)
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this.momentUtcDateAdapter.getDayOfWeekNames(style);
  }

  // Obtient la liste des noms de chaque jour du mois
  getDateNames(): string[] {
    return [...Array(31).keys()].map(x => (x + 1).toString());
  }

  // Obtient le nom de l'année de la date donnée (exemple: '2020')
  getYearName(date: moment.Moment): string {
    return date.year().toString();
  }

  // Obtient le premier jour de la semaine
  getFirstDayOfWeek(): number {
    return this.momentUtcDateAdapter.getFirstDayOfWeek();
  }

  // Obtient le nombre de jours du mois de la date donné
  getNumDaysInMonth(date: moment.Moment): number {
    return date.daysInMonth();
  }

  clone(date: moment.Moment): moment.Moment {
    return date.clone();
  }

  today(): moment.Moment {
    return moment();
  }

  // Ajout d'un nombre d'années à une date
  // Si on ajoute 1 an au 29 Février 2016, le résultat sera 28 Février 2016
  addCalendarYears(date: moment.Moment, years: number): moment.Moment {
    return date.clone().add(years, 'years');
  }

  // Ajout d'un nombre de mois à une date
  // Si on ajoute 1 mois au 31 Janvier 2017, le résultat sera 28 Février 2017
  addCalendarMonths(date: moment.Moment, months: number): moment.Moment {
    return date.clone().add(months, 'months');
  }

  // Ajout d'un nombre de jours à une date
  addCalendarDays(date: moment.Moment, days: number): moment.Moment {
    return date.clone().add(days, 'days');
  }

  isValid(date: moment.Moment): boolean {
    return date.isValid();
  }

  isDateInstance(date: moment.Moment): boolean {
    return moment.isMoment(date);
  }

  toIso8601(date: moment.Moment): string {
    return date.toISOString();
  }

  invalid() {
    return moment.invalid();
  }

  getHour(date: moment.Moment): number {
    return date.hours();
  }

  getMinute(date: moment.Moment): number {
    return date.minutes();
  }

  getSecond(date: moment.Moment): number {
    return date.seconds();
  }

  setHour(date: moment.Moment, value: number): void {
    date.set({ hour: value });
  }

  setMinute(date: moment.Moment, value: number): void {
    date.set({ minute: value });
  }

  setSecond(date: moment.Moment, value: number): void {
    date.set({ second: value });
  }
}

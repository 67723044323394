import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AdvancedSearchRequestViewModel } from 'app/shared/models/advanced-search/advanced-search-request.model';
import { AutoCompleteModel } from 'app/shared/models/advanced-search/auto-complete.model';
import { CriterionSemanticViewModel } from 'app/shared/models/advanced-search/criterion-semantic.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { SemanticOptionViewModel } from 'app/shared/models/advanced-search/semantic-option.model';
import { ActionWithPayload } from 'app/shared/models/state/action-with-payload.model';
import { PositionPayload } from 'app/shared/models/state/position-payload.model';
import { CriterionCategory, CustomSynonymsModel, LanguageService, RefinedCriterionModel, SearchesService, SemanticCategory } from 'common-services';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import * as AdvancedSearchResponseActions from '../actions/advanced-search-response.action';

@Injectable()
export class AdvancedSearchResponseEffects {

  constructor(
    private readonly searchesService: SearchesService,
    private readonly effectActions: Actions,
    private readonly languageService: LanguageService
  ) { }

  searchCandidates$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.GET_ADVANCED_SEARCH_RESPONSE),
      switchMap(async (action: ActionWithPayload<AdvancedSearchRequestViewModel>) => {
        return this.searchesService.searchCandidates(action.payload)
          .then(response => AdvancedSearchResponseActions.getAdvancedSearchResponseSuccess({ payload: response }))
          .catch(() => AdvancedSearchResponseActions.getAdvancedSearchResponseError());
      })
    )
  );

  // Charger la recherche
  loadAdvancedSearch$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.LOAD_ADVANCED_SEARCH),
      mergeMap((action: ActionWithPayload<number>) => {
        let criteria = [];
        return this.searchesService.loadAdvancedSearchById(action.payload)
          .then(async response => {
            // Ajoute les sémantiques du critère raffiné
            // Pour chaque critère
            for (const criterion of response.advancedSearch.criteria) {
              // On ajoute ses sémantiques
              await this.addSemantics(criterion).then(cr => {
                if (cr) {
                  criteria = [...criteria, ...[cr]];
                }
              }).catch(cr => {
                criteria = [...criteria, ...[cr]];
              });
            }

            response.advancedSearch.criteria = criteria;
            return AdvancedSearchResponseActions.loadAdvancedSearchSuccess({ payload: response });
          })
          .catch(() => AdvancedSearchResponseActions.loadAdvancedSearchError());
      })
    )
  );

  // Cherche les candidats correspondants par position id
  searchCandidatesByPositionIdOrUrl$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.SEARCH_CANDIDATES_BY_POSITION_ID_OR_URI),
      mergeMap((action: ActionWithPayload<PositionPayload>) => {

        const positionId = action.payload.positionId;
        const codeLanguage = action.payload.codeLanguage;
        const positionUri = action.payload.positionUri;

        if (positionUri) {
          return this.searchesService.searchCandidatesByPositionUri(positionUri)
            .then(response => AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriSuccess({ payload: response }))
            .catch(() => AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriError());
        }

        return this.searchesService.searchCandidatesByPositionId(positionId, codeLanguage)
          .then(response => AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriSuccess({ payload: response }))
          .catch(() => AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriError());
      })
    )
  );

  // Ajoute les sémantiques du critère raffiné
  addSemantics$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.ADD_REFINED_CRITERION),
      mergeMap((action: ActionWithPayload<RefinedCriterionViewModel>) => {
        const refinedCriterion = _.cloneDeep(action.payload);
        return this.addSemanticsAndDispatchAction(refinedCriterion);
      })
    )
  );

  /**
   * Ajoute les sémantiques du critère raffiné et dispatcher une action
   * @param refinedCriterion Le critère raffiné à ajouter
   */
  private addSemanticsAndDispatchAction(refinedCriterion: RefinedCriterionModel) {
    return this.addSemantics(refinedCriterion).then(
      result => AdvancedSearchResponseActions.addRefinedCriterionSuccess({ payload: result }))
      .catch(() => AdvancedSearchResponseActions.addRefinedCriterionError());
  }

  /**
   * Ajoute les sémantiques du critère raffiné
   * @param refinedCriterion Le critère raffiné à ajouter
   */
  private addSemantics(refinedCriterion: RefinedCriterionModel): Promise<RefinedCriterionModel> {
    switch (refinedCriterion.category) {
      case CriterionCategory.LanguageSkills:
        return this.languageService
          .getLanguageLevels()
          .then(languageLevels => {
            let semanticLanguage = refinedCriterion.semantics.find(s => s.category === SemanticCategory.Level);
            if (!semanticLanguage) {
              semanticLanguage = new CriterionSemanticViewModel(SemanticCategory.Level, []);
              refinedCriterion.semantics.push(semanticLanguage);
            }
            semanticLanguage.options = languageLevels.map(languageLevel =>
              semanticLanguage.options.find(o => o.value === languageLevel.id.toString())
              || new SemanticOptionViewModel(languageLevel.id.toString(), false, languageLevel.label)
            );

            return Promise.resolve().then(() => {
              return refinedCriterion;
            });
          }).catch(() => {
            return Promise.reject().then(() => {
              return refinedCriterion;
            });
          });
      case CriterionCategory.City:
        const semantic = refinedCriterion.semantics.find(s => s.category === SemanticCategory.Radius);
        if (!semantic) {
          refinedCriterion.semantics.push(
            new CriterionSemanticViewModel(SemanticCategory.Radius, [])
          );
        }
        return Promise.resolve().then(() => {
          return refinedCriterion;
        });
      default:
        return Promise.resolve().then(() => {
          return refinedCriterion;
        });
    }
  }

  // Définir les synonymes d'un critère
  defineKeywordSynonyms$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.DEFINE_KEYWORD_SYNONYMS),
      mergeMap(async (action: ActionWithPayload<CustomSynonymsModel>) => {
        return this.searchesService.defineKeywordSynonyms(action.payload)
          .then(() => AdvancedSearchResponseActions.defineKeywordSynonymsSuccess({ payload: true }))
          .catch(() => AdvancedSearchResponseActions.defineKeywordSynonymsError({ payload: false }));
      })
    )
  );

  // Lancer une recherche pour l'autocomplete
  searchCompletions$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchResponseActions.AdvancedSearchResponseActionsTypes.SEARCH_COMPLETIONS),
      switchMap(async (action: ActionWithPayload<AutoCompleteModel>) => {
        return this.searchesService.searchCompletions(action.payload)
          .then(data => AdvancedSearchResponseActions.searchCompletionsSuccess({ payload: data }));
      })
    )
  );

}

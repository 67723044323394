import { SemanticCategory } from 'app/shared/enums/semantic-category.enum';
import { SemanticOptionViewModel } from './semantic-option.model';
import {CriterionSemanticModel} from 'common-services';

export class CriterionSemanticViewModel implements CriterionSemanticModel {
  constructor(
    public category: SemanticCategory,
    public options: Array<SemanticOptionViewModel>,
  ) { }
  id: number;
  criterionId: number;
  criterion: string;
  language: string;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() items: string[];
  @Input() strongifyValue: string;
  @Output() selected: EventEmitter<string> = new EventEmitter();

  public isClickable: boolean;

  ngOnInit(): void {
    this.isClickable = this.selected.observers.length > 0;
  }

  public itemClicked(value: string) {
    this.selected.emit(value);
    this.items = [];
  }
}

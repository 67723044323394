import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory, GeographicAreasService, IndividualModel } from 'common-services';

@Component({
  selector: 'app-criteria-group-region',
  templateUrl: './criteria-group-region.component.html'
})
export class CriteriaGroupRegionComponent implements OnInit {

  public criterionCategory = CriterionCategory;
  public countryLabel: string;
  public regionLabel: string;
  public emptyOptionLabel: string;
  public noOptionAvailableLabel: string;
  public defaultCountryCode = 'FR';

  public countries: Array<KeyValue<string | number, string>>;
  public regions: Array<KeyValue<number, string>>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly geographicAreasService: GeographicAreasService,
    private readonly translateService: TranslateService
  ) { }

  public ngOnInit() {
    // Obtient les traductions
    this.translateService.get(['country', 'region', 'noOptionAvailable', 'chooseOption']).subscribe(translations => {
      this.countryLabel = translations.tagCloudCategory;
      this.regionLabel = translations.tagCloudBooleanCategory;
      this.noOptionAvailableLabel = translations.noOptionAvailable;
      this.emptyOptionLabel = translations.chooseOption;
    });

    this.geographicAreasService.getAvailableCountries().then(data => {
      this.storeRegions(data);
      this.countries = data.map(country => ({ key: country.code, value: country.label }));
      const individual: IndividualModel = JSON.parse(localStorage.getItem('individual'));
      if (individual && this.countries?.find(country => country.key === individual?.languageBU?.code)) {
        this.defaultCountryCode = individual.languageBU.code;
      }

      this.loadRegions(this.defaultCountryCode);
    });
  }

  public onCountryChange(selectedCountryCode: string) {
    this.loadRegions(selectedCountryCode);
  }

  public onRegionChange(selectedRegion: string | number) {
    const selected = this.regions.find(a => a.key === selectedRegion);
    if (selected && selected.key > 0) {
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.Region,
        selected.key.toString(),
        CriterionPriority.Required
      );
      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());
      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
  }

  private loadRegions(selectedCountryCode: string) {
    this.geographicAreasService.getRegionsByCountryCode(selectedCountryCode).then(data => {
      this.regions = data.map(region => ({ key: region.id, value: region.label }));
    });
  }

  private async storeRegions(countries) {
    const regions = [];
    if (countries) {
      for (const country of countries) {
        await this.geographicAreasService.getRegionsByCountryId(country.id).then(data => {
          data.forEach(region => {
            regions.push(region);
          });
        });
      }
    }

    localStorage.setItem('regions', JSON.stringify(regions));
  }
}

import { Component, OnDestroy } from '@angular/core';
import { CandidateModel, DynamicFieldEntity, DynamicFieldService } from 'common-services';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-candidate-add-container',
  templateUrl: './candidate-add-container.component.html',
  styleUrls: ['./candidate-add-container.component.scss']
})
export class CandidateAddContainerComponent implements OnDestroy {
  private readonly candidateSubject: ReplaySubject<CandidateModel>;

  public get candidateObservable(): Observable<CandidateModel> {
    return this.candidateSubject;
  }

  constructor(dynamicFieldService: DynamicFieldService) {
    this.candidateSubject = new ReplaySubject<CandidateModel>();
    dynamicFieldService.getDynamicFieldConfigurationsByEntity(DynamicFieldEntity.Candidate).then(dynamicFields => {
      const candidateModel = { dynamicFieldEntity: DynamicFieldEntity.Candidate, dynamicFields } as CandidateModel;
      this.candidateSubject.next(candidateModel);
    });
  }

  ngOnDestroy(): void {
    this.candidateSubject?.complete();
  }
}

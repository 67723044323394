import { createAction, props } from '@ngrx/store';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { AdvancedSearchModel } from 'common-services';

export enum AdvancedSearchActionsTypes {
  GET_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Get Advanced Searches',
  GET_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Get Advanced Searches Success',
  GET_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Get Advanced Searches Error',

  ADD_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Add Advanced Search',
  ADD_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Add Advanced Search Success',
  ADD_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Add Advanced Search Error',

  CLONE_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Clone Advanced Search',
  CLONE_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Clone Advanced Search Success',
  CLONE_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Clone Advanced Search Error',

  UPDATE_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Update Advanced Search',
  UPDATE_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Update Advanced Search Success',
  UPDATE_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Update Advanced Search Error',

  DELETE_ADVANCED_SEARCH = '[AdvancedSearchViewModel] Delete Advanced Search',
  DELETE_ADVANCED_SEARCH_SUCCESS = '[AdvancedSearchViewModel] Delete Advanced Search Success',
  DELETE_ADVANCED_SEARCH_ERROR = '[AdvancedSearchViewModel] Delete Advanced Search Error',

  LOAD_CRITERION_BY_ADVANCED_SEARCH_ID = '[RefinedCriterionModel] Load Criterion By Advanced Search Id',
  LOAD_CRITERION_BY_ADVANCED_SEARCH_ID_SUCCESS = '[RefinedCriterionModel] Load Criterion By Advanced Search Id Success',
  LOAD_CRITERION_BY_ADVANCED_SEARCH_ID_ERROR = '[RefinedCriterionModel] Load Criterion By Advanced Search Id Error'
}

// Lancement de la récupération des recherches enregistrées
export const getAdvancedSearch = createAction(
  AdvancedSearchActionsTypes.GET_ADVANCED_SEARCH
);
// Récupération des recherches enregistrées avec succées
export const getAdvancedSearchSuccess = createAction(
  AdvancedSearchActionsTypes.GET_ADVANCED_SEARCH_SUCCESS,
  props<{ payload: Array<AdvancedSearchViewModel> }>()
);
// Erreur lors de la récupération des recherches enregistrées
export const getAdvancedSearchError = createAction(
  AdvancedSearchActionsTypes.GET_ADVANCED_SEARCH_ERROR
);
// Supprimer une recherche enregistrée
export const deleteAdvancedSearch = createAction(
  AdvancedSearchActionsTypes.DELETE_ADVANCED_SEARCH,
  props<{ payload: number }>()
);
// Supprimer une recherche enregistrée avec succées
export const deleteAdvancedSearchSuccess = createAction(
  AdvancedSearchActionsTypes.DELETE_ADVANCED_SEARCH_SUCCESS,
  props<{ payload: number }>()
);
// Erreur dans la suppression d'une recherche enregistrée
export const deleteAdvancedSearchError = createAction(
  AdvancedSearchActionsTypes.DELETE_ADVANCED_SEARCH_SUCCESS
);
// Modifier une recherche enregistrée
export const updateAdvancedSearch = createAction(
  AdvancedSearchActionsTypes.UPDATE_ADVANCED_SEARCH,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Modifier une recherche enregistrée avec succès
export const updateAdvancedSearchSuccess = createAction(
  AdvancedSearchActionsTypes.UPDATE_ADVANCED_SEARCH_SUCCESS,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Erreur dans la modification une recherche enregistrée
export const updateAdvancedSearchError = createAction(
  AdvancedSearchActionsTypes.UPDATE_ADVANCED_SEARCH_ERROR
);
// Enregistrer une nouvelle recherche
export const addAdvancedSearch = createAction(
  AdvancedSearchActionsTypes.ADD_ADVANCED_SEARCH,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Enregistrer une nouvelle recherche avec succès
export const addAdvancedSearchSuccess = createAction(
  AdvancedSearchActionsTypes.ADD_ADVANCED_SEARCH_SUCCESS,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Erreur dans l'enregistrement une nouvelle recherche
export const addAdvancedSearchError = createAction(
  AdvancedSearchActionsTypes.ADD_ADVANCED_SEARCH_ERROR
);
// Cloner une nouvelle recherche
export const cloneAdvancedSearch = createAction(
  AdvancedSearchActionsTypes.CLONE_ADVANCED_SEARCH,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Cloner une nouvelle recherche avec succès
export const cloneAdvancedSearchSuccess = createAction(
  AdvancedSearchActionsTypes.CLONE_ADVANCED_SEARCH_SUCCESS,
  props<{ payload: AdvancedSearchViewModel }>()
);
// Erreur lors du clonage d'une recherche
export const cloneAdvancedSearchError = createAction(
  AdvancedSearchActionsTypes.CLONE_ADVANCED_SEARCH_ERROR
);
// Récupérer les critères d'une recherche enregistrée via l'Id
export const loadCriterionByAdvancedSearchId = createAction(
  AdvancedSearchActionsTypes.LOAD_CRITERION_BY_ADVANCED_SEARCH_ID,
  props<{ payload: number }>()
);
// Récupérer les critères d'une recherche enregistrée via l'Id avec succées
export const loadCriterionByAdvancedSearchIdSuccess = createAction(
  AdvancedSearchActionsTypes.LOAD_CRITERION_BY_ADVANCED_SEARCH_ID_SUCCESS,
  props<{ payload: AdvancedSearchModel }>()
);
// Erreur lors de la récupération des critères d'une recherche enregistrée
export const loadCriterionByAdvancedSearchIdIdError = createAction(
  AdvancedSearchActionsTypes.LOAD_CRITERION_BY_ADVANCED_SEARCH_ID_ERROR
);

import { Component, Input } from '@angular/core';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';

@Component({
  selector: 'app-saved-request-criterion',
  templateUrl: './saved-request-criterion.component.html'
})

export class SavedRequestCriterionComponent {
  @Input() refinedCriterion: RefinedCriterionViewModel;

  constructor(
    private readonly advancedSearchManager: AdvancedSearchManager
  ) { }

  public get label() {
    return this.advancedSearchManager.defineCriterionLabel(this.refinedCriterion)?.label;
  }
}

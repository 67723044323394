import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { CriterionCategory, RefinedCriterionModel } from 'common-services';
import { CriterionSemanticViewModel } from './criterion-semantic.model';

export class RefinedCriterionViewModel implements RefinedCriterionModel {
  constructor(
    public category: CriterionCategory,
    public value: string,
    public priority: CriterionPriority,
  ) {
    if (!this.semantics) {
      this.semantics = [];
    }
  }
  id: number;
  advancedSearchId: number;
  advancedSearch: string;
  hasSynonyms: boolean;
  isHidden: boolean;
  semantics: Array<CriterionSemanticViewModel>;
  label?: string;
}

import { Component, OnInit } from '@angular/core';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { ParameterService } from 'app/shared/services/parameter.service';
import { ApplicativeAreaCode } from 'common-services';

@Component({
  selector: 'app-geolocate-candidate',
  templateUrl: './geolocate-candidate.component.html'
})

export class GeolocateCandidateComponent implements OnInit {
  public enableGeolocation: boolean;

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly parameterService: ParameterService,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) { }

  ngOnInit(): void {
    this.enableGeolocation = this.parameterService.getApplicativeArea(ApplicativeAreaCode.MapsServices)?.applicativeAreaIsActive;
  }

  // Si au moins un candidat est selectionné
  public get isDisabled(): boolean {
    return !this.advancedSearchManager.advancedSearchResponseState.selectedCandidates.length;
  }

  public localizeCandidates(): void {
    if (!this.isDisabled) {
      this.dialogsManager.openDialog({ dialog: Dialogs.GeolocateCandidates, closeClickOutside: false });
    }
  }
}

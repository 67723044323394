import { Injectable } from '@angular/core';
import { HttpHelper } from 'app/shared/helpers/http.helper';

@Injectable()
export class ImageService {

  constructor(
    private readonly httpHelper: HttpHelper
  ) { }

  // Charge une image
  public loadImage(
    url: string
  ): Promise<any> {
    return this.httpHelper.get(
      ImageService.name,
      'loadImage',
      '',
      url
    );
  }

  public readSvg(
    svgFileData: string
  ): Promise<string> {
    return this.httpHelper.get(
      ImageService.name,
      'readSvg',
      '',
      svgFileData
    );
  }
}

import {
  CandidateAttachmentFileModel,
  CandidateExperienceModel,
  CandidateFormationModel,
  CandidateModel,
  CandidateSocialNetworkModel,
  CandidatureModel,
  ChipModel,
  CountryModel,
  DynamicFieldDtoModel,
  DynamicFieldEntity
} from 'common-services';

export class PRCandidateModel implements CandidateModel {

  constructor(
    public id: number,
    public civilityId: number,
    public chipId: number,
    public nationalityId: number,
    public nationality: string,
    public countryId: number,
    public firstRegionMobilityId: number,
    public secondRegionMobilityId: number,
    public regionOriginId: number,
    public experienceLevelId: number,
    public currentSalary: number,
    public lastName: string,
    public firstName: string,
    public emailAddress: string,
    public currentCompany: string,
    public customerId: number,
    public currentPosition: string,
    public professionalPhone: string,
    public personalPhone: string,
    public mobilePhone: string,
    public birthDate: Date,
    public age: number,
    public address: string,
    public zipCode: string,
    public city: string,
    public additionalTextArea: string,
    public candidatures: CandidatureModel[],
    public attachmentsFiles: CandidateAttachmentFileModel[],
    public socialNetworkLinks: CandidateSocialNetworkModel[],
    public experiences: CandidateExperienceModel[],
    public formations: CandidateFormationModel[],
    public firstLanguageId: number,
    public secondLanguageId: number,
    public thirdLanguageId: number,
    public firstLanguageLevelId: number,
    public secondLanguageLevelId: number,
    public thirdLanguageLevelId: number,
    public dynamicFields: DynamicFieldDtoModel[],
    public dynamicFieldEntity: DynamicFieldEntity,
    public visibility: boolean,
    public latitude: number,
    public longitude: number,
    public chip: ChipModel,
    public originCountry: CountryModel
  ) { }
}

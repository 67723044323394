import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-candidate-scoring',
  templateUrl: './candidate-scoring.component.html',
  styleUrls: ['./candidate-scoring.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateScoringComponent {
  @Input() score = 0;

  get hasscore1(): boolean {
    return (this.score > 0) && (this.score < 0.2);
  }

  get hasscore2(): boolean {
    return (this.score >= 0.2) && (this.score < 0.4);
  }

  get hasscore3(): boolean {
    return (this.score >= 0.4) && (this.score < 0.6);
  }

  get hasscore4(): boolean {
    return (this.score >= 0.6) && (this.score < 0.8);
  }

  get hasscore5(): boolean {
    return this.score >= 0.8;
  }

  get percentageOfScore(): string {
    let percentage = '';
    switch (true) {
      case this.hasscore1:
        percentage = '0% - 20%';
        break;
      case this.hasscore2:
        percentage = '20% - 40%';
        break;
      case this.hasscore3:
        percentage = '40% - 60%';
        break;
      case this.hasscore4:
        percentage = '60% - 80%';
        break;
      case this.hasscore5:
        percentage = '80% - 100%';
        break;
    }

    return percentage;
  }
}

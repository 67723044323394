<div class="blocCriteria">
  <div [attr.id]="'criteria_group_chips_' + i" class="form-check" *ngFor="let chip of chipSelected; let i=index">
    <mat-checkbox id="criteria_group_chips_checkbox_{{ i }}" 
      [(ngModel)]="chip.selected" 
      (change)="onChipChange()">
      <span class="d-flex align-items-center">
        <span *ngIf="chip.value === noChip.id; else elseBlock">
          <svg style="width:10px;height:10px" viewBox="0 0 10 10" class="noChipSvg">
            <path fill="currentColor" d="M1.4,9.4L2,8.5C1,7.7,0.4,6.4,0.4,5c0-2.6,2.1-4.6,4.6-4.6c0.8,0,1.6,0.2,2.3,0.6l0.6-0.9l0.8,0.5L8,1.5
	          C9,2.3,9.6,3.6,9.6,5c0,2.6-2.1,4.6-4.6,4.6c-0.8,0-1.6-0.2-2.3-0.6L2.1,9.9L1.4,9.4 M5,1.3C2.9,1.3,1.3,2.9,1.3,5
	          c0,1.1,0.5,2.1,1.2,2.8l4.2-6C6.2,1.4,5.6,1.3,5,1.3 M5,8.7c2.1,0,3.7-1.7,3.7-3.7c0-1.1-0.5-2.1-1.2-2.8l-4.2,6
	          C3.8,8.6,4.4,8.7,5,8.7z" />
          </svg>
        </span>
        <ng-template #elseBlock>
          <mat-icon class="icon" [ngStyle]="setStyle(chip)">{{'circle'}}</mat-icon>
        </ng-template>
        {{ chip.value === noChip.id ? ('noChip' | translate) : chip.label }}
      </span>
    </mat-checkbox>
  </div>
</div>

import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'app/generic-components/confirm-dialog/confirm-dialog.component';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { ConfirmDialogModel } from 'app/shared/models/confirm-dialog.model';
import { loadAdvancedSearch, resetAdvancedSearchResponse, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import * as AdvancedSearchActions from 'app/state/actions/advanced-search.action';
import { AppState } from 'app/state/app.state';
import { CustomSnackBarService } from 'custom-snack-bar';

@Component({
  selector: 'app-saved-request-resume',
  templateUrl: './saved-request-resume.component.html',
  styleUrls: ['./saved-request-resume.component.scss']
})
export class SavedRequestResumeComponent implements OnInit, AfterViewChecked {

  @Input() advancedSearch: AdvancedSearchViewModel;

  private dialogTitle: string;
  private dialogMessage: string;
  private errorMessage: string;
  private deleteSuccessMessage: string;

  public customHeight = 'auto';
  public loadingCriteria: boolean;

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly snackbarService: CustomSnackBarService,
    private readonly translateService: TranslateService,
    private readonly dialogManager: DialogsManager,
    private readonly store: Store<AppState>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) { }

  public ngOnInit() {
    this.translateService.get(['deleteRequestConfirmationLabel', 'deleteConfirmationTitle', 'error', 'deleteSuccess']).subscribe(translations => {
      this.dialogMessage = translations.deleteRequestConfirmationLabel;
      this.dialogTitle = translations.deleteConfirmationTitle;
      this.errorMessage = translations.error;
      this.deleteSuccessMessage = translations.deleteSuccess;
    });
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public get isActive() {
    return this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.id === this.advancedSearch.id;
  }

  /** Ouvre le dialog d'enregistrement de la recherche avancée */
  public modifyAdvancedSearch() {
    const data = {
      advancedSearch: this.advancedSearch
    };
    this.dialogsManager.openDialog({ dialog: Dialogs.SavedRequest, closeClickOutside: false, data });
  }

  /** Charge la recherche avancée dans le state */
  public loadAdvancedSearch() {
    // Réinitialiser les candidats sélectionnés
    this.store.dispatch(resetSelectCandidates());
    this.store.dispatch(loadAdvancedSearch({ payload: this.advancedSearch.id }));
    // Réinitialiser le scroll du menu des critères
    this.initialiseCriteriaMenuScroll();
  }

  /** Supprime la recherche avancée */
  public deleteAdvancedSearch() {
    // Préparation de la dialog de confirmation de suppression
    const dialogData = new ConfirmDialogModel(this.dialogTitle, this.dialogMessage);

    // Ouvrir la confirmation
    this.dialogManager.openDialog<ConfirmDialogComponent>({ dialog: Dialogs.ConfirmDialog, closeClickOutside: false, data: dialogData });
    const dialogRef = this.dialogManager.getDialogRef(Dialogs.ConfirmDialog);

    // Lancer la suppression si l'utilisateur confirme
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // dispatcher l'action pour récupérer les recherches sauvegardés
        this.store.dispatch(AdvancedSearchActions.deleteAdvancedSearch({ payload: this.advancedSearch.id }));

        // Récupérer l'etat du store pour afficher le message
        const searcheObservable$ = this.store.select(data => data.advancedSearchState);
        searcheObservable$.subscribe(data => {
          if (data.dataState === StateStatus.DELETED) {
            this.snackbarService.showSuccess(this.deleteSuccessMessage);

            if (this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.id === this.advancedSearch.id) {
              this.store.dispatch(resetAdvancedSearchResponse());
            }

          } else if (data.dataState === StateStatus.ERRORDELETING) {
            this.snackbarService.showDanger(this.errorMessage);
          }
        });
      }
    });
  }

  public get criterias(): Array<RefinedCriterionViewModel> {
    const advancedSearches = this.advancedSearchManager.advancedSearchState.advancedSearches;
    const index = advancedSearches.findIndex(search => search.id === this.advancedSearch.id);

    if (
      index !== -1 &&
      advancedSearches[index]?.criteria.length > 0
    ) {
      this.loadingCriteria = false;
      return advancedSearches[index].criteria;
    }

    return [];
  }

  public getAdvancedSearchCriteria() {
    if (this.advancedSearch.criteria.length === 0) {
      this.store.dispatch(AdvancedSearchActions.loadCriterionByAdvancedSearchId({ payload: this.advancedSearch.id }));
    }

    this.loadingCriteria = this.advancedSearchManager.advancedSearchState.dataState === StateStatus.LOADINGCRITERION;
  }

  private initialiseCriteriaMenuScroll() {
    const criteriaMenu = document.getElementById('scrollCriteria');
    if (criteriaMenu) {
      criteriaMenu.style.right = `0px`;
    }
  }
}

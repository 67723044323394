import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-criterion-semantic-city-radius',
  templateUrl: './criterion-semantic-city-radius.component.html',
  styleUrls: ['./criterion-semantic-city-radius.component.scss']
})
export class CriterionSemanticCityRadiusComponent implements OnInit {
  @Input() identifier: string;
  @Input() isExpanded ?= true;
  @Input() sliderCurrentValue: number;
  @Output() radiusChanged = new EventEmitter<number>();

  // Périmètre minimum et maximum
  public readonly minRadius = 0;
  public readonly maxRadius = 75;
  public readonly stepRadius = 1;

  public ngOnInit(): void {
    // On communique la valeur du rayon de recherche
    this.radiusChanged.emit(this.sliderCurrentValue);
  }

  public onSliderChanged(event): void {
    this.sliderCurrentValue = event.value;
    // On communique aux autres composants le nouveau périmètre
    this.radiusChanged.emit(this.sliderCurrentValue);
  }

  public onInputChange(): void {
    // Si la valeur entrée par l'utilisateur est bien comprise dans l'interval min/max du périmètre, et qu'elle n'est pas vide
    if (
      this.sliderCurrentValue <= this.maxRadius
      && this.sliderCurrentValue >= this.minRadius
      && (this.sliderCurrentValue || this.sliderCurrentValue === 0)
    ) {
      this.radiusChanged.emit(this.sliderCurrentValue);
    }
  }
}

<button class="close m-3" matDialogClose>
  <mat-icon appIgnoreCloseDialog>close</mat-icon>
</button>
<ng-container *ngIf="isValidHtmlDocument | async; else elseBlock">
  <div class="m-3">
    <div *ngFor="let criterion of chips | async" class="criteriaChipsList">
      <mat-chip-list [multiple]="true" selectable>
        <mat-chip class="criterionChip" [value]="criterion" (click)="chipClick(chip)" #chip="matChip">
          {{ criterion.value }} ({{criterion.count}})
        </mat-chip>
        <ng-container *ngIf="criterion.childs">
          <mat-chip *ngFor="let criterionChild of criterion.childs" class="criterionChip criterionChipChild"
                    [value]="criterionChild" (click)="chipClick(chip)" #chip="matChip">
            {{ criterionChild.value }} ({{criterionChild.count}})
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
  </div>
  <div class="previewContainer">
    <div class="scroll-wrapper">
      <div class="scroll">
        <div scrollViewport [innerHtml]="(attachment | async)?.preview | highlight : searchKeywords | htmlSecurityByPasser">
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template class="previewContainer" #elseBlock>{{'noPreview' | translate }}</ng-template>

import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionManager } from 'app/shared/managers/action.manager';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { IndividualManager } from 'app/shared/managers/individual.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { ActionFollowUpService, CriterionCategory, CriterionPriority, IndividualModel, OrganizationService, StringHelper, QueryableModel, DataDepthLevel, SortOrder } from 'common-services';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-criteria-group-last-status',
  templateUrl: './criteria-group-last-status.component.html'
})
export class CriteriaGroupLastStatusComponent implements OnInit {

  public criterionCategory = CriterionCategory;
  public lastStepLabel: string;
  public emptyOptionLabel: string;
  public lastActionFollowUpTypesHeaders: Array<KeyValue<string, Array<KeyValue<number, string>>>>;
  public responsibleControl = new FormControl();
  public responsibleOptions: Array<IndividualModel> = [];
  public responsibleCompletionOptions: ReadonlyArray<IndividualModel> = [];

  constructor(
    public readonly advancedSearchManager: AdvancedSearchManager,
    private readonly individualManager: IndividualManager,
    private readonly organizationService: OrganizationService,
    private readonly actionFollowUpService: ActionFollowUpService,
    private readonly actionManager: ActionManager,
    private readonly translateService: TranslateService,
    private readonly stringHelper: StringHelper,
    private readonly store: Store<AppState>
  ) { }

  public ngOnInit() {
    this.translateService.get(['lastStep', 'chooseOption']).subscribe(translations => {
      this.lastStepLabel = translations['lastStep'];
      this.emptyOptionLabel = translations['chooseOption'];
    });

    this.storeActionTypes();

    this.actionFollowUpService.getActionFollowUpTypes().then(actions => {
      this.lastActionFollowUpTypesHeaders = this.actionManager.getActionsTypes(actions);
    });

    const query: QueryableModel = {
      whereExpression: 'i => i.licenses.any(l=> l.applicativeAreaCode=20)',
      dataDepthLevel: DataDepthLevel.Flat,
      sortExpression: 'firstName',
      sortOrder: SortOrder.Ascending.toString(),
      page: 0,
      pageSize: 10000
    };

    this.organizationService.findIndividualsByQueryable(query)
      .then(result => {
        // On récupère tous les individus qui ont un nom ou un prénom
        this.responsibleOptions = result.items.filter(i => i.firstName || i.lastName);
        this.responsibleCompletionOptions = this.responsibleOptions;
      });

    this.responsibleControl.valueChanges.pipe(
      startWith(this.responsibleControl.value),
      map(individual => {
        // S'il n'y a pas encore de prénom/nom de responsable entré
        if (!individual) {
          return '';
        }
        return typeof individual === 'string' ? individual : this.individualManager.responsibleDisplayFunction(individual);
      }
      )).subscribe(name => {
        // On filtre s'il y a une recherche en cours, sinon on renvoie le tableau complet
        this.responsibleCompletionOptions = this.individualManager.filterUser(name, this.responsibleOptions);
      });
  }

  /**
   * Ajoute le critère raffiné du dernier suivi d'action
   * @param selectedLastActionFollowUpType Le dernier suivi d'action sélectionné
   */
  public onLastActionFollowUpTypeChange(
    selectedLastActionFollowUpType: number
  ) {
    for (const header of this.lastActionFollowUpTypesHeaders) {
      if (header.value) {
        for (const actionType of header.value) {
          if (actionType.key === selectedLastActionFollowUpType) {
            // On ajoute le critère à la liste des critères raffinés du state
            const refinedCriterion = new RefinedCriterionViewModel(
              CriterionCategory.LastActionFollowUp,
              actionType.key.toString(),
              CriterionPriority.Required
            );

            // Réinitialiser les candidats sélectionnés
            this.store.dispatch(resetSelectCandidates());

            this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));

            return;
          }
        }
      }
    }
  }

  public addLastActionResponsibleCriterion(individual: IndividualModel) {
    // On vérifie que la recherche ne soit pas nulle ou vide
    if (!this.stringHelper.isNullOrWhiteSpace(individual.userGuid)) {
      const refinedCriterion = new RefinedCriterionViewModel(
        CriterionCategory.LastActionUser,
        individual.id.toString(),
        CriterionPriority.Required
      );

      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());

      this.store.dispatch(addRefinedCriterion({ payload: refinedCriterion }));
    }
    // On vide l'input après l'ajout
    this.responsibleControl.reset('');
  }

  public displayUser(individual: IndividualModel): string {
    return this.individualManager.responsibleDisplayFunction(individual);
  }

  private storeActionTypes() {
    this.actionFollowUpService.getActionFollowUpTypes().then(data => {
      localStorage.setItem('actionTypes', JSON.stringify(data));
    });
  }
}

<div  *ngIf="percentageOfScore != ''">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 48 48" class="img_score" matTooltip="{{ percentageOfScore }}" matTooltipPosition="right">
    <g [class.score1]="hasscore1" [class.score2]="hasscore2" [class.score3]="hasscore3" [class.score4]="hasscore4" [class.score5]="hasscore5">
      <rect class="bg_bar" y="0" x="0" height="25" width="30" />
      <rect class="bar bar1" y="16" x="2" height="8" width="3" />
      <rect class="bar bar2" y="13.5" x="8" height="10.5" width="3" />
      <rect class="bar bar3" y="11" x="14" height="13" width="3" />
      <rect class="bar bar4" y="8.5" x="20" height="15.5" width="3" />
      <rect class="bar bar5" y="6" x="26" height="18" width="3" />
    </g>
  </svg>
  <span class="material-icons loupe">
    search
  </span> 
</div>


import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CandidateModule } from 'app/business-components/candidate/candidate.module';
import { CandidateDetailsModule } from 'app/pages/candidate-form/candidate-details/candidate-details.module';
import { CandidateContainerComponent } from 'app/pages/candidate-form/candidate-modal/candidate-container/candidate-container.component';
import { CandidateModalModule } from 'app/pages/candidate-form/candidate-modal/candidate-modal.module';
import { AttachedDocumentsComponent } from './attached-documents/attached-documents.component';
import { AttachmentPopupComponent } from './attachment-popup/attachment-popup.component';
import { CandidateResultsComponent } from './candidate-results/candidate-results.component';
import { CandidateResumeComponent } from './candidate-resume/candidate-resume.component';
import { CandidateScoringComponent } from './candidate-scoring/candidate-scoring.component';
import { CustomWebSearchComponent } from './custom-web-search/custom-web-search.component';

// Ajoutez vos components ici
const components = [
  CandidateResultsComponent,
  CandidateResumeComponent,
  CandidateScoringComponent,
  AttachedDocumentsComponent,
  AttachmentPopupComponent,
  CustomWebSearchComponent
];

// Ajoutez vos modules ici
const businessModules = [
  CandidateModule
];

const modules = [
  CandidateDetailsModule,
  CandidateModalModule,
  TranslateModule
];

@NgModule({
  declarations: [
    ...components
  ],
  entryComponents: [
    AttachmentPopupComponent,
    CandidateContainerComponent
  ],
  imports: [
    ...modules,
    ...businessModules
  ],
  exports: [
    ...businessModules,
    ...components
  ]
})
export class SearchResultsModule { }

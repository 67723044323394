import { Component, Input } from '@angular/core';
import { AttachmentFileModel } from 'common-services';

@Component({
  selector: 'app-attached-documents',
  templateUrl: './attached-documents.component.html',
  styleUrls: ['./attached-documents.component.scss']
})
export class AttachedDocumentsComponent {
  @Input() attachments: Array<AttachmentFileModel>;
  @Input() enablePreview: boolean;
  @Input() candidateId: number;

  constructor(
  ) { }

  public trackByFunction(index: number, attachment: AttachmentFileModel) {
    if (!attachment) { return null; }
    return index;
  }
}

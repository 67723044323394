import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { CandidateModel, CandidateService, ChipModel, ChipService, IndexingService } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidate-chip',
  templateUrl: './candidate-chip.component.html',
  styleUrls: ['./candidate-chip.component.scss']
})
export class CandidateChipComponent implements OnInit {
  @Input() candidateObservable: Observable<CandidateModel>;

  private candidateModel: CandidateModel;
  private successfulOperationMessage: string;
  private unsuccessfulOperationMessage: string;

  public chips: ReadonlyArray<ChipModel>;
  public selectedChipId: number;

  constructor(
    private readonly chipService: ChipService,
    private readonly candidateService: CandidateService,
    private readonly indexingService: IndexingService,
    private readonly translateService: TranslateService,
    private readonly snackbarService: CustomSnackBarService,
    private readonly advancedSearchManager: AdvancedSearchManager
  ) {
    this.chipService.getCandidateChips()
      .then(data => {
        this.chips = data;
      });
  }

  ngOnInit(): void {
    this.translateService.get(['chipSaved', 'error']).subscribe(translations => {
      this.successfulOperationMessage = translations.chipSaved;
      this.unsuccessfulOperationMessage = translations.error;
    });

    this.candidateObservable.subscribe(candidate => {
      this.candidateModel = candidate;
      this.selectedChipId = this.candidateModel.chipId || -1;
    });
  }

  public get selectedChip(): ChipModel {
    if (this.chips && this.selectedChipId > -1) {
      return this.chips.find(chip => chip.id === this.selectedChipId);
    }
    return undefined;
  }

  private get candidateToUpdate(): CandidateModel {
    const candidateClone = Object.assign({}, this.candidateModel);
    candidateClone.attachmentsFiles = null;
    candidateClone.candidatures = null;
    candidateClone.chip = null;
    candidateClone.socialNetworkLinks = null;
    return candidateClone;
  }

  public updateChip(chipId: number): void {
    if (chipId === -1) {
      chipId = undefined;
    }
    this.candidateModel.chip = this.selectedChip;
    if (this.candidateModel.chipId === chipId) {
      return;
    }
    this.candidateModel.chipId = chipId;
    this.candidateService.updateCandidate(this.candidateToUpdate)
      .then(() => {
        this.indexingService.indexCandidateById(this.candidateToUpdate.id);
        this.snackbarService.showSuccess(this.successfulOperationMessage);
        const candidateIndex = this.advancedSearchManager.candidatesMatches.findIndex(c => c.candidate.id === this.candidateToUpdate.id);
        if (candidateIndex !== -1) {
          const candidate = this.advancedSearchManager.candidatesMatches[candidateIndex].candidate;
          candidate.chipId = chipId;
          candidate.chip = this.selectedChip;
          this.advancedSearchManager.updateCandidate(candidate);
        }
      })
      .catch(() => this.snackbarService.showDanger(this.unsuccessfulOperationMessage));
  }

}

import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { addRefinedCriterion, resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CandidateService, CriterionCategory, CriterionPriority, DynamicFieldDtoModel, DynamicFieldEntity, DynamicFieldInputControlType, DynamicFieldService } from 'common-services';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-criteria-group-tag-clouds',
  templateUrl: './criteria-group-tag-clouds.component.html'
})
export class CriteriaGroupTagCloudsComponent implements OnInit, OnDestroy {

  private readonly filteredTagClouds: Array<string>;
  private readonly tagCloundsSubscription: Subscription;
  public criterionCategory = CriterionCategory;
  public tagCloudsFormControl: FormControl;
  public filteredTagCloudsObservable: Observable<Array<string>>;
  public dynamicFieldsConfigurations: Array<DynamicFieldDtoModel>;
  public dynamicFieldsLists: Array<KeyValue<number, string>>;
  public dynamicFieldsBooleanLists: Array<KeyValue<number, string>>;
  public dynamicFieldPredefinedValues: Array<KeyValue<number, string>>;
  public dynamicFieldLabel = '';
  public dynamicFieldListsLabel: string;
  public dynamicFieldBooleanListsLabel: string;
  public noOptionAvailableLabel: string;
  public emptyOptionLabel: string;
  public isLoading: boolean;

  constructor(
    private readonly candidateService: CandidateService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>,
    private readonly dynamicFieldService: DynamicFieldService
  ) {
    this.filteredTagClouds = new Array<string>();
    this.filteredTagCloudsObservable = of(this.filteredTagClouds);
    this.tagCloudsFormControl = new FormControl();
    this.tagCloundsSubscription = this.tagCloudsFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(
      (filter: string) => {
        if (filter && filter.length >= 3) {
          this.isLoading = true;
          this.candidateService.findCandidateDynamicFieldSelectedValuesStartingBy(filter).then(filteredTagClouds => {
            this.filteredTagClouds.splice(0, this.filteredTagClouds.length);
            this.filteredTagClouds.push(...filteredTagClouds);
            this.isLoading = false;
          });
        } else {
          this.filteredTagClouds.splice(0, this.filteredTagClouds.length);
        }
      }
    );
  }

  public ngOnInit() {
    // Obtient les traductions
    this.translateService.get(['tagCloudCategory', 'tagCloudBooleanCategory', 'noOptionAvailable', 'chooseOption']).subscribe(translations => {
      this.dynamicFieldListsLabel = translations.tagCloudCategory;
      this.dynamicFieldBooleanListsLabel = translations.tagCloudBooleanCategory;
      this.noOptionAvailableLabel = translations.noOptionAvailable;
      this.emptyOptionLabel = translations.chooseOption;
    });

    this.dynamicFieldService.getDynamicFieldConfigurationsByEntity(DynamicFieldEntity.Candidate).then((data) => {
      // Récupérer les configurations des champs dynamiques
      this.dynamicFieldsConfigurations = data;
      // Filtrer les champs dynamiques de type 'Select' (Listes) non boolean
      this.dynamicFieldsLists = this.dynamicFieldsConfigurations
        .filter(dfc => dfc.inputControlType === DynamicFieldInputControlType.Select && !dfc.predefinedValues
          .map(pv => (pv.value).toLowerCase()).includes('oui'))
        .map(dynamicFieldCategory => ({ key: dynamicFieldCategory.id, value: dynamicFieldCategory.label }));

      // Filtrer les champs dynamiques boolean
      this.dynamicFieldsBooleanLists = this.dynamicFieldsConfigurations
        .filter(dfc => dfc.inputControlType === DynamicFieldInputControlType.Select && dfc.predefinedValues
          .map(pv => (pv.value).toLowerCase()).includes('oui'))
        .map(dynamicFieldCategory => ({ key: dynamicFieldCategory.id, value: dynamicFieldCategory.label }));
    }
    );
  }

  public search(value: string) {
    if (value) {
      // Réinitialiser les candidats sélectionnés
      this.store.dispatch(resetSelectCandidates());
      this.store.dispatch(addRefinedCriterion({
        payload: new RefinedCriterionViewModel(CriterionCategory.TagCloudsOthers, value, CriterionPriority.Required)
      }));
      this.tagCloudsFormControl.reset(null, { onlySelf: true, emitEvent: false });
    }
  }

  public onTagCloudsCategoryChange(selectedTagCloudCategory: number) {
    // Récupérer le champ dynamique choisie dans les catégories
    const selected = this.dynamicFieldsLists.find(a => a.key === selectedTagCloudCategory);
    // Récupérer les label du champ
    if (selected && selected.key > 0) {
      this.dynamicFieldLabel = selected.value;
      // Récupérer les predefined values par Id du champ
      this.dynamicFieldPredefinedValues = this.dynamicFieldsConfigurations
        .find(dfc => dfc.id === selectedTagCloudCategory).predefinedValues
        .map(
          dynamicFieldPredefinedValue => ({ key: dynamicFieldPredefinedValue.id, value: dynamicFieldPredefinedValue.value }));
    } else {
      this.dynamicFieldLabel = '';
      this.dynamicFieldPredefinedValues = [];
    }
  }

  public onPredefinedValueChanged(selectedValue: number) {
    const selected = this.dynamicFieldPredefinedValues.find(a => a.key === selectedValue);
    if (selected && selected.key > 0) {
      this.search(selected.value);
    }
  }

  public onTagCloudsBooleanCategoryChanged(selectedValue: number) {
    const selected = this.dynamicFieldsBooleanLists.find(a => a.key === selectedValue);
    if (selected && selected.key > 0) {
      this.search(selected.value);
    }
  }

  ngOnDestroy(): void {
    if (this.tagCloundsSubscription) {
      this.tagCloundsSubscription.unsubscribe();
    }
  }
}

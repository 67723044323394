import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { SelectedItemModel } from 'app/shared/models/selected-item.model';
import { resetSelectCandidates } from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { CriterionCategory, EmployabilitiesService } from 'common-services';

@Component({
  selector: 'app-criteria-group-experience',
  templateUrl: './criteria-group-experience.component.html'
})
export class CriteriaGroupExperiencesComponent implements OnInit {

  private _experienceLevels: Array<SelectedItemModel>;

  public criterionCategory = CriterionCategory;

  constructor(
    public readonly advancedSearchManager: AdvancedSearchManager,
    private readonly store: Store<AppState>,
    private readonly employabilitiesService: EmployabilitiesService
  ) { }

  ngOnInit() {
    this.employabilitiesService.getExperienceLevels().then(data => {
      localStorage.setItem('experiences', JSON.stringify(data));
      this._experienceLevels = data.map(experienceLevel => new SelectedItemModel(experienceLevel.id, experienceLevel.label));
    });
  }

  public get experienceLevels(): Array<SelectedItemModel> {
    if (this.advancedSearchManager.advancedSearchResponseState.dataState === StateStatus.LOADED) {
      const selectedItems = this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria
      .filter(c => c.category === CriterionCategory.ExperienceLevel).map(c => c.value );

      // On met à jour la liste des options du select
      return this._experienceLevels?.map(
        experience => {
          experience.selected = selectedItems?.includes(experience.value.toString());
          return experience;
        }
      );
    }

    return this._experienceLevels;
  }

  public onExperienceLevelChange(checkedExperiencesLevel: Array<number>) {
    this.store.dispatch(resetSelectCandidates());
    // On ajoute les éléments sélectionnés à la liste des critères raffinés du state
    this.advancedSearchManager.addRefinedCriteria(
      CriterionCategory.ExperienceLevel,
      this._experienceLevels
        .filter(checkBoxItem => checkedExperiencesLevel.indexOf(checkBoxItem.value) !== -1)
        .map(checkBoxItem => checkBoxItem.value.toString()),
      CriterionPriority.Optional
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'htmlSecurityByPasser'
})
export class HtmlSecurityByPasserPipe implements PipeTransform {

    constructor(private readonly _sanitizer: DomSanitizer) {
    }

    transform(html: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }
}

import { NgModule } from '@angular/core';
import { CandidateModule } from 'app/business-components/candidate/candidate.module';
import { AngularMaterialsModule } from 'app/shared/angular-materials.module';
import { SavedRequestComponent } from '../saved-requests/saved-request/saved-request.component';
import { CriterionSemanticCityRadiusComponent } from './criterion-semantic-city-radius/criterion-semantic-city-radius.component';
import { CriterionSemanticCustomSynonymComponent } from './criterion-semantic-custom-synonym/criterion-semantic-custom-synonym.component';
import { CriterionSemanticComponent } from './criterion-semantic/criterion-semantic.component';
import { RefinedCriteriaMenuComponent } from './refined-criteria-menu/refined-criteria-menu.component';
import { RefinedCriterionComponent } from './refined-criterion/refined-criterion.component';

// Ajoutez vos components ici
const components = [
  CriterionSemanticComponent,
  CriterionSemanticCityRadiusComponent,
  RefinedCriteriaMenuComponent,
  RefinedCriterionComponent,
  CriterionSemanticCustomSynonymComponent
];

// Ajoutez vos modules ici
const modules = [
  CandidateModule,
  AngularMaterialsModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...components,
    ...modules
  ],
  entryComponents: [
    SavedRequestComponent
  ]
})
export class RefinedCriteriaModule { }

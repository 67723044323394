import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedSearchRequestSource } from 'app/shared/enums/advanced-search-request-source.enum';
import { AdvancedSearchSorting } from 'app/shared/enums/advanced-search-sorting.enum';
import { Dialogs } from 'app/shared/enums/dialogs-enum';
import { SortOrder } from 'app/shared/enums/sort-order.enum';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { DialogsManager } from 'app/shared/managers/dialogs.manager';
import { MatchResultModel } from 'app/shared/models/advanced-search/match-result.model';
import { AdvancedSearchResponseState } from 'app/shared/models/state/advanced-search-response-state.model';
import {
resetSelectCandidates,
searchCandidatesByPositionIdOrUri,
searchCandidatesByPositionIdOrUriSuccess,
selectCandidate,
selectCandidates,
unSelectCandidates,
updateStateStatus
} from 'app/state/actions/advanced-search-response.action';
import { AppState } from 'app/state/app.state';
import { NavigatorHelper } from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CandidateResultsSortOption } from './candidate-results-sort-option.model';

@Component({
  selector: 'app-candidate-results',
  templateUrl: './candidate-results.component.html',
  styleUrls: ['./candidate-results.component.scss']
})
export class CandidateResultsComponent implements OnInit, OnDestroy {
  @ViewChild('candidatesDisplayGroup') public candidatesDisplayGroup: ElementRef<HTMLElement>;

  private readonly searchSubject: Subject<AdvancedSearchRequestSource>;
  private readonly subscriptions: Subscription;
  private hasMoreResults: boolean;
  private selectedCandidates: Array<number>;
  private _isSelectAllChecked: boolean;
  private _isSelectAllIndeterminated: boolean;
  private _displayPagination = true;
  private errorMessage: string;
  public isScrolled = false;
  public isSearching = false;
  public pageSize = 20;
  public labelSortSelect: string;

  // KeyValue : en clé le libellé d'option de select, en valeur un tri
  public readonly sortOptions: Array<KeyValue<CandidateResultsSortOption, string>> = [];
  public selectedSort: CandidateResultsSortOption;

  public get isInfiniteScroll(): boolean {
    return environment.isInfiniteScroll;
  }

  public get isSelectAllChecked(): boolean {
    return this._isSelectAllChecked;
  }

  public set isSelectAllChecked(value: boolean) {
    this._isSelectAllChecked = value;
  }

  public get isSelectAllIndeterminated(): boolean {
    return this._isSelectAllIndeterminated;
  }

  public get disableSortCandidates(): boolean {
    return this.candidatesMatches.filter(match => match.candidate.visibility).length < 2;
  }

  public get isDisabled(): boolean {
    return this.candidatesMatches.filter(match => match.candidate.visibility).length < 1 || this.isSearching;
  }

  public get candidatesCount(): number {
    if (this.advancedSearchManager.advancedSearchResponseState.dataState === StateStatus.ERRORCLONIG) {
      return 0;
    }

    return this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.matchSize ?? 0;
  }

  public get candidatesMatches(): MatchResultModel[] {
    return this.advancedSearchManager.candidatesMatches?.filter(candidateMatch => candidateMatch.candidate.emailAddress !== null) ?? [];
  }

  public get displayPagination(): boolean {
    return this.candidatesCount > 0 && !this.isInfiniteScroll && this._displayPagination;
  }

  public get sortOptionsKeys() {
    return this.sortOptions.map(option => option.key);
  }

  constructor(
    private readonly dialogsManager: DialogsManager,
    private readonly navigatorHelper: NavigatorHelper,
    private readonly translateService: TranslateService,
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly cdRef: ChangeDetectorRef,
    private readonly snackbarService: CustomSnackBarService
  ) {
    this.subscriptions = new Subscription();
  }

  public ngOnInit(): void {
    this.searchCandidatesByUriParams();

    this.translateStrings();

    const searcheCandidatesObservable$ = this.store.select(data => data.advancedSearchResponseState);
    // Récupérer les candidats depuis le store
    const searchCandidatesSubscription = searcheCandidatesObservable$.subscribe(advancedSearchResponseState => {
      this.hasMoreResults = advancedSearchResponseState?.advancedSearchResponse?.hasMoreResults;

      switch (advancedSearchResponseState.dataState) {
        case StateStatus.LOADED:
          // Scroller automatiquement vers le haut de la page lorsqu' on est en mode pagination
          if (!this.isInfiniteScroll && this.candidatesDisplayGroup) {
            this.candidatesDisplayGroup.nativeElement.scrollTop = 0;
          }
          break;

        case StateStatus.ERRORLOADING:
          this.snackbarService.showDanger(this.errorMessage);
          break;

        case StateStatus.RELOAD:
        case StateStatus.INITIAL:
          // Recharger les candidats
          this.advancedSearchManager.fetchCandidates();
          break;

        default:
          break;
      }

      // Afficher ou cacher la barre de chargement ( progress bar )
      this.toggleprogressBar(advancedSearchResponseState.dataState);

      // Vérifier la sélection des candidats
      const candidates = _.cloneDeep(advancedSearchResponseState?.selectedCandidates);
      this.selectedCandidates = candidates.map(c => c.id);
      this.manageSelectCandidatesCheckbox(advancedSearchResponseState);
    });

    this.subscriptions.add(searchCandidatesSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private searchCandidatesByUriParams(): void {
    const url = new URL(document.URL);
    // Récupération de l'ID du poste depuis URL pour le match poste candidats
    const positionIdParam = url.searchParams.get('positionId');
    const positionId = (positionIdParam) ? parseInt(positionIdParam, 10) : 0;
    // Récupération de la langue du poste depuis Url pour le match poste candidats
    const codeLangueParam = url.searchParams.get('codeLanguage');
    const codeLanguage = (codeLangueParam) ?? '';
    // Récupération d'URL du poste depuis URL pour le match poste candidats
    const positionUriParam = url.searchParams.get('positionUrl');
    const positionUri = this.navigatorHelper.isValidUrl(positionUriParam) ? positionUriParam : '';

    // Si un identifiant ou l'uri de poste est fourni, alors on va rechercher des candidats selon les informations données
    if ((positionId && codeLanguage) || positionUri) {
      // Suppression des parametres get depuis Url
      this.navigatorHelper.removeGetParametersFromCurrentHistoryUrl();

      this.store.dispatch(
        searchCandidatesByPositionIdOrUri({
          payload: {
            positionId,
            codeLanguage,
            positionUri
          }
        })
      );

      const actionSubscription = this.actions.pipe(
        ofType(searchCandidatesByPositionIdOrUriSuccess),
        first()
      ).subscribe(
        () => {
          // Changer le statut du state pour détecter les changements des critères
          this.store.dispatch(updateStateStatus({
            payload: StateStatus.LOADED
          }));
        }
      );

      this.subscriptions.add(actionSubscription);
    }
  }

  private manageSelectCandidatesCheckbox(advancedSearchResponseState: AdvancedSearchResponseState) {
    if (this.selectedCandidates && advancedSearchResponseState.advancedSearchResponse) {
      const candidates = this.candidatesMatches?.filter(match => match.candidate.visibility);

      this._isSelectAllChecked = this.selectedCandidates?.length > 0;

      if (candidates?.every(c => this.selectedCandidates.includes(c.candidate.id)) && candidates?.length > 0) {
        this._isSelectAllIndeterminated = false;
        this._isSelectAllChecked = true;
      } else {
        const index = advancedSearchResponseState.advancedSearchResponse.candidatesMatches?.findIndex(
          result => this.selectedCandidates.includes(result.candidate.id)
        );

        this._isSelectAllIndeterminated = index && (index !== -1);
        this._isSelectAllChecked = false;
      }

    }
  }

  private toggleprogressBar(dataState: StateStatus) {
    const searching = [
      StateStatus.LOADING,
      StateStatus.ADDING,
      StateStatus.UPDATING,
      StateStatus.INITIAL
    ].includes(dataState);
    
    if (this.isSearching !== searching) {
      this.isSearching = searching;
      this.cdRef.detectChanges();
    }
  }

  private translateStrings() {
    const translateSubscription = this.translateService.get([
      'relevance',
      'lastActionDateDesc',
      'lastActionDateAsc',
      'error',
      'orderBy'
    ]).subscribe(translation => {
      this.errorMessage = translation['error'];
      this.labelSortSelect = translation['orderBy'];

      this.sortOptions.push(
        { key: new CandidateResultsSortOption(AdvancedSearchSorting.None, SortOrder.None), value: translation['relevance'] },
        { key: new CandidateResultsSortOption(AdvancedSearchSorting.CandidateActionFollowUpDate, SortOrder.Descending), value: translation['lastActionDateDesc'] },
        { key: new CandidateResultsSortOption(AdvancedSearchSorting.CandidateActionFollowUpDate, SortOrder.Ascending), value: translation['lastActionDateAsc'] }
      );

      this.selectedSort = this.sortOptions[0]?.key;
    });

    this.subscriptions.add(translateSubscription);
  }

  public candidateReverseState(id : number) {
    this.store.dispatch(selectCandidate({ payload: id }));
  }

  /**
   * Actualise la recherche selon l'option choisi dans la liste déroulante du tri
   * @param option Les options de tri de candidats
   */
  public onSortChange(option: CandidateResultsSortOption): void {
    this._displayPagination = false;
    // désélectionner les candidates
    this.store.dispatch(resetSelectCandidates());
    // On modifie le tri de la recherche active
    this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortedElement = option.sortedElement;
    this.advancedSearchManager.advancedSearchResponseState.advancedSearchResponse.advancedSearch.sortOrder = option.sortOrder;

    this.advancedSearchManager.fetchCandidates();
    this._displayPagination = true;
  }

  public isMatchResultSelected(matchResultModel: MatchResultModel) {
    return this.selectedCandidates?.findIndex(id => id === matchResultModel.candidate.id) !== -1;
  }

  public getMoreResults() {
    if (this.hasMoreResults && !this.isSearching) {
      this.searchSubject.next(AdvancedSearchRequestSource.InfiniteScrollFetch);
    }
  }

  public onPaginate(page: number): void {
    const offset = (page - 1) * this.pageSize;
    this.advancedSearchManager.fetchCandidates(null, false, offset);
  }

  public openAddCandidateDialog() {
    this.dialogsManager.openDialog({ dialog: Dialogs.AddCandidateForm });
  }

  public openImportCVDialog() {
    this.dialogsManager.openDialog({ dialog: Dialogs.importCV });
  }

  // Sélectionner ou désélectionner tout les candidats
  public selectAllChanged() {
    const candidatesIds = this.candidatesMatches?.filter(match => match.candidate.visibility).map(c => c.candidate.id);

    const unselectedCandidates = candidatesIds?.filter(c => !this.selectedCandidates.includes(c));

    if (unselectedCandidates?.length === 0) {
      this.store.dispatch(unSelectCandidates({ payload: candidatesIds }));
    } else if (candidatesIds?.length > 0) {
      this.store.dispatch(selectCandidates({ payload: candidatesIds }));
    }
  }
}

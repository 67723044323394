import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from 'app/shared/models/state/action-with-payload.model';
import { AdvancedSearchModel, SearchesService } from 'common-services';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as AdvancedSearchActions from '../actions/advanced-search.action';

@Injectable()
export class AdvancedSearchEffects {

  constructor(
    private readonly searchesService: SearchesService,
    private readonly effectActions: Actions
  ) { }

  // Récupérer des recherches enregistrées via ( ngrx effect )
  getAdvancedSearches$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.GET_ADVANCED_SEARCH),
      mergeMap(() => {
        return this.searchesService.getAdvancedSearches().then(advancedSearches => {
          return AdvancedSearchActions.getAdvancedSearchSuccess({ payload: advancedSearches });
        }).catch(() => AdvancedSearchActions.getAdvancedSearchError());
      })
    )
  );

  // Ajouter une nouvelle recherche
  addAdvancedSearch$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.ADD_ADVANCED_SEARCH),
      mergeMap((action: ActionWithPayload<AdvancedSearchModel>) => {
        return this.searchesService.addAdvancedSearch(action.payload).then(search => {
          return AdvancedSearchActions.addAdvancedSearchSuccess({ payload: search });
        }).catch(() => AdvancedSearchActions.addAdvancedSearchError());
      })
    )
  );

  // Modifier une recherche enregistrée
  updateAdvancedSearchById$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.UPDATE_ADVANCED_SEARCH),
      mergeMap((action: ActionWithPayload<AdvancedSearchModel>) => {
        return this.searchesService.updateAdvancedSearch(action.payload).then(search => {
          return AdvancedSearchActions.updateAdvancedSearchSuccess({ payload: search });
        }).catch(() => AdvancedSearchActions.updateAdvancedSearchError());
      })
    )
  );

  // Cloner une recherche
  cloneAdvancedSearch: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.CLONE_ADVANCED_SEARCH),
      mergeMap((action: ActionWithPayload<AdvancedSearchModel>) => {
        return this.searchesService.cloneAdvancedSearch(action.payload).then(search => {
          return AdvancedSearchActions.cloneAdvancedSearchSuccess({ payload: search });
        }).catch(() => AdvancedSearchActions.cloneAdvancedSearchError());
      })
    )
  );

  // Supprimer une recherche enregistrée
  deleteAdvancedSearchById$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.DELETE_ADVANCED_SEARCH),
      mergeMap((action: ActionWithPayload<number>) => {
        return this.searchesService.deleteAdvancedSearchById(action.payload).then(() => {
          return AdvancedSearchActions.deleteAdvancedSearchSuccess({ payload: action.payload });
        }).catch(() => AdvancedSearchActions.deleteAdvancedSearchError());
      })
    )
  );

  // Récupération de AdvancedSearchResponseViewModel via l'id d'une recherche enregistrée  
  loadAdvancedSearchById$: Observable<Action> = createEffect(
    () => this.effectActions.pipe(
      ofType(AdvancedSearchActions.AdvancedSearchActionsTypes.LOAD_CRITERION_BY_ADVANCED_SEARCH_ID),
      mergeMap(async (action: ActionWithPayload<number>) => {
        return this.searchesService.loadAdvancedSearchById(action.payload)
          .then(response => AdvancedSearchActions.loadCriterionByAdvancedSearchIdSuccess({ payload: response.advancedSearch }))
          .catch(() => AdvancedSearchActions.loadCriterionByAdvancedSearchIdIdError());
      })
    )
  );
}

<button class="close" matDialogClose>
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title class="text-uppercase text-primary font-weight-bold mr-5">
  {{ 'save' | translate  }}
</h1>
<mat-dialog-content>
  <form [formGroup]="form" (keyup.enter)="saveAdvancedSearch()">
    <div *ngIf="isFormSubmitted && form.invalid">
      <div *ngIf="propDescription.hasError('required')">
        {{ 'label' | translate  }} {{ 'requiredField' | translate  }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ 'label' | translate  }}</mat-label>
      <input [value]="data.advancedSearch?.label" formControlName="propDescription" matInput required>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-column w-100 m-auto">
  <div *ngIf="isSavingNewAdvancedSearch; else elseBlock" class="w-100">
    <button mat-button (click)="saveAdvancedSearch()" [disabled]="!form.valid || disabled" class="btn btn-primary w-100 text-white text-uppercase">
      <span>{{ 'save' | translate  }}</span>
    </button>
  </div>
  <ng-template #elseBlock>
    <div class="w-100">
      <button mat-button (click)="cloneAdvancedSearch()" [disabled]="!form.valid || disabled" class="btn btn-primary w-100 text-white text-uppercase">
        <span>{{ 'saveAsNewAdvancedSearch' | translate  }}</span>
      </button>
    </div>
    <div class="text-center text-grey font-italic my-3"><span class="or">{{ 'or' | translate  }}</span></div>
    <div class="w-100">
      <button mat-button (click)="saveAdvancedSearch()" [disabled]="!form.valid || disabled" class="btn btn-primary w-100 text-white text-uppercase">
        <span>{{ 'replaceCurrentAdvancedSearch' | translate  }}</span>
      </button>
    </div>
  </ng-template>
</mat-dialog-actions>

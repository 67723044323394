import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CandidateModule } from 'app/business-components/candidate/candidate.module';
import { AngularMaterialsModule } from 'app/shared/angular-materials.module';
import { CandidateActionComponent } from './candidate-action/candidate-action.component';
import { CandidateActionsComponent } from './candidate-actions/candidate-actions.component';
import { CandidateAttachmentsListComponent } from './candidate-attachments-list/candidate-attachments-list.component';
import { CandidateCandidaturesComponent } from './candidate-candidatures/candidate-candidatures.component';
import { CandidateExperiencesFormationsComponent } from './candidate-experiences-formations/candidate-experiences-formations.component';
import { CandidateExperiencesComponent } from './candidate-experiences/candidate-experiences.component';
import { CandidateFormationsComponent } from './candidate-formations/candidate-formations.component';
import { CandidateGroupedActionsComponent } from './candidate-grouped-actions/candidate-grouped-actions.component';
import { CandidateInformationsComponent } from './candidate-informations/candidate-informations.component';

const components = [
  CandidateActionComponent,
  CandidateActionsComponent,
  CandidateAttachmentsListComponent,
  CandidateCandidaturesComponent,
  CandidateExperiencesComponent,
  CandidateExperiencesFormationsComponent,
  CandidateFormationsComponent,
  CandidateGroupedActionsComponent,
  CandidateInformationsComponent
];

const modules = [
  AngularMaterialsModule,
  CandidateModule,
  ReactiveFormsModule
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components
  ]
})
export class CandidateDetailsModule { }

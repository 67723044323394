<div class="blocCriteria">  
  <div class="form-group">
    <app-select [identifier]="'criteria_group_language_select'"
                class="form-control-select-custom"
                [options]="candidatesLanguages"
                (selectedOptionsChanged)="onLanguageChange($event)"
                [emptyOption]="emptyOptionLabel"
                [isEmptyOptionHasValue]="true"
                [resetAtDefault]="true"
                [label]="label"></app-select>
  </div>
  <app-criteria-list [criterionCategory]="criterionCategory.LanguageSkills"></app-criteria-list>
</div>

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter, first, throttleTime } from 'rxjs/operators';
import { Dialogs } from '../enums/dialogs-enum';
import { DialogHelper } from '../helpers/dialog.helper';
import { DialogsManager } from '../managers/dialogs.manager';
import { EventsManager } from '../managers/events.manager';
import { DialogBehaviorHandler } from './dialog-behavior.handler';

@Injectable({
  providedIn: 'root'
})
export class DialogCloseOutsideBehaviorHandler extends DialogBehaviorHandler {

  public constructor(
    dialog: MatDialog,
    dialogHelper: DialogHelper,
    private readonly eventsManager: EventsManager
  ) {
    super(dialog, dialogHelper);
  }

  public handle<T>(dialogManager: DialogsManager, dialog: Dialogs, dialogConfig: MatDialogConfig): MatDialogRef<T> {
    const dialogRef = super.handle<T>(dialogManager, dialog, dialogConfig);
    let subscription: Subscription;
    dialogRef.afterOpened()
      .pipe(first())
      .subscribe(
        () => subscription = this.subscribeToMouseClick<T>(dialogManager, dialogRef)
      );
    dialogRef.afterClosed()
      .subscribe(
        () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        }
      );
    return dialogRef;
  }

  private subscribeToMouseClick<T>(dialogManager: DialogsManager, dialogRef: MatDialogRef<T>): Subscription {
    const container = this.dialogHelper.getContainerInstanceRef<T>(dialogRef);
    if (!container) {
      return undefined;
    }
    return this.eventsManager.mouseClick
      .pipe(
        throttleTime(50),
        filter(
          (click: MouseEvent) => {
            const targetElement = click.target as Element;
            return this.getDialogsClosingRoles<T>(dialogManager, targetElement);
          }))
      .subscribe(
        click => {
          const position = this.dialogHelper.getPosition(container.nativeElement);
          const x = click.clientX;
          const y = click.clientY;
          if (!this.dialogHelper.elementContainsPosition({ x, y }, position)) {
            dialogManager.closeDialog<T>(dialogRef);
          }
        }
      );
  }

  private getDialogsClosingRoles<T>(dialogManager: DialogsManager, target: Element): boolean {
    if (target.hasAttribute('appIgnoreCloseDialog')) {
      return false;
    }

    return (dialogManager.isDialogOpen(Dialogs.Attachment) || dialogManager.isDialogOpen(Dialogs.CandidateForm)) &&
      !dialogManager.isDialogOpen(Dialogs.ActionsCandidate) &&
      !dialogManager.isDialogOpen(Dialogs.SavedRequest) &&
      !dialogManager.isDialogOpen(Dialogs.SearchPosition) &&
      !dialogManager.isDialogOpen(Dialogs.SendMail) &&
      (!dialogManager.isDialogOpen(Dialogs.Attachment) || !dialogManager.isDialogOpen(Dialogs.CandidateForm)) &&
      (!dialogManager.isDialogOpen(Dialogs.GeolocateCandidates) || !dialogManager.isDialogOpen(Dialogs.CandidateForm));
  }

}

import { Action, createReducer, on } from '@ngrx/store';
import { StateStatus } from 'app/shared/enums/state-status.enum';
import { AdvancedSearchResponseViewModel } from 'app/shared/models/advanced-search/advanced-search-response.model';
import { AdvancedSearchViewModel } from 'app/shared/models/advanced-search/advanced-search.model';
import { AdvancedSearchResponseState } from 'app/shared/models/state/advanced-search-response-state.model';
import { AdvancedSearchSorting, CriterionCategory, SortOrder } from 'common-services';
import * as _ from 'lodash';
import * as AdvancedSearchResponseActions from '../actions/advanced-search-response.action';

const initialState: AdvancedSearchResponseState = {
    advancedSearchResponse: null,
    dataState: StateStatus.INITIAL,
    selectedCandidates: []
};

const reducer = createReducer(
    initialState,
    // Modification du status du store et de data, et récupération de l'AdvancedSearchResponseViewModel
    on(
        AdvancedSearchResponseActions.getAdvancedSearchResponse,
        (state, result) => {
            const advancedSearchResponse = _.cloneDeep(state.advancedSearchResponse);
            if (
                result.payload.advancedSearch?.criteria.length > 0 &&
                advancedSearchResponse.advancedSearch
            ) {
                advancedSearchResponse.advancedSearch.criteria = result.payload.advancedSearch.criteria;
            }

            return { ...state, dataState: StateStatus.LOADING, advancedSearchResponse };
        }
    ),
    // Récupération d'AdvancedSearchResponseViewModel avec succées
    on(
        AdvancedSearchResponseActions.getAdvancedSearchResponseSuccess,
        (state, result) => {
            const advancedSearchResponse = _.cloneDeep(result.payload);

            if (state.advancedSearchResponse?.advancedSearch?.id) {
                advancedSearchResponse.advancedSearch.id = state.advancedSearchResponse.advancedSearch.id;
            }

            return { ...state, dataState: StateStatus.LOADED, advancedSearchResponse };
        }
    ),
    // Erreur lors de la récupération de l'AdvancedSearchResponseViewModel
    on(
        AdvancedSearchResponseActions.getAdvancedSearchResponseError,
        (state) => ({ ...state, dataState: StateStatus.ERRORLOADING })
    ),
    // modification de l'AdvancedSearchResponseViewModel
    on(
        AdvancedSearchResponseActions.updateAdvancedSearchResponse,
        (state, result) => ({ ...state, advancedSearchResponse: result.payload, dataState: StateStatus.UPDATED })
    ),
    // Modification de la recherche actuelle
    on(
        AdvancedSearchResponseActions.loadAdvancedSearch,
        (state) => {
            return { ...state, dataState: StateStatus.LOADING };
        }
    ),
    // Modification de la recherche actuelle
    on(
        AdvancedSearchResponseActions.loadAdvancedSearchSuccess,
        (state, result) => {
            return { ...state, dataState: StateStatus.LOADED, advancedSearchResponse: result.payload };
        }
    ),
    // Modification de la recherche actuelle
    on(
        AdvancedSearchResponseActions.loadAdvancedSearchError,
        (state) => {
            return { ...state, dataState: StateStatus.ERRORLOADING };
        }
    ),
    // Modification de la recherche actuelle
    on(
        AdvancedSearchResponseActions.updateCurrentAdvancedSearch,
        (state, result) => {
            return { ...state, currentAdvancedSearch: result.payload, dataState: StateStatus.RELOAD };
        }
    ),
    // Supprimer un critère depuis le state
    on(
        AdvancedSearchResponseActions.deleteCriterion,
        (state, result) => {
            const currentAdvancedSearchResponse = _.cloneDeep(state.advancedSearchResponse);
            // On obtient l'index du critère
            const criterionIndex = currentAdvancedSearchResponse.advancedSearch.criteria.findIndex(
                c => ((c.category === result.payload.category || result.payload.category === CriterionCategory.Deprecated) && c.value === result.payload.value)
            );

            if (criterionIndex === -1) {
                return { ...state, dataState: StateStatus.ERRORDELETING };
            }

            // On supprime le critère
            currentAdvancedSearchResponse.advancedSearch.criteria.splice(criterionIndex, 1);

            return { ...state, dataState: StateStatus.RELOAD, advancedSearchResponse: currentAdvancedSearchResponse };
        }
    ),
    // Modifier un critère depuis le state
    on(
        AdvancedSearchResponseActions.updateCriterion,
        (state, result) => {
            const currentAdvancedSearchResponse = _.cloneDeep(state.advancedSearchResponse);

            // On obtient l'index du critère
            const criterionIndex = currentAdvancedSearchResponse.advancedSearch.criteria.findIndex(
                c => c.category === result.payload.category && c.value === result.payload.value
            );

            if (criterionIndex === -1) {
                return { ...state, dataState: StateStatus.ERRORUPDATING };
            }

            // On supprime le critère
            currentAdvancedSearchResponse.advancedSearch.criteria[criterionIndex] = result.payload;

            return { ...state, dataState: StateStatus.RELOAD, advancedSearchResponse: currentAdvancedSearchResponse };
        }
    ),
    // Modifier un critère depuis le state
    on(
        AdvancedSearchResponseActions.addRefinedCriterion,
        (state, result) => {
            const advancedSearchResponse = _.cloneDeep(state.advancedSearchResponse);
            const refinedCriterion = result.payload;
            const criterias = advancedSearchResponse.advancedSearch.criteria;

            if ((criterias.find(rc => rc.category === refinedCriterion.category
                // On compare les deux valeurs en ignorant les décorations (accent, cédille etc) pour voir si elles sont égales
                && rc.value.localeCompare(refinedCriterion.value, undefined, { sensitivity: 'base' }) === 0)
            )) {
                return { ...state, dataState: StateStatus.LOADED };
            }

            advancedSearchResponse.advancedSearch.criteria.push(refinedCriterion);

            return { ...state, dataState: StateStatus.UPDATED, advancedSearchResponse };
        }
    ),
    // Ajout des semantics à un critère
    on(
        AdvancedSearchResponseActions.addRefinedCriterionSuccess,
        (state, result) => {
            const advancedSearchResponse = _.cloneDeep(state.advancedSearchResponse);
            const refinedCriterion = result.payload;

            const criteriaIndex = advancedSearchResponse.advancedSearch.criteria
                .findIndex(c => c.category === refinedCriterion.category && c.value.trim() === refinedCriterion.value.trim());

            if (criteriaIndex !== -1 && state.dataState === StateStatus.UPDATED) {
                advancedSearchResponse.advancedSearch.criteria[criteriaIndex] = refinedCriterion;
                return { ...state, dataState: StateStatus.RELOAD, advancedSearchResponse };
            }

            return { ...state, dataState: StateStatus.LOADED };
        }
    ),
    // Erreur lors de l'ajout d'un critère
    on(
        AdvancedSearchResponseActions.addRefinedCriterionError,
        (state) => {
            return { ...state, dataState: StateStatus.ERRORADDING };
        }
    ),
    // Cherche les candidats correspondants par position id
    on(
        AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUri,
        (state) => ({ ...state, dataState: StateStatus.LOADING })
    ),
    // Récupération des candidats correspondants par position id avec succés
    on(
        AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriSuccess,
        (state, result) => {
            return { ...state, dataState: StateStatus.LOADEDBYMATCHING, advancedSearchResponse: result.payload };
        }
    ),
    // Erreur lors de la recherche des candidats correspondants par position id
    on(
        AdvancedSearchResponseActions.searchCandidatesByPositionIdOrUriError,
        (state) => {
            return { ...state, dataState: StateStatus.LOADED };
        }
    ),
    // Sélectionner ou désélectionner un candidat
    on(
        AdvancedSearchResponseActions.selectCandidate,
        (state, result) => {
            const candidateId = result.payload;
            const selectedCandidates = _.cloneDeep(state.selectedCandidates);
            const candidatesMatches = _.cloneDeep(state.advancedSearchResponse.candidatesMatches);
            const candidateMatche = candidatesMatches.find(m => m.candidate.id === candidateId);
            const candidateIndex = selectedCandidates.findIndex(c => c.id === candidateMatche.candidate.id);

            if (candidateIndex !== -1) {
                selectedCandidates.splice(candidateIndex, 1);
            } else if (candidateMatche.candidate.visibility) {
                selectedCandidates.push(candidateMatche.candidate);
            }

            return { ...state, dataState: StateStatus.UPDATED, selectedCandidates };
        }
    ),
    // Sélectionner plusieur candidats
    on(
        AdvancedSearchResponseActions.selectCandidates,
        (state, result) => {
            const selectedCandidatesIds = result.payload;
            const candidatesMatches = _.cloneDeep(state.advancedSearchResponse.candidatesMatches);
            let selectedCandidates = _.cloneDeep(state.selectedCandidates);
            const candidates = candidatesMatches?.filter(m => selectedCandidatesIds.includes(m.candidate.id)).map(m => m.candidate);

            selectedCandidates = [...selectedCandidates, ...candidates];
            selectedCandidates = Array.from(new Set(
                selectedCandidates.map(c => c.id)))
                .map(id => {
                    return selectedCandidates.find(a => a.id === id);
                }
                );

            return { ...state, dataState: StateStatus.UPDATED, selectedCandidates };
        }
    ),
    // désélectionner les candidats
    on(
        AdvancedSearchResponseActions.unSelectCandidates,
        (state, result) => {
            const selectedCandidates = _.cloneDeep(state.selectedCandidates);

            result.payload.forEach(id => {
                const index = selectedCandidates.findIndex(c => c.id === id);
                if (index !== -1) {
                    selectedCandidates.splice(index, 1);
                }
            });

            return { ...state, dataState: StateStatus.UPDATED, selectedCandidates };
        }
    ),
    // Réinitialiser les candidats sélectionnés
    on(
        AdvancedSearchResponseActions.resetSelectCandidates,
        (state) => {
            return { ...state, dataState: StateStatus.UPDATED, selectedCandidates: [] };
        }
    ),
    // Réinitialise la recherche avancée
    on(
        AdvancedSearchResponseActions.resetAdvancedSearchResponse,
        (state) => {
            const advancedSearchResponse = new AdvancedSearchResponseViewModel(0, false, new AdvancedSearchViewModel(AdvancedSearchSorting.None, SortOrder.None), []);
            advancedSearchResponse.advancedSearch.criteria = [];

            return { ...state, dataState: StateStatus.RELOAD, advancedSearchResponse };
        }
    ),
    // Modifier le statut du state
    on(
        AdvancedSearchResponseActions.updateStateStatus,
        (state, action) => ({ ...state, dataState: action.payload })
    ),
);

export function advancedSearchResponseReducer(state: AdvancedSearchResponseState = initialState, action: Action): AdvancedSearchResponseState {
    return reducer(state, action);
}

<div id="refined_criteria_component" class="refinedCriteriaMenu d-flex" #refinedCriteriaMenu
  *ngIf="hasSelectedCriteria">
  <div class="scroll-btn btn-left d-flex align-self-start align-items-center" id="leftScroll">
    <button *ngIf="showLeftScrollButton" id="refined_criteria_menu_scroll_left_button" (click)="scrollLeft()"
      class="btn btn-secondary clear-button">
      <mat-icon class="material-icons-outlined">
        expand_circle_down
      </mat-icon>
    </button>
  </div>
  <div class="flex-grow-1 align-self-start align-items-center searchCriterion" id="scrollCriteria">
    <mat-accordion id="refined_criteria_container">
      <div *ngFor="let criterion of criteriaArray;index as i;trackBy: trackByFunction;" class="refinedCriteriaArea ">
        <app-refined-criterion id="refined_criteria_{{ i }}" [identifier]="'refined_criteria_' + i"
          [criterion]="criterion"></app-refined-criterion>
      </div>
    </mat-accordion>
  </div>
  <div class="scroll-btn d-flex align-self-start align-items-center" id="rightScroll">
    <button *ngIf="showRightScrollButton" id="refined_criteria_menu_scroll_right_button" (click)="scrollRight()"
      class="btn btn-secondary clear-button">
      <mat-icon class="material-icons-outlined">
        expand_circle_down_outline
      </mat-icon>
    </button>
  </div>

  <div class="searchButton d-flex align-self-start align-items-center" id="searchButtonsId">
    <div class="separator">
      <div class="vertical"></div>
    </div>
    <button id="refined_criteria_menu_save_button" type="button" (click)="saveAdvancedSearch()"
      class="btn btn-secondary clear-button" title="{{ 'save' | translate  }}">
      <mat-icon class="material-icons-outlined">
        save
      </mat-icon>
    </button>
    <button id="refined_criteria_menu_clear_button" (click)="clearAdvancedSearch()"
      class="btn btn-secondary clear-button" title="{{ 'clearAdvancedSearch' | translate  }}">
      <mat-icon class="material-icons-outlined">
        backspace
      </mat-icon>
    </button>
  </div>
</div>

export enum CriteriaGroup {
  Function,
  Region,
  City,
  LastStatus,
  Status,
  Chip,
  Language,
  Skill,
  Formation,
  Experience,
  TagCloudsOthers
}

<div class="d-flex flex-column h-100 is-gradient-gray">
  <div class="modalHeader">
    <button class="close" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
    <h1 mat-dialog-title class="text-uppercase text-primary font-weight-bold">{{ 'sendMail' | translate }}</h1>
  </div>

  <div class="scroll-wrapper">
    <div class="scroll">
      <mat-dialog-content class="matDialogContent custom-padding">
        <form [formGroup]="sendMailForm">
          <div class="fieldsetBox has-padding">
            <!-- DATE -->
            <mat-form-field appearance="outline" class="w-50 pr-2 mb-3">
              <mat-label>{{ 'date' | translate }}</mat-label>
              <input id="send_mail_component_date_input" matInput [matDatepicker]="picker" [value]="actionDate"
                formControlName="actionDate" required />
              <mat-datepicker-toggle matSuffix [for]="picker" class="datepickerBtn"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <!-- TYPE ACTION -->
            <app-select-group [identifier]="'send_mail_component_action_type_select'" [class]="'w-50 pl-2 mb-3'"
              (selectedOptionsChanged)="onActionTypeChange($event)" [formControl]="sendMailForm.controls.actionType"
              [groups]="actionTypesHeader" [emptyOption]="emptyOptionLabel" [label]="labelActionType" [required]="true">
            </app-select-group>
            <!-- POSITION -->
            <app-select *ngIf="candidateIds.length === 1" [identifier]="'send_mail_component_position_select'"
              [class]="'w-100 mb-3'" (selectedOptionsChanged)="onPositionChange($event)" [options]="positions"
              [emptyOption]="emptyOptionLabel" [label]="positionLabel">
            </app-select>
            <!-- MODELE DE LETTRE -->
            <app-select [identifier]="'send_mail_letter_model_select'" [class]="'w-100 mb-3'"
              (selectedOptionsChanged)="onLetterModelChange($event)" [options]="letterModels"
              [emptyOption]="emptyOptionLabel" [label]="labelLetterModel">
            </app-select>
            <!-- SIGNATURE -->
            <app-select *ngIf="signatures?.length" [identifier]="'send_mail_component_signature_select'"
              [class]="'w-100 mb-3'" (selectedOptionsChanged)="onChangeMailSignature($event)" [options]="signatures"
              [isEmptyOptionHasValue]="true" [emptyOption]="emptyOptionLabel" [selectedOption]="selectedSignature"
              [label]="labelSignature">
            </app-select>
          </div>
          <div class="fieldsetBox formMail background-grey has-padding">
            <!-- EXPEDITEUR -->
            <app-select [identifier]="'send_mail_component_sender_select'" [class]="'w-100'" [options]="senders"
              [formControl]="sendMailForm.controls.mailSender" [selectedOption]="defaultSender" [label]="labelSender">
            </app-select>
            <!-- DESTINATAIRE -->
            <mat-form-field appearance="outline" class="w-100">
              <span matPrefix class="matPrefix">
                {{ 'recipients' | translate }}
                <span class="mat-placeholder-required mat-form-field-required-marker">
                  <sup>*</sup>
                </span>
              </span>
              <div class="d-flex align-self-center align-items-center">
                <div class="flex-grow-1">
                  <mat-chip-list #recipientsList formControlName="recipientControl">
                    <mat-chip *ngFor="let recipient of recipients" [removable]="removable"
                      (removed)="removeRecipient(recipient)">
                      {{ recipient }}
                      <mat-icon matChipRemove *ngIf="removable && recipients.length > 1">cancel</mat-icon>
                    </mat-chip>
                    <input id="send_mail_component_recipient_input" [matChipInputFor]="recipientsList" disabled
                      required />
                  </mat-chip-list>
                </div>
                <div class="copyMail" *ngIf="!isVisibleCc || !isVisibleCci">
                  <a (click)="isVisibleCc=true" [ngClass]="{'invisible': isVisibleCc }">CC</a>&nbsp;&nbsp;<a
                    (click)="isVisibleCci=true" [ngClass]="{'invisible': isVisibleCci }">CCI</a>
                </div>
              </div>
            </mat-form-field>
            <!-- CC -->
            <mat-form-field appearance="outline" class="w-100" *ngIf="isVisibleCc">
              <span matPrefix class="matPrefix text-uppercase">{{ 'carbonCopy' | translate }}</span>
              <mat-chip-list #carbonCopy formControlName="carbonCopyControl">
                <mat-chip *ngFor="let carbonCopyRecipient of carbonCopiesRecipients" [removable]="removable"
                  (removed)="removeCarbonCopyMail(carbonCopyRecipient)">
                  {{ carbonCopyRecipient }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="carbonCopy" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addCarbonCopyMail($event)" [matChipInputAddOnBlur]="true" />
              </mat-chip-list>
            </mat-form-field>
            <!-- CCI -->
            <mat-form-field appearance="outline" class="w-100" *ngIf="isVisibleCci">
              <span matPrefix class="matPrefix text-uppercase">{{ 'blindCarbonCopy' | translate }}</span>
              <mat-chip-list #blindCarbonCopy formControlName="blindCarbonCopyControl">
                <mat-chip *ngFor="let blindCarbonCopyRecipient of blindCarbonCopiesRecipients" [removable]="removable"
                  (removed)="removeCarbonCopyInvisibleMail(blindCarbonCopyRecipient)">
                  {{ blindCarbonCopyRecipient }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="blindCarbonCopy" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addBlindCarbonCopyMail($event)" [matChipInputAddOnBlur]="true" />
              </mat-chip-list>
            </mat-form-field>
            <!-- SUJET -->
            <mat-form-field appearance="outline" class="w-100">
              <span matPrefix class="matPrefix">
                {{ 'mailSubject' | translate }}
                <span class="mat-placeholder-required mat-form-field-required-marker">
                  <sup>*</sup>
                </span>
              </span>
              <input id="send_mail_component_subject_input" matInput type="text" formControlName="mailSubject"
                required />
            </mat-form-field>
            <!-- CORPS DU MAIL -->
            <div class="position-relative">
              <div class="micro position-absolute">
                <app-web-speech [language]="language" (onStartSpeech)="onStartSpeech()" (onStopSpeech)="onStopSpeech()"
                  (onSpeech)="onSpeech($event)"></app-web-speech>
              </div>
              <app-text-editor id="send_mail_component_text_editor" configType="Mail" formControlName="mailBody">
              </app-text-editor>
            </div>
            <hr class="hrSpecCkeditor" />
            <app-files-uploader
              [translation]="fileUploaderTranslation"
              [letterModelsFiles]="letterModelFiles"
              (uploadedFiles)="uploadedFilesFromPjComponent($event)">
            </app-files-uploader>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </div>

  <mat-dialog-actions class="btns-actions justify-content-end mb-0 position-relative grey">
    <div class="p-0">
      <button id="send_mail_component_send_button" mat-button [disabled]="!sendMailForm.valid" (click)="sendMails()"
        mat-flat-button color="primary" class="btn text-uppercase">
        <mat-icon class="mr-2">send</mat-icon>
        <span>{{ 'send' | translate }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
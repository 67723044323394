import { Injectable } from '@angular/core';
import { AuthenticationsService, DataDepthLevel, OrganizationService, QueryableModel } from 'common-services';
import { IconsLoaderManager } from '../managers/icons-loader.manager';
import { ParameterService } from '../services/parameter.service';

@Injectable()
export class InitializerProvider {

  constructor(
    private readonly authenticationsService: AuthenticationsService,
    private readonly iconsLoaderManager: IconsLoaderManager,
    private readonly organizationService: OrganizationService,
    private readonly parameterService: ParameterService
  ) { }

  public async init(): Promise<void> {
    if (this.authenticationsService.isLoggedIn()) {
      this.iconsLoaderManager.loadCustomIcons();
      this.initIndiviudals();
      await this.parameterService.getParameters();
    }
  }

  private initIndiviudals() {
    const query: QueryableModel = {
      whereExpression: `i => true`,
      parameters: [],
      dataDepthLevel: DataDepthLevel.Flat,
      sortOrder: 'NONE',
      page: 0,
      pageSize: 10000
    };

    this.organizationService.findIndividualsByQueryable(query);
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MomentUtcDateAdapter } from 'app/shared/adapters/moment-utc-date.adapter';
import { ActionFollowUpModel } from 'common-services';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-candidate-grouped-actions',
  templateUrl: './candidate-grouped-actions.component.html',
  styleUrls: ['./candidate-grouped-actions.component.scss'],
  providers: [DatePipe]
})
export class CandidateGroupedActionsComponent implements OnInit {
  @Input() groupActions: Array<ActionFollowUpModel>;
  @Input() groupKey: string;
  @Input() expanded: boolean;
  private readonly actionsArray: Array<ActionFollowUpModel>;
  private readonly actionsObservable: Observable<Array<ActionFollowUpModel>>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly momentUtcDateAdapter: MomentUtcDateAdapter
  ) {
    this.actionsArray = new Array<ActionFollowUpModel>();
    this.actionsObservable = of(this.actionsArray);
  }

  public get groupTitle(): string {
    // S'il s'agit d'une date on spécifie le format de date local
    const date = moment(this.groupKey, 'DD/MM/YYYY');

    return date.isValid() ? date.format(this.momentUtcDateAdapter.parseFormat()) : this.groupKey;
  }

  public get actions(): Observable<Array<ActionFollowUpModel>> {
    return this.actionsObservable;
  }

  ngOnInit() {
    if (this.expanded) {
      this.afterExpand();
    }
    if (!this.groupKey) {
      this.translateService.get('noResponsible').subscribe(translations => {
        this.groupKey = translations.noResponsible;
      });
    }
  }

  public afterExpand() {
    if (this.actionsArray.length === 0) {
      setTimeout(() => {
        this.loadActions();
      });
    }
  }

  trackById(index: number, item: ActionFollowUpModel) {
    return item.id;
  }

  private loadActions() {
    for (const action of this.groupActions) {
      setTimeout(() => this.actionsArray.push(action));
    }
  }
}

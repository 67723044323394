<mat-form-field appearance="outline" class="w-50 pr-2 mb-3">
  <mat-label>
    {{ 'actionDate' | translate  }}
  </mat-label>
  <input  matInput
          [formControl]="control"
          [ngxMatDatetimePicker]="dateTimePicker" 
          [placeholder]="placeholder" 
          [min]="minDate"
          [max]="maxDate"
          [attr.disabled]="disabled"
  ><mat-datepicker-toggle 
          matSuffix 
          [for]="dateTimePicker"
  ></mat-datepicker-toggle>
  <ngx-mat-datetime-picker 
          #dateTimePicker 
          [showSpinners]="showSpinners" 
          [showSeconds]="showSeconds"
          [stepHour]="stepHour" 
          [stepMinute]="stepMinute" 
          [stepSecond]="stepSecond"
          [touchUi]="touchUi" 
          [color]="color" 
          [enableMeridian]="enableMeridian" 
          [disableMinute]="disableMinute" 
          [hideTime]="hideTime"
  ></ngx-mat-datetime-picker>
</mat-form-field>

import { Pipe, PipeTransform } from '@angular/core';
import * as escapeStringRegexp from 'escape-string-regexp';

// Utilisation dans le code HTML : {{ phrase | strongify:stringToStrongify }}
@Pipe({ name: 'strongify' })
export class StrongifyPipe implements PipeTransform {

  public transform(phrase: string, stringToStrongify: string): string {
    return stringToStrongify
      ? phrase.replace(new RegExp(`(${escapeStringRegexp(stringToStrongify)})`, 'gi'), '<strong>$1</strong>')
      : phrase;
  }
  
}

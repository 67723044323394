<mat-expansion-panel class="border" [ngClass]="{'active' : isActive}" [disabled]="true" #mep="matExpansionPanel">
  <mat-expansion-panel-header [collapsedHeight]="customHeight" [expandedHeight]="customHeight">
    <mat-panel-title class="d-flex align-items-center">
      <div class="d-flex flex-column">
        <a (click)="loadAdvancedSearch()" title="{{ 'load' | translate  }}">
          <span class="title">{{ advancedSearch.label }}</span>
        </a>
        <div class="date">{{ advancedSearch.lastUpdate | toLocalDate:'short' }}</div>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <button mat-icon-button [matMenuTriggerFor]="actionMenu">
        <mat-icon class="icon">more_horiz</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="modifyAdvancedSearch()">
          <mat-icon>edit</mat-icon>
          {{ 'modify' | translate  }}
        </button>
        <button mat-menu-item class="btn-delete" (click)="deleteAdvancedSearch()">
          <mat-icon>delete</mat-icon>
          {{ 'delete' | translate  }}
        </button>
      </mat-menu>
      <button mat-icon-button class="mat-cell" (click)="mep.expanded = !mep.expanded;getAdvancedSearchCriteria()">
        <mat-icon class="icon" color="primary" *ngIf="!mep.expanded">expand_more</mat-icon>
        <mat-icon class="icon" color="primary" *ngIf="mep.expanded">expand_less</mat-icon>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  
  <div class="save_search_elements">
    <mat-progress-bar id="saved_request_resume_criteria_loading_progress_bar" mode="indeterminate" *ngIf="loadingCriteria">
    </mat-progress-bar>
    <app-saved-request-criterion *ngFor="let refinedCriterion of criterias" [refinedCriterion]="refinedCriterion">
    </app-saved-request-criterion>
  </div>
</mat-expansion-panel>

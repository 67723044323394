import { Component, Input } from '@angular/core';
import { CandidateModel } from 'common-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-candidate-experiences-formations',
  templateUrl: './candidate-experiences-formations.component.html',
  styleUrls: ['candidate-experiences-formations.component.scss']
})

export class CandidateExperiencesFormationsComponent {
  @Input() candidate: Observable<CandidateModel>;
}

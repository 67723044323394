import { AdvancedSearchSorting } from 'app/shared/enums/advanced-search-sorting.enum';
import { SortOrder } from 'app/shared/enums/sort-order.enum';
import { AdvancedSearchModel } from 'common-services';
import { RefinedCriterionViewModel } from './refined-criterion.model';

export class AdvancedSearchViewModel implements AdvancedSearchModel {
  constructor(
    public sortedElement: AdvancedSearchSorting,
    public sortOrder: SortOrder,
  ) { }
  id: number;
  individualId: number;
  label: string;
  lastUpdate: Date;
  query: string;
  criteria: Array<RefinedCriterionViewModel>;
}

import { Component } from '@angular/core';
import '../../../../assets/elements/eolia-cv-import-1.0.0.js';
@Component({
  selector: 'app-import-cv',
  templateUrl: './import-cv.component.html',
  styleUrls: ['./import-cv.component.scss'],
})
export class ImportCvComponent {
  public emptyParsedFiles(): void {
    localStorage.setItem('parsedFiles', JSON.stringify([]));
  }
}

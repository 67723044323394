import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CriterionPriority } from 'app/shared/enums/criterion-priority.enum';
import { UtilHelper } from 'app/shared/helpers/util.helper';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { RefinedCriterionViewModel } from 'app/shared/models/advanced-search/refined-criterion.model';
import { AppState } from 'app/state/app.state';
import { CustomWebSearchModel } from 'common-services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-web-search',
  templateUrl: './custom-web-search.component.html',
  styleUrls: ['./custom-web-search.component.scss']
})
export class CustomWebSearchComponent implements OnInit, OnDestroy {
  @Input() customWebSearch: CustomWebSearchModel;
  @Input() refreshObservable: Observable<boolean>;
  @ViewChild('gcseWrapper') gcseWrapper: ElementRef;

  private readonly subscription: Subscription;
  // Variable google de type any, parce que c'est une variable récupérée depuis le script javascript de google cse
  private gcse: any;
  private query: string;
  private _isSearching: boolean;

  public gcseId: string;

  public get isSearching(): boolean {
    return this._isSearching;
  }

  constructor(
    private readonly utilHelper: UtilHelper,
    private readonly advancedSearchManager: AdvancedSearchManager,
    private readonly store: Store<AppState>
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    // Initialisation d'identifiant de la balise google cse
    this.gcseId = `eolia-gcse-tag-${this.customWebSearch.key}`;

    if (this.refreshObservable) {
      this.refreshObservable.subscribe(() => {
        this._isSearching = true;

        this.removeCseScripts();
        this.insertCseScript(this.customWebSearch.key);

        this.utilHelper.objectHasProperty(window, 'google', () => {
          this.gcse = window['google'].search.cse.element;

          window['__gcse'] = {
            parsetags: 'explicit',
            initializationCallback: () => {
              this.gcseWrapper.nativeElement.innerHTML = '';

              if (document.readyState === 'complete') {

                this.gcseWrapper.nativeElement.innerHTML = '';

                if (document.readyState === 'complete') {
                  this.renderCseTag();
                }
              }
            }
          };
        });
        this._isSearching = false;
      });
    }

    this.searchFromCriteriaMenu();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private searchFromCriteriaMenu() {
    // Recherche dans google cse depuis criteria menu
    const searchesObservable$ = this.store.select(data => data.advancedSearchResponseState);
    const storeSubscription = searchesObservable$.subscribe(advancedSearchResponse => {
      if (advancedSearchResponse.advancedSearchResponse) {
        const currentQuery = this.getCriteria(advancedSearchResponse.advancedSearchResponse.advancedSearch.criteria);
        if (currentQuery !== this.query) {
          this._isSearching = true;
          this.query = currentQuery;
          this.executeQuery(this.query);
        }
      }
    });

    this.subscription.add(storeSubscription);
  }

  private renderCseTag() {
    if (!this.gcse.getElement(this.gcseId)) {
      // Rendre un élément avec le champ google de recherche
      this.gcse.render({
        div: this.gcseId,
        tag: 'search',
        gname: this.gcseId
      });
      this.executeQuery(this.query);
    }
  }

  // Exécution d'une requête de recherche dans google cse
  private executeQuery(query: string) {
    if (this.gcse) {
      const element = this.gcse.getElement(this.gcseId);
      element.execute(query);
      this._isSearching = false;
    }
  }

  // Cette fonction renvoie les critères de recherche concaténés avec un espace
  private getCriteria(criteria: RefinedCriterionViewModel[]): string {
    return criteria
      .filter(element => this.utilHelper.ignoredCategoryForCse().indexOf(element.category) === -1)
      .map(element => {
        element = Object.assign({}, element);
        const value = this.advancedSearchManager.defineCriterionLabel(element)?.label.replace(/"/g, '');
        switch (element.priority) {
          case CriterionPriority.Required:
            return `"${value}"`;
          case CriterionPriority.Optional:
            return `${value}`;
          case CriterionPriority.Excluded:
            if (value.split(' ').length > 1) {
              return `-"${value}"`;
            } else {
              return `-${value}`;
            }
        }
      })
      .join(' ');
  }

  // Suppression des scripts google cse
  private removeCseScripts() {
    const scripts = document.querySelectorAll(`[src*='//cse.google.com/cse']`);
    Array.from(scripts).forEach(script => { script.remove(); });
  }

  // insertion du script google cse
  private async insertCseScript(key: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = document.location.protocol + `//cse.google.com/cse.js?cx=${key}`;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

<div *ngIf="attachment"
     class="attachmentButton"
     (click)="onDownload(attachment); $event.stopPropagation();"
     (mouseenter)="onPreview(attachment)"
     (mouseleave)="onMouseLeave()"
     [ngClass]="getAttachmentIconName(attachment)"
>
  <mat-icon svgIcon="{{ getAttachmentIconName(attachment) }}">
    {{getAttachmentIconName(attachment)}}
  </mat-icon>
</div>

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CkEditorHelper } from 'app/shared/helpers/ck-editor.helper';
import { ActionManager } from 'app/shared/managers/action.manager';
import {
  ActionFollowUpModel,
  ActionFollowUpService,
  ApplicativeAreaCode,
  CandidateModel,
  CandidateService,
  CommonHelper,
  DataDepthLevel,
  FileModel,
  FileType,
  FusionFieldService,
  IndividualModel,
  LetterModelModel,
  LetterModelService,
  MailModel,
  MailService,
  MailSignatureModel,
  PositionModel,
  PositionService,
  SendCandidateMailFusionFieldsModel
} from 'common-services';
import { CustomSnackBarService } from 'custom-snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  private readonly mailSignatureSeparator = '<p>----</p>';
  private readonly speechTempText = '';
  private noValidMailAddressOfSenderFound: string;
  private candidatesWithInvalidMailAddresses: string;
  private addMailError: string;
  private sendMailSuccessfull: string;
  private error: string;

  public readonly individual: IndividualModel;
  public readonly candidateIds: Array<number>;
  public candidatesModel: ReadonlyArray<CandidateModel>;
  public positionLabel: string;
  public labelLetterModel: string;
  public labelSignature: string;
  public emptyOptionLabel: string;
  public labelActionType: string;
  public labelSender: string;

  // Paramètres des input avec chipList (Cc, Cci et destinataires)
  public removable = true;
  public readonly separatorKeysCodes: number[] = [COMMA, ENTER];

  // Paramètres de la popup
  public recipients: Array<string> = [];
  public carbonCopiesRecipients: Array<string> = [];
  public blindCarbonCopiesRecipients: Array<string> = [];
  public senders: Array<KeyValue<string, string>>;
  public actionTypesHeader: Array<KeyValue<string, Array<KeyValue<number, string>>>>;
  public letterModels: Array<KeyValue<LetterModelModel, string>>;
  public positions: Array<KeyValue<number, string>>;
  public positionModel: PositionModel;
  public selectedLetterModel: LetterModelModel;
  public actionTypeId?: number;
  public actionDate = moment().toISOString();
  public uploadedFiles: Array<FileModel> = [];
  public letterModelFiles: Array<FileModel> = [];
  public isVisibleCc: boolean;
  public isVisibleCci: boolean;
  public signatures: Array<KeyValue<MailSignatureModel, string>>;
  public selectedSignature: MailSignatureModel;
  public defaultSender: string;
  public language: string;
  public fileUploaderTranslation: Object;

  // Formulaire
  public sendMailForm = new FormGroup({
    actionDate: new FormControl(this.actionDate, [Validators.required]),
    actionType: new FormControl('', [Validators.required]),
    mailSender: new FormControl('', [Validators.required]),
    recipientControl: new FormControl(this.recipients, (control) => this.recipientControlValidator(control)),
    carbonCopyControl: new FormControl(this.carbonCopiesRecipients),
    blindCarbonCopyControl: new FormControl(this.blindCarbonCopiesRecipients),
    mailSubject: new FormControl('', [Validators.required]),
    mailBody: new FormControl('', [Validators.required])
  });

  public get isSelectEmpty(): boolean {
    return !!this.sendMailForm.controls.mailSender.value;
  }

  constructor(
    private readonly dialogRef: MatDialogRef<SendMailComponent>,
    private readonly snackbarService: CustomSnackBarService,
    private readonly letterModelsService: LetterModelService,
    private readonly commonHelper: CommonHelper,
    private readonly translateService: TranslateService,
    private readonly mailService: MailService,
    private readonly actionFollowUpService: ActionFollowUpService,
    private readonly positionService: PositionService,
    private readonly ckEditorHelper: CkEditorHelper,
    private readonly actionManager: ActionManager,
    private readonly fusionFieldService: FusionFieldService,
    private readonly candidateService: CandidateService,
    @Inject(MAT_DIALOG_DATA) data: { candidates: Array<CandidateModel> }
  ) {
    // On injecte la liste des candidats sélectionnés
    this.candidatesModel = data.candidates;
    this.candidateIds = this.candidatesModel.map(candidate => candidate.id);

    // On injecte le type d'élements
    this.actionFollowUpService.getActionsTypeGroupByHeaders().then(result => {
      this.actionTypesHeader = this.actionManager.getActionsTypeGroupByHeaders(result);
    });

    if (this.candidateIds?.length === 1) {
      this.positionService.getPositionsByCandidateId(this.candidateIds[0]).then(
        result => this.positions = result.map(position => ({ key: position.id, value: `${position.title} - ${position.reference}` }))
      );
    }

    this.individual = JSON.parse(localStorage.getItem('individual'));
  }

  public ngOnInit() {
    this.getTranslations();
    // On obtient les modèles de lettres par la fonctionalité d'envoi de mail candidat
    this.letterModelsService.getLetterModelsByApplicativeAreaCode(ApplicativeAreaCode.CandidateSearch).then(
      result => this.letterModels = result.map(letterModel => ({ key: letterModel, value: letterModel.label + letterModel.codification }))
    );

    // On obtient la liste des signatures et la signature par défaut
    this.mailService.getMailSignatures().then(signatures => {
      this.selectedSignature = signatures.find(signature => signature.isDefault);
      this.signatures = signatures.map(signature => ({ key: signature, value: signature.label }));
      // appelle la méthode 'onChangeSignature' pour afficher la signature selectionné
      this.onChangeMailSignature(this.selectedSignature);
    });
    // On obtient les expéditeurs
    this.mailService.getUsableSenderAddresses().then(senders => {
      this.senders = senders.map(sender => ({ key: sender, value: sender }));
      if (senders.length) {
        this.defaultSender = senders[0];
      } else {
        this.snackbarService.showDanger(this.noValidMailAddressOfSenderFound);
      }
    });

    this.recipients = this.candidatesModel.filter(c => c.emailAddress !== '').map(c => c.emailAddress);
    this.recipientControl.setValue(this.recipients);
    this.filterMails();

    // La langue d'utilisateur connecté pour la reconnaissance vocale
    this.language = this.individual.languageBU.code;

    // Charger les traductions du composant File-Uploader
    this.translateService.get([
      'files',
      'maxFilesUploadError',
      'maxFileSizeError',
      'fileTypeError',
      'fileTypeInfo',
      'fileUploadError',
      'deleteCandidateAttachmentFailed',
      'invalidFileModel',
      'dragDropFiles',
      'or',
      'addFiles'
    ]).subscribe(translation => {
      this.fileUploaderTranslation = translation;
    });
  }

  /** Obtient l'élément des destinataires */
  public get recipientControl() {
    return this.sendMailForm.get('recipientControl');
  }

  /** Obtient l'élément des mails de copie carbone */
  public get carbonCopyControl() {
    return this.sendMailForm.get('carbonCopyControl');
  }

  /** Obtient l'élément des mails de copie carbone invisibles*/
  public get blindCarbonCopyControl() {
    return this.sendMailForm.get('blindCarbonCopyControl');
  }

  /**
   * Ajoute un mail de copie carbone
   * @param event L'évènement d'ajout
   */
  public addCarbonCopyMail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // On ajoute le mail
    if (value.trim()) {
      // On valide si l'input est bien un mail
      if (this.commonHelper.isValidEmailAddress(value)) {
        this.carbonCopiesRecipients.push(value);
      } else {
        // On appelle la snack-bar pour indiquer à l'utilisateur que l'adresse mail qu'il vient de rentrer n'est pas valide
        const errorMessage =
          `${this.error}
          ${this.addMailError}`;
        this.snackbarService.showDanger(errorMessage);
        // Et on vide l'input
        input.value = '';
      }
    }
    // On vide l'input
    if (input) {
      input.value = '';
    }
  }

  /**
   * Ajout un mail de copie carbone invisible
   * @param event L'évènement d'ajout
   */
  public addBlindCarbonCopyMail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // On ajoute le mail
    if (value.trim()) {
      // On valide si l'input est bien un mail
      if (this.commonHelper.isValidEmailAddress(value)) {
        this.blindCarbonCopiesRecipients.push(value);
      } else {
        // On appelle la snack-bar pour indiquer à l'utilisateur que l'adresse mail qu'il vient de rentrer n'est pas valide
        const errorMessage =
          `${this.error}
          ${this.addMailError}`;
        this.snackbarService.showDanger(errorMessage);
        // Et on vide l'input
        input.value = '';
      }
    }
    // On vide l'input
    if (input) {
      input.value = '';
    }
  }

  public onActionTypeChange(actionTypeId: number) {
    this.actionTypeId = actionTypeId;
  }

  public async onPositionChange(positionId: number) {
    this.positionModel = null;
    if (positionId) {
      await this.positionService.getPositionById(positionId, DataDepthLevel.WithSubObjectsAndSubLists).then(position => {
        this.positionModel = position;
      });
    }

    // On appelle 'onLetterModelChange' pour mettre à jour le modèle de lettre
    this.onLetterModelChange(this.selectedLetterModel);
  }

  public async onLetterModelChange(selectedLetterModel: LetterModelModel) {
    const signature = this.selectedSignature ? (this.mailSignatureSeparator + this.selectedSignature.signature) : '';
    if (selectedLetterModel) {
      if (this.selectedLetterModel?.id !== selectedLetterModel?.id) {
        this.selectedLetterModel = selectedLetterModel;
        // On obtient les pièces jointes du modèle de lettre s'ils existent, sinon on supprime celles ajoutées au composants upload
        this.getAttachmentsLetterModel();
      }

      // Si il n'y a qu'un seul candidat selectionné
      if (this.candidateIds.length === 1) {
        // On obtient le candidat et on crée le contexte de champs de fusion
        const context: SendCandidateMailFusionFieldsModel = {
          candidateModel: this.candidatesModel[0],
          positionModel: this.positionModel
        };

        // On fusionne les champs avec le sujet du mail
        this.fusionFieldService
          .replaceFusionFields(context, selectedLetterModel.subject)
          .then(text =>
            // On met à jour le formulaire
            this.sendMailForm.patchValue({ mailSubject: text })
          );
        // On fusionne les champs avec le message HTML
        this.fusionFieldService
          .replaceFusionFields(context, selectedLetterModel.htmlMessage)
          .then(text =>
            // On appelle 'onChangeSignature' pour mettre à jour le formulaire avec la signature sélectionnée
            this.onChangeMailSignature(this.selectedSignature, text)
          );
      } else {
        // S'il n'y a pas exactement 1 candidat sélectionné, on met à jour les valeurs sans fusionner
        this.sendMailForm.patchValue({
          mailSubject: selectedLetterModel.subject,
          mailBody: selectedLetterModel.htmlMessage + signature
        });
      }
    } else {
      this.selectedLetterModel = null;
      this.letterModelFiles = [];
      this.sendMailForm.patchValue({
        mailSubject: '',
        mailBody: signature
      });
    }
  }

  public onChangeMailSignature(signatureModel: MailSignatureModel, mailBody = '') {
    // On affiche la signature
    this.selectedSignature = signatureModel;
    // On obtient le corps du mail
    mailBody = this.getMailBodyWithoutSignature(mailBody);

    if (signatureModel) {
      // On remet le séparateur et la signature sélectionnée
      mailBody += this.mailSignatureSeparator + signatureModel.signature;
    }
    // On met à jour le formulaire
    this.sendMailForm.patchValue({
      mailBody
    });
  }

  private getAttachmentsLetterModel() {
    this.letterModelsService.getAttachmentFilesByLetterModelId(this.selectedLetterModel?.id).then(data => {
      if (data.length > 0) {
        data.forEach(attachment => {
          this.letterModelFiles.push({
            fileName: attachment.fileName,
            fileType: FileType.LetterModel,
            fileSize: 0,
            databaseFilePath: null,
            width: null,
            height: null,
            fileData: null,
            overWrite: true,
            filePath: null
          } as FileModel);
        });
      } else {
        this.letterModelFiles = [];
      }
    });
  }

  /**
   * Obtient le corps du mail sans signature
   * @param mailBody Le corps du mail
   */
  private getMailBodyWithoutSignature(mailBody = '') {
    const text = (mailBody !== '' ? mailBody : this.sendMailForm.controls.mailBody.value).split(this.mailSignatureSeparator)[0];
    return this.ckEditorHelper.cleanCkEditorText(text);
  }

  private getAttachmentFiles(): Array<FileModel> {
    const attachments = this.uploadedFiles.concat(this.letterModelFiles.filter(item => this.uploadedFiles.indexOf(item) < 0));

    return attachments ?? [];
  }

  /** Obtient le contexte de champ de fusion à l'aide des infos du formulaire,
   * construit un mail et un suivi d'action par candidat
   * et envoie le tout */
  public async sendMails() {
    // On désactive le formulaire et le bouton d'envoi
    this.sendMailForm.disable();
    // On définit les infos du mail
    const baseMailModel = {
      sender: this.senders.find(item => item.key === this.sendMailForm.controls.mailSender.value).value,
      recipients: this.recipients,
      carbonCopiesRecipients: this.carbonCopiesRecipients ?? [],
      blindCarbonCopiesRecipients: this.blindCarbonCopiesRecipients ?? [],
      subject: this.sendMailForm.controls.mailSubject.value,
      htmlBody: this.ckEditorHelper.getNormalizedContentWithReplacedBase64(),
      attachments: this.getAttachmentFiles()
    } as MailModel;

    // On prépare la liste des ids de candidats ayant des problèmes d'envoi de mail
    const errorAfterwardsCandidateMailAdresses: string[] = [];

    // On filtre la liste des candidats aprés la vérification des emails
    this.candidatesModel = this.candidatesModel.filter(candidate => this.recipients.includes(candidate.emailAddress));

    for (const candidateModel of this.candidatesModel) {
      // On adapte le destinataire du mail pour ce candidat en particulier
      const candidateMailModel: MailModel = { ...baseMailModel };
      const candidateMailAddress = candidateMailModel.recipients.find(r => r === candidateModel.emailAddress);
      if (candidateMailAddress) {
        candidateMailModel.recipients = [candidateMailAddress];
      }

      // On construit son contexte de champs de fusion
      const context: SendCandidateMailFusionFieldsModel = {
        candidateModel,
        positionModel: this.positionModel
      };

      // On construit son suivi d'action
      const actionFollowUp = {
        candidateId: candidateModel.id,
        positionId: this.positionModel?.id,
        letterModelId: this.selectedLetterModel?.id,
        actionTypeId: this.actionTypeId,
        actionDate: this.sendMailForm.controls.actionDate.value
      } as ActionFollowUpModel;

      // On remplace les champs de fusion du sujet et du corps HTML du mail
      await Promise.all([
        this.fusionFieldService
          .replaceFusionFields(context, candidateMailModel.subject)
          .then(text => candidateMailModel.subject = text),
        this.fusionFieldService
          .replaceFusionFields(context, candidateMailModel.htmlBody)
          .then(text => candidateMailModel.htmlBody = text)]);

      // On envoie le mail
      await this.candidateService.sendMail(candidateMailModel, actionFollowUp)
        .catch(() => {
          errorAfterwardsCandidateMailAdresses.push(candidateMailAddress);
        });
    }

    // Si tout s'est bien passé
    if (!errorAfterwardsCandidateMailAdresses.length) {
      // On confirme l'envoi des mails
      this.snackbarService.showSuccess(
        `${this.candidateIds.length.toString()}
        ${this.sendMailSuccessfull}`);
    } else {
      // Sinon on compose le message d'erreur
      const errorMessage = [];

      // On indique les adresses mail des candidats
      if (errorAfterwardsCandidateMailAdresses.length) {
        errorMessage.push(
          `[${errorAfterwardsCandidateMailAdresses.join(', ')}]:
          ${this.error}`);
      }

      this.snackbarService.showDanger(errorMessage.join('<br/>'));
    }

    // On ferme la fenêtre
    this.close();
  }

  /**
   * Enlève un destinataire
   * @param recipient Le mail du destinataire à enlever
   */
  public removeRecipient(recipient: string) {
    const index = this.recipients.indexOf(recipient);

    if (index >= 0) {
      this.recipients.splice(index, 1);
      this.recipientControl.setValue(this.recipients);
    }
  }
  /**
   * Enlève un mail de copie carbone
   * @param carbonCopyMail Le mail copie carbone à enlever
   */
  public removeCarbonCopyMail(carbonCopyMail: string) {
    const index = this.carbonCopiesRecipients.indexOf(carbonCopyMail);

    if (index >= 0) {
      this.carbonCopiesRecipients.splice(index, 1);
    }
    // Si il n' y plus de mail de copie carbone
    if (this.carbonCopiesRecipients.length === 0) {
      this.validateControl(this.carbonCopyControl);
    }
  }

  /**
   * Enlève un mail de copie carbone invisible
   * @param carbonCopyInvisibleMail Le mail de copie carbone invisible à enlever
   */
  public removeCarbonCopyInvisibleMail(carbonCopyInvisibleMail: string) {
    const index = this.blindCarbonCopiesRecipients.indexOf(carbonCopyInvisibleMail);

    if (index >= 0) {
      this.blindCarbonCopiesRecipients.splice(index, 1);
    }
    // Si il n' y plus de mail de copie carbone invisible
    if (this.blindCarbonCopiesRecipients.length === 0) {
      this.validateControl(this.blindCarbonCopyControl);
    }
  }

  public close() {
    this.dialogRef.close();
  }

  /**
   * Relance la validation du contrôle
   * @param control Le contrôle à valider
   */
  private validateControl(control: AbstractControl) {
    // Pour re-valider le contrôle, on le desactive
    control.disable();
    // Et on le réactive
    control.enable();
  }

  /**
   * Filtre les mails des destinataires en supprimant les mails non valide
   * @param mails Les mails des destinataires et si ils sont valide ou non
   */
  private filterMails() {
    let invalidMailCounter = 0;
    const emails = [...this.recipients];
    emails.forEach((email: string) => {
      if (!this.commonHelper.isValidEmailAddress(email)) {
        const index = this.recipients.indexOf(email);
        if (index >= 0) {
          this.recipients.splice(index, 1);
          this.recipientControl.setValue(this.recipients);
          invalidMailCounter++;
        }
      }
    });

    if (invalidMailCounter !== 0) {
      // On avertit l'utilisateur avec une snack-bar
      const errorMessage = `${invalidMailCounter} ${this.candidatesWithInvalidMailAddresses}`;
      this.snackbarService.showDanger(errorMessage);
    }
  }

  /**
   * Faire la validation pour les mails des destinataires (il faut au moins un mail destinataire)
   * @param recipientsControl Le champ des mails des destinataires à valider
   */
  private recipientControlValidator(recipientsControl: AbstractControl) {
    const recipients = recipientsControl.value as string[];
    if (recipients?.length === 0) {
      return {
        requireAtLeastOneRecipient: true
      };
    }

    return null;
  }

  public uploadedFilesFromPjComponent(files: Array<FileModel>) {
    this.uploadedFiles = files;
  }

  onStopSpeech(): void {
    this.onStartOrStopSpeech();
  }

  onStartSpeech(): void {
    this.onStartOrStopSpeech();
  }

  private onStartOrStopSpeech(): void {
    this.getMailBodyWithoutSignature();
  }

  public onSpeech(text: string): void {
    const content =
      (this.speechTempText.length > 0) ?
        `${this.speechTempText} ${text}` :
        text;
    const mailBody =
      (this.selectedSignature?.signature.length > 0) ?
        `${content} <p> ${this.mailSignatureSeparator} </p> ${this.selectedSignature.signature}` :
        content;
    this.sendMailForm.patchValue({
      mailBody
    });
  }

  // Obtient les traductions utilisés dans le composant
  private getTranslations() {
    this.translateService.get(
      [
        'forThePosition',
        'letterModel',
        'signature',
        'none',
        'actionType',
        'sender',
        'noValidMailAddressOfSenderFound',
        'candidatesWithInvalidMailAddresses',
        'addMailError',
        'sendMailSuccessfull',
        'error'
      ]
    ).subscribe(translations => {
      this.positionLabel = translations.forThePosition;
      this.labelLetterModel = translations.letterModel;
      this.labelSignature = translations.signature;
      this.emptyOptionLabel = translations.none;
      this.labelActionType = translations.actionType;
      this.labelSender = translations.sender;
      this.noValidMailAddressOfSenderFound = translations.noValidMailAddressOfSenderFound;
      this.candidatesWithInvalidMailAddresses = translations.candidatesWithInvalidMailAddresses;
      this.addMailError = translations.addMailError;
      this.sendMailSuccessfull = translations.sendMailSuccessfull;
      this.error = translations.error;
    });
  }
}

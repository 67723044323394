<div class="paginator">
  <div *ngIf="displayInput" ng-controller="isInteger">
    <form [formGroup]="paginationForm">
      <mat-form-field appearance="outline">
        <input matInput
               placeholder="Ex. 10"
               min="1"
               max="{{pagesCount}}"
               (change)="paginate()"
               type="number"
               formControlName="page">
      </mat-form-field>
      <span class="paginator-range mx-1">
        / {{pagesCount}}<span *ngIf="exceedMaxLength">+</span>
      </span>
    </form>
  </div>
  <mat-paginator [ngClass]="{'hide-paginator-range': hidePaginatorRange}"
                 hidePageSize
                 #paginator
                 showFirstLastButtons="showFirstLastButtons"
                 [length]="exceedMaxLength ? maxLength : length"
                 [pageSize]="pageSize"
                 (page)="onChangePage($event)">
  </mat-paginator>
</div>

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonServicesModule, IndividualModel } from 'common-services';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { AdvancedSearchResponseEffects } from './state/effects/advanced-search-response.effect';
import { AdvancedSearchEffects } from './state/effects/advanced-search.effect';
import { advancedSearchResponseReducer } from './state/reducers/advanced-search-response.reducer';
import { advancedSearchReducer } from './state/reducers/advanced-search.reducer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const individual: IndividualModel = JSON.parse(localStorage.getItem('individual'));
// Ajoutez vos modules ici
const modules = [
  // Modules du projet
  AppRoutingModule,
  CommonServicesModule.forRoot(environment),
  EffectsModule.forRoot([
    AdvancedSearchResponseEffects,
    AdvancedSearchEffects
  ]),
  PagesModule,
  HttpClientModule,
  TranslateModule.forRoot({
    defaultLanguage: individual?.languageBU?.code.split('-')[0] ?? 'fr',
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  StoreModule.forRoot({
    advancedSearchState: advancedSearchReducer,
    advancedSearchResponseState: advancedSearchResponseReducer
  })
];

// Module utilisable pour le débogage su store
const devModules = !environment.production ? [StoreDevtoolsModule.instrument({ maxAge: 25 })] : [];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ...modules,
    ...devModules
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

import { Component, Input, OnInit } from '@angular/core';
import { CriteriaGroup } from 'app/shared/enums/criteria-group.enum';
import { AdvancedSearchManager } from 'app/shared/managers/advanced-search.manager';
import { CriteriaGroupModel } from 'app/shared/models/advanced-search/criteria-group.model';
import { CriterionCategory } from 'common-services';

@Component({
  selector: 'app-criteria-group',
  templateUrl: './criteria-group.component.html',
  styleUrls: ['./criteria-group.component.scss']
})
export class CriteriaGroupComponent implements OnInit {

  @Input() public groupModel: CriteriaGroupModel;

  public criteriaGroup = CriteriaGroup;
  public groupHtmlId: string;
  public groupName: string;
  public criterionCategory = CriterionCategory;

  constructor(
    public advancedSearchManager: AdvancedSearchManager
  ) { }

  public ngOnInit() {
    this.groupHtmlId = `criteriaGroup_${this.groupModel.group}`;
    this.groupName = `collapseCriteriaGroup_${this.groupModel.group}`;
  }

  public get hasCriterionUsed(): boolean {
    return this.advancedSearchManager.advancedSearchResponseState?.advancedSearchResponse?.advancedSearch?.criteria?.
    find(criterion => this.groupModel.categories.indexOf(criterion.category) >= 0) !== undefined;
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { CandidateModel, CandidateService } from 'common-services';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})

export class ProfilePictureComponent implements OnChanges {

  @Input() candidateModel: CandidateModel;
  public readonly width = 115;
  public readonly height = 115;
  public image = '';

  constructor(
    private readonly candidateService: CandidateService
  ) { }

  ngOnChanges() {
    // Récuperer la photo de profil du candidat en base 64
    this.candidateService.getCandidateProfilePicture(this.candidateModel.id, this.width, this.height)
      .then(file => this.image = file?.fileData)
      .catch(() => this.image = null);
  }

  public get candidateName() {
    return `${this.candidateModel.lastName} ${this.candidateModel.firstName}`;
  }
}

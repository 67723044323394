<div [class]="'criterion ' + criterionPriority[criterion.priority]" [class.deprecated]="(isDeprecated)" id="{{ identifier }}_criterion">
  <mat-expansion-panel class="mainCollapsiblePanel" hideToggle="{{isDeprecated}}">
    <mat-expansion-panel-header title="{{criterionTitle}}" class="criterion-header d-flex align-items-center"
      [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
      <mat-panel-title id="criterion-title-{{identifier}}">
        <button class="criterion-delete mr-2" [ngClass]="{'fix-max-height': outrunHeight }" role="button" type="button"
          (click)="deleteCriterion()">×</button>
        <span class="criterion-icon material-icons">{{ icon }}</span>
        <span class="criterion-label">
          <span class="content">
            {{ criterion?.label }}
            <span class="semantic-counter">
              <span *ngIf="isCityCriterion">
                +
              </span>
              {{ semanticCounter }}
              <span *ngIf="isCityCriterion">
                {{ 'km' | translate }}
              </span>
            </span>
          </span>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="!isDeprecated" class="criterion-body">
      <div class="scroll-wrapper">
        <div class="scroll blue max-h-5">
          <div class="oneLineCriterion">
            <label>{{ 'required' | translate }}</label>
            <app-horizontal-switch id="{{ identifier }}_required_switch" class="horizontal-switch"
              [checked]="criterion.priority === criterionPriority.Required"
              (checkedChange)="setPriority(criterionPriority.Required, $event)">
            </app-horizontal-switch>
          </div>
          <div class="oneLineCriterion">
            <label>{{ 'excluded' | translate }}</label>
            <app-horizontal-switch id="{{ identifier }}_exluded_switch" class="minus-checkbox"
              [checked]="criterion.priority === criterionPriority.Excluded"
              (checkedChange)="setPriority(criterionPriority.Excluded, $event)">
            </app-horizontal-switch>
          </div>
          <div *ngFor="let semantic of criterion.semantics; trackBy: trackByFunction;index as i;"
            class="oneLineCriterion bloc-synonyms">
            <div *ngIf="( isCustomSynonym(semantic.category) && ( semanticCount > 0 ) );else elseBlock">
              <app-criterion-semantic-custom-synonym [customSynonymToRemove]="eventRemovedcustomSynonym.asObservable()"
                [customSynonymToAdd]="addCustomSynonymSubject.asObservable()" [identifier]="identifier"
                [criterionSemantic]="semantic" (removedSynonym)="removeSynonym($event)"
                (changeCustomSynonym)="onChangeCustomSynonym($event)" (addedSynonym)="addSynonym($event)">
              </app-criterion-semantic-custom-synonym>
            </div>
            <ng-template #elseBlock>
              <app-criterion-semantic [criterion]="criterion" [identifier]="identifier + '_' + i"
                [radius]="semanticCounter" (emptyRadius)="onEmptyRadius()"
                (changeCriterionSemantics)="onChangeCriterionSemantics($event)" [criterionSemantic]="semantic"
                [isExpanded]="true"></app-criterion-semantic>
            </ng-template>
          </div>
          <div *ngIf="canHaveButHasNoCustomSynonyms && ( semanticCount > 0 )"
            class="oneLineCriterion bloc-synonyms customS">
            <app-criterion-semantic-custom-synonym [customSynonymToRemove]="eventRemovedcustomSynonym.asObservable()"
              [customSynonymToAdd]="addCustomSynonymSubject.asObservable()" [identifier]="identifier"
              (changeCustomSynonym)="onChangeCustomSynonym($event)" (removedSynonym)="removeSynonym($event)"
              (addedSynonym)="addSynonym($event)"></app-criterion-semantic-custom-synonym>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
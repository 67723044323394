export enum LetterModelsType {
  APPLICANT = 'APPLICANT',
  CANDIDAT = 'CANDIDAT',
  CLIENT = 'CLIENT',
  CNIL = 'CNIL',
  DEMANDE_CREATION = 'DEMANDE_CREATION',
  DEMANDE_REFUS = 'DEMANDE_REFUS',
  DEMANDE_TRANSFERT = 'DEMANDE_TRANSFERT',
  DEMANDE_VALIDE = 'DEMANDE_VALIDE',
  FORGET_PWD = 'FORGET_PWD',
  PAGECLIENT = 'PAGECLIENT',
  RECRUITMENT_REQUEST = 'RECRUITMENT_REQUEST',
  UTILISATEUR = 'UTILISATEUR'
}
